import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-editor",
  templateUrl: "./marketing-portal-editor.component.html",
  styleUrls: ["./marketing-portal-editor.component.scss"],
})
export class MarketingPortalEditorComponent implements OnInit {
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Output() onCloseWindow: EventEmitter<any> = new EventEmitter();
  @Input() customerId: string;
  @Input() customerLogo: string;
  @Input() channelId: number;
  @Input() mediaAsset: MPMediaAsset;
  @Input() formatMediaAssets: any;
  @Input() resolutionId: number;
  @Input() googleDisplayMediaFormatId: number;
  @Input() isEdit: boolean;
  @Input() preSelectTemplateId: number;
  @Input() selectedResolutions: any[];
  public templates: any;
  public selectedTemplate: any;
  public isOpenMenu: boolean = false;
  public page: number = 0;
  public itemsPerPage: number = 100;
  public isInitLoading: boolean = true;
  public originalMediaAssetId: number = null;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    setTimeout(() => this.appLoader.open());

    const data = {};

    if (this.selectedResolutions) {
      data["resolutionIds"] = this.selectedResolutions.map(
        (resolution) => resolution.resolutionId
      );
    } else if (this.resolutionId) {
      data["resolutionIds"] = [this.resolutionId];
    }

    const request = this.crudService.getAllMPSetTemplates(
      this.channelId,
      this.page,
      this.itemsPerPage,
      this.customerId,
      data
    );

    await request
      .toPromise()
      .then((res: any) => {
        this.templates = this.getTemplates(res);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        setTimeout(() => this.appLoader.close());
        this.isInitLoading = false;
        return;
      });

    if (this.preSelectTemplateId) {
      this.selectedTemplate = this.getTemplateById(this.preSelectTemplateId);
      setTimeout(() => this.appLoader.close());
      this.isInitLoading = false;
      return;
    }

    if (this.mediaAsset) {
      this.originalMediaAssetId = this.mediaAsset.mediaAssetId;

      // Check if media asset is not part of a default template
      if (!this.isDefaultTemplate(this.mediaAsset.mediaAssetId)) {
        await this.crudService
          .getHistoricTemplateSetConfigNew(this.mediaAsset.mediaAssetId)
          .toPromise()
          .then((res) => {
            this.selectedTemplate = res;
            this.mediaAsset = null;

            setTimeout(() => this.appLoader.close());
            this.isInitLoading = false;
          })
          .catch((error) => {
            this.snackbarService.show(error, "danger");
            setTimeout(() => this.appLoader.close());
            this.isInitLoading = false;
          });
      } else {
        this.selectedTemplate = this.getDefaultSetTemplate(
          this.mediaAsset.mediaAssetId
        );
        this.mediaAsset = null;
        setTimeout(() => this.appLoader.close());
        this.isInitLoading = false;
      }
    } else {
      setTimeout(() => this.appLoader.close());
      this.isInitLoading = false;
    }
  }

  isDefaultTemplate(mediaAssetId: number): boolean {
    return this.isDefaultSetTemplate(mediaAssetId);
  }

  isDefaultSingleTemplate(imageId: number): boolean {
    const index = this.templates.findIndex(
      (template) => template.templateImageId === imageId
    );
    return index != -1;
  }

  isDefaultSetTemplate(imageId: number): boolean {
    let found: boolean = false;

    this.templates.forEach((template) => {
      template.singleTemplates.forEach((singleTemplate) => {
        if (singleTemplate.imageId === imageId) {
          found = true;
        }
      });
    });

    return found;
  }

  getTemplateById(templateId: number) {
    const index = this.templates.findIndex(
      (template) => template.templateSetId === templateId
    );
    return this.templates[index];
  }

  getDefaultSingleTemplate(imageId: number) {
    const index = this.templates.findIndex(
      (template) => template.templateImageId === imageId
    );
    return this.templates[index];
  }

  getDefaultSetTemplate(imageId: number) {
    let setTemplate = null;

    this.templates.forEach((template) => {
      template.singleTemplates.forEach((singleTemplate) => {
        if (singleTemplate.imageId === imageId) {
          setTemplate = template;
        }
      });
    });

    return setTemplate;
  }

  getTemplates(templates) {
    return templates["templateSets"];
  }

  toggleMenu() {
    this.isOpenMenu = !this.isOpenMenu;
  }

  onSelectTemplate(res) {
    this.selectedTemplate = res.template;
  }

  onDeselectTemplate() {
    this.selectedTemplate = null;
  }

  onClose() {
    this.onCloseWindow.emit();
  }
}
