import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "resolution-shape",
  templateUrl: "./resolution-shape.component.html",
  styleUrls: ["./resolution-shape.component.scss"],
})
export class ResolutionShapeComponent implements OnInit {
  @Input() resolution: string;
  @Input() size: number;
  @Input() bgColor: string;
  public height: number = null;
  public width: number = null;
  constructor() {}

  ngOnInit() {
    this.calcWidth();
  }

  calcWidth() {
    this.height = this.size;

    if (this.resolution === "L-Banner") {
      this.width = (this.height * 16) / 9;
      return;
    }

    const resolutionSplit = this.resolution.split(":");
    const width = parseInt(resolutionSplit[0]);
    const height = parseInt(resolutionSplit[1]);

    const ratio = width / height;

    this.width = this.height * ratio;
  }
}
