export class SMCampaignType {
  public campaignTypeId: number;
  public campaignTypeName: string;
  public campaignTypeLogo: string;

  constructor(
    campaignTypeId: number = null,
    campaignTypeName: string = null,
    campaignTypeLogo: string = null
  ) {
    this.campaignTypeId = campaignTypeId;
    this.campaignTypeName = campaignTypeName;
    this.campaignTypeLogo = campaignTypeLogo;
  }

  public deserialize(serializedObj: object): SMCampaignType {
    if (!serializedObj) {
      return null;
    }

    this.campaignTypeId = serializedObj["campaignTypeId"];
    this.campaignTypeName = serializedObj["campaignTypeName"];
    this.campaignTypeLogo = serializedObj["campaignTypeLogo"];

    return this;
  }
}
