import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { ModuleService } from "app/shared/services/module.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "ma-extended-configurator-channel",
  templateUrl: "./extended-configurator-channel.component.html",
  styleUrls: ["./extended-configurator-channel.component.scss"],
})
export class ExtendedConfiguratorChannelComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() configuratorHeaderHeight: number;
  @Input() selectedGoal: MPObjective;
  @Input() restoreChannelIds: string;
  public marketingPortalChannels: any = [];
  public recommendedChannels: any = [];
  public customerId: number;
  public customer: any;
  public mediaAsset: number;
  public channels: string;
  public channelIds: number[];
  readonly environment = environment;
  public contentHeight: number;

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private moduleService: ModuleService
  ) {}

  ngOnInit() {
    this.getMPChannels();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
  }

  async getMPChannels() {
    setTimeout(() => this.appLoader.open());

    await this.crudService
      .getAllMarketingPortallChannels()
      .toPromise()
      .then((res) => {
        let channels = this.filterChannels(res);
        this.marketingPortalChannels = channels;
        this.recommendedChannels = this.filterRecommendedChannels(channels);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    setTimeout(() => this.appLoader.close());
  }

  filterChannels(channels: MPChannel[]) {
    if (!this.restoreChannelIds) {
      return channels;
    }

    let channelIds: number[] = this.restoreChannelIds.split(",").map(Number);

    return channels.filter((channel: MPChannel) =>
      channelIds.includes(channel.channelId)
    );
  }

  filterRecommendedChannels(channels: MPChannel[]) {
    return channels.filter((channel: MPChannel) =>
      this.selectedGoal.channelIds.includes(channel.channelId)
    );
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;

    windowHeight -= this.configuratorHeaderHeight;

    const toolbar = document.querySelector(".mat-toolbar-single-row");

    if (toolbar) {
      windowHeight -= toolbar.clientHeight;
    }

    this.contentHeight = windowHeight;
  }
}
