import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-presales-item-expanded-dialog",
  templateUrl: "./presales-item-expanded-dialog.component.html",
  styleUrls: ["./presales-item-expanded-dialog.component.scss"],
})
export class PresalesItemExpandedDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PresalesItemExpandedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
