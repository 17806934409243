import { Component, Input, OnInit } from "@angular/core";
import { LayoutService } from "app/shared/services/layout.service";

@Component({
  selector: "marketing-portal-editor-sidebar",
  templateUrl: "./marketing-portal-editor-sidebar.component.html",
  styleUrls: ["./marketing-portal-editor-sidebar.component.scss"],
})
export class MarketingPortalEditorSidebarComponent implements OnInit {
  @Input() isOpenMenu: boolean = false;
  constructor(public layout: LayoutService) {}

  ngOnInit() {}
}
