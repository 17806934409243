import { Component, Input } from "@angular/core";

@Component({
  selector: "creative-image-template-card",
  templateUrl: "./creative-image-template-card.component.html",
  styleUrls: ["./creative-image-template-card.component.scss"],
})
export class CreativeImageTemplateCardComponent {
  @Input() template: any;
  @Input() width: number;
  public previewImageUrl: string;
  public showImageLoading: boolean = true;
  public theme: any;
  constructor() {}

  ngOnInit() {
    this.setPreviewImageUrl();
    this.setSkeletonTheme();
  }

  setPreviewImageUrl() {
    this.previewImageUrl = this.template.hasOwnProperty("singleTemplates")
      ? this.template.singleTemplates[0].imageUrlThumbnail
      : this.template.templateImageUrlThumbnail;
  }

  loadImage() {
    this.showImageLoading = false;
  }

  setSkeletonTheme() {
    this.theme = {
      width: this.width + "px",
      height: this.width + "px",
      "margin-bottom": 0,
    };
  }
}
