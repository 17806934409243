import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { PresalesItem } from "app/model/presales/PresalesItem";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";

@Component({
  selector: "ma-presales-presentation-slider",
  templateUrl: "./presales-presentation-slider.component.html",
  styleUrls: ["./presales-presentation-slider.component.scss"],
})
export class PresalesPresentationSliderComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective) psContainer: PerfectScrollbarDirective;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() presalesItems: PresalesItem[];
  @Input() customer: MPCustomer;
  public currentSlideIndex: number = 0;
  constructor() {}

  ngOnInit() {}

  next() {
    if (this.currentSlideIndex >= this.presalesItems.length - 1) {
      return;
    }

    this.currentSlideIndex++;
    this.psContainer.scrollToTop();
  }

  back() {
    if (this.currentSlideIndex === 0) {
      return;
    }

    this.currentSlideIndex--;
    this.psContainer.scrollToTop();
  }
}
