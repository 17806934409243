import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";

@Component({
  selector: "select-media-asset-format",
  templateUrl: "./select-media-asset-format.component.html",
  styleUrls: ["./select-media-asset-format.component.scss"],
})
export class SelectMediaAssetFormatComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() selected: string;
  @Input() templates: any;
  @Input() resolutionList: MPTemplateResolution[];
  public resolutions: string[] = [];

  constructor() {}

  ngOnInit() {
    this.filterResolutions();
  }

  filterResolutions() {
    this.resolutions = [];
    this.templates.forEach((template) => {
      if (!this.resolutions.includes(template.resolution)) {
        this.resolutions.push(template.resolution);
      }
    });
  }
}
