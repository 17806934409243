import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { GAMMediaAssetFormat } from "app/model/marketing-portal/GAMMediaAssetFormat";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { AddGamMediaAssetDialogComponent } from "app/shared/components/dialogs/add-gam-media-asset-dialog/add-gam-media-asset-dialog.component";
import { AddGamMediaAssetTextsDialogComponent } from "app/shared/components/dialogs/add-gam-media-asset-texts-dialog/add-gam-media-asset-texts-dialog.component";

@Component({
  selector: "gam-media-assets-campaign-form-section",
  templateUrl: "./gam-media-assets-campaign-form-section.component.html",
  styleUrls: ["./gam-media-assets-campaign-form-section.component.scss"],
})
export class GamMediaAssetsCampaignFormSectionComponent implements OnInit {
  @Input() destinationLinkControl: FormControl;
  @Input() protocolControl: FormControl;
  @Input() mediaFormatsControl: FormControl;
  @Input() mediaFormats: GAMMediaFormat[];
  @Input() selectedCustomer: MPCustomer;
  @Input() channelId: number;
  @Input() data: any;
  @Input() errors: any;
  @Input() readonly: boolean;
  @Input() selectedTemplateImages: TemplateBasedImage[];
  public selectedMediaFormat: GAMMediaFormat;
  public MEDIA_ASSETS_MAX: number = 5;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.data) {
      this.initData();
    } else if (this.selectedCustomer) {
      this.setCustomerURL();
    }

    if (this.selectedTemplateImages) {
      const gamMediaAssetsWithTextInput =
        this.createGamMediaAssetByTemplateImages(
          this.selectedTemplateImages,
          true
        );
      const gamMediaAssetsWithoutTextInput =
        this.createGamMediaAssetByTemplateImages(
          this.selectedTemplateImages,
          false
        );

      gamMediaAssetsWithoutTextInput.forEach((gamMediaAssetFormat) => {
        this.addNewGAMMediaAssetFormatValue(gamMediaAssetFormat);
      });

      if (gamMediaAssetsWithTextInput.length) {
        setTimeout(() => {
          const dialogSecondRef = this.dialog.open(
            AddGamMediaAssetTextsDialogComponent,
            {
              width: "680px",
              panelClass: "no-spacing",
              height: "auto",
              maxHeight: "100vh",
              disableClose: true,
              data: {
                gamMediaAssetFormats: gamMediaAssetsWithTextInput,
                customer: this.selectedCustomer,
                mediaFormats: this.mediaFormats,
                hideClose: true,
              },
            }
          );

          dialogSecondRef
            .afterClosed()
            .subscribe((gamMediaAssetFormats: GAMMediaAssetFormat[]) => {
              if (gamMediaAssetFormats) {
                this.addNewGAMMediaAssetFormatValues(gamMediaAssetFormats);
              }
            });
        }, 0);
      }
    }
  }

  initData() {
    this.addNewGAMMediaAssetFormatValues(this.data.mediaAssets);
    this.setURL();
  }

  setCustomerURL() {
    let value = this.selectedCustomer.customerHomepage.replace(
      /^https?:\/\//,
      ""
    );

    while (/^https?:\/\//.test(value)) {
      value = value.replace(/^https?:\/\//, "");
    }

    this.destinationLinkControl.setValue(value);
  }

  setURL() {
    let value = this.data.gamDestinationLink.replace(/^https?:\/\//, "");

    while (/^https?:\/\//.test(value)) {
      value = value.replace(/^https?:\/\//, "");
    }

    this.destinationLinkControl.setValue(value);
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");

    this.destinationLinkControl.setValue(value);
  }

  createGamMediaAssetByTemplateImages(
    templateBasedImages: TemplateBasedImage[],
    hasTextInput: boolean
  ) {
    const gamMediaAssetFormats: GAMMediaAssetFormat[] = [];

    templateBasedImages.forEach((templateBasedImage) => {
      const newGAMMediaAssetFormat = {
        mediaAssetId: templateBasedImage.imageId,
        mediaFormatId: templateBasedImage.formatId,
        headline: null,
        body: null,
      };

      if (
        (hasTextInput && this.hasTextInputs(newGAMMediaAssetFormat)) ||
        (!hasTextInput && !this.hasTextInputs(newGAMMediaAssetFormat))
      ) {
        gamMediaAssetFormats.push(newGAMMediaAssetFormat);
      }
    });

    return gamMediaAssetFormats;
  }

  onDeleteMediaAsset(gamMediaAssetFormat: GAMMediaAssetFormat, index: number) {
    let value: number[] = this.mediaFormatsControl.value || [];

    value.splice(index, 1);
    this.mediaFormatsControl.setValue(value);
  }

  onEditMediaAsset(texts: any, index: number) {
    let value: number[] = this.mediaFormatsControl.value || [];

    if (texts.hasOwnProperty("headline")) {
      value[index]["headline"] = texts.headline;
    }

    if (texts.hasOwnProperty("body")) {
      value[index]["body"] = texts.body;
    }

    this.mediaFormatsControl.setValue(value);
  }

  onChangeMediaAsset(mediaAssetId: number, index: number) {
    let value: number[] = this.mediaFormatsControl.value || [];

    value[index]["mediaAssetId"] = mediaAssetId;

    this.mediaFormatsControl.setValue(value);
  }

  addMediaAsset() {
    let dialogRef = this.dialog.open(AddGamMediaAssetDialogComponent, {
      width: "960px",
      panelClass: "no-spacing",
      height: "auto",
      maxHeight: "100vh",
      disableClose: true,
      data: {
        mediaFormats: this.mediaFormats,
        channelId: this.channelId,
        selectedCustomer: this.selectedCustomer,
        gamMediaAssetFormats: this.mediaFormatsControl.value,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((gamMediaAssetFormat: GAMMediaAssetFormat) => {
        if (gamMediaAssetFormat) {
          // Check if media asset format has any text values
          if (!this.hasTextInputs(gamMediaAssetFormat)) {
            this.addNewGAMMediaAssetFormatValue(gamMediaAssetFormat);
            return;
          }

          const dialogSecondRef = this.dialog.open(
            AddGamMediaAssetTextsDialogComponent,
            {
              width: "680px",
              panelClass: "no-spacing",
              height: "auto",
              maxHeight: "100vh",
              disableClose: true,
              data: {
                gamMediaAssetFormats: [gamMediaAssetFormat],
                customer: this.selectedCustomer,
                mediaFormats: this.mediaFormats,
              },
            }
          );

          dialogSecondRef
            .afterClosed()
            .subscribe((gamMediaAssetFormats: GAMMediaAssetFormat[]) => {
              if (gamMediaAssetFormats) {
                this.addNewGAMMediaAssetFormatValue(gamMediaAssetFormats[0]);
              }
            });
        }
      });
  }

  hasTextInputs(gamMediaAssetFormat: GAMMediaAssetFormat): boolean {
    const mediaFormat: GAMMediaFormat = this.mediaFormats.find(
      (item: GAMMediaFormat) => item.id === gamMediaAssetFormat.mediaFormatId
    );

    return mediaFormat.bodyLength > 0 || mediaFormat.headlineLength > 0;
  }

  addNewGAMMediaAssetFormatValues(gamMediaAssetFormats: GAMMediaAssetFormat[]) {
    gamMediaAssetFormats.forEach((gamMediaAssetFormat) => {
      this.addNewGAMMediaAssetFormatValue(gamMediaAssetFormat);
    });
  }

  addNewGAMMediaAssetFormatValue(gamMediaAssetFormat: GAMMediaAssetFormat) {
    let value = this.mediaFormatsControl.value;
    value.push(gamMediaAssetFormat);

    this.mediaFormatsControl.setValue(value);
  }
}
