import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-image-editor-template-details-dialog",
  templateUrl: "./image-editor-template-details-dialog.component.html",
  styleUrls: ["./image-editor-template-details-dialog.component.scss"],
})
export class ImageEditorTemplateDetailsDialogComponent implements OnInit {
  public setIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ImageEditorTemplateDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  next() {
    if (this.setIndex >= this.data.template.singleTemplates.length - 1) {
      this.setIndex = 0;
      return;
    }

    this.setIndex++;
  }

  back() {
    if (this.setIndex === 0) {
      this.setIndex = this.data.template.singleTemplates.length - 1;
      return;
    }

    this.setIndex--;
  }

  onClose() {
    this.dialogRef.close();
  }
}
