import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorService {
  constructor() {}

  getPrimaryColor(): string {
    let elm: any = document.querySelector(".pace-progress");

    if (!elm) {
      return "#fff";
    }

    return window.getComputedStyle(elm).background;
  }

  RGBToRGBA(color, opacity): string {
    return color.replace("rgb", "rgba").split(")")[0] + `, ${opacity})`;
  }
}
