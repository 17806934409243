import { MPImageMetaData } from "app/model/marketing-portal/MPImageMetaData";

export class ImageService {
  public image: any;
  public metaData: MPImageMetaData;
  constructor(image) {
    this.image = image;
  }

  public isTransparent() {
    return new Promise((resolve, reject) => {
      let hasAlpha = false;
      const img = new Image();
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      img.crossOrigin = "Anonymous";
      img.onerror = reject;
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);
        const imgData = ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        ).data;

        for (let j = 0; j < imgData.length; j += 4) {
          if (imgData[j + 3] < 255) {
            hasAlpha = true;
            break;
          }
        }
        resolve(hasAlpha);
      };
      img.src = URL.createObjectURL(this.image);
    });
  }

  public getSize() {
    return new Promise(async (resolve, reject) => {
      await this.getMetaData();
      resolve({ height: this.metaData.height, width: this.metaData.width });
    });
  }

  public getMetaData(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.metaData) {
        resolve(true);
      }

      var fileReader = new FileReader();
      const self = this;
      fileReader.onload = function () {
        // file is loaded
        var img = new Image();

        img.onload = function () {
          resolve({ height: img.height, width: img.width });
          self.setMetaData(img.height, img.width);
          resolve(true);
        };
        img.src = fileReader.result.toString();
      };

      fileReader.readAsDataURL(this.image);
    });
  }

  public setMetaData(height: number, width: number) {
    this.metaData = {
      height,
      width,
    };
  }
}
