import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CreateApprovalElementCommentFormComponent } from "../../forms/campaign/create-approval-element-comment-form/create-approval-element-comment-form.component";

@Component({
  selector: "approval-element-comment-dialog",
  templateUrl: "./approval-element-comment-dialog.component.html",
  styleUrls: ["./approval-element-comment-dialog.component.scss"],
})
export class ApprovalElementCommentDialogComponent implements OnInit {
  @ViewChild(CreateApprovalElementCommentFormComponent)
  createApprovalElementCommentFormComponent: CreateApprovalElementCommentFormComponent;
  public isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ApprovalElementCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  submitted(value) {
    this.dialogRef.close({ value: value });
  }

  onRemove() {
    this.dialogRef.close({ value: null });
  }

  onApply() {
    this.createApprovalElementCommentFormComponent.onSubmit();
  }
}
