import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-add-region-dialog",
  templateUrl: "./add-region-dialog.component.html",
  styleUrls: ["./add-region-dialog.component.scss"],
})
export class AddRegionDialogComponent implements OnInit {
  @ViewChild("pacInput") input: ElementRef;
  public selectedPlace: any;
  public searchString: String = "";
  public selectedLocationId: number;
  public onlyRegions: boolean = false;
  public inputPlaceholder: string =
    "Bitte gib einen Ort ein oder wähle einen Standort aus";

  constructor(
    public dialogRef: MatDialogRef<AddRegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const input = document.getElementById("search-form") as HTMLInputElement;

    this.onlyRegions = this.data && this.data.onlyRegions ? true : false;
    if (this.onlyRegions) this.inputPlaceholder = "Bitte gib eine PLZ ein";

    let types = this.onlyRegions ? ["postal_code"] : ["geocode"];
    let fields = this.onlyRegions
      ? null
      : [
          "adr_address",
          "address_component",
          "formatted_address",
          "geometry",
          "name",
        ];

    const options = {
      componentRestrictions: { country: "de" },
      fields: fields,
      strictBounds: false,
      types: types,
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      this.selectedPlace = null;
      this.selectGooglePlace(autocomplete.getPlace());
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  removeSelectedPlace() {
    this.selectedPlace = null;
    this.selectedLocationId = null;
  }

  add() {
    this.dialogRef.close({ selectedRegion: this.selectedPlace });
  }

  selectGooglePlace(place) {
    const regionAddress = {
      longitude: null,
      latitude: null,
      zip_code: null,
      address: null,
      city: null,
      state: null,
      country: null,
    };

    const zipcode = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("postal_code")
    );

    if (zipcode) {
      regionAddress.zip_code = zipcode.short_name;
    }

    const country = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("country")
    );

    if (country) {
      regionAddress.country = country.short_name;
    }

    const state = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("administrative_area_level_1")
    );

    if (state) {
      regionAddress.state = state.short_name;
    }

    const city = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("locality")
    );

    if (city) {
      regionAddress.city = city.short_name;
    }

    const address = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("route")
    );
    const streetNumber = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("street_number")
    );

    if (address) {
      regionAddress.address = address.short_name;

      var curAddress = address.long_name;
      if (streetNumber) curAddress = curAddress + " " + streetNumber.long_name;

      regionAddress.address = curAddress;
    }

    regionAddress.latitude = place.geometry.location.lat();
    regionAddress.longitude = place.geometry.location.lng();

    this.selectPlace(regionAddress);
  }

  selectPlace(address) {
    this.input.nativeElement.value = "";
    this.selectedLocationId = null;
    this.selectedPlace = address;
    // setTimeout(() => (this.selectedPlace = address));
  }

  selectLocation(location) {
    this.input.nativeElement.value = "";
    if (this.selectedLocationId === location.customerLocationId) {
      this.selectedPlace = null;
      this.selectedLocationId = null;
    } else {
      this.selectedPlace = location.locationAddress;
      this.selectedLocationId = location.customerLocationId;
    }
  }
}
