import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "approval-history-collapse-list",
  templateUrl: "./approval-history-collapse-list.component.html",
  styleUrls: ["./approval-history-collapse-list.component.scss"],
})
export class ApprovalHistoryCollapseListComponent implements OnInit {
  @Input() approvalHistory: any;

  constructor() {}

  ngOnInit() {}
}
