import { MPMediaAsset } from "./MPMediaAsset";

export class MPMediaAssetList {
  public mediaAssets: MPMediaAsset[];

  constructor() {}

  public deserializeList(serializedObj: MPMediaAsset[]): MPMediaAsset[] {
    return serializedObj.map((x: MPMediaAsset) =>
      new MPMediaAsset().deserialize(x)
    );
  }
}
