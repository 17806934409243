import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "select-resolution",
  templateUrl: "./select-resolution.component.html",
  styleUrls: ["./select-resolution.component.scss"],
})
export class SelectResolutionComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() resolutions: string[];
  @Input() selectedResolution: string;
  constructor() {}

  ngOnInit() {}
}
