import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ExternalSourceMedia } from "app/model/ExternalSourceMedia";
import { dataURLtoFile } from "app/shared/utils/blob.utils";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-unsplash-image-search-dialog",
  templateUrl: "./unsplash-image-search-dialog.component.html",
  styleUrls: ["./unsplash-image-search-dialog.component.scss"],
})
export class UnsplashImageSearchDialogComponent implements OnInit {
  public selectedImage: any = null;
  public page: number = 1;
  public itemsPerPage: number = 24;
  public count: number = 0;
  public totalPage: number = 1;
  public imageList: any;
  public isLoading: boolean = false;
  public searchString: string = "";
  public scrollLoading: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<UnsplashImageSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  getBase64FromImage(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };

      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  }

  onInputChange($event) {
    this.searchString = $event.target.value;
  }

  onSearch() {
    this.page = 1;
    this.scrollLoading = true;
    this.getImages();
  }

  selectImage(image) {
    this.selectedImage = image;
  }

  clearSearch() {
    this.searchString = "";
  }

  onScroll($event) {
    if (!this.scrollLoading || this.isLoading) {
      return;
    }

    if (
      $event.target.scrollTop ===
      $event.target.scrollHeight - $event.target.offsetHeight
    ) {
      this.page++;
      this.getImages(true);
    }
  }

  async getImages(concat = false) {
    this.isLoading = true;

    await this.crudService
      .getUnsplashImages(this.searchString, this.page, this.itemsPerPage)
      .toPromise()
      .then((res) => {
        if (!res) {
          this.scrollLoading = false;
          return;
        }
        this.imageList = concat ? this.imageList.concat(res) : res;
      })
      .catch((e) => {
        // this.imageList = [];
      });

    this.isLoading = false;
  }

  buildCreditsHTML(credits) {
    return (
      '<span >Photo by <a target="_blank" href="' +
      credits.ImageOwnerPageLink +
      '?utm_source=mapAds&utm_medium=referral">' +
      credits.ImageOwnerName +
      "</a> on Unsplash</span>"
    );
  }

  onApply() {
    this.isLoading = true;
    this.getBase64FromImage(this.selectedImage.FullSizeUrl).then((result) => {
      const file = dataURLtoFile(
        result,
        this.selectedImage.ExternalId + ".jpg"
      );
      this.isLoading = false;
      const creditsHTML = this.buildCreditsHTML(this.selectedImage.Credits);

      this.dialogRef.close({
        file,
        originalSource: this.selectedImage.FullSizeUrl,
        credits: creditsHTML,
        externalSource: ExternalSourceMedia.EXTERNAL_SOURCE_MEDIA_UNSPLASH,
      });

      this.crudService
        .triggerUnsplashDownloadEndpoint(
          this.selectedImage.DownloadEndpointTrigger
        )
        .subscribe((res) => {});
    });
  }
}
