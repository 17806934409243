import { Component, Input, OnInit } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";

@Component({
  selector: "campaign-creative-preview",
  templateUrl: "./campaign-creative-preview.component.html",
  styleUrls: ["./campaign-creative-preview.component.scss"],
})
export class CampaignCreativePreviewComponent implements OnInit {
  @Input() mediaAssetUrl: string;
  @Input() isImage: boolean;
  @Input() customer: MPCustomer;
  @Input() channelId: number;
  public MP_CHANNEL = MPChannel;
  constructor() {}

  ngOnInit() {}
}
