import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotificationSettings } from "app/model/settings/NotificationSettings";

@Component({
  selector: "notification-settings-form",
  templateUrl: "./notification-settings-form.component.html",
  styleUrls: ["./notification-settings-form.component.scss"],
})
export class NotificationSettingsFormComponent implements OnInit {
  @ViewChild("form") form;
  @Output() isSubmitted: EventEmitter<any> = new EventEmitter();
  @Input() notificationSettings: NotificationSettings[];
  @Input() isChanged: boolean;
  public itemForm: FormGroup;
  constructor() {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.itemForm = new FormGroup({});

    this.notificationSettings.forEach((notificationSetting) => {
      this.itemForm.addControl(
        notificationSetting.NotificationTypeName,
        new FormControl(
          notificationSetting.NotificationChannels.Email,
          Validators.required
        )
      );
    });
  }

  onChange($event) {
    this.isChanged = true;
  }

  toggleNotificationSettings(name: string) {}

  updateNotificationSettings() {
    this.notificationSettings.forEach((notificationSetting, index) => {
      this.notificationSettings[index].NotificationChannels.Email =
        this.itemForm.get(notificationSetting.NotificationTypeName).value;
    });
  }

  onSubmit() {
    this.updateNotificationSettings();
    this.isChanged = false;
    this.isSubmitted.emit();
  }
}
