import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { CrudService } from "app/views/mapAds/crud.service";
import { AddRegionDialogComponent } from "../../dialogs/add-region-dialog/add-region-dialog.component";
import * as uuid from "uuid";
import { SnackbarService } from "app/shared/services/snackbar.service";

@Component({
  selector: "campaign-regions",
  templateUrl: "./campaign-regions.component.html",
  styleUrls: ["./campaign-regions.component.scss"],
})
export class CampaignRegionsComponent implements OnInit, OnChanges {
  @Input() regions: any[];
  @Input() locations: any[] = [];
  @Input() isOpen: boolean;
  @Input() isLocked?: boolean = false;
  public stores: any;
  public parentStoreId;
  public isLoadingParentStore: boolean = true;
  public defaultRadius: any;
  public radiusOptions;
  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {
    this.parentStoreId = window.localStorage["storeId"];
  }

  async ngOnChanges(changes: SimpleChanges) {
    //Read radius once
    if (!this.defaultRadius) {
      this.radiusOptions = await this.crudService.getMPRadius().toPromise();
      this.defaultRadius = this.radiusOptions[1];
      this.isLoadingParentStore = false;
    }

    if (!this.isLoadingParentStore) {
      if (changes && changes.locations) {
        this.locations = changes.locations.currentValue;
        if (this.locations.length) {
          this.locations.forEach((location) => {
            let objRadius = location.locationRadius || location.radius;
            const radiusOption =
              this.getRadiusByValue(objRadius) || this.defaultRadius;
            this.regions.push({
              id: uuid.v4(),
              address: location.locationAddress,
              radius: radiusOption,
              url: location.locationUrl || location.url,
              name: location.locationName || location.name,
            });
          });
        }
      }
    }
  }

  async ngOnInit() {}

  getRadiusByValue(value) {
    return this.radiusOptions.find(
      (radiusOption) => radiusOption.radiusValue === value
    );
  }

  openAddRegionDialog() {
    const dialogRef = this.dialog.open(AddRegionDialogComponent, {
      width: "800px",
      panelClass: "no-spacing",
      height: "auto",
      maxHeight: "100vh",
      data: {
        locations: this.locations,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.regions.push({
          id: uuid.v4(),
          address: res.selectedRegion,
          radius: this.defaultRadius,
          url: res.locationUrl || res.url,
          name: res.locationName || res.name,
        });
      }
    });
  }

  removeRegion(regionId: number) {
    const index = this.regions.findIndex((region) => region.id === regionId);
    if (index > -1) {
      this.regions.splice(index, 1);
    }
  }
}
