import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "lotte-animation-icon",
  templateUrl: "./lotte-animation-icon.component.html",
  styleUrls: ["./lotte-animation-icon.component.scss"],
})
export class LotteAnimationIconComponent implements OnInit {
  @Input() name: string;
  @Input() play: boolean;
  @Input() width?: number = 300;
  @Input() height?: number = 300;
  public lottieConfig: Object;
  private anim: any;

  constructor() {}

  ngOnInit(): void {
    this.lottieConfig = {
      path: `assets/lotte/${this.name}.json`,
      renderer: "canvas",
      autoplay: this.play,
      loop: true,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("play") && !changes.play.firstChange) {
      if (changes.play.currentValue) {
        this.anim.play();
        return;
      }
      this.anim.stop();
    }
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }
}
