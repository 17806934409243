import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "app/shared/services/image.service";
import { VideoService } from "app/shared/services/video.service";
import {
  MAX_UPLOAD_VIDEO_SIZE,
  MAX_UPLOAD_IMAGE_SIZE,
} from "../../../constants";
import { MPMediaUploadFormatConfiguration } from "./MPMediaUploadFormatConfiguration";

export class MPMediaUploadFormatValidator {
  public mpMediaUploadFormatConfiguration: MPMediaUploadFormatConfiguration;
  public file: File;
  public translate: TranslateService;
  public isImage: boolean;
  public isError: boolean = false;
  public error: string;
  public isWarning: boolean = false;
  public warning: string;
  public videoService: VideoService;
  public imageService: ImageService;

  constructor(
    mpMediaUploadFormatConfiguration: MPMediaUploadFormatConfiguration,
    file: File,
    translate: TranslateService,
    isImage: boolean = true
  ) {
    this.mpMediaUploadFormatConfiguration = mpMediaUploadFormatConfiguration;
    this.file = file;
    this.translate = translate;
    this.isImage = isImage;

    this.videoService = new VideoService(this.file);
    this.imageService = new ImageService(this.file);
  }

  public validate(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (
        !this.validateFileSize() ||
        !(await this.validateMediaSize()) ||
        !(await this.validateDuration()) ||
        !(await this.validateResolution())
      ) {
        resolve(false);
        return;
      }

      resolve(true);
    });
  }

  public validateFileSize(): boolean {
    const fileSize = Number((this.file.size / 1024 / 1024).toFixed(4));
    const maxUploadSize = this.getMaxUploadSize();
    if (fileSize > maxUploadSize) {
      this.error = this.translate.instant("ImageUploadMaxSizeError", {
        maxUploadSize: maxUploadSize,
      });
      this.isError = true;
      return false;
    }

    return true;
  }

  public validateMediaSize(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { maxHeight, maxWidth, minWidth, minHeight, forceSize } =
        this.mpMediaUploadFormatConfiguration;

      if (!forceSize) {
        resolve(true);
        return;
      }

      const { height, width } = await this.getMediaSize();

      if (maxHeight && maxWidth && (height > maxHeight || width > maxWidth)) {
        this.error = `Die Datei darf maximal eine Auflösung von ${maxWidth}x${maxHeight}px haben.`;
        this.isError = true;
        resolve(false);
        return;
      }

      if (minHeight && minWidth && (height < minHeight || width < minWidth)) {
        this.error = `Die Datei muss mindestens eine Auflösung von ${minWidth}x${minHeight}px haben.`;
        this.isError = true;
        resolve(false);
        return;
      }

      resolve(true);
    });
  }

  public validateDuration(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this.isImage) {
        resolve(true);
        return;
      }

      const duration = await this.videoService.getDuration();

      const { minDuration, maxDuration, minDurationWarning } =
        this.mpMediaUploadFormatConfiguration;

      if (minDuration && duration < minDuration) {
        this.error = `Das Video muss eine minimale Länge von ${minDuration} Sekunden haben.`;
        this.isError = true;
        resolve(false);
        return;
      }

      if (maxDuration && duration > maxDuration) {
        this.error = `Das Video darf die maximale Länge von ${maxDuration} Sekunden nicht überschreiten.`;
        this.isError = true;
        resolve(false);
        return;
      }

      if (minDurationWarning && duration > minDurationWarning) {
        this.warning = `Wir empfehlen eine maximale Länge von ${minDurationWarning} Sekunden.`;
        this.isWarning = true;
      }

      resolve(true);
    });
  }

  public validateResolution(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const format = this.mpMediaUploadFormatConfiguration.format;

      if (!format) {
        resolve(true);
        return;
      }

      const formats = format.split(",");
      const { height, width } = await this.getMediaSize();

      const aspect = this.calcAspect(width, height);

      if (!formats.includes(aspect)) {
        this.warning = `Wir empfehlen eines der folgenden Formate: ${format}.`;
        this.isWarning = true;
      }

      resolve(true);
    });
  }

  public calcAspect(width: number, height: number): string {
    const r = this.gcd(width, height);

    return width / r + ":" + height / r;
  }

  public gcd(a: number, b: number): number {
    return b == 0 ? a : this.gcd(b, a % b);
  }

  public getMediaSize(): Promise<any> {
    return this.isImage ? this.getImageMediaSize() : this.getVideoMediaSize();
  }

  public getVideoMediaSize(): Promise<any> {
    return this.videoService.getSize();
  }

  public getImageMediaSize(): Promise<any> {
    return this.imageService.getSize();
  }

  public getMaxUploadSize() {
    return this.isImage ? MAX_UPLOAD_IMAGE_SIZE : MAX_UPLOAD_VIDEO_SIZE;
  }

  public getError(): string {
    return this.error;
  }

  public hasErrors(): boolean {
    return this.isError;
  }

  public getWarning(): string {
    return this.warning;
  }

  public hasWarnings(): boolean {
    return this.isWarning;
  }
}
