import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPLocation } from "app/model/marketing-portal/MPLocation";

@Component({
  selector: "campaign-zipcode-location-item",
  templateUrl: "./campaign-zipcode-location-item.component.html",
  styleUrls: ["./campaign-zipcode-location-item.component.scss"],
})
export class CampaignZipcodeLocationItemComponent implements OnInit {
  @Input() location: MPLocation;
  @Input() index: number;
  @Output() removeRegion: EventEmitter<any> = new EventEmitter();
  public selectedRadius: any;
  public selectedRadiusId: number;
  constructor() {}

  ngOnInit() {}

  remove() {
    this.removeRegion.emit(this.index);
  }
}
