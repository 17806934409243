import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "add-text-form",
  templateUrl: "./add-text-form.component.html",
  styleUrls: ["./add-text-form.component.scss"],
})
export class AddTextFormComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() label: string;
  @Input() maxLength: number;
  public form: FormGroup;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      text: ["", Validators.required],
    });
  }
}
