import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "media-assets-libary-ad-materials",
  templateUrl: "./media-assets-libary-ad-materials.component.html",
  styleUrls: ["./media-assets-libary-ad-materials.component.scss"],
})
export class MediaAssetsLibaryAdMaterialsComponent implements OnInit {
  @Output() selectImage: EventEmitter<any> = new EventEmitter();
  @Input() selectedImage: MPLibaryMediaAsset;
  public libaryMediaAssetList: MPLibaryMediaAsset[];
  public isLoading: boolean;
  public page: number = 0;
  public itemsPerPage: number = 6;
  public count: number = 0;
  public totalPages: number = 1;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getImages();
  }

  onPage(page: number) {
    this.page = page;
    this.getImages();
  }

  async getImages() {
    this.isLoading = true;

    await this.crudService
      .GetMyMediaAssets(
        MPLibaryMediaAsset.LIBARY_MEDIA_ASSET_FILTER_AD_MATERIAL,
        this.page,
        this.itemsPerPage,
        null,
        null,
        null,
        null
      )
      .toPromise()
      .then((res) => {
        if (!res) {
          return;
        }
        this.libaryMediaAssetList = res.MpImages;
        this.count = res.count;
        this.totalPages = Math.ceil(this.count / this.itemsPerPage);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }
}
