import { Component, OnInit } from "@angular/core";

@Component({
  selector: "marketing-portal-video-editor-slide-sidebar",
  templateUrl: "./marketing-portal-video-editor-slide-sidebar.component.html",
  styleUrls: ["./marketing-portal-video-editor-slide-sidebar.component.scss"],
})
export class MarketingPortalVideoEditorSlideSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
