import { Component, HostBinding, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { PresalesItem } from "app/model/presales/PresalesItem";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-presales-presentation-dialog",
  templateUrl: "./presales-presentation-dialog.component.html",
})
export class PresalesPresentationDialogComponent implements OnInit {
  public start: boolean = false;
  public customer: MPCustomer = null;
  public presalesItems: PresalesItem[];

  constructor(
    public dialogRef: MatDialogRef<PresalesPresentationDialogComponent>,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.init();
  }

  async init() {
    const { customer, presalesItems } = this.data;

    if (customer) {
      this.customer = customer;
      this.presalesItems = presalesItems;

      return;
    }
  }

  selectCustomer($event) {
    this.customer = $event;
    this.getCreatives();
  }

  async getCreatives() {
    this.appLoader.open();

    await this.crudService
      .getPresalesItems(this.customer.customerId)
      .toPromise()
      .then((res) => {
        this.presalesItems = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  startPresention() {
    this.start = true;
  }

  goToCustomerSelection() {
    this.customer = null;
    this.presalesItems = null;
  }

  onClose() {
    this.dialogRef.close();
  }
}
