import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { environment } from "environments/environment";

@Component({
  selector: "campaign-gdn-ads-preview",
  templateUrl: "./campaign-gdn-ads-preview.component.html",
  styleUrls: ["./campaign-gdn-ads-preview.component.scss"],
})
export class CampaignGdnAdsPreviewComponent implements OnInit {
  @Input() imageInfo?: any;
  @Input() googleFormatMediaAssets?: any;
  @Input() description: string;
  @Input() headline: string;
  @Input() businessName: string;
  @Input() callToActionText: string = "Mehr";
  public logoImageUrl: string;
  public landscaleImageUrl: string;
  public environment = environment;
  readonly GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO = 2;
  readonly GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LANDSCAPE = 3;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("googleFormatMediaAssets") ||
      changes.hasOwnProperty("imageInfo")
    ) {
      if (changes.hasOwnProperty("googleFormatMediaAssets")) {
        this.setGoogleFormatMediaAssetUrls(
          changes.googleFormatMediaAssets.currentValue
        );
      } else {
        this.setImageUrls();
      }
    }
  }

  setGoogleFormatMediaAssetUrls(googleFormatMediaAssets) {
    if (!Object.keys(googleFormatMediaAssets).length) {
      return;
    }

    const logoMediaAssets =
      googleFormatMediaAssets[this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO]
        .mediaAssets;

    if (logoMediaAssets.length) {
      const logoMediaAsset: MPMediaAsset = logoMediaAssets[0];

      this.logoImageUrl = logoMediaAsset.mediaAssetId
        ? logoMediaAsset.mediaAssetUrl
        : logoMediaAsset.mediaAssetData.toString();
    } else {
      this.logoImageUrl = null;
    }

    const landscaleMediaAssets =
      googleFormatMediaAssets[this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LANDSCAPE]
        .mediaAssets;

    if (landscaleMediaAssets.length) {
      const landscaleMediaAsset: MPMediaAsset = landscaleMediaAssets[0];

      this.landscaleImageUrl = landscaleMediaAsset.mediaAssetId
        ? landscaleMediaAsset.mediaAssetUrl
        : landscaleMediaAsset.mediaAssetData.toString();
    } else {
      this.landscaleImageUrl = null;
    }
  }

  setImageUrls() {
    let logoImageIndex = this.imageInfo.findIndex(
      (image) =>
        image.googleDisplayMediaFormatId ===
        this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO
    );

    this.logoImageUrl =
      logoImageIndex != -1
        ? this.buildImageUrl(this.imageInfo[logoImageIndex].mediaAssetId)
        : null;

    let landscaleImageIndex = this.imageInfo.findIndex(
      (image) =>
        image.googleDisplayMediaFormatId ===
        this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LANDSCAPE
    );

    this.landscaleImageUrl =
      landscaleImageIndex != -1
        ? this.buildImageUrl(this.imageInfo[landscaleImageIndex].mediaAssetId)
        : null;
  }

  buildImageUrl(mediaAssetId) {
    return (
      environment.imageServerUrl + "api/Public/GetMediaAsset?id=" + mediaAssetId
    );
  }
}
