import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-editor-global-toolset",
  templateUrl: "./marketing-portal-editor-global-toolset.component.html",
  styleUrls: ["./marketing-portal-editor-global-toolset.component.scss"],
})
export class MarketingPortalEditorGlobalToolsetComponent implements OnInit {
  @Output() onChangeGlobalColor: EventEmitter<any> = new EventEmitter();
  @Input() globalColor: string;
  public isOpen: boolean = true;
  constructor() {}

  ngOnInit() {}

  toggle() {
    this.isOpen = !this.isOpen;
  }

  isValidHexCode(value) {
    const reg = /^#([0-9a-f]{3}){1,2}$/i;
    return reg.test(value);
  }

  onChangeHexColor($event) {
    let value = $event.target.value;

    if (value.charAt(0) != "#") {
      value = "#" + value;
    }

    let isValid = this.isValidHexCode(value);

    if (isValid) {
      this.globalColor = value;
      this.onChangeGlobalColor.emit($event);
    }
  }
}
