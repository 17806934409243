import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { VideoEditorTemplateDetailsDialogComponent } from "../../dialogs/video-editor-template-details-dialog/video-editor-template-details-dialog.component";
import { ImageEditorTemplateDetailsDialogComponent } from "../../dialogs/image-editor-template-details-dialog/image-editor-template-details-dialog.component";

@Component({
  selector: "creative-template-card",
  templateUrl: "./creative-template-card.component.html",
  styleUrls: ["./creative-template-card.component.scss"],
})
export class CreativeTemplateCardComponent implements OnInit {
  @ViewChild("creativeTemplateCard") creativeTemplateCard: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() template: any;
  @Input() isVideo: boolean;
  public width: number;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.width = this.creativeTemplateCard.nativeElement.clientWidth;
    }, 0);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    setTimeout(() => {
      this.width = this.creativeTemplateCard.nativeElement.clientWidth;
    }, 0);
  }

  openDetailsWindow($event) {
    $event.preventDefault();
    $event.stopPropagation();

    const dialogCompoent: any = this.isVideo
      ? VideoEditorTemplateDetailsDialogComponent
      : ImageEditorTemplateDetailsDialogComponent;

    this.dialog.open(dialogCompoent, {
      maxWidth: "min-content",
      width: "auto",
      maxHeight: "95vh",
      panelClass: "no-spacing",
      disableClose: true,
      data: {
        template: this.template,
      },
    });
  }
}
