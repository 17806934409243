import { MPCampaign } from "./MPCampaign";
import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPCampaignPackage } from "./MPCampaingPackage";
import { MPCategory } from "./MPCategory";
import { MPCustomer } from "./MPCustomer";
import { MPCustomerApprovalStatus } from "./MPCustomerApprovalStatus";
import { MPGender } from "./MPGender";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";
import { MPLocation } from "./MPLocation";
import { MPLocationList } from "./MPLocationList";
import { MPMediaAsset } from "../media-asset/MPMediaAsset";
import { MPMediaAssetList } from "../media-asset/MPMediaAssetList";
import { MPSocialMediaPlacement } from "./MPSocialMediaPlacement";
import { SMCampaignType } from "./SMCampaignType";
import { SMCampaignTypeList } from "./SMCampaignTypeList";
import { SponsoredFacebookPage } from "./SponsoredFacebookPage";

export class SMCampaign extends MPCampaign {
  public socialMediaCampaignId: number;
  public socialMediaCampaignTypes: SMCampaignType[];
  public package: MPCampaignPackage;
  public goalAndPerformance: MPGoalAndPerformance;
  public socialMediaCampaignDisplayText: string;
  public socialMediaCampaignLink: string;
  public gender: MPGender;
  public fromAge: number;
  public toAge: number;
  public targets: any[];
  public facebookPageId: number;
  public facebookPageImageUrl: string;
  public instagramPageId: number;
  public facebookPageName: string;
  public instagramPageName: string;
  public sponsoredFacebookPage: SponsoredFacebookPage;
  public category: MPCategory;
  public linkToPreview: string;
  public campaignStatistics: MPCampaignStatistics;
  public customerApprovalOpen: boolean;
  public customerApprovalStatus: MPCustomerApprovalStatus;
  public hasCampaign: boolean;
  public placement: MPSocialMediaPlacement;
  public publicStatisticsUrl: string;

  constructor(
    mpCampaignId: number = null,
    socialMediaCampaignId: number = null,
    socialMediaCampaignTypes: SMCampaignType[] = null,
    dateTimeDisplayName: string = null,
    start: Date = null,
    end: Date = null,
    status: MPCampaignStatus = null,
    name: string = null,
    displayBudget: number = null,
    campaignPackage: MPCampaignPackage = null,
    goalAndPerformance: MPGoalAndPerformance = null,
    socialMediaCampaignDisplayText: string = null,
    socialMediaCampaignLink: string = null,
    mediaAssets: MPMediaAsset[] = null,
    gender: MPGender = null,
    fromAge: number = null,
    toAge: number = null,
    targets: any[] = null,
    facebookPageId: number = null,
    facebookPageImageUrl: string = null,
    instagramPageId: number = null,
    facebookPageName: string = null,
    instagramPageName: string = null,
    sponsoredFacebookPage: SponsoredFacebookPage = null,
    locations: MPLocation[] = null,
    category: MPCategory = null,
    linkToPreview: string = null,
    createdOn: Date = null,
    campaignStatistics: MPCampaignStatistics = null,
    customer: MPCustomer = null,
    showApproalButton: boolean = null,
    channelId: number = null,
    channelLogo: number = null,
    channelName: string = null,
    isPayed: boolean = null,
    hasCampaign: boolean = null,
    customerApprovalOpen: boolean = null,
    customerApprovalStatus: MPCustomerApprovalStatus = null,
    hasNewNotifications: boolean = null,
    placement: MPSocialMediaPlacement = null,
    appliedCoupon: string = null,
    publicStatisticsUrl: string = null,
    manualBudget: number = null,
    isBudgetCheckoutNeeded: boolean = null
  ) {
    super(
      mpCampaignId,
      dateTimeDisplayName,
      start,
      end,
      status,
      name,
      displayBudget,
      mediaAssets,
      locations,
      createdOn,
      customer,
      showApproalButton,
      channelId,
      channelLogo,
      channelName,
      isPayed,
      customerApprovalOpen,
      customerApprovalStatus,
      campaignStatistics,
      hasNewNotifications,
      appliedCoupon,
      publicStatisticsUrl,
      goalAndPerformance,
      manualBudget,
      isBudgetCheckoutNeeded
    );
    this.socialMediaCampaignId = socialMediaCampaignId;
    this.socialMediaCampaignTypes = socialMediaCampaignTypes;
    this.package = campaignPackage;
    this.goalAndPerformance = goalAndPerformance;
    this.socialMediaCampaignDisplayText = socialMediaCampaignDisplayText;
    this.socialMediaCampaignLink = socialMediaCampaignLink;
    this.gender = gender;
    this.fromAge = fromAge;
    this.toAge = toAge;
    this.targets = targets;
    this.facebookPageId = facebookPageId;
    this.facebookPageImageUrl = facebookPageImageUrl;
    this.instagramPageId = instagramPageId;
    this.facebookPageName = facebookPageName;
    this.instagramPageName = instagramPageName;
    this.sponsoredFacebookPage = sponsoredFacebookPage;
    this.category = category;
    this.linkToPreview = linkToPreview;
    this.hasCampaign = hasCampaign;
    this.placement = placement;
  }

  hasBudget(): boolean {
    return (
      (this.displayBudget != 0 && this.displayBudget != null) ||
      this.package != null
    );
  }

  isUnboostedPost(): boolean {
    return this.isPost() && !this.hasBudget();
  }

  isBoostedPost(): boolean {
    return this.isPost() && this.hasBudget();
  }

  deserialize(serializedObj: SMCampaign): SMCampaign {
    if (!serializedObj) {
      return null;
    }

    this.mpCampaignId = serializedObj["mpCampaignId"];
    this.socialMediaCampaignId = serializedObj["socialMediaCampaignId"];
    this.socialMediaCampaignTypes = new SMCampaignTypeList().deserializeList(
      serializedObj["socialMediaCampaignTypes"]
    );
    this.dateTimeDisplayName = serializedObj["dateTimeDisplayName"];
    this.start = serializedObj["start"];
    this.end = serializedObj["end"];
    this.status = new MPCampaignStatus().deserialize(serializedObj["status"]);
    this.name = serializedObj["name"];
    this.displayBudget = serializedObj["displayBudget"];
    this.package = new MPCampaignPackage().deserialize(
      serializedObj["package"]
    );
    this.goalAndPerformance = new MPGoalAndPerformance().deserialize(
      serializedObj["goalAndPerformance"]
    );
    this.socialMediaCampaignDisplayText =
      serializedObj["socialMediaCampaignDisplayText"];
    this.socialMediaCampaignLink = serializedObj["socialMediaCampaignLink"];
    this.mediaAssets = new MPMediaAssetList().deserializeList(
      serializedObj["mediaAssets"]
    );
    this.gender = new MPGender().deserialize(serializedObj["gender"]);
    this.fromAge = serializedObj["fromAge"];
    this.toAge = serializedObj["toAge"];
    this.targets = serializedObj["targets"];
    this.facebookPageId = serializedObj["facebookPageId"];
    this.facebookPageImageUrl = serializedObj["facebookPageImageUrl"];
    this.instagramPageId = serializedObj["instagramPageId"];
    this.facebookPageName = serializedObj["facebookPageName"];
    this.instagramPageName = serializedObj["instagramPageName"];
    this.sponsoredFacebookPage = new SponsoredFacebookPage().deserialize(
      serializedObj["sponsoredFacebookPage"]
    );
    this.locations = new MPLocationList().deserializeList(
      serializedObj["locations"]
    );
    this.category = new MPCategory().deserialize(serializedObj["category"]);
    this.linkToPreview = serializedObj["linkToPreview"];
    this.createdOn = serializedObj["createdOn"];
    this.campaignStatistics = new MPCampaignStatistics().deserialize(
      serializedObj["campaignStatistics"]
    );
    this.customer = new MPCustomer().deserialize(serializedObj["customer"]);
    this.showApproalButton = serializedObj["showApproalButton"];
    this.channelId = serializedObj["channelId"];
    this.channelLogo = serializedObj["channelLogo"];
    this.channelName = serializedObj["channelName"];
    this.isPayed = serializedObj["isPayed"];
    this.hasCampaign = serializedObj["hasCampaign"];

    this.customerApprovalOpen = serializedObj["customerApprovalOpen"];
    this.customerApprovalStatus = new MPCustomerApprovalStatus().deserialize(
      serializedObj["customerApprovalStatus"]
    );
    this.appliedCoupon = serializedObj["appliedCoupon"];
    this.hasNewNotifications = serializedObj["hasNewNotifications"];
    this.placement = new MPSocialMediaPlacement().deserialize(
      serializedObj["placement"]
    );

    this.publicStatisticsUrl = serializedObj["publicStatisticsUrl"];

    this.manualBudget = serializedObj["manualBudget"];
    this.isBudgetCheckoutNeeded = serializedObj["isBudgetCheckoutNeeded"];
    return this;
  }
}
