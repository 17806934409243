import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPCampaignSettings } from "app/model/marketing-portal/MPCampaignSettings";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPChannelPackage } from "app/model/marketing-portal/MPChannelPackage";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { MPPackage } from "app/model/marketing-portal/MPPackage";
import { CurrencyService } from "app/shared/services/currency.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "budget-campaign-form-section",
  templateUrl: "./budget-campaign-form-section.component.html",
  styleUrls: ["./budget-campaign-form-section.component.scss"],
})
export class BudgetCampaignFormSectionComponent implements OnInit {
  @Output() getManualBudgetImpressions = new EventEmitter<{
    goalId: number;
    budget: number;
    onResult: Function;
  }>();
  @Input() manualBudgetControl: FormControl;
  @Input() campaignGoalIdControl: FormControl;
  @Input() campaignPackageIdControl: FormControl;
  @Input() packages: MPPackage[];
  @Input() settings: MPCampaignSettings;
  @Input() data: any;
  @Input() selectedObjective: MPObjective;
  @Input() readonly: boolean;
  @Input() errors: any;
  @Input() multiplier?: number = 1;
  public channelPackages: MPChannelPackage[];
  public mediaPackageType: number;
  public selectedMediaPackage: any;
  public manualBudgetGoal: any;
  public manualBudgeRaw: number;
  public manualBudgetImpressions: any;
  public readonly MAX_MANUAL_BUDGET: number = 1000000;

  constructor(private crudService: CrudService) {}

  ngOnInit() {
    this.initData();
  }

  async initData() {
    this.selectedMediaPackage = this.getInitMediaPackage(this.data);

    if (this.selectedObjective) {
      await this.crudService
        .getChannelGoalFromObjectives(
          this.selectedObjective.id,
          MPChannel.CHANNEL_ID_FACEBOOK_INSTAGRAM_ADS
        )
        .toPromise()
        .then((goalId: number) => {
          if (goalId) {
            this.selectedMediaPackage = this.getInitMediaPackage(goalId);

            this.mediaPackageType =
              this.selectedMediaPackage.packageGoal.goalId;

            this.campaignGoalIdControl.setValue(this.mediaPackageType);
            this.channelPackages = this.selectedMediaPackage.channelPackages;

            this.campaignPackageIdControl.setValue(
              this.channelPackages[0].packageId
            );
          }
        })
        .catch((e) => {});
    }

    if (this.settings.UsePackages) {
      this.mediaPackageType = this.selectedMediaPackage.packageGoal.goalId;
      this.campaignGoalIdControl.setValue(this.mediaPackageType);
      this.channelPackages = this.selectedMediaPackage.channelPackages;

      const preselectedChannelPackage: MPChannelPackage =
        this.data && this.data.package
          ? this.channelPackages.find(
              (channelPackage: MPChannelPackage) =>
                channelPackage.packageId === this.data.package.packageId
            )
          : this.channelPackages[0];

      this.campaignPackageIdControl.setValue(
        preselectedChannelPackage.packageId
      );
    } else {
      this.manualBudgetGoal = this.selectedMediaPackage.packageGoal;
      this.mediaPackageType = this.manualBudgetGoal.goalId;

      this.campaignGoalIdControl.setValue(this.mediaPackageType);

      if (!this.data) {
        this.manualBudgetControl.setValue(
          this.manualBudgetGoal.goalMinimumBudget
        );
      }

      this.manualBudgetControl.setValidators([
        ValidationService.priceNumber({
          min: this.manualBudgetGoal.goalMinimumBudget,
        }),
      ]);

      this.campaignPackageIdControl.setValue(null);
      this.campaignPackageIdControl.setValidators(null);

      this.manualBudgetControl.updateValueAndValidity();
      this.campaignPackageIdControl.updateValueAndValidity();

      this.calcManualBudgetImpressions(this.manualBudgetGoal.goalMinimumBudget);
    }
  }

  getInitMediaPackage(items) {
    if (!items) return this.packages[0];

    return this.getPackageGoal(items.goalAndPerformance.goalId);
  }

  onClickMediaPackageType(goalId: number) {
    const mediaPackage = this.getPackageGoal(goalId);

    this.mediaPackageType = goalId;
    this.campaignGoalIdControl.setValue(goalId);
    this.channelPackages = mediaPackage.channelPackages;
    this.selectedMediaPackage = mediaPackage;

    this.campaignPackageIdControl.setValue(this.channelPackages[0].packageId);
  }

  onClickBudgetPackage(packageId: number) {
    this.campaignPackageIdControl.setValue(packageId);
  }

  onClickManualGoalType(goalId: number) {
    const goal = this.getPackageGoal(goalId);

    this.mediaPackageType = goalId;
    this.manualBudgetGoal = goal.packageGoal;

    this.campaignGoalIdControl.setValue(goalId);
    this.manualBudgetControl.setValidators(null);
    this.manualBudgetControl.updateValueAndValidity();
    this.manualBudgetControl.setValidators([
      ValidationService.priceNumber({
        min: this.manualBudgetGoal.goalMinimumBudget,
      }),
    ]);
    this.manualBudgetControl.updateValueAndValidity();

    this.calcManualBudgetImpressions(this.manualBudgetGoal.goalMinimumBudget);
  }

  calcManualBudgetImpressions(budget: number) {
    this.manualBudgeRaw = budget;

    if (this.manualBudgetGoal.goalMinimumBudget > this.manualBudgeRaw) {
      this.manualBudgetImpressions = 0;
      return;
    }

    this.getManualBudgetImpressions.emit({
      goalId: this.mediaPackageType,
      budget: this.manualBudgeRaw,
      onResult: (result: number) => (this.manualBudgetImpressions = result),
    });
  }

  getPackageGoal(goalId: number) {
    return this.packages.find(
      (mediaPackage) => mediaPackage.packageGoal.goalId === goalId
    );
  }

  focusPrice($event) {
    $event.target.value = CurrencyService.removeCurrencySymbol(
      $event.target.value
    );
  }

  blurPrice($event) {
    $event.target.value = CurrencyService.addCurrencySymbol(
      $event.target.value
    );
  }

  convertPrice($event) {
    let rawValue = CurrencyService.formatPriceToNumber($event.target.value);

    if (rawValue > this.MAX_MANUAL_BUDGET) {
      rawValue = this.MAX_MANUAL_BUDGET;
    } else if (isNaN(rawValue)) {
      rawValue = 0;
    }

    $event.target.value = CurrencyService.formatInputPrice(rawValue.toString());
    this.calcManualBudgetImpressions(rawValue);
    // this.setControlValidationError("manualBudget");
  }
}
