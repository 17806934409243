import { Component, OnInit } from "@angular/core";

@Component({
  selector: "marketing-portal-video-editor-content",
  templateUrl: "./marketing-portal-video-editor-content.component.html",
  styleUrls: ["./marketing-portal-video-editor-content.component.scss"],
})
export class MarketingPortalVideoEditorContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
