import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { FileType } from "app/shared/components/customer/generate-customer-media-asset-quick-tool/models/FileType";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "ma-extended-configurator-asset-type",
  templateUrl: "./extended-configurator-asset-type.component.html",
  styleUrls: ["./extended-configurator-asset-type.component.scss"],
})
export class ExtendedConfiguratorAssetTypeComponent implements OnInit {
  @ViewChild("configuratorFooter") configuratorFooter: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() goToConfigurator: EventEmitter<any> = new EventEmitter();
  @Input() selectedChannel: MPChannel;
  @Input() configuratorHeaderHeight: number;
  @Input() creationType: MPConfiguratorCreationType;
  @Input() singleSelection?: boolean;
  public isLoading: boolean;
  public assetType: number;
  public formatType: number;
  public resolutionList: MPTemplateResolution[];
  public videoResolutionList: MPTemplateResolution[] = [];
  public imageResolutionList: MPTemplateResolution[] = [];
  public selectedResolutions: MPTemplateResolution[] = [];
  public contentHeight: number;
  public lockResolutionSelection: boolean = false;
  readonly mpConfigurationCreationType = MPConfiguratorCreationType;
  readonly fileType = FileType;

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.getResolutions();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
  }

  onSelectAssetType($event: number) {
    this.assetType = $event;

    this.resolutionList =
      this.assetType === this.fileType.FILE_TYPE_IMAGE
        ? this.imageResolutionList
        : this.videoResolutionList;

    if (this.selectedChannel.channelId === MPChannel.CHANNEL_ID_GOOGLE_GDN) {
      this.selectedResolutions = this.resolutionList.map(
        (resolution) => resolution
      );
      this.lockResolutionSelection = true;
    } else {
      this.selectedResolutions = [];
    }
  }

  onSelectResolution($event: any) {
    if (this.lockResolutionSelection) {
      return;
    }

    // If video is selected, only one resolution is allowed
    if (
      this.assetType === this.fileType.FILE_TYPE_VIDEO ||
      this.singleSelection
    ) {
      this.selectedResolutions = [$event];
      return;
    }

    if (this.selectedResolutions.includes($event)) {
      const index = this.selectedResolutions.indexOf($event);

      this.selectedResolutions.splice(index, 1);
    } else {
      this.selectedResolutions.push($event);
    }
  }

  next() {
    this.onSelect.emit({
      assetType: this.assetType,
      resolutions: this.selectedResolutions,
    });
  }

  async getResolutions() {
    this.appLoader.open();
    this.isLoading = true;

    await this.crudService
      .getAllTemplateResolutions(true)
      .toPromise()
      .then((res) => {
        this.videoResolutionList = this.filterResolutionsByChannel(res);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await this.crudService
      .getAllTemplateResolutions(false)
      .toPromise()
      .then((res) => {
        this.imageResolutionList = this.filterResolutionsByChannel(res);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
    this.isLoading = false;
  }

  filterResolutionsByChannel(
    resolutions: MPTemplateResolution[]
  ): MPTemplateResolution[] {
    const data: MPTemplateResolution[] = [];

    resolutions.forEach((resolution: MPTemplateResolution) => {
      const index = resolution.channels.findIndex(
        (channel: MPChannel) =>
          channel.channelId === this.selectedChannel.channelId
      );

      if (index != -1) {
        data.push(resolution);
      }
    });

    return data;
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;

    windowHeight -= this.configuratorHeaderHeight;

    windowHeight -= this.configuratorFooter.nativeElement.offsetHeight;

    this.contentHeight = windowHeight;
  }
}
