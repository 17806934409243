import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-video-editor-slide-list",
  templateUrl: "./marketing-portal-video-editor-slide-list.component.html",
  styleUrls: ["./marketing-portal-video-editor-slide-list.component.scss"],
})
export class MarketingPortalVideoEditorSlideListComponent implements OnInit {
  @Output() onSelectSlide: EventEmitter<any> = new EventEmitter();
  @Input() slides: any;
  @Input() selectedSlide: any;
  @Input() editedSlideIds: number[];
  @Input() updatedSlideIds: number[];

  constructor() {}

  ngOnInit() {}
}
