import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
  selector: "assistant-company-information-form",
  templateUrl: "./assistant-company-information-form.component.html",
  styleUrls: ["./assistant-company-information-form.component.scss"],
})
export class AssistantCompanyInformationFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() inverted?: boolean = false;
  public formGroup: FormGroup;
  public errors = {};
  constructor(private fb: FormBuilder, private translate: TranslateService) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      name: ["", Validators.required],
      homepage: [""],
      protocol: ["https://"],
    });
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    this.applyValidatorsForNonEmptyField("homepage", [
      ValidationService.urlValidator,
    ]);

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");
    this.formGroup.get("homepage").setValue(value);
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.formGroup.controls);
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.formGroup.get(name).value === "") {
      this.formGroup.get(name).clearValidators();
    } else {
      this.formGroup.get(name).setValidators(validators);
    }
    this.formGroup.get(name).updateValueAndValidity();
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  onSubmit() {
    this.markFormControls(this.formGroup.controls);

    if (this.formGroup.invalid) {
      this.getValidationErrors();
      return;
    }

    const value = this.formGroup.getRawValue();

    this.submitted.emit(value);
  }
}
