import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPMediaUploadFormatConfiguration } from "app/model/marketing-portal/MPMediaUploadFormatConfiguration";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "media-assets-form-section",
  templateUrl: "./media-assets-form-section.component.html",
  styleUrls: ["./media-assets-form-section.component.scss"],
})
export class MediaAssetsFormSectionComponent implements OnInit {
  @Output() onChange?: EventEmitter<any> = new EventEmitter();
  @Input() imageIdsControl: FormControl;
  @Input() customer: MPCustomer;
  @Input() channelId: number;
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;
  @Input() selectedTemplateImages?: TemplateBasedImage[];
  @Input() selectedResolutions?: MPTemplateResolution[];
  @Input() videoUploadConfiguration: MPMediaUploadFormatConfiguration;
  public mediaAssets: MPMediaAsset[] = [];
  constructor(private crudService: CrudService) {}

  ngOnInit() {
    if (this.data) {
      this.initData();
    }

    if (this.selectedTemplateImages) {
      this.restoreTemplateImages();
    }
  }

  initData() {
    this.data.mediaAssets.forEach((mediaAsset) => {
      const newMediaAsset = new MPMediaAsset(
        mediaAsset.mediaAssetId,
        mediaAsset.mediaAssetName,
        mediaAsset.width,
        mediaAsset.height,
        mediaAsset.sizeInByte,
        mediaAsset.mediaAssetDataType,
        mediaAsset.mediaAssetIsTransparent,
        mediaAsset.isImage,
        mediaAsset.isVideo,
        mediaAsset.isReady,
        mediaAsset.mediaAssetUpdateTime,
        mediaAsset.mediaAssetData,
        mediaAsset.mediaAssetUrl,
        mediaAsset.mediaAssetUrl,
        mediaAsset.isPartOfSet,
        mediaAsset.templateId,
        mediaAsset.googleDisplayMediaFormatId
      );

      this.mediaAssets.push(newMediaAsset);
    });

    this.onChangeMediaAssets(this.mediaAssets);
  }

  restoreTemplateImages() {
    const promises = [];

    this.selectedTemplateImages.forEach(
      (selectedTemplateImage: TemplateBasedImage) => {
        promises.push(
          this.crudService
            .getMediaAssetInformation(selectedTemplateImage.imageId)
            .toPromise()
        );
      }
    );

    Promise.all(promises).then((values) => {
      this.onChangeMediaAssets(this.getMediaAssetObjs(values));
    });
  }

  getMediaAssetObjs(mediaAssets) {
    const objs = [];

    mediaAssets.forEach((mediaAsset) => {
      const newObj = new MPMediaAsset(
        mediaAsset.mediaAssetId,
        mediaAsset.mediaAssetName,
        mediaAsset.width,
        mediaAsset.height,
        mediaAsset.sizeInByte,
        mediaAsset.mediaAssetDataType,
        mediaAsset.mediaAssetIsTransparent,
        mediaAsset.isImage,
        mediaAsset.isVideo,
        mediaAsset.isReady,
        mediaAsset.mediaAssetUpdateTime,
        mediaAsset.mediaAssetData,
        mediaAsset.mediaAssetUrl,
        mediaAsset.mediaAssetThumbnailUrl,
        mediaAsset.isPartOfSet,
        mediaAsset.templateId
      );

      objs.push(newObj);
    });

    return objs;
  }

  onChangeMediaAssets($event) {
    this.mediaAssets = $event;
    this.setControlIds();

    this.onChange.emit(this.mediaAssets);
  }

  setControlIds() {
    let value: number[] = [];
    this.mediaAssets.forEach((mediaAsset: MPMediaAsset) => {
      value.push(mediaAsset.mediaAssetId);
    });

    this.imageIdsControl.setValue(value);
  }
}
