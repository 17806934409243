import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPAgeGroup } from "app/model/marketing-portal/MPAgeGroup";

@Component({
  selector: "age-group-campaign-form-section",
  templateUrl: "./age-group-campaign-form-section.component.html",
  styleUrls: ["./age-group-campaign-form-section.component.scss"],
})
export class AgeGroupCampaignFormSectionComponent implements OnInit {
  @Input() ageGroupIdControl: FormControl;
  @Input() ageGroups: MPAgeGroup[];
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;
  constructor() {}

  ngOnInit() {
    if (this.data && this.data.ageGroup) {
      this.initData();
    }
  }

  initData() {
    this.ageGroupIdControl.setValue(this.data.ageGroup.ageGroupId);
  }

  onClick(ageGroupId: number) {
    this.ageGroupIdControl.setValue(ageGroupId);
  }
}
