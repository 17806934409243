import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPGender } from "app/model/marketing-portal/MPGender";

@Component({
  selector: "gender-campaign-form-section",
  templateUrl: "./gender-campaign-form-section.component.html",
  styleUrls: ["./gender-campaign-form-section.component.scss"],
})
export class GenderCampaignFormSectionComponent implements OnInit {
  @Input() genderIdControl: FormControl;
  @Input() genders: MPGender[];
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;
  constructor() {}

  ngOnInit() {
    if (this.data && this.data.gender) {
      this.initData();
    }
  }

  initData() {
    this.genderIdControl.setValue(this.data.gender.genderId);
  }

  onClick(genderId: number) {
    this.genderIdControl.setValue(genderId);
  }
}
