import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ChangePasswordFormComponent } from "../../forms/settings/change-password-form/change-password-form.component";

@Component({
  selector: "app-password-change-dialog",
  templateUrl: "./password-change-dialog.component.html",
  styleUrls: ["./password-change-dialog.component.scss"],
})
export class PasswordChangeDialogComponent implements OnInit {
  @ViewChild(ChangePasswordFormComponent)
  changePasswordFormComponent: ChangePasswordFormComponent;
  constructor(
    public dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    this.changePasswordFormComponent.onSumbit(this.dialogRef);
  }
}
