import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { MPConfiguratorMainAction } from "app/model/marketing-portal/MPConfiguratorMainAction";
import { MPConfiguratorStep } from "app/model/marketing-portal/MPConfiguratorStep";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { FileType } from "app/shared/components/customer/generate-customer-media-asset-quick-tool/models/FileType";
import { ModuleService } from "app/shared/services/module.service";

@Component({
  selector: "ma-extended-configurator-navigation",
  templateUrl: "./extended-configurator-navigation.component.html",
  styleUrls: ["./extended-configurator-navigation.component.scss"],
})
export class ExtendedConfiguratorNavigationComponent implements OnInit {
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() goToConfigurator: EventEmitter<any> = new EventEmitter();
  @Input() stepNavigation: any[];
  @Input() creationType: MPConfiguratorCreationType;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedChannel: MPChannel;
  @Input() selectedAssetType: number;
  @Input() selectedResolutions: string;
  @Input() selectedMainAction: MPConfiguratorMainAction;
  @Input() selectedGoal: MPObjective;
  @Input() selectedTemplate: any;
  @Input() stepNumber: number;
  public configuratorStep = MPConfiguratorStep;
  public fileType = FileType;
  public mainAction = MPConfiguratorMainAction;
  readonly mpConfiguratorCreationType = MPConfiguratorCreationType;
  readonly MODULE_SERVICE_CUSTOMER = MPModule.MODULE_SERVICE_CUSTOMER;

  constructor(public moduleService: ModuleService) {}

  ngOnInit() {}
}
