import { MPGoogleDisplayMediaFormat } from "./MPGoogleDisplayMediaFormat";

export class MPGoogleDisplayMediaFormatList {
  public googleDisplayMediaFormat: MPGoogleDisplayMediaFormat[];

  constructor() {}

  public deserializeList(
    serializedObj: MPGoogleDisplayMediaFormat[]
  ): MPGoogleDisplayMediaFormat[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPGoogleDisplayMediaFormat) =>
      new MPGoogleDisplayMediaFormat().deserialize(x)
    );
  }
}
