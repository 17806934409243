import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

import { FileType } from "./models/FileType";
import { StepIndex } from "./models/StepIndex";

@Component({
  selector: "generate-customer-media-asset-quick-tool",
  templateUrl: "./generate-customer-media-asset-quick-tool.component.html",
  styleUrls: ["./generate-customer-media-asset-quick-tool.component.scss"],
})
export class GenerateCustomerMediaAssetQuickToolComponent implements OnInit {
  @Output() updateStepIndex?: EventEmitter<any> = new EventEmitter();
  @Output() onClose?: EventEmitter<any> = new EventEmitter();
  @Input() customer: MPCustomer;
  @Input() step?: number;
  @Input() psContainer?: any;
  public fileType = FileType;
  public stepIndex = StepIndex;
  public selectedFileType: number = null;
  public selectedFormat: number = null;
  public selectedTemplate: number = null;
  public templates: any;
  public resolutionList: MPTemplateResolution[];
  public availableChannels: MPChannel[];
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {}

  goBack() {
    this.step--;
    this.updateStepIndex.emit(this.step);
  }

  async onSelectFileType(fileType: number) {
    this.selectedFileType = fileType;

    this.appLoader.open();
    this.templates = await this.getTemplateByFileType();
    this.resolutionList = await this.getResolutionList();
    this.appLoader.close();

    this.goToNextStep();
  }

  onSelectFormat(data: any) {
    const { format, channels } = data;

    this.selectedFormat = format;
    this.availableChannels = channels;

    this.goToNextStep();
  }

  onSelectTemplate(template: any) {
    this.selectedTemplate = template.template;

    this.goToNextStep();
  }

  goToNextStep() {
    this.step++;
    this.updateStepIndex.emit(this.step);
    this.psContainer.scrollToTop();
  }

  onDone() {
    this.updateStepIndex.emit(this.step + 1);
  }

  getResolutionList() {
    var isVideo = false;
    if (this.selectedFileType === this.fileType.FILE_TYPE_VIDEO) isVideo = true;

    return this.crudService.getAllTemplateResolutions(isVideo).toPromise();
  }

  getTemplateByFileType() {
    return new Promise(async (resolve, reject) => {
      let request =
        this.selectedFileType === this.fileType.FILE_TYPE_VIDEO
          ? this.crudService.getAllMPVideoTemplates(
              null,
              0,
              100,
              this.customer.customerId
            )
          : this.crudService.getAllMPTemplates(
              null,
              0,
              100,
              this.customer.customerId
            );

      await request
        .toPromise()
        .then((res) => {
          resolve(res.templates);
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          reject(e.error);
        });
    });
  }
}
