import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";

@Component({
  selector: "customer-media-asset-grid",
  templateUrl: "./customer-media-asset-grid.component.html",
  styleUrls: ["./customer-media-asset-grid.component.scss"],
})
export class CustomerMediaAssetGridComponent implements OnInit {
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() mediaAssets: MPMediaAsset[];
  @Input() channels: MPChannel[];
  @Input() customerId: number;
  @Input() customerLogo: string;
  constructor() {}

  ngOnInit() {}
}
