import { MPChannelAdvantage } from "./MPChannelAdvantage";

export class MPChannel {
  public channelId: number;
  public channelName: string;
  public channelShortName: string;
  public channelDescription: string;
  public channelLogo: string;
  public channelAdvantages: MPChannelAdvantage[];
  public channelMinimumPrice: number;
  static CHANNEL_ID_FACEBOOK_INSTAGRAM_ADS = 1;
  static CHANNEL_ID_FACEBOOK_INSTAGRAM_POST = 8;
  static CHANNEL_ID_GOOGLE_GDN = 10;
  static CHANNEL_ID_YOUTUBE = 4;
  static CHANNEL_ID_ADDRESSABLE_TV = 12;
  static CHANNEL_ID_MOPO = 13;
}
