import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { MPConfiguratorMainAction } from "app/model/marketing-portal/MPConfiguratorMainAction";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPObjective } from "app/model/marketing-portal/MPObjective";

@Component({
  selector: "ma-extended-configurator-header",
  templateUrl: "./extended-configurator-header.component.html",
})
export class ExtendedConfiguratorHeaderComponent implements OnInit {
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() goToConfigurator: EventEmitter<any> = new EventEmitter();
  @Input() stepNavigation: any[];
  @Input() creationType: MPConfiguratorCreationType;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedChannel: MPChannel;
  @Input() selectedAssetType: number;
  @Input() selectedResolutions: string;
  @Input() selectedMainAction: MPConfiguratorMainAction;
  @Input() selectedGoal: MPObjective;
  @Input() selectedTemplate: any;
  @Input() stepNumber: number;

  public mpConfiguratorCreationType = MPConfiguratorCreationType;

  constructor() {}

  ngOnInit() {}
}
