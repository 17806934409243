import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { FileType } from "app/shared/components/customer/generate-customer-media-asset-quick-tool/models/FileType";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "ma-extended-configurator-templates",
  templateUrl: "./extended-configurator-templates.component.html",
  styleUrls: ["./extended-configurator-templates.component.scss"],
})
export class ExtendedConfiguratorTemplatesComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() selectedAssetType: number;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedResolutions: any[];
  @Input() selectedChannel: MPChannel;
  @Input() configuratorHeaderHeight: number;
  public fileType = FileType;
  public templates: any;
  public contentHeight: number;
  public groupedTemplates: any = [];

  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    setTimeout(() => this.appLoader.open());

    this.templates = await this.getTemplateByFileType();
    this.groupTemplateByCategory();

    setTimeout(() => this.appLoader.close());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
  }

  groupTemplateByCategory() {
    this.templates.forEach((template) => {
      let categoryName = template.category.templateCategoryName;

      if (!this.categoryExists(categoryName)) {
        this.groupedTemplates.push({
          category: {
            name: categoryName,
          },
          templates: [],
        });
      }

      let index = this.getGroupedTemplateIndexByCategory(categoryName);
      this.groupedTemplates[index].templates.push(template);
    });
  }

  categoryExists(categoryName) {
    return this.getGroupedTemplateIndexByCategory(categoryName) != -1;
  }

  getGroupedTemplateIndexByCategory(categoryName) {
    return this.groupedTemplates.findIndex(
      (groupedTemplate) => groupedTemplate.category.name === categoryName
    );
  }

  getTemplateByFileType() {
    return new Promise(async (resolve, reject) => {
      const data = {};

      if (this.selectedResolutions) {
        data["resolutionIds"] = this.selectedResolutions.map(
          (resolution) => resolution.resolutionId
        );
      }

      let request =
        this.selectedAssetType === this.fileType.FILE_TYPE_VIDEO
          ? this.crudService.getAllMPVideoTemplates(
              this.selectedChannel.channelId,
              0,
              100,
              this.selectedCustomer.customerId,
              data
            )
          : this.crudService.getAllMPSetTemplates(
              this.selectedChannel.channelId,
              0,
              100,
              this.selectedCustomer.customerId,
              data
            );

      await request
        .toPromise()
        .then((res) => {
          const templates = res.hasOwnProperty("templateSets")
            ? res.templateSets
            : res.templates;

          resolve(templates);
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          reject(e.error);
        });
    });
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;
    windowHeight -= this.configuratorHeaderHeight;

    this.contentHeight = windowHeight;
  }
}
