import { MPLocation } from "./MPLocation";

export class MPLocationList {
  public mpLocations: MPLocation[];

  constructor() {}

  public deserializeList(serializedObj: MPLocation[]): MPLocation[] {
    return serializedObj.map((x: MPLocation) =>
      new MPLocation().deserialize(x)
    );
  }
}
