import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { Location } from "@angular/common";
import { CrudService } from "app/views/mapAds/crud.service";
import { CreateGdnFormComponent } from "app/shared/components/forms/campaign/create-gdn-form/create-gdn-form.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { environment } from "environments/environment";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { MPObjective } from "app/model/marketing-portal/MPObjective";

@Component({
  selector: "marketing-portal-channel-gdn",
  templateUrl: "./marketing-portal-channel-gdn.component.html",
  styleUrls: ["./marketing-portal-channel-gdn.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(50px)" }),
        animate(
          "750ms cubic-bezier(0, 0, 0.2, 1)",
          style({ opacity: 1, transform: "translateY(0)" })
        ),
      ]),
    ]),
  ],
})
export class MarketingPortalChannelGdnComponent implements OnInit {
  @ViewChild(CreateGdnFormComponent)
  createGdnFormComponent: CreateGdnFormComponent;
  @Input() googleDisplayCampaign: any;
  @Input() selectedChannel: MPChannel;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedResolutions: any[];
  @Input() selectedTemplateImages: TemplateBasedImage[] = null;
  @Input() contentHeight: number;
  @Input() duplicate: number = null;
  @Input() selectedObjective: MPObjective;
  public isLoading: boolean = false;
  public googleSettings: any;
  readonly environment = environment;
  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    setTimeout(() => this.appLoader.open());

    this.googleSettings = await this.crudService
      .getGoogleSettings()
      .toPromise();

    setTimeout(() => this.appLoader.close());
    this.isLoading = false;
  }

  goBack() {
    this.location.back();
  }

  onSubmit(isDraft: boolean) {
    this.createGdnFormComponent.onSubmit(isDraft);
  }
}
