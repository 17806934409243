import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "media-assets-libary-suggestions",
  templateUrl: "./media-assets-libary-suggestions.component.html",
  styleUrls: ["./media-assets-libary-suggestions.component.scss"],
})
export class MediaAssetsLibarySuggestionsComponent implements OnInit {
  @Output() selectImage: EventEmitter<any> = new EventEmitter();
  @Input() customerId: number;
  @Input() selectedImage: MPLibaryMediaAsset;

  public suggestedMediaAssetList: MPLibaryMediaAsset[];
  public isLoading: boolean;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getSuggestions();
  }
  async getSuggestions() {
    this.isLoading = true;

    await this.crudService
      .GetMediaAssetSuggestions(this.customerId)
      .toPromise()
      .then((res) => {
        if (!res) {
          return;
        }
        this.suggestedMediaAssetList = res.Images;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }
}
