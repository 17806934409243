import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { GAMCampaign } from "app/model/marketing-portal/GAMCampaign";
import { MPCampaignSettings } from "app/model/marketing-portal/MPCampaignSettings";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { CreateMopoFormComponent } from "app/shared/components/forms/campaign/create-mopo-form/create-mopo-form.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-channel-mopo",
  templateUrl: "./marketing-portal-channel-mopo.component.html",
  styleUrls: ["./marketing-portal-channel-mopo.component.scss"],
})
export class MarketingPortalChannelMopoComponent implements OnInit {
  @ViewChild(CreateMopoFormComponent)
  createMopoFormComponent: CreateMopoFormComponent;
  @Input() gamCampaign?: GAMCampaign;
  @Input() selectedChannel: MPChannel;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedTemplateImages: TemplateBasedImage[] = null;
  @Input() selectedObjective: MPObjective;
  @Input() selectedResolutions: MPTemplateResolution;
  @Input() contentHeight: number;
  @Input() duplicate: number = null;
  public isLoading: boolean = false;
  public gamSettings: MPCampaignSettings;

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService
  ) {}

  async ngOnInit() {
    setTimeout(() => this.appLoader.open());
    this.isLoading = true;

    this.gamSettings = await this.crudService.getGAMSettings().toPromise();

    setTimeout(() => this.appLoader.close());
    this.isLoading = false;
  }

  onSubmit(isDraft: boolean) {
    this.createMopoFormComponent.onSubmit(isDraft);
  }
}
