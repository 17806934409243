import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "assistant-creative-card",
  templateUrl: "./assistant-creative-card.component.html",
  styleUrls: ["./assistant-creative-card.component.scss"],
})
export class AssistantCreativeCardComponent implements OnInit {
  @Input() channelCreative: any;
  constructor() {}

  ngOnInit() {}

  createCampaign() {}

  edit() {}
}
