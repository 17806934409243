import { Component, Input, OnInit } from "@angular/core";
import { MPGoalAndPerformance } from "app/model/marketing-portal/MPGoalAndPerformance";

@Component({
  selector: "mc-head-map-statistics",
  templateUrl: "./mc-head-map-statistics.component.html",
  styleUrls: ["./mc-head-map-statistics.component.scss"],
})
export class McHeadMapStatisticsComponent implements OnInit {
  @Input() locationsStatistics;
  @Input() locations: any = [];
  @Input() goal: MPGoalAndPerformance;
  public stores: any[] = [];

  constructor() {}

  ngOnInit() {
    if (this.locations && this.locations.length > 0) {
      this.convertLocationsToStores();
    }
  }
  convertLocationsToStores() {
    this.stores = [];

    this.locations.forEach((loc) => {
      this.stores.push({
        // storeId: loc.locationId,
        latitude: loc.locationLatitude,
        longitude: loc.locationLongitude,
        storeName:
          loc.locationStreet +
          " " +
          loc.locationZipCode +
          " " +
          loc.locationCountry,
        // storeAddressString:
        //   loc.locationStreet +
        //   " " +
        //   loc.locationZipCode +
        //   " " +
        //   loc.locationCountry,
      });
    });
  }
}
