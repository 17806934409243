import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPCustomerLocation } from "app/model/marketing-portal/MPCustomerLocation";

@Component({
  selector: "assistant-location-card",
  templateUrl: "./assistant-location-card.component.html",
  styleUrls: ["./assistant-location-card.component.scss"],
})
export class AssistantLocationCardComponent implements OnInit {
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Input() location: MPCustomerLocation;
  @Input() index: number;
  @Input() readonly?: boolean = false;
  constructor() {}

  ngOnInit() {}
}
