import { Component, Input, OnInit } from "@angular/core";
import { PresalesItem } from "app/model/presales/PresalesItem";

@Component({
  selector: "presales-presentation-progress-line",
  templateUrl: "./presales-presentation-progress-line.component.html",
  styleUrls: ["./presales-presentation-progress-line.component.scss"],
})
export class PresalesPresentationProgressLineComponent implements OnInit {
  @Input() currentSlideIndex: number;
  @Input() presalesItems: PresalesItem[];
  constructor() {}

  ngOnInit() {}
}
