import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "smartphone-iphone-x",
  templateUrl: "./smartphone-iphone-x.component.html",
  styleUrls: ["./smartphone-iphone-x.component.scss"],
})
export class SmartphoneIphoneXComponent implements OnInit {
  @Input() width?: number = 240;
  constructor() {}

  ngOnInit() {}
}
