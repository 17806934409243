import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "campaign-youtube-preview",
  templateUrl: "./campaign-youtube-preview.component.html",
  styleUrls: ["./campaign-youtube-preview.component.scss"],
})
export class CampaignYoutubePreviewComponent implements OnInit {
  @Input() mediaAsset: any;
  @Input() headline: string = "["  + this.translate.instant("Headline") +  "]";
  @Input() url: string = "[Homepage]";
  @Input() callToActionText: string = "[Call-To-Action]";
  constructor(private translate: TranslateService) {}

  ngOnInit() {}
}
