import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { CrudService } from "app/views/mapAds/crud.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CreateInstagramFacebookPostFormComponent } from "app/shared/components/forms/campaign/create-instagram-facebook-post-form/create-instagram-facebook-post-form.component";
import { environment } from "environments/environment";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { SMCampaign } from "app/model/marketing-portal/SMCampaign";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";

@Component({
  selector: "marketing-portal-channel-facebook-instagram-posts",
  templateUrl:
    "./marketing-portal-channel-facebook-instagram-posts.component.html",
  styleUrls: [
    "./marketing-portal-channel-facebook-instagram-posts.component.scss",
  ],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(50px)" }),
        animate(
          "750ms cubic-bezier(0, 0, 0.2, 1)",
          style({ opacity: 1, transform: "translateY(0)" })
        ),
      ]),
    ]),
  ],
})
export class MarketingPortalChannelFacebookInstagramPostsComponent
  implements OnInit
{
  @ViewChild(CreateInstagramFacebookPostFormComponent)
  createInstagramFacebookPostFormComponent: CreateInstagramFacebookPostFormComponent;
  @Input() socialMediaCampaign?: SMCampaign;
  @Input() selectedChannel: MPChannel;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedTemplateImages: TemplateBasedImage[] = null;
  @Input() contentHeight: number;
  @Input() duplicate: boolean = null;

  public mediaAssetIds: number[];
  public customerId: number = null;
  public customer: any;
  public campaignChannelId: number;
  public isLoading: boolean = false;
  public socialMediaSettings: any;
  public smCampaign: any;
  readonly environment = environment;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private location: Location
  ) {}

  async ngOnInit() {
    if (this.selectedTemplateImages) {
      this.mediaAssetIds = this.selectedTemplateImages.map(
        (selectedTemplateImage: TemplateBasedImage) =>
          selectedTemplateImage.imageId
      );
    }

    this.isLoading = true;

    this.socialMediaSettings = await this.crudService
      .getSocialMediaSettings()
      .toPromise();

    this.isLoading = false;
  }

  goBack() {
    this.location.back();
  }

  onSubmit() {
    this.createInstagramFacebookPostFormComponent.onSubmit();
  }
}
