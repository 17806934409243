import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { AddRegionDialogComponent } from "app/shared/components/dialogs/add-region-dialog/add-region-dialog.component";

@Component({
  selector: "zipcode-location-campaign-form-section",
  templateUrl: "./zipcode-location-campaign-form.component.html",
  styleUrls: ["./zipcode-location-campaign-form.component.scss"],
})
export class ZipcodeLocationCampaignFormComponent implements OnInit {
  @Input() locationControl: FormControl;
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;
  public parentStoreId: number;
  public storeLocations: any = [];
  public isLoading: boolean = true;
  constructor(private dialog: MatDialog) {
    this.parentStoreId = window.localStorage["storeId"];
  }

  ngOnInit() {}

  openAddRegionDialog() {
    const dialogRef = this.dialog.open(AddRegionDialogComponent, {
      width: "800px",
      panelClass: "no-spacing",
      height: "auto",
      maxHeight: "100vh",
      data: {
        locations: [],
        onlyRegions: true,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let region = {
          address: res.selectedRegion,
          url: res.locationUrl || res.url,
          name: res.locationName || res.name,
        };

        let location = {
          campaignLocationRadiusId: null,
          locationLatitude: region.address.latitude,
          locationLongitude: region.address.longitude,
          locationStreet: region.address.address,
          locationStreetNumber: null,
          locationCity: region.address.city,
          locationZipCode: region.address.zip_code,
          locationCountry: region.address.country,
          locationState: region.address.state,
          locationUrl: region.url,
          locationName: region.name,
        };

        const value = this.locationControl.value || [];
        value.push(location);
        this.locationControl.setValue(value);
      }
    });
  }

  removeRegion($event) {
    let index = $event;
    const value = this.locationControl.value || [];
    value.splice(index, 1);
    this.locationControl.setValue(value);
  }
}
