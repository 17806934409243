import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaign-form-container",
  templateUrl: "./campaign-form-container.component.html",
  styleUrls: ["./campaign-form-container.component.scss"],
})
export class CampaignFormContainerComponent implements OnInit {
  @Input() title: string;
  @Input() show?: boolean = true;
  @Input() label?: string = null;

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.show = !this.show;
  }
}
