export class MPGeoTargeting {
  id: number;
  name: string;
  description: string;

  constructor(
    id: number = null,
    name: string = null,
    description: string = null
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  deserialize(serializedObj: object): MPGeoTargeting {
    if (!serializedObj) {
      return null;
    }

    this.id = serializedObj["id"];
    this.name = serializedObj["name"];
    this.description = serializedObj["description"];

    return this;
  }
}
