import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";

@Component({
  selector: "video-asset-libary-card",
  templateUrl: "./video-asset-libary-card.component.html",
  styleUrls: ["./video-asset-libary-card.component.scss"],
})
export class VideoAssetLibaryCardComponent implements OnInit {
  @ViewChild("videoPlayer") videoPlayer: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() libaryMediaAsset: MPLibaryMediaAsset;
  @Input() selected: MPLibaryMediaAsset;
  public isPlayingVideo: boolean = false;
  public playProgress: number = 0;
  public playTimeProgressInterval: any = null;
  constructor() {}

  ngOnInit() {}

  enter() {
    this.playVideo();
  }

  leave() {
    this.stopVideo();
  }

  playVideo() {
    this.isPlayingVideo = true;
    this.videoPlayer.nativeElement.muted = true;
    this.videoPlayer.nativeElement.play();

    this.updatePlayTimeProgress();
  }

  stopVideo() {
    this.isPlayingVideo = false;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;

    this.playProgress = 0;

    clearInterval(this.playTimeProgressInterval);
  }

  updatePlayTimeProgress() {
    const self = this;
    this.playTimeProgressInterval = setInterval(function () {
      self.playProgress =
        (self.videoPlayer.nativeElement.currentTime /
          self.libaryMediaAsset.Duration) *
        100;

      if (self.playProgress >= 100) {
        self.playProgress = 100;
        clearInterval(self.playTimeProgressInterval);
      }
    }, 50);
  }
}
