export class MPCategory {
  public id: number;
  public displayName: string;
  public icon: string;

  constructor(
    id: number = null,
    displayName: string = null,
    icon: string = null
  ) {
    this.id = id;
    this.displayName = displayName;
    this.icon = icon;
  }

  deserialize(serializedObj: object): MPCategory {
    if (!serializedObj) {
      return null;
    }

    this.id = serializedObj["id"];
    this.displayName = serializedObj["displayName"];
    this.icon = serializedObj["icon"];

    return this;
  }
}
