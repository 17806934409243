import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";
import { MPMediaImportType } from "app/model/marketing-portal/MPMediaImportType";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "media-assets-libary-uploaded-assets",
  templateUrl: "./media-assets-libary-uploaded-assets.component.html",
  styleUrls: ["./media-assets-libary-uploaded-assets.component.scss"],
})
export class MediaAssetsLibaryUploadedAssetsComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() selected: MPLibaryMediaAsset;
  @Input() customerId: number;
  @Input() channelId: number;
  @Input() googleDisplayMediaFormatId: number;
  @Input() hideAdMaterials?: boolean = false;
  @Input() isImage?: boolean = true;
  @Input() mediaAsset?: number = MPMediaImportType.MEDIA_IMPORT_TYPE_IMAGE;
  public libaryMediaAssetList: MPLibaryMediaAsset[];
  public isLoading: boolean;
  public page: number = 0;
  public itemsPerPage: number = 12;
  public count: number = 0;
  public totalPages: number = 1;
  public filterType: number = 0;
  readonly LIBARY_MEDIA_ASSET_FILTER_ALL: number =
    MPLibaryMediaAsset.LIBARY_MEDIA_ASSET_FILTER_ALL;
  readonly LIBARY_MEDIA_ASSET_FILTER_UPLOADED_ASSETS: number =
    MPLibaryMediaAsset.LIBARY_MEDIA_ASSET_FILTER_UPLOADED_ASSETS;
  readonly LIBARY_MEDIA_ASSET_FILTER_AD_MATERIAL: number =
    MPLibaryMediaAsset.LIBARY_MEDIA_ASSET_FILTER_AD_MATERIAL;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    if (this.hideAdMaterials) {
      this.filterType = this.LIBARY_MEDIA_ASSET_FILTER_UPLOADED_ASSETS;
    }

    this.getMediaAssets();
  }

  selectionChange($event) {
    if (this.hideAdMaterials) {
      return;
    }

    this.page = 0;
    this.getMediaAssets();
  }

  onPage(page: number) {
    this.page = page;
    this.getMediaAssets();
  }

  async getMediaAssets() {
    this.isLoading = true;

    await this.crudService
      .GetMyMediaAssets(
        this.filterType,
        this.page,
        this.itemsPerPage,
        this.customerId,
        this.channelId,
        this.googleDisplayMediaFormatId,
        this.mediaAsset
      )
      .toPromise()
      .then((res) => {
        if (!res) {
          return;
        }
        this.libaryMediaAssetList = res.MpImages;
        this.count = res.count;
        this.totalPages = Math.ceil(this.count / this.itemsPerPage);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }
}
