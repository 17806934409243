import { Component, Input, OnInit } from "@angular/core";
import { LayoutService } from "app/shared/services/layout.service";

@Component({
  selector: "marketing-portal-editor-content",
  templateUrl: "./marketing-portal-editor-content.component.html",
  styleUrls: ["./marketing-portal-editor-content.component.scss"],
})
export class MarketingPortalEditorContentComponent implements OnInit {
  @Input() isOpenMenu: boolean = false;
  constructor(public layout: LayoutService) {}

  ngOnInit() {}
}
