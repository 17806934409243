import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
  selector: "create-approval-element-comment-form",
  templateUrl: "./create-approval-element-comment-form.component.html",
  styleUrls: ["./create-approval-element-comment-form.component.scss"],
})
export class CreateApprovalElementCommentFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() comment: any;
  @Input() type: string;
  @Input() readonly: boolean;
  @ViewChild("form") form: NgForm;
  public formGroup: FormGroup;
  public errors = {};
  public isProposalOpen: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.setIntiIsOpenProposal();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      note: [this.comment ? this.comment.note : "", Validators.required],
      proposal: this.comment ? this.comment.proposal : "",
    });

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  setIntiIsOpenProposal() {
    if (this.comment && this.comment.proposal != "") {
      this.isProposalOpen = true;
    }
  }

  onOpenProposal() {
    this.isProposalOpen = !this.isProposalOpen;

    if (!this.isProposalOpen) {
      this.formGroup.get("proposal").clearValidators();
      this.formGroup.get("proposal").setValue("");
    } else {
      this.formGroup.get("proposal").setValidators(Validators.required);
    }
    this.formGroup.get("proposal").updateValueAndValidity();
  }

  clearProposal() {
    this.formGroup.get("proposal").setValue("");
  }

  getValidationErrors(): Object {
    const errors = {};

    // FormGroup validation errors
    Object.keys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  onSubmit() {
    this.markFormControls(this.formGroup.controls);
    this.errors = this.getValidationErrors();

    if (this.formGroup.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        this.translate.instant("PleaseFillInAllRequiredFields"),
        "danger"
      );

      return;
    }

    const value = this.formGroup.getRawValue();

    this.submitted.emit(value);
  }
}
