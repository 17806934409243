import { Component, Input, OnInit } from "@angular/core";
import { MPGoalAndPerformance } from "app/model/marketing-portal/MPGoalAndPerformance";
import { SelectMenu } from "app/model/SelectMenu";
import { ChartOptions, ChartType } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: "mc-gender-statistics",
  templateUrl: "./mc-gender-statistics.component.html",
  styleUrls: ["./mc-gender-statistics.component.scss"],
})
export class McGenderStatisticsComponent implements OnInit {
  @Input() genderStatistics;
  @Input() goal: MPGoalAndPerformance;
  public channelMenuItems: SelectMenu[];

  public pieChartOptions: ChartOptions = {
    layout: {
      padding: 50,
    },
    legend: {
      position: "left",
      labels: {
        padding: 10,
      },
    },
    devicePixelRatio: 2,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          return "";
        },
      },
    },
  };

  public colors = { MALE: "#a8cdff", FEMALE: "#ff9a90" };

  public pieChartLabels: any = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [],
    },
  ];

  constructor() {}

  ngOnInit() {
    if (this.genderStatistics.length) {
      this.initFilters();
      this.setData();
    }
  }

  initFilters() {
    this.channelMenuItems = [];

    this.genderStatistics.forEach((statistic, i) => {
      const channelName = statistic.ChannelName;
      const cleanChannelName = channelName.split(" ")[0];

      this.channelMenuItems.push({
        label: channelName,
        value: channelName.toUpperCase(),
        icon: cleanChannelName.toLowerCase() + "Logo",
        isSelected: i === 0,
      });
    });
  }

  setData() {
    const goalName = this.goal.goalInternalName
      ? this.goal.goalInternalName
      : "Impressions"; //: "Clicks"
    let totalValue = 0;
    this.pieChartLabels = [];
    this.pieChartData = [];
    this.pieChartColors[0].backgroundColor = [];

    if (!this.genderStatistics.length) {
      return;
    }

    const selectedMenuItem = this.channelMenuItems.find(
      (item) => item.isSelected
    );

    const genderStatistic = this.genderStatistics.find(
      (statistics) => statistics.ChannelName === selectedMenuItem.label
    );

    genderStatistic.ChannelStatistics.forEach((statistic) => {
      totalValue += statistic.Statistics[goalName];
    });

    genderStatistic.ChannelStatistics.forEach((statistic, i) => {
      this.pieChartLabels.push(statistic.Value);
      this.pieChartData.push(
        Math.round((statistic.Statistics[goalName] / totalValue) * 100)
      );

      if (statistic.hasOwnProperty("InternalValue")) {
        this.pieChartColors[0].backgroundColor.push(
          this.colors[statistic.InternalValue.toUpperCase()]
        );
      }
    });
  }

  selectChannelMenu(menuItem: SelectMenu) {
    this.channelMenuItems.forEach((item, i) => {
      this.channelMenuItems[i].isSelected = item.value === menuItem.value;
    });

    this.setData();
  }
}
