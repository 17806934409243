import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MPNotificationPriority } from "app/model/marketing-portal/MPNotificationPriority";

@Component({
  selector: "notification-priority-status",
  templateUrl: "./notification-priority-status.component.html",
  styleUrls: ["./notification-priority-status.component.scss"],
})
export class NotificationPriorityStatusComponent implements OnInit {
  @Input() status: MPNotificationPriority;
  public colors: object = {
    1: {
      backgroundColor: "background-primary-light",
      color: "primary-color",
      label: this.translate.instant("Low"),
    },
    2: {
      backgroundColor: "background-warning-light",
      color: "warning-color",
      label: this.translate.instant("Medium"),
    },
    3: {
      backgroundColor: "background-danger-light",
      color: "danger-color",
      label: this.translate.instant("High"),
    },
  };

  constructor(private translate: TranslateService) {}

  ngOnInit() {}
}
