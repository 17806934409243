import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Promo } from "../../shared/promoStruct";
import {
  FacebookStorePageTreeModel,
  FacebookCompactPagesModel,
  FacebookUserModel,
  FacebookPageInstaAccountMapping,
} from "app/shared/models/FacebookServiceModels";

import { SetPasswordBindingModel } from "../sessions/forgot-password/forgot-password.component";
import { environment } from "../../../environments/environment";
import { ProductChannelInformation } from "app/model/ProductChannelInformation";
import { ChannelHistory } from "app/model/ChannelHistory";
import { AdPreview } from "app/model/AdPreview";
import { GoogleProductStatus } from "app/model/GoogleProductStatus";
import { ChannelPackage } from "app/model/ChannelPackage";
import { ChannelHistoryPaginationView } from "app/model/ChannelHistoryPaginationView";
import {
  ExternalSystem,
  ExternalSystemUserInformation,
} from "./external-systems/external-systems.component";
import { ExternalSystemCategory } from "./external-systems/settings-external-system/settings-external-system-categories/settings-external-system-categories.component";
import { ChildrenStoresPaginationView } from "app/model/ChildrenStoresPaginationView";
import { SupportedCountry } from "app/model/SupportedCountry";
import { ExternalServiceLinkInfo } from "./external-systems/link-external-system/link-external-system.component";
import { PromoLimitStatus } from "app/model/products/PromoLimitStatus";
import { UserVideoStoreMapping } from "app/model/video-templates/UserVideoStoreMapping";
import { UserVideoTemplate } from "app/model/video-templates/UserVideoTemplate";
import { TemplateInformation } from "app/model/video-templates/TemplateInformation";
import { VideoThemeInformation } from "app/model/video-templates/VideoThemeInformation";
import { CorporateColors } from "app/model/CorporateColors";
import { SubChannelSelection } from "app/model/channel/SubChannelSelection";
import { ChannelSelection } from "app/model/channel/ChannelSelection";
import { ChannelStats } from "app/model/channel/ChannelStats";
import { channelStatistics } from "app/model/channel/ChannelStatistics";
import { channelDetails } from "app/model/channel/ChannelDetails";
import { channelModel } from "app/model/channel/ChannelModel";
import { SubChannelDetails } from "app/model/channel/SubChannelDetails";
import { RecurringBudget } from "app/model/budget/RecurringBudget";
import { UserRecommendation } from "app/model/user/UserRecommendation";
import { ExternalLinks } from "app/model/ExternalLinks";
import { SMCampaign } from "app/model/marketing-portal/SMCampaign";
import { MPGDNCampaign } from "app/model/marketing-portal/MPGDNCampaign";
import { MPMetaData } from "app/model/marketing-portal/MPMetaData";
import { YTCampaign } from "app/model/marketing-portal/YTCampaign";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";
import { MPSound } from "app/model/marketing-portal/MPSound";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { NotificationSettings } from "app/model/settings/NotificationSettings";
import { MPNotification } from "app/model/marketing-portal/MPNotification";
import { MPSocialMediaPlacement } from "app/model/marketing-portal/MPSocialMediaPlacement";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { PexelVideo } from "app/model/stock/PexelVideo";
import { MediaAssetExternalSource } from "app/model/marketing-portal/MediaAssetExternalSource";
import { SavedPaymentMethod } from "app/model/payment/SavedPaymentMethod";
import { PaymentResult } from "app/model/payment/PaymentResult";
import { BudgetBreakDown } from "app/model/payment/BudgetBreakDown";
import { TVCampaignPaginationView } from "app/model/tv/TVCampaignPaginationView";
import { PresalesItem } from "app/model/presales/PresalesItem";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { TemplateSetImage } from "app/model/marketing-portal/TemplateSetImage";
import { MPGAMAdUnit } from "app/model/marketing-portal/MPGAMAdUnit";
import { MPPackage } from "app/model/marketing-portal/MPPackage";
import { MPCampaignSettings } from "app/model/marketing-portal/MPCampaignSettings";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { GAMCampaign } from "app/model/marketing-portal/GAMCampaign";
import { ATVMediaFormat } from "app/model/marketing-portal/ATVMediaFormat";
import { MPTopic } from "app/model/marketing-portal/MPTopic";
import { MPGender } from "app/model/marketing-portal/MPGender";
import { MPAgeGroup } from "app/model/marketing-portal/MPAgeGroup";
import { TVAdsCampaign } from "app/model/marketing-portal/TVAdsCampaign";

@Injectable({
  providedIn: "root",
})
export class CrudService {
  constructor(private http: HttpClient) {}

  private getToken() {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    if (window.localStorage["token"] != null) {
      headers = headers.set(
        "Authorization",
        "Bearer " + window.localStorage["token"]
      );
    }
    return {
      headers,
    };
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  //******* Implement your APIs ********

  uploadStoreImage(form): Observable<any> {
    let myHeader = new HttpHeaders();
    myHeader.set("Content-Type", "application/json");

    return this.http.post(
      environment.backendUrl + "Store/UploadStoreImage",
      form,
      {
        reportProgress: true,
        headers: myHeader,
        observe: "events",
      }
    );
  }

  getItems(): Observable<any> {
    var items = this.http.get<Promo>(
      environment.backendUrl + "GetPromotionFunnel",
      this.getToken()
    );
    //  varname.forEach(element => {
    //    this.items.push(element);
    //  });
    return items;
  }

  getProductOverview(): Observable<any> {
    return this.http.get<Promo>(
      environment.backendUrl + "/GetProductOverview",
      this.getToken()
    );
  }

  GetActivPromoCount(): Observable<any> {
    var items = this.http.get<Promo>(
      environment.backendUrl + "/GetActivPromoCount",
      this.getToken()
    );
    return items;
  }

  getPromoLimitStatus() {
    return this.http.get<PromoLimitStatus>(
      environment.backendUrl + "/GetPromoLimitStatus",
      this.getToken()
    );
  }

  getProductById(promoId: number): Observable<any> {
    return this.http.get<Promo>(
      environment.backendUrl + "Promo/Get?promoId=" + promoId,
      this.getToken()
    );
  }

  getProducts(): Observable<any> {
    var products = this.http.get<Promo>(
      environment.backendUrl + "GetProducts",
      this.getToken()
    );
    // varname.forEach(element => {
    //   this.products.push(element);
    // });
    return products;
  }

  getAllProducts(
    limit,
    offset,
    type,
    searchString,
    order,
    orderBy
  ): Observable<any> {
    const query = `?limit=${limit}&offset=${offset}&type=${type}&searchString=${searchString}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<Promo>(
      environment.backendUrl + "GetAllPromotions" + query,
      this.getToken()
    );
  }

  getProductPagination(limit, offset, searchString): Observable<any> {
    const query = `?limit=${limit}&offset=${offset}&searchText=${searchString}`;
    return this.http.get<Promo>(
      environment.backendUrl + "StoreProductPagination" + query,
      this.getToken()
    );
  }

  GetProductByGTIN(gtin): Observable<any> {
    return this.http.get<Promo>(
      environment.backendUrl + "GetProductByGTIN?eannumber=" + gtin,
      this.getToken()
    );
  }

  // //for the map long/lat
  // getStoreInformation() {
  //   return this.http.get(environment.backendUrl+'GetMyStoreInformation',this.getToken());
  // }

  getLibrary(): Observable<any> {
    var library = this.http.get<Promo>(
      environment.backendUrl + "GetPromotionLibrary",
      this.getToken()
    );
    // varname.forEach(element => {
    //   this.library.push(element);
    // });
    return library;
  }

  //for the map long/lat
  // getOwnLocations(): Observable<any> {
  //   return this.http.get(
  //     environment.backendUrl +
  //       "/Store/getMyStores?partnerAppId=" +
  //       environment.ownPartnerAppId +
  //       "&storeId=" +
  //       window.localStorage["storeId"],
  //     this.getToken()
  //   );
  // }

  getStoreCoordinatesFromAddress(data): Observable<any> {
    return this.http.post(
      environment.backendUrl + "GetStoreCoordinatesFromAddress",
      data,
      this.getToken()
    );
  }

  getLocations(): Observable<any> {
    return this.http.get(
      environment.backendUrl +
        "GetStoreCoordinates?id=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }

  getLocationCods(storeId) {
    return this.http.get(
      environment.backendUrl + "GetStoreCoordinates?id=" + storeId,
      this.getToken()
    );
  }

  //for Tree Component
  public getTree() {
    return this.http.get(
      environment.backendUrl +
        "Store/GetStoresClustered?storeId=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }
  public getStoreTree() {
    return this.http.get(
      environment.backendUrl +
        "Store/GetStoreTree?storeId=" +
        window.localStorage["storeId"],
      this.getToken()
    );
  }

  public getStoreTreeById(storeId: number) {
    return this.http.get(
      environment.backendUrl + "Store/GetStoreTree?storeId=" + storeId,
      this.getToken()
    );
  }

  public getPromotionPreview(storeId: string): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "GetStorePromotions?storeId=" + storeId,
      this.getToken()
    );
  }

  public getStoreSubCategories(catId: any): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreSubCategories?catId=" + catId,
      this.getToken()
    );
  }
  public updatePartnerSelection(newData): Observable<any> {
    return this.http.post<any[]>(
      environment.backendUrl + "Store/updatePartnerSelection",
      newData,
      this.getToken()
    );
  }
  public getPartnerSelection(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "Store/getPartnerSelection",
      this.getToken()
    );
  }

  //NEU FÜR BACKEND VERBINDUNG

  //for product
  public getProduct() {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProduct",
      this.getToken()
    );
  }

  //for unit
  public getUnit(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductUnits",
      this.getToken()
    );
  }

  //for category
  public getCategory() {
    return this.http.get(
      environment.backendUrl + "StoreProductCategory",
      this.getToken()
    );
  }

  public getColors(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductColors",
      this.getToken()
    );
  }
  public getShoeSizes(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductShoeSizes",
      this.getToken()
    );
  }
  public getGenders(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductGender",
      this.getToken()
    );
  }
  public getAgegroups(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductAgeGroup",
      this.getToken()
    );
  }
  public getFashionSizes(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductFashionSizes",
      this.getToken()
    );
  }

  public getConditions(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductConditions",
      this.getToken()
    );
  }

  public getEnergyEfficiencyCategories(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "StoreProductEnergyEfficiencyCategories",
      this.getToken()
    );
  }

  public getTContracts(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "GetTContracts",
      this.getToken()
    );
  }

  public getDefaultValues(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "User/GetDefaultValues",
      this.getToken()
    );
  }

  //ENDE NEU FÜR BACKEND VERBINDUNG

  //START Statistics
  public getTotalClicks() {
    return this.http.get(
      environment.backendUrl + "stat/GetTotalClicks",
      this.getToken()
    );
  }

  public getTotalImpressions() {
    return this.http.get(
      environment.backendUrl + "stat/GetTotalImpressions",
      this.getToken()
    );
  }

  public getClickCounterDays(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetClickCounterDays",
      this.getToken()
    );
  }

  public getClickCounterWeeks(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetClickCounterWeeks",
      this.getToken()
    );
  }

  public getClickCounterMonths(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetClickCounterMonths",
      this.getToken()
    );
  }

  public getMostPopularHours(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetMostPopularHours",
      this.getToken()
    );
  }
  public getCategoryRanking(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetCategoryRanking",
      this.getToken()
    );
  }
  public getPromotionRankingFirstThree(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetPromotionRankingFirstThree",
      this.getToken()
    );
  }

  public getPromotionRanking(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "stat/GetPromotionRanking",
      this.getToken()
    );
  }

  public getAllChannelsStatistics(start?, end?) {
    return this.http.get<channelStatistics[]>(
      environment.backendUrl +
        "Channel/GetAllChannelStatistic?start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getChannelStats(channelId: number) {
    return this.http.get<ChannelStats>(
      environment.backendUrl +
        "Channel/GetChannelStatistic?channelId=" +
        channelId,
      this.getToken()
    );
  }

  public getSearchKeyStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetSearchKeysStatistics",
      this.getToken()
    );
  }

  public getAgeRangeStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetAgeRangeStatistics",
      this.getToken()
    );
  }

  public getGenderStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetGenderStatistics",
      this.getToken()
    );
  }

  public getTotalStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetTotalStatistics",
      this.getToken()
    );
  }

  public getTotalFreeStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetTotalFreeStatistics",
      this.getToken()
    );
  }

  public getWeeklyProductStatistics(
    limit,
    offset,
    order,
    orderBy,
    searchString
  ) {
    const query = `?limit=${limit}&offset=${offset}&order=${order}&orderBy=${orderBy}&searchString=${searchString}`;
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetWeeklyProductStatistics" + query,
      this.getToken()
    );
  }

  public getWeeklyProductStatisticsByProductId(productId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "Channel/GetWeeklyProductStatistics?productId=" +
        productId,
      this.getToken()
    );
  }

  public getWeeklyProductStatisticsSummaryByChannel(productId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "Channel/GetWeeklyProductStatisticsSummaryByChannel?productId=" +
        productId,
      this.getToken()
    );
  }

  public getWeeklyProductStatisticsByChannel(productId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "Channel/GetWeeklyProductStatisticsByChannel?productId=" +
        productId,
      this.getToken()
    );
  }

  public getLocationsStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetLocationsStatistics",
      this.getToken()
    );
  }

  public getWeeklyStatistics() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetWeeklyStatistics",
      this.getToken()
    );
  }

  public getWeeklyStatisticsSummary(type: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "Channel/GetWeeklyStatisticsSummary?type=" +
        type,
      this.getToken()
    );
  }

  public getAllTvCampaigns(page: number, itemsPerPage: number) {
    return this.http.get<TVCampaignPaginationView>(
      environment.backendUrl +
        `stat/GetAllTvCampaigns?page=${page}&itemsPerPage=${itemsPerPage}`,
      this.getToken()
    );
  }

  public getAllTvCampaignImpressionsPerChannel(data: object = {}) {
    return this.http.post(
      environment.backendUrl + "stat/GetAllTvCampaignImpressionsPerChannel",
      data,
      this.getToken()
    );
  }

  public getTvCampaignImpressionsPerChannel(
    campaignName: string,
    data: object = {}
  ) {
    return this.http.post(
      environment.backendUrl +
        `stat/GetTvCampaignImpressionsPerChannel?campaignName=${campaignName}`,
      data,
      this.getToken()
    );
  }

  public getTVCampaignImages(campaignName: string) {
    return this.http.get<any>(
      environment.backendUrl +
        `stat/GetTVCampaignImages?campaignName=${campaignName}`,
      this.getToken()
    );
  }

  public getTvCampaign(campaignName: string) {
    return this.http.get<any>(
      environment.backendUrl +
        `stat/GetTvCampaign?campaignName=${campaignName}`,
      this.getToken()
    );
  }

  public getSingleCampaignGroupedTVImpressions(
    campaignName: string,
    type: number = 0
  ) {
    return this.http.get<any>(
      environment.backendUrl +
        `stat/getSingleCampaignGroupedTVImpressions?campaignName=${campaignName}&type=${type}`,
      this.getToken()
    );
  }

  public getChannels() {
    return this.http.get<ChannelStats>(
      environment.backendUrl + "Channel/GetChannels",
      this.getToken()
    );
  }

  public getChannelDetails(channelId: number) {
    return this.http.get<channelDetails[]>(
      environment.backendUrl +
        "Channel/GetChannelDetails?channelId=" +
        channelId,
      this.getToken()
    );
  }

  public getChannelInformation(channelId: number) {
    return this.http.get<channelModel>(
      environment.backendUrl +
        "Channel/GetChannelInformation?channelId=" +
        channelId,
      this.getToken()
    );
  }

  public getSubChannels() {
    return this.http.get<SubChannelDetails[]>(
      environment.backendUrl + "Channel/GetSubChannels",
      this.getToken()
    );
  }

  public getChannelHistoryByProduct(productId = null) {
    return this.http.get<ChannelHistory[]>(
      environment.backendUrl +
        `Channel/GetChannelHistoryForProduct${
          productId ? "?productId=" + productId : ""
        }`,
      this.getToken()
    );
  }

  public getChannelHistory(
    startDate,
    endDate,
    channelId,
    activity,
    offset,
    limit,
    searchText,
    order,
    orderBy
  ) {
    const query = `?startDate=${startDate}&endDate=${endDate}&channelId=${channelId}&activity=${activity}&offset=${offset}&limit=${limit}&searchText=${searchText}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<ChannelHistoryPaginationView>(
      environment.backendUrl + "Channel/GetChannelHistory" + query,
      this.getToken()
    );
  }

  public getChannelHistoryByWeek(
    channelsIds: number[],
    startDate: Date,
    endDate: Date
  ) {
    let query = "";

    if (channelsIds) {
      query === "" ? (query += "?") : (query += "&");
      query += `channelIds=${channelsIds}`;
    }

    if (startDate) {
      query === "" ? (query += "?") : (query += "&");
      query += `&startDate=${startDate}`;
    }

    if (endDate) {
      query === "" ? (query += "?") : (query += "&");
      query += `&endDate=${endDate}`;
    }

    return this.http.post<ChannelHistoryPaginationView>(
      environment.backendUrl + "Channel/GetChannelHistoryByWeek" + query,
      {},
      this.getToken()
    );
  }

  public getFutureChannelActivity() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetFutureChannelActivity",
      this.getToken()
    );
  }

  // Apis for Packages

  public getChannelPackages(): Observable<ChannelPackage[]> {
    return this.http.get<ChannelPackage[]>(
      environment.backendUrl + "Channel/GetChannelPackages",
      this.getToken()
    );
  }

  // APIs for Orders (Get)

  public getOrders(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "OrderProcessing/GetOrders",
      this.getToken()
    );
  }
  public getOrder(id: number): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "OrderProcessing/GetOrder?orderId=" + id,
      this.getToken()
    );
  }
  public getOrdersUserId(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "OrderProcessing/GetOrder?userId=",
      this.getToken()
    );
  }

  public getOrderStatuses(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "OrderProcessing/GetOrderStatuses",
      this.getToken()
    );
  }

  public getShippingStatus(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "OrderProcessing/GetShippingStatuses",
      this.getToken()
    );
  }

  public getPaymentStatuses(): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl + "OrderProcessing/GetPaymentStatuses",
      this.getToken()
    );
  }

  // APIs for Orders (Post)

  public updateOrder(data) {
    return this.http.post(
      environment.backendUrl + "OrderProcessing/UpdateOrder",
      data,
      this.getToken()
    );
  }

  public updateOrderStatus(data) {
    return this.http.post(
      environment.backendUrl + "OrderProcessing/UpdateOrderStatus",
      data,
      this.getToken()
    );
  }

  ///////////////////////

  ///////////////////////////////////

  public getTotalClicks2(storeId?, start?, end?) {
    return this.http.get(
      environment.backendUrl +
        "stat/GetTotalClicks?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getTotalImpressions2(storeId?, start?, end?) {
    return this.http.get(
      environment.backendUrl +
        "stat/GetTotalImpressions?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getClickCounterDays2(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetClickCounterDays?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getClickCounterWeeks2(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetClickCounterWeeks?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getClickCounterMonths2(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetClickCounterMonths?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getMostPopularHours2(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetMostPopularHours?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }
  public getCategoryRanking2(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetCategoryRanking?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }
  public getSubCategoryRanking(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetSubCategoryRanking?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }
  public getPromotionRankingFirstThree2(
    storeId?,
    start?,
    end?
  ): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetPromotionRankingFirstThree?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getPromotionRanking2(storeId?, start?, end?): Observable<any> {
    return this.http.get<any[]>(
      environment.backendUrl +
        "stat/GetPromotionRanking?storeId=" +
        storeId +
        "&start=" +
        start +
        "&end=" +
        end,
      this.getToken()
    );
  }

  public getExternalLinks() {
    return this.http.get<ExternalLinks>(
      environment.backendUrl + "User/GetExternalLinks",
      this.getToken()
    );
  }

  public getUserRecommendations() {
    return this.http.get<UserRecommendation[]>(
      environment.backendUrl + "User/GetUserRecommendations",
      this.getToken()
    );
  }

  public getUserTypes() {
    return this.http.get<any[]>(
      environment.backendUrl + "User/GetAllUserTypes"
    );
  }

  public getAllDocuments(mpPartnerId: number) {
    return this.http.get<any[]>(
      environment.backendUrl + "User/GetAllDocuments?mpPartnerId=" + mpPartnerId
    );
  }

  public getSKDocuments() {
    return this.http.get<any[]>(environment.backendUrl + "User/GetSKDocuments");
  }

  public logInput(input: string) {
    return this.http.post<any[]>(
      environment.backendUrl + "User/logInput?input=" + input,
      null
    );
  }

  public signUp(signUpData) {
    return this.http.post(
      environment.backendUrl + "Admin/SignUpUser",
      signUpData
    );
  }

  public signUpSk(signUpDataSk) {
    return this.http.post(
      environment.backendUrl + "Admin/SignUpUserWithSparkasse",
      signUpDataSk
    );
  }

  public signUpKorona(signUpDataKorona) {
    return this.http.post(
      environment.backendUrl + "Korona/SignUpUserWithKorona",
      signUpDataKorona
    );
  }

  public GetKoronaAccountId(token) {
    return this.http.get<any[]>(
      environment.backendUrl + "Korona/GetKoronaAccountId?token=" + token
    );
  }

  public logEvent(
    type: number,
    message: string,
    value: any,
    isAssistant: boolean = false
  ) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/sendlRequest?type=${type}&message=${message}&externalValue=${value}&isAssistant=${isAssistant}`,
      this.getToken()
    );
  }

  public UploadFile(
    myFile: any,
    isTransparent: boolean = false
  ): Observable<any> {
    var myform = new FormData();
    myform.append("Upload_Image", myFile);

    //header insert
    let myHeader = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    myHeader.set("Content-Type", "application/json");
    return this.http.post(
      environment.backendUrl +
        "Promo/UploadPromoImage?isTransparent=" +
        isTransparent,
      myform,
      { reportProgress: true, headers: myHeader, observe: "events" }
    );
  }

  public uploadCampaignImageAssets(
    myFile: any,
    isTransparent: boolean = false,
    scaleImage: boolean = false,
    customerId: number = null,
    externalSource = null,
    credits = null,
    originalSource = null
  ) {
    var myform = new FormData();
    myform.append("Upload_Image", myFile);

    //header insert
    let myHeader = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    myHeader.set("Content-Type", "application/json");

    let url = `MarketingPortal/AddCampaignImageAsset?isTransparent=${isTransparent}&scaleImage=${scaleImage}`;

    if (customerId) {
      url += `&customerId=${customerId}`;
    }

    if (externalSource) {
      url += `&externalSource=${externalSource}&credits=${encodeURIComponent(
        credits
      )}&originalSource=${originalSource}`;
    }

    return this.http.post(environment.backendUrl + url, myform, {
      reportProgress: true,
      headers: myHeader,
      observe: "events",
    });
  }

  public uploadCampaignVideoAssets(
    myFile: any,
    width: number = null,
    height: number = null,
    duration: number = null,
    customerId: number = null,
    externalSource = null,
    credits = null,
    originalSource = null
  ) {
    var myform = new FormData();
    myform.append("Upload_Image", myFile);

    // if (width) {
    //   myform.append("width", width.toString());
    // }

    // if (height) {
    //   myform.append("height", height.toString());
    // }

    if (duration) {
      duration = Math.round(duration);
    }

    //header insert
    let myHeader = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    myHeader.set("Content-Type", "application/json");

    let url = `MarketingPortal/AddCampaignVideoAsset?width=${width}&height=${height}&duration=${duration}`;

    if (customerId) {
      url += `&customerId=${customerId}`;
    }

    if (externalSource) {
      url += `&externalSource=${externalSource}&credits=${encodeURIComponent(
        credits
      )}&originalSource=${originalSource}`;
    }

    return this.http.post(environment.backendUrl + url, myform, {
      reportProgress: true,
      headers: myHeader,
      observe: "events",
    });
  }

  //END Statitistics

  addItem(item): Observable<any> {
    return this.http.post(
      environment.backendUrl + "Promo",
      item,
      this.getToken()
      //  {
      //   headers:new HttpHeaders()
      //   .set('Content-Type','application/json')
      //   }
    );

    //  return this.http.get("http://141.99.153.112:45455/api/GetStoreProducts").pipe(delay(1000));
  }

  updateItem(id, item) {
    return this.http.post(
      environment.backendUrl + "Promo/Modify",
      item,
      this.getToken()
    );
  }

  // removeProduct(productId) {

  deactivateItem(row) {
    return this.http.post(
      environment.backendUrl + "Promo/Deactivate",
      row,
      this.getToken()
    );
  }

  removeItem(row) {
    return this.http.post(
      environment.backendUrl + "Promo/Delete",
      row,
      this.getToken()
    );
  }
  public getVirtualStores() {
    return this.http.get(
      environment.backendUrl + "GetVirtualStores",
      this.getToken()
    );
  }
  public addVirtualStore(data) {
    return this.http.post(
      environment.backendUrl + "AddVirtualStore",
      data,
      this.getToken()
    );
  }
  public deleteVirtualStore(storeId) {
    return this.http.post(
      environment.backendUrl + "DeleteVirtualStore?storeId=" + storeId,
      storeId,
      this.getToken()
    );
  }
  public getChildStores(vStoreId) {
    return this.http.get(
      environment.backendUrl + "GetAllChildStores?virtualStoreId=" + vStoreId,
      this.getToken()
    );
  }

  public getChildrenStoresWithFilter(
    vStoreId: number,
    limit: number,
    offset: number,
    searchString: string,
    order: string,
    orderBy: number
  ) {
    const query = `?limit=${limit}&offset=${offset}&virtualStoreId=${vStoreId}&searchString=${searchString}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<ChildrenStoresPaginationView>(
      environment.backendUrl + "GetChildrenStoresWithFilter" + query,
      this.getToken()
    );
  }

  public getVirtualStoresWithFilter(
    offset: number,
    limit: number,
    searchString: string,
    order: string,
    orderBy: number
  ) {
    const query = `?limit=${limit}&offset=${offset}&searchString=${searchString}&order=${order}&orderBy=${orderBy}`;
    return this.http.get<ChildrenStoresPaginationView>(
      environment.backendUrl + "GetVirtualStoresWithFilter" + query,
      this.getToken()
    );
  }

  public updateStore(data) {
    return this.http.post(
      environment.backendUrl + "UpdateStoreDetailsApp",
      data,
      this.getToken()
    );
  }
  public deleteStore(storeId) {
    return this.http.get(
      environment.backendUrl + "DeleteStore?storeId=" + storeId,
      this.getToken()
    );
  }
  public addStore(data) {
    return this.http.post(
      environment.backendUrl + "AddStore",
      data,
      this.getToken()
    );
  }
  public getSupportedCountries() {
    return this.http.get<SupportedCountry[]>(
      environment.backendUrl + "GetCountries"
      // this.getToken()
    );
  }
  public addStoreِApp(storeData) {
    return this.http.post(
      environment.backendUrl + "AddStoreApp",
      storeData,
      this.getToken()
    );
  }
  public getStoreDetails(storeId): Observable<any> {
    return this.http.get(
      environment.backendUrl + "GetStoreDetails?storeId=" + storeId,
      this.getToken()
    );
  }

  public getBase64ImageFromUrl(url) {
    return this.http.get(url);
  }

  public togglePromotionActivate(id) {
    return true;
  }

  public addProduct(data) {
    return this.http.post(
      environment.backendUrl + "Product/Create",
      data,
      this.getToken()
    );
  }
  public modifyProduct(data) {
    return this.http.post(
      environment.backendUrl + "Product/Modify",
      data,
      this.getToken()
    );
  }

  public deleteProduct(data) {
    return this.http.post(
      environment.backendUrl + "Product/Delete",
      data,
      this.getToken()
    );
  }

  // Facebook Apis
  public CheckFacebookRegisteredUser(): Observable<boolean> {
    return this.http.get<boolean>(
      environment.facebookUrl + "FacebookConnectivity/CheckRegisteredUser",
      this.getToken()
    );
  }
  public InitializeFacebookUser(token) {
    return this.http.post(
      environment.facebookUrl + "FacebookConnectivity/InitializeUser",
      { access_token: token },
      this.getToken()
    );
  }
  public GetFacebookUserToken() {
    return this.http.get(
      environment.facebookUrl + "FacebookConnectivity/GetUserToken=",
      this.getToken()
    );
  }
  public GetMyFacebookPages(): Observable<FacebookCompactPagesModel[]> {
    return this.http.get<FacebookCompactPagesModel[]>(
      environment.facebookUrl + "FacebookConnectivity/GetMyPages",
      this.getToken()
    );
  }

  public GetAllFacebookPageDetails(): Observable<any> {
    return this.http.get<FacebookCompactPagesModel[]>(
      environment.facebookUrl +
        "FacebookConnectivity/GetAllFacebookPageDetails",
      this.getToken()
    );
  }

  public GetFacebookStorePageTree(): Observable<FacebookStorePageTreeModel> {
    return this.http.get<FacebookStorePageTreeModel>(
      environment.facebookUrl + "FacebookConnectivity/GetStorePageTree",
      this.getToken()
    );
  }

  public searchForFacebookPage(query: string): Observable<any> {
    return this.http.get<any[]>(
      environment.facebookUrl +
        "FacebookSearch/SearchForFacebookPage?query=" +
        query,
      this.getToken()
    );
  }

  // public UpdateFacebookStorePageTree(tree: FacebookStorePageTreeModel) {
  //   return this.http.post(
  //     environment.backendUrl + "FacebookConnectivity/UpdateStorePageTree",
  //     tree,
  //     this.getToken()
  //   );
  // }
  public GetFacebookUserInformation(): Observable<FacebookUserModel> {
    return this.http.get<FacebookUserModel>(
      environment.facebookUrl + "FacebookConnectivity/GetMyUserInformation",
      this.getToken()
    );
  }
  public SyncFacebookUser(accessToken = null) {
    if (accessToken == null) {
      return this.http.post(
        environment.facebookUrl + "FacebookConnectivity/SyncUser",
        null,
        this.getToken()
      );
    } else {
      let myHeader = new HttpHeaders().set(
        "Authorization",
        "Bearer " + accessToken
      );
      myHeader.set("Content-Type", "application/json");
      return this.http.post(
        environment.facebookUrl + "FacebookConnectivity/SyncUser",
        null,
        {
          headers: myHeader,
        }
      );
    }
  }

  // public SendPromoToFacebookPage(promo: FacebookPromotionRequestModel) {
  //   return this.http.post(
  //     environment.backendUrl + "FacebookConnectivity/SendPromotionToFacebookPage",
  //     promo,
  //     this.getToken()
  //   );
  // }
  // public CreateFacebookAd(promoId) {
  //   return this.http.get(
  //     environment.backendUrl + "facebookAds/Custom?promoId=" + promoId,
  //     this.getToken()
  //   );
  // }
  // public GetFacebookCampaigns(): Observable<any> {
  //   return this.http.get(
  //     environment.backendUrl + "facebookAds/Campaigns",
  //     this.getToken()
  //   );
  // }
  // public GetFacebookAdsets(): Observable<any> {
  //   return this.http.get(
  //     environment.backendUrl + "facebookAds/Adsets",
  //     this.getToken()
  //   );
  // }
  // public GetFacebookAds(): Observable<any> {
  //   return this.http.get(
  //     environment.backendUrl + "facebookAds/Ads",
  //     this.getToken()
  //   );
  // }

  public GetFacebookAdPreviews(AdId: string): Observable<AdPreview[]> {
    return this.http.get<AdPreview[]>(
      environment.facebookUrl + "FacebookConnectivity/AdPreview?adId=" + AdId,
      this.getToken()
    );
  }
  // public GetFacebookAdsActiveStatus(): Observable<FacebookAdvertisementActive> {
  //   return this.http.get<FacebookAdvertisementActive>(
  //     environment.backendUrl + "facebookAds/Activate",
  //     this.getToken()
  //   );
  // }
  // public SetFacebookAdsActiveStatus(
  //   body: FacebookAdvertisementActive
  // ): Observable<any> {
  //   return this.http.post(
  //     environment.backendUrl + "facebookAds/Activate",
  //     body,
  //     this.getToken()
  //   );
  // }

  public GetAllPermissions(): Observable<string[]> {
    return this.http.get<string[]>(
      environment.facebookUrl + "FacebookConnectivity/GetAllPermissions",
      this.getToken()
    );
  }
  public UpdateFacebookUserToken(token) {
    return this.http.post(
      environment.facebookUrl + "FacebookConnectivity/UpdateUserToken",
      { access_token: token },
      this.getToken()
    );
  }

  public CheckLinkedInstagramAccount(): Observable<boolean> {
    return this.http.get<boolean>(
      environment.facebookUrl + "Instagram/CheckLinkedAccount",
      this.getToken()
    );
  }
  public GetLinkedInstagramAccounts(): Observable<
    FacebookPageInstaAccountMapping[]
  > {
    return this.http.get<FacebookPageInstaAccountMapping[]>(
      environment.facebookUrl + "Instagram/GetLinkedAccount",
      this.getToken()
    );
  }
  public RemoveUser() {
    return this.http.post(
      environment.facebookUrl + "FacebookConnectivity/RemoveUser",
      null,
      this.getToken()
    );
  }
  // public GetFacebookPosts(): Observable<FacebookPostModel[]> {
  //   return this.http.get<FacebookPostModel[]>(
  //     environment.facebookUrl + "FacebookInsights/GetMyPosts",
  //     this.getToken()
  //   );
  // }

  // public GetFacebookRunningAdsStats(): Observable<
  //   FacebookInsightsProductModel[]
  // > {
  //   return this.http.get<FacebookInsightsProductModel[]>(
  //     environment.backendUrl + "FacebookInsights/Ads/GetRunningFacebookStats",
  //     this.getToken()
  //   );
  // }
  // public GetInstagramRunningAdsStats(): Observable<
  //   FacebookInsightsProductModel[]
  // > {
  //   return this.http.get<FacebookInsightsProductModel[]>(
  //     environment.backendUrl + "FacebookInsights/Ads/GetRunningInstagramStats",
  //     this.getToken()
  //   );
  // }
  // public GetInstagramPastAdsStats(): Observable<
  //   FacebookInsightsProductModel[]
  // > {
  //   return this.http.get<FacebookInsightsProductModel[]>(
  //     environment.backendUrl + "FacebookInsights/Ads/GetPastInstagramAds",
  //     this.getToken()
  //   );
  // }
  // public GetPastFacebookAdsStats(): Observable<FacebookInsightsProductModel[]> {
  //   return this.http.get<FacebookInsightsProductModel[]>(
  //     environment.backendUrl + "FacebookInsights/Ads/GetPastFacebookAds",
  //     this.getToken()
  //   );
  // }

  public getFAQs() {
    return this.http.get<any>(
      environment.backendUrl + "Info/GetFAQs",
      this.getToken()
    );
  }

  public GetAccounts() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetAccounts",
      this.getToken()
    );
  }

  public GetChildStoreStatus(storeId) {
    return this.http.get<any>(
      environment.backendUrl + "GetChildStoreStatus?virtualStoreId=" + storeId,
      this.getToken()
    );
  }
  public GetBudgetSummary() {
    return this.http.get<any>(
      environment.backendUrl + "User/GetBudgetSummary",
      this.getToken()
    );
  }
  public GetReach() {
    return this.http.get<any>(
      environment.backendUrl + "User/GetReach",
      this.getToken()
    );
  }

  public GetBudget() {
    return this.http.get<any>(
      environment.backendUrl + "User/GetBudget",
      this.getToken()
    );
  }
  public GetLastPayments() {
    return this.http.get<any>(
      environment.backendUrl + "User/GetLastPayments",
      this.getToken()
    );
  }

  // public SetBudget(amount: number) {
  //   return this.http.get<any>(
  //     environment.backendUrl + "User/SetBudget?amount=" + amount,
  //     this.getToken()
  //   );
  // }

  public setDefaultValues(defaultData) {
    return this.http.post(
      environment.backendUrl + "User/SetDefaultValues",
      defaultData,
      this.getToken()
    );
  }

  public updateVirtualStore(storeData) {
    return this.http.post(
      environment.backendUrl + "UpdateVirtualStore",
      storeData,
      this.getToken()
    );
  }
  public changePassword(passwordData) {
    return this.http.post(
      environment.backendUrl + "Account/ChangePassword",
      passwordData,
      this.getToken()
    );
  }

  public GetGoogleAccount(refresh: boolean, includeProds: boolean = true) {
    return this.http.get<any>(
      environment.googleUrl +
        "GoogleChannel/GetGoogleAccount?detailed=" +
        refresh +
        "&includeProdInfos=" +
        includeProds,
      this.getToken()
    );
  }

  public GetFacebookAccount() {
    return this.http.get<any>(
      environment.facebookUrl + "FacebookChannel/GetFacebookAccount",
      this.getToken()
    );
  }

  public getSparkasseAccount() {
    return this.http.get<any>(
      environment.backendUrl + "Sparkasse/GetSparkasseAccount",
      this.getToken()
    );
  }

  public updateSparkasseAccount() {
    return this.http.get<any>(
      environment.backendUrl + "Sparkasse/UpdateSparkasseAccount",
      this.getToken()
    );
  }

  public GetProductChannelInformation(promoId: number) {
    return this.http.get<ProductChannelInformation[]>(
      environment.backendUrl +
        `Channel/GetProductChannelInformation?promoId=${promoId}`,
      this.getToken()
    );
  }

  public GetStorePageMapping() {
    return this.http.get<any>(
      environment.facebookUrl + "FacebookConnectivity/StorePageMapping",
      this.getToken()
    );
  }

  public PostStorePageMapping(data: any) {
    return this.http.post(
      environment.facebookUrl + "FacebookConnectivity/StorePageMapping",
      data,
      this.getToken()
    );
  }
  public SendRequestsToPages() {
    return this.http.get<any>(
      environment.facebookUrl + "FacebookConnectivity/SendRequestsToPages",
      this.getToken()
    );
  }

  public createOrUpdateGoolgeAccount() {
    return this.http.get<any>(
      environment.googleUrl + "Google/CreateOrUpdateAccount",
      this.getToken()
    );
  }

  public updateGoogleMyBusiness(email: string) {
    return this.http.get<any>(
      environment.googleUrl +
        "Google/UpdateGoogleMyBusiness?googleMyBusinessEmail=" +
        email,
      this.getToken()
    );
  }

  /*
    type = 1 --> Automatically
    type = 2 --> Manually
  */
  public setGoogleOnboardingPreferences(type: number) {
    return this.http.get<any>(
      environment.googleUrl + "Google/SetOnboardingPreferences?type=" + type,
      this.getToken()
    );
  }

  public getGoogleStoreCodeMapping() {
    return this.http.get<any>(
      environment.googleUrl + "Google/GetStoreCodeMapping",
      this.getToken()
    );
  }

  public updateGoogleStoreCodeMapping(data: any) {
    return this.http.post<any>(
      environment.googleUrl + "Google/UpdateStoreCodeMapping",
      data,
      this.getToken()
    );
  }

  public requestGoogleInventoryVerification() {
    return this.http.get<any>(
      environment.googleUrl + "Google/RequestInventoryVerification",
      this.getToken()
    );
  }

  public getGoogleProductStatus() {
    return this.http.get<GoogleProductStatus[]>(
      environment.googleUrl + "Google/GetProductStatus",
      this.getToken()
    );
  }

  public CancelAllSubscriptions() {
    return this.http.get<ChannelStats>(
      environment.backendUrl + "Payment/CancelAllSubscriptions",
      this.getToken()
    );
  }

  public GetAllRecurringPaymentMethods() {
    return this.http.get<any[]>(
      environment.backendUrl + "Payment/GetAllRecurringPaymentMethods",
      this.getToken()
    );
  }
  public GetCurrentSubscriptionAmount() {
    return this.http.get<ChannelStats>(
      environment.backendUrl + "Payment/GetCurrentSubscriptionAmount",
      this.getToken()
    );
  }

  public UpdateRecBudgetForUser(data: RecurringBudget) {
    return this.http.post(
      environment.backendUrl + "Payment/UpdateRecBudgetForUser",
      data,
      this.getToken()
    );
  }

  public createMpCampaignPayment(
    mpCampaignId: number,
    savePaymentDetails: boolean = false,
    couponCode: string = null
  ) {
    return this.http.post(
      environment.backendUrl +
        `Payment/CreateMpCampaignPayment?mpCampaignId=${mpCampaignId}&savePaymentDetails=${savePaymentDetails}`,
      JSON.stringify(couponCode),
      this.getToken()
    );
  }

  public createMpCampaignPaymentFromSavedSource(
    mpCampaignId: number,
    paymentMethod: string,
    couponCode: string = null
  ) {
    return this.http.post<PaymentResult>(
      environment.backendUrl +
        `Payment/CreateMpCampaignPaymentFromSavedSource?mpCampaignId=${mpCampaignId}&paymentMethod=${paymentMethod}`,
      JSON.stringify(couponCode),
      this.getToken()
    );
  }

  public confirmPaymentForMpCampaign(
    mpCampaignId: number,
    couponCode: string = null
  ) {
    return this.http.post(
      environment.backendUrl +
        `Payment/ConfirmPaymentForMpCampaign?mpCampaignId=${mpCampaignId}`,
      JSON.stringify(couponCode),
      this.getToken()
    );
  }

  public getUserSavedPaymentMethods() {
    return this.http.get<SavedPaymentMethod[]>(
      environment.backendUrl + "Payment/GetUserSavedPaymentMethods",
      this.getToken()
    );
  }

  public deleteUserSavedPaymentMethod(paymentMethod: string) {
    return this.http.get<SavedPaymentMethod[]>(
      environment.backendUrl +
        "Payment/DeleteUserSavedPaymentMethod?paymentMethod=" +
        paymentMethod,
      this.getToken()
    );
  }

  public getMpCampaignPaymentBreakdown(mpCampaignId, couponCode: string) {
    return this.http.post<BudgetBreakDown>(
      environment.backendUrl +
        "Payment/GetMPCampaignBudgetBreakDown?mpCampaignId=" +
        mpCampaignId,
      JSON.stringify(couponCode),
      this.getToken()
    );
  }

  public ForgetPassword(email) {
    return this.http.get(
      environment.backendUrl + "Account/ForgetPassword?email=" + email
    );
  }

  public SetNewPassword(data: SetPasswordBindingModel) {
    return this.http.post(environment.backendUrl + "Account/SetPassword", data);
  }

  public GetAllVideoTemplates(mobile: boolean = false) {
    return this.http.get<any>(
      environment.backendUrl + "User/GetAllVideoTemplates?mobile=" + mobile,
      this.getToken()
    );
  }

  public GetVideoStoreMapping(mobile: boolean = false) {
    return this.http.get<any>(
      environment.backendUrl + "User/GetVideoStoreMapping?mobile=" + mobile,
      this.getToken()
    );
  }

  public SetVideoStoreMapping(data: any, mobile: boolean = false) {
    return this.http.post(
      environment.backendUrl + "User/SetVideoStoreMapping?mobile=" + mobile,
      data,
      this.getToken()
    );
  }

  public downloadAuthFile(url: string) {
    let myHeader = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    return this.http.get(url, { headers: myHeader, responseType: "blob" });
  }

  public ShowAssistent(): Observable<boolean> {
    var items = this.http.get<boolean>(
      environment.backendUrl + "User/ShowAssistent",
      this.getToken()
    );
    return items;
  }

  public DisableAssistent(): Observable<boolean> {
    var items = this.http.get<boolean>(
      environment.backendUrl + "User/DisableAssistent",
      this.getToken()
    );
    return items;
  }

  public getVideoStoreMapping(storeId: number) {
    let url = environment.backendUrl + "User/GetVideoStoreMapping";

    if (storeId) {
      url += "?storeId=" + storeId;
    }

    return this.http.get<UserVideoStoreMapping[]>(url, this.getToken());
  }

  public getAllVideoTemplatesNew(storeId: number) {
    let url = environment.backendUrl + "User/GetAllVideoTemplatesNew";

    if (storeId) {
      url += "?storeId=" + storeId;
    }

    return this.http.get<UserVideoTemplate[]>(url, this.getToken());
  }

  public getAllVideoTemplatesWithThemes(storeId: number) {
    let url = environment.backendUrl + "User/GetAllVideoTemplatesWithThemes";

    if (storeId) {
      url += "?storeId=" + storeId;
    }

    return this.http.get<UserVideoTemplate[]>(url, this.getToken());
  }

  public getTemplateInformation(
    templateId: number,
    virtualStoreId: number,
    storeId: number
  ) {
    let url =
      environment.backendUrl +
      `User/GetTemplateInformation?templateId=${templateId}&virtualStoreId=${virtualStoreId}`;

    if (storeId) {
      url += "&storeId=" + storeId;
    }

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public setTemplateInformation(
    virtualStoreId: number,
    templateInformation: TemplateInformation
  ) {
    let url =
      environment.backendUrl +
      `User/SetTemplateInformation?virtualStoreId=${virtualStoreId}`;

    return this.http.post<TemplateInformation>(
      url,
      templateInformation,
      this.getToken()
    );
  }

  public setTemplateInformationForAllStores(
    rootStoreId: number,
    data: TemplateInformation[]
  ) {
    let url =
      environment.backendUrl +
      `User/SetTemplateInformationForAllStores?rootStoreId=${rootStoreId}`;

    return this.http.post<TemplateInformation>(url, data, this.getToken());
  }

  public getTemplateInformationForAllStores(virtualStoreId: number) {
    let url =
      environment.backendUrl +
      `User/GetTemplateInformationForAllStores?virtualStoreId=${virtualStoreId}`;

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public getTemplateThemeInformation(templateId: number, storeId?: number) {
    let url =
      environment.backendUrl +
      `User/GetTemplateThemeInformation?templateId=${templateId}`;

    if (storeId) {
      url += "&storeId=" + storeId;
    }

    return this.http.get<VideoThemeInformation>(url, this.getToken());
  }

  public getStoreCorporateColor(virtualStoreId: number, storeId?: number) {
    let url =
      environment.backendUrl +
      `User/GetStoreCorporateColor?virtualStoreId=${virtualStoreId}`;

    if (storeId) {
      url += "&storeId=" + storeId;
    }

    return this.http.get<any>(url, this.getToken());
  }

  public setStoreCorporateColors(
    corporateColors: CorporateColors,
    virtualStoreId: number,
    storeId?: number
  ) {
    let url =
      environment.backendUrl +
      `User/SetStoreCorporateColor?virtualStoreId=${virtualStoreId}`;

    if (storeId) {
      url += "&storeId=" + storeId;
    }

    return this.http.post<any>(url, corporateColors, this.getToken());
  }

  public getNewPaymentAmounts(totalAmount: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "Payment/getNewPaymentAmounts?totalAmount=" +
        totalAmount,
      this.getToken()
    );
  }

  public UnsubscribeUserFromEmails(unsubscribeToken: string) {
    return this.http.get<any>(
      environment.backendUrl + "Account/Unsubscribe?token=" + unsubscribeToken
    );
  }

  public SubscribeUserToEmails() {
    return this.http.get<any>(
      environment.backendUrl + "Account/Subscribe",
      this.getToken()
    );
  }

  public getChannelPreferences() {
    return this.http.get<ChannelSelection>(
      environment.backendUrl + "Channel/GetChannelPreferences",
      this.getToken()
    );
  }

  public storeChannelPreferences(data) {
    return this.http.post(
      environment.backendUrl + "Channel/StoreChannelPreferences",
      data,
      this.getToken()
    );
  }

  public getSubChannelPreferences() {
    return this.http.get<SubChannelSelection>(
      environment.backendUrl + "Channel/GetSubChannelPreferences",
      this.getToken()
    );
  }

  public storeSubChannelPreferences(data) {
    return this.http.post(
      environment.backendUrl + "Channel/StoreSubChannelPreferences",
      data,
      this.getToken()
    );
  }

  public getUserReachDistribution() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetUserReachDistribution",
      this.getToken()
    );
  }

  public storeQuestionnaireContent(data) {
    return this.http.post(
      environment.backendUrl + "User/StoreQuestionnaireContent",
      data,
      this.getToken()
    );
  }

  public getMarketingPreferences() {
    return this.http.get<any>(
      environment.backendUrl + "Channel/GetMarketingPreferences",
      this.getToken()
    );
  }

  public storeMarktingPreferences(
    marketingPreferenceId: number,
    facebookPostFrequency: number
  ) {
    return this.http.get(
      environment.backendUrl +
        `Channel/StoreUserMarketingPreferences?marketingPreferenceId=${marketingPreferenceId}&facebookPostFrequency=${facebookPostFrequency}`,
      this.getToken()
    );
  }

  public GetAllExternalSystems() {
    return this.http.get<ExternalSystem[]>(
      environment.externalSystemsUrl +
        "UserExternalServices/GetAllExternalSystems",
      this.getToken()
    );
  }

  public LinkUserExternalSystemInformation(
    data: ExternalSystemUserInformation
  ) {
    return this.http.post(
      environment.externalSystemsUrl +
        "UserExternalServices/LinkUserExternalSystemInformation",
      data,
      this.getToken()
    );
  }

  public RemoveUserExternalSystemInformation(
    data: ExternalSystemUserInformation
  ) {
    return this.http.post(
      environment.externalSystemsUrl +
        "UserExternalServices/RemoveUserExternalSystemInformation",
      data,
      this.getToken()
    );
  }

  public GetExternalSystemInformation(ExternalSystemId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "User/GetExternalSystemInformation?ExternalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public GeExternalSystem(ExternalSystemId: number) {
    return this.http.get<any>(
      environment.externalSystemsUrl +
        "UserExternalServices/GetExternalSystem?ExternalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public CheckExternalConnection(ExternalSystemId: number) {
    return this.http.get<any>(
      environment.externalSystemsUrl +
        "UserExternalServices/CheckConnection?ExternalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public GetShopifyApplicationApi() {
    return this.http.get<any>(
      environment.externalSystemsUrl + "ShopifyConnection/GetApiKey",
      this.getToken()
    );
  }

  public GetShopifyRandomNonce(shopName: string) {
    return this.http.get<any>(
      environment.externalSystemsUrl +
        "ShopifyConnection/GenerateNonceForUser?shopId=" +
        shopName,
      this.getToken()
    );
  }

  public GetExternalSystemAllCategories(ExternalSystemId: number) {
    return this.http.get<ExternalSystemCategory[]>(
      environment.externalSystemsUrl +
        "UserExternalServices/GetAllCategories?externalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public GetExternalSystemAllTags(ExternalSystemId: number) {
    return this.http.get<ExternalSystemCategory[]>(
      environment.externalSystemsUrl +
        "UserExternalServices/GetAllTags?externalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public GetExternalSystemSelectedCategories(ExternalSystemId: number) {
    return this.http.get<string[]>(
      environment.externalSystemsUrl +
        "UserExternalServices/GetUserCategories?externalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public GetExternalSystemSelectedTags(ExternalSystemId: number) {
    return this.http.get<string[]>(
      environment.externalSystemsUrl +
        "UserExternalServices/GetUserTags?externalSystemId=" +
        ExternalSystemId,
      this.getToken()
    );
  }

  public UpdateExternalSystemSelectedCategories(
    ExternalSystemId: number,
    updatedCats: ExternalSystemCategory[]
  ) {
    return this.http.post(
      environment.externalSystemsUrl +
        "UserExternalServices/UpdateCategories?externalSystemId=" +
        ExternalSystemId,
      updatedCats,
      this.getToken()
    );
  }

  public UpdateExternalSystemSelectedTags(
    ExternalSystemId: number,
    updatedTags: ExternalSystemCategory[]
  ) {
    return this.http.post(
      environment.externalSystemsUrl +
        "UserExternalServices/UpdateTags?externalSystemId=" +
        ExternalSystemId,
      updatedTags,
      this.getToken()
    );
  }

  public LinkAccountWithExternalService(
    externalServiceInfo: ExternalServiceLinkInfo
  ) {
    return this.http.post(
      environment.externalSystemsUrl +
        "UserExternalServices/LinkUserToExternalService",
      externalServiceInfo,
      this.getToken()
    );
  }

  public LinkAccountWithExternalServiceRedirectionToEndpoint(
    endpoint: string,
    parameterName: string,
    parameterValue: string
  ) {
    if (parameterName && parameterValue) {
      let params = new URLSearchParams();
      params.append(parameterName, parameterValue);
      // var body = {};
      // body[parameterName] = parameterValue;
      return this.http.get(
        environment.externalSystemsUrl +
          endpoint +
          "?" +
          parameterName +
          "=" +
          parameterValue,
        this.getToken()
      );
    } else {
      return this.http.get(
        environment.externalSystemsUrl + endpoint,
        this.getToken()
      );
    }
  }
  public setTaxId(taxId) {
    return this.http.get(
      environment.backendUrl + "User/SetTaxId?TaxId=" + taxId,
      this.getToken()
    );
  }
  public changeHighlighting(promoId, highlightDate) {
    return this.http.get(
      environment.backendUrl +
        `Promo/ChangeHighlighting?promoId=${promoId}&highlightDate=${highlightDate}`,
      this.getToken()
    );
  }

  public downloadPDF(url: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    return this.http.get(url, { headers: headers, responseType: "blob" });
  }

  public getBackgroundRemovalTries() {
    return this.http.get<any>(
      environment.backendUrl + "GetBackgroundRemovalTries",
      this.getToken()
    );
  }

  public removeImageBackground(
    imageData: any,
    checkTransparency: boolean = false
  ) {
    const form = new FormData();
    form.append("Upload_Image", imageData);

    //header insert
    const header = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    header.set("Content-Type", "application/json");
    return this.http.post(
      environment.backendUrl +
        "RemoveImageBackground?checkTransparency=" +
        checkTransparency,
      form,
      {
        reportProgress: true,
        headers: header,
        observe: "events",
        responseType: "blob",
      }
    );
  }

  public getSocialMediaCampaigns(page: number, itemsPerPage: number, data) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/GetSocialMediaCampaigns?page=${page}&itemsPerPage=${itemsPerPage}`,
      data,
      this.getToken()
    );
  }
  public getAllCampaigns(page: number, itemsPerPage: number, data) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/GetAllCampaigns?page=${page}&itemsPerPage=${itemsPerPage}`,
      data,
      this.getToken()
    );
  }

  // public getAllCampaigns(page: number, itemsPerPage: number) {
  //   return this.http.get<any>(
  //     environment.backendUrl +
  //       `MarketingPortal/GetAllCampaigns?page=${page}&itemsPerPage=${itemsPerPage}`,
  //     this.getToken()
  //   );
  // }

  public getSocialMediaPackages() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetSocialMediaPackages",
      this.getToken()
    );
  }

  public getAllMarketingPortallChannels() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetAllChannels",
      this.getToken()
    );
  }

  public getMarketingPortalChannel(channelId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "MarketingPortal/GetChannel?channelId=" +
        channelId,
      this.getToken()
    );
  }

  public getChannelObjectives() {
    return this.http.get<MPObjective[]>(
      environment.backendUrl + "MarketingPortal/GetChannelObjectives",
      this.getToken()
    );
  }

  public getChannelGoalFromObjectives(objectiveId: number, channelId: number) {
    return this.http.get<number>(
      environment.backendUrl +
        `MarketingPortal/GetChannelGoalFromObjectives?objectiveId=${objectiveId}&channelId=${channelId}`,
      this.getToken()
    );
  }

  public getMPRadius() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetMPRadius",
      this.getToken()
    );
  }

  public getSocialMediaAgeGroups() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetSocialMediaAgeGroups",
      this.getToken()
    );
  }

  public getSocialMediaGenders() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetSocialMediaGenders",
      this.getToken()
    );
  }

  public getSocialMediaTargets() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetSocialMediaTargets",
      this.getToken()
    );
  }

  public getSocialMediaSettings() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetSocialMediaSettings",
      this.getToken()
    );
  }

  public getSocialMediaCategories() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetSocialMediaCategories",
      this.getToken()
    );
  }

  public createSocialMediaCampaign(data) {
    return this.http.post(
      environment.backendUrl + "MarketingPortal/CreateSocialMediaCampaign",
      data,
      this.getToken()
    );
  }

  public editSocialMediaCampaign(data, socialMediaCampaignId) {
    return this.http.post(
      environment.backendUrl +
        "MarketingPortal/EditSocialMediaCampaign?socialMediaCampaignId=" +
        socialMediaCampaignId,
      data,
      this.getToken()
    );
  }

  public createSocialMediaPost(data) {
    return this.http.post(
      environment.backendUrl + "MarketingPortal/CreateSocialMediaPost",
      data,
      this.getToken()
    );
  }

  public boostSocialMediaPost(data, socialMediaCampaignId) {
    return this.http.post(
      environment.backendUrl +
        "MarketingPortal/BoostSocialMediaPost?socialMediaCampaignId=" +
        socialMediaCampaignId,
      data,
      this.getToken()
    );
  }

  public getCampaign(campaignId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "MarketingPortal/GetCampaign?campaignId=" +
        campaignId,
      this.getToken()
    );
  }

  public getSocialMediaCampaign(socialMediaCampaignId: number) {
    return this.http.get<SMCampaign>(
      environment.backendUrl +
        "MarketingPortal/GetSocialMediaCampaign?socialMediaCampaignId=" +
        socialMediaCampaignId,
      this.getToken()
    );
  }

  public getSocialMediaCampaignByMPCampaign(mpCampaignId: number) {
    return this.http.get<SMCampaign>(
      environment.backendUrl +
        "MarketingPortal/getSocialMediaCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId,
      this.getToken()
    );
  }

  public deleteMPCampaign(campaignId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "MarketingPortal/DeleteCampaign?campaignId=" +
        campaignId,
      this.getToken()
    );
  }

  public getGAMSettings() {
    return this.http.get<MPCampaignSettings>(
      environment.backendUrl + "MarketingPortal/GetGAMSettings",
      this.getToken()
    );
  }

  public getGAMMediaFormats() {
    return this.http.get<GAMMediaFormat[]>(
      environment.backendUrl + "MarketingPortal/GetGAMMediaFormats",
      this.getToken()
    );
  }

  public getGAMAdUnits() {
    return this.http.get<MPGAMAdUnit[]>(
      environment.backendUrl + "MarketingPortal/GetGAMAdUnits",
      this.getToken()
    );
  }

  public getGAMGeoTargeting() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGAMGeoTargeting",
      this.getToken()
    );
  }

  public getGAMDevices() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGAMDevices",
      this.getToken()
    );
  }

  public getGAMPackages() {
    return this.http.get<MPPackage[]>(
      environment.backendUrl + "MarketingPortal/GetGAMPackages",
      this.getToken()
    );
  }

  public getGAMGoals() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGAMGoals",
      this.getToken()
    );
  }

  public GetGAMPerformanceByBudget(goalId: number, budget: number) {
    return this.http.get<GAMCampaign>(
      environment.backendUrl +
        `MarketingPortal/GetGAMPerformanceByBudget?goalId=${goalId}&budget=${budget}`,
      this.getToken()
    );
  }

  public createGAMCampaign(data: any) {
    return this.http.post<any>(
      environment.backendUrl + `MarketingPortal/CreateGAMCampaign`,
      data,
      this.getToken()
    );
  }

  public editGAMCampaign(gamCampaignId: number, data: any) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/EditGAMCampaign?gamCampaignId=` +
        gamCampaignId,
      data,
      this.getToken()
    );
  }

  public getGAMCampaign(gamCampaignId: number) {
    return this.http.get<GAMCampaign>(
      environment.backendUrl +
        "MarketingPortal/GetGAMCampaign?gamCampaignId=" +
        gamCampaignId,
      this.getToken()
    );
  }

  public getGAMCampaignByMPCampaign(mpCampaignId: number) {
    return this.http.get<GAMCampaign>(
      environment.backendUrl +
        "MarketingPortal/GetGAMCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId,
      this.getToken()
    );
  }

  public getATVSettings() {
    return this.http.get<MPCampaignSettings>(
      environment.backendUrl + "MarketingPortal/GetATVSettings",
      this.getToken()
    );
  }

  public getATVMediaFormats() {
    return this.http.get<ATVMediaFormat[]>(
      environment.backendUrl + "MarketingPortal/GetATVMediaFormats",
      this.getToken()
    );
  }

  public getATVPackages() {
    return this.http.get<MPPackage[]>(
      environment.backendUrl + "MarketingPortal/GetATVPackages",
      this.getToken()
    );
  }

  public getATVTopics() {
    return this.http.get<MPTopic[]>(
      environment.backendUrl + "MarketingPortal/GetATVTopics",
      this.getToken()
    );
  }

  public getATVGender() {
    return this.http.get<MPGender[]>(
      environment.backendUrl + "MarketingPortal/GetATVGender",
      this.getToken()
    );
  }

  public getATVAgeGroups() {
    return this.http.get<MPAgeGroup[]>(
      environment.backendUrl + "MarketingPortal/GetATVAgeGroups",
      this.getToken()
    );
  }

  public createATVCampaign(data: any) {
    return this.http.post<any>(
      environment.backendUrl + `MarketingPortal/CreateATVCampaign`,
      data,
      this.getToken()
    );
  }

  public editATVCampaign(atvCampaignId: number, data: any) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/EditATVCampaign?atvCampaignId=` +
        atvCampaignId,
      data,
      this.getToken()
    );
  }

  public getATVCampaign(atvCampaignId: number) {
    return this.http.get<TVAdsCampaign>(
      environment.backendUrl +
        "MarketingPortal/GetATVCampaign?atvCampaignId=" +
        atvCampaignId,
      this.getToken()
    );
  }

  public getATVCampaignByMPCampaign(mpCampaignId: number) {
    return this.http.get<TVAdsCampaign>(
      environment.backendUrl +
        "MarketingPortal/GetATVCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId,
      this.getToken()
    );
  }

  public approveCampaign(campaignId: number, isChecked: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "MarketingPortal/ApproveCampaign?campaignId=" +
        campaignId +
        "&isChecked=" +
        isChecked,
      this.getToken()
    );
  }

  public getAllMPTemplates(
    channelId: number,
    page: number,
    itemsPerPage: number,
    customerId: string | number,
    data: any = {}
  ) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/GetTemplateBanners?channelId=${channelId}&page=${page}&itemsPerPage=${itemsPerPage}&customerId=${customerId}`,
      data,
      this.getToken()
    );
  }

  public getAllMPSetTemplates(
    channelId: number,
    page: number,
    itemsPerPage: number,
    customerId: string | number,
    data: any = {}
  ) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/GetTemplateBannerSets?channelId=${channelId}&page=${page}&itemsPerPage=${itemsPerPage}&customerId=${customerId}`,
      data,
      this.getToken()
    );
  }

  public getAllMPVideoTemplates(
    channelId: number,
    page: number,
    itemsPerPage: number,
    customerId: string | number,
    data: any = {}
  ) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/GetVideoTemplates?channelId=${channelId}&page=${page}&itemsPerPage=${itemsPerPage}&customerId=${customerId}`,
      data,
      this.getToken()
    );
  }

  public getAdPreviewFromCampaign(smCampaignid: number) {
    return this.http.get<any>(
      environment.facebookUrl +
        `FacebookMPInsights/GetAdPreviewFromCampaign?smCampaignid=${smCampaignid}`,

      this.getToken()
    );
  }

  public createTemplateBasedImage(
    channelId,
    data,
    customerId,
    mediaAssetId,
    isDraft = null
  ) {
    return this.http.post(
      environment.backendUrl +
        `MarketingPortal/CreateTemplateBasedImage?channelId=${channelId}&customerId=${customerId}&mediaAssetId=${mediaAssetId}&isDraft=${isDraft}`,
      data,
      this.getToken()
    );
  }

  public createTemplateBasedImageSet(
    channelId,
    data,
    customerId,
    mediaAssetId,
    isDraft = null
  ) {
    return this.http.post<TemplateBasedImage[]>(
      environment.backendUrl +
        `MarketingPortal/CreateTemplateBasedImageSet?channelId=${channelId}&customerId=${customerId}&mediaAssetId=${mediaAssetId}&isDraft=${isDraft}`,
      data,
      this.getToken()
    );
  }

  public createFirstTemplateBasedCreatives(customerId: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/CreateFirstTemplateBasedCreatives?customerId=${customerId}`,
      this.getToken()
    );
  }

  public createPreviewImageForSlides(data, customerId) {
    return this.http.post(
      environment.backendUrl +
        `MarketingPortal/CreatePreviewImageForSlides?customerId=${customerId}`,
      data,
      this.getToken()
    );
  }

  public createTemplateBasedVideo(data, customerId, mediaAssetId) {
    return this.http.post(
      environment.backendUrl +
        `MarketingPortal/CreateTemplateBasedVideo?customerId=${customerId}&mediaAssetId=${mediaAssetId}`,
      data,
      this.getToken()
    );
  }

  public getVideoStatusByMediaAssetId(mediaAssetId: number) {
    return this.http.get<MPVideoStatus>(
      environment.backendUrl +
        `MarketingPortal/GetVideoStatusBymediaAssetId?mediaAssetId=` +
        mediaAssetId,
      this.getToken()
    );
  }

  public getExternalSourcesFromMediaAssetId(mediaAssetId: number) {
    return this.http.get<MediaAssetExternalSource[]>(
      environment.backendUrl +
        `MarketingPortal/GetExternalSourcesFromMediaAssetId?mediaAssetId=` +
        mediaAssetId,
      this.getToken()
    );
  }

  public getMediaAssetSuggestions(customerId: number, templateId: number) {
    return this.http.get<MPVideoStatus>(
      environment.backendUrl +
        `MarketingPortal/GetMediaAssetSuggestions?customerId=${customerId}&templateId=${templateId}`,
      this.getToken()
    );
  }

  public getAllTemplateResolutions(isVideo: boolean) {
    return this.http.get<MPTemplateResolution[]>(
      environment.backendUrl +
        `MarketingPortal/GetAllTemplateResolutions?isVideo=${isVideo}`,
      this.getToken()
    );
  }

  public getHistoricTemplateConfig(mediaAssetId: number) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetHistoricTemplateConfig?mediaAssetId=${mediaAssetId}`;

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public getHistoricTemplateSetConfig(mediaAssetId: number) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetHistoricTemplateSetConfig?mediaAssetId=${mediaAssetId}`;

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public getHistoricTemplateSetConfigNew(mediaAssetId: number) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetHistoricTemplateSetConfigNew?mediaAssetId=${mediaAssetId}`;

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public getHistoricVideoTemplateConfig(mediaAssetId: number) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetHistoricVideoTemplateConfig?mediaAssetId=${mediaAssetId}`;

    return this.http.get<TemplateInformation>(url, this.getToken());
  }

  public getMediaAssetInformation(mediaAssetId: number) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetMediaAssetInformation?mediaAssetId=${mediaAssetId}`;

    return this.http.get<MPMediaAsset>(url, this.getToken());
  }

  public getAllMediaAssetsForCampaign(channelId: number, mediaAssetId: number) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetAllMediaAssetsForCampaign?channelId=${channelId}&mediaAssetId=${mediaAssetId}`;

    return this.http.get<TemplateSetImage[]>(url, this.getToken());
  }

  public getFontFamilyForVideoTemplate(templateId: number) {
    return this.http.get<MPFontFamily[]>(
      environment.backendUrl +
        `MarketingPortal/GetFontFamilyForVideoTemplate?templateId=${templateId}`,
      this.getToken()
    );
  }

  public getFontWeightForVideoTemplate(templateId: number) {
    return this.http.get<MPFontWeight[]>(
      environment.backendUrl +
        `MarketingPortal/GetFontWeightForVideoTemplate?templateId=${templateId}`,
      this.getToken()
    );
  }

  public getSoundsForVideoTemplate(templateId: number) {
    return this.http.get<MPSound[]>(
      environment.backendUrl +
        `MarketingPortal/GetSoundsForVideoTemplate?templateId=${templateId}`,
      this.getToken()
    );
  }

  public getFontFamilyForTemplate(templateId: number) {
    return this.http.get<MPFontFamily[]>(
      environment.backendUrl +
        `MarketingPortal/getFontFamilyForTemplate?templateId=${templateId}`,
      this.getToken()
    );
  }

  public getFontWeightForTemplate(templateId: number) {
    return this.http.get<MPFontWeight[]>(
      environment.backendUrl +
        `MarketingPortal/getFontWeightForTemplate?templateId=${templateId}`,
      this.getToken()
    );
  }
  public getFontFamilyForTemplateSet(templateSetId: number) {
    return this.http.get<MPFontFamily[]>(
      environment.backendUrl +
        `MarketingPortal/getFontFamilyForTemplateSet?templateSetId=${templateSetId}`,
      this.getToken()
    );
  }

  public getFontWeightForTemplateSet(templateSetId: number) {
    return this.http.get<MPFontWeight[]>(
      environment.backendUrl +
        `MarketingPortal/getFontWeightForTemplateSet?templateSetId=${templateSetId}`,
      this.getToken()
    );
  }

  public getUnsplashImages(keyword, page, itemsPerPage) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetImagesBasedOnKeyword?keyword=${keyword}&page=${page}&itemsPerPage=${itemsPerPage}`,
      this.getToken()
    );
  }

  public getVideosBasedOnKeyword(
    keyword,
    page,
    itemsPerPage,
    minWidth,
    minHeight
  ) {
    return this.http.get<PexelVideo[]>(
      environment.backendUrl +
        `MarketingPortal/GetVideosBasedOnKeyword?keyword=${keyword}&page=${page}&itemsPerPage=${itemsPerPage}&minHeight=${minHeight}&minWidth=${minWidth}`,
      this.getToken()
    );
  }

  public GetMediaAssetSuggestions(
    customerId = null,
    templateId = null,
    videoTemplateId = null
  ) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetMediaAssetSuggestions?customerId=${customerId}&templateId=${templateId}&videoTemplateId=${videoTemplateId}`;

    return this.http.get<any>(url, this.getToken());
  }

  public GetMyMediaAssets(
    mode,
    page,
    itemsPerPage,
    customerId,
    channelId = null,
    googleDisplayMediaFormatId = null,
    assetType = null
  ) {
    let url =
      environment.backendUrl +
      `MarketingPortal/GetMyMediaAssets?mode=${mode}&customerId=${customerId}&page=${page}&itemsPerPage=${itemsPerPage}`;

    if (channelId) {
      url += `&channelId=${channelId}`;
    }

    if (googleDisplayMediaFormatId) {
      url += `&googleDisplayMediaFormatId=${googleDisplayMediaFormatId}`;
    }

    if (assetType) {
      url += `&assetType=${assetType}`;
    }

    return this.http.get<any>(url, this.getToken());
  }

  public getMyCreatives(
    customerId: number,
    resolutionId: number,
    assetType: number,
    page: number,
    itemsPerPage,
    data = {}
  ) {
    return this.http.post<MPSocialMediaPlacement[]>(
      environment.backendUrl +
        `MarketingPortal/GetMyCreatives?customerId=${customerId}&resolutionId=${resolutionId}&assetType=${assetType}&page=${page}&itemsPerPage=${itemsPerPage}`,
      data,
      this.getToken()
    );
  }

  public getPresalesItems(customerId: number) {
    return this.http.get<PresalesItem[]>(
      environment.backendUrl +
        `MarketingPortal/getPresalesItems?customerId=${customerId}`,
      this.getToken()
    );
  }

  public addItemToPresales(
    mediaAssetId: number,
    customerId: number,
    data: any
  ) {
    return this.http.post<PresalesItem[]>(
      environment.backendUrl +
        `MarketingPortal/addItemToPresales?customerId=${customerId}&mediaAssetId=${mediaAssetId}`,
      data,
      this.getToken()
    );
  }

  public deleteItemFromPresales(
    mediaAssetId: number,
    channelId: number,
    customerId: number
  ) {
    return this.http.get<PresalesItem[]>(
      environment.backendUrl +
        `MarketingPortal/deleteItemFromPresales?customerId=${customerId}&mediaAssetId=${mediaAssetId}&channelId=${channelId}`,
      this.getToken()
    );
  }

  public addCommentToPresales(preSalesId: number, comment: string) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/addCommentToPresales?preSalesId=${preSalesId}&comment=${comment}`,
      this.getToken()
    );
  }

  public setLikeToPresales(preSalesId: number, likeValue: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/setLikeToPresales?preSalesId=${preSalesId}&likeValue=${likeValue}`,
      this.getToken()
    );
  }

  public regenerateMediaAssets(channelId: number, customerId: number, data) {
    return this.http.post<PresalesItem[]>(
      environment.backendUrl +
        `MarketingPortal/RegenerateMediaAssets?customerId=${customerId}&channelId=${channelId}`,
      data,
      this.getToken()
    );
  }

  public getSocialMediaPlacements() {
    return this.http.get<MPSocialMediaPlacement[]>(
      environment.backendUrl + `MarketingPortal/GetSocialMediaPlacements`,
      this.getToken()
    );
  }

  public getMetaInfoFromUrl(url: string) {
    return this.http.get<MPMetaData>(
      environment.backendUrl +
        `MarketingPortal/GetMetaInfoFromUrl?url=${encodeURIComponent(url)}`,
      this.getToken()
    );
  }

  public triggerUnsplashDownloadEndpoint(imageDownloadUrl) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/TriggerUnsplashDownloadEndpoint?imageDownloadUrl=${encodeURIComponent(
          imageDownloadUrl
        )}`,
      this.getToken()
    );
  }

  public getUserNotificationSettings() {
    return this.http.get<NotificationSettings[]>(
      environment.facebookUrl +
        `Notification/GetUserDefaultCampaignNotificationSubscriptionSettings`,
      this.getToken()
    );
  }

  public updateUserNotificationSettings(data) {
    return this.http.post(
      environment.facebookUrl +
        `Notification/UpdateUserDefaultCampaignNotificationSubscriptionSettings`,
      data,
      this.getToken()
    );
  }

  public getCampaignNotificationSettings(mpCampaignId: number) {
    return this.http.get<NotificationSettings[]>(
      environment.facebookUrl +
        `Notification/GetCampaignNotificationSubscriptionSettings?mpCampaignId=` +
        mpCampaignId,
      this.getToken()
    );
  }

  public updateCampaignNotificationSettings(data, mpCampaignId: number) {
    return this.http.post(
      environment.facebookUrl +
        `Notification/UpdateCampaignNotificationSubscriptionSettings?mpCampaignId=` +
        mpCampaignId,
      data,
      this.getToken()
    );
  }

  public getCampaignNotifications(
    mpCampaignId: number,
    newOnly: boolean = false
  ) {
    return this.http.get<MPNotification[]>(
      environment.facebookUrl +
        `Notification/GetMPCampaignNotifications?mpCampaignId=${mpCampaignId}&newOnly=${newOnly}`,
      this.getToken()
    );
  }

  public getAllCustomers(page: number, itemsPerPage: number, data: any) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/GetAllCustomers?&page=${page}&itemsPerPage=${itemsPerPage}`,
      data,
      this.getToken()
    );
  }

  public getSingleCustomer(customerId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetSingleCustomer?customerId=${customerId}`,
      this.getToken()
    );
  }
  public getUserLocations() {
    return this.http.get<any>(
      environment.backendUrl + `MarketingPortal/GetUserLocations`,
      this.getToken()
    );
  }

  public GetChannel(channelId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetChannel?channelId=${channelId}`,
      this.getToken()
    );
  }

  public addNewCustomer(data) {
    return this.http.post(
      environment.backendUrl + "MarketingPortal/AddNewCustomer",
      data,
      this.getToken()
    );
  }

  public updateCustomerLocations(customerId: number, data) {
    return this.http.post<any>(
      environment.backendUrl +
        "MarketingPortal/UpdateCustomerLocations?customerId=" +
        customerId,
      data,
      this.getToken()
    );
  }

  public updateCustomer(data) {
    return this.http.post<any>(
      environment.backendUrl + "MarketingPortal/UpdateCustomer",
      data,
      this.getToken()
    );
  }

  public UpdateCustomerCD(data) {
    return this.http.post<any>(
      environment.backendUrl + "MarketingPortal/UpdateCustomerCD",
      data,
      this.getToken()
    );
  }

  public deleteCustomer(customerId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/DeleteCustomer?customerId=${customerId}`,
      this.getToken()
    );
  }

  public addNewCustomerLocation(data, customerId) {
    return this.http.post(
      environment.backendUrl +
        "MarketingPortal/AddNewCustomerLocation?customerId=" +
        customerId,
      data,
      this.getToken()
    );
  }

  public updateCustomerLocation(data, customerLocationId) {
    return this.http.post(
      environment.backendUrl +
        "MarketingPortal/UpdateCustomerLocation?customerLocationId=" +
        customerLocationId,
      data,
      this.getToken()
    );
  }

  public deleteCustomerLocation(customerLocationId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/DeleteCustomerLocation?customerLocationId=${customerLocationId}`,
      this.getToken()
    );
  }

  public getSocialMediaImpressionsByBudget(goalId, budget) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetSocialMediaImpressionsByBudget?goalId=${goalId}&budget=${budget}`,
      this.getToken()
    );
  }

  public sendRequestToPage(pageId: number) {
    const header = new HttpHeaders().set(
      "Authorization",
      "Bearer " + window.localStorage["token"]
    );
    header.set("Content-Type", "application/json");
    return this.http.get(
      environment.facebookUrl +
        "MarketingPortal/SendRequestToPage?pageId=" +
        pageId,
      { responseType: "text", headers: header }
    );
  }

  public GetSingleLocationsStatistics(mpCampaignid: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetSingleLocationsStatistics?mpCampaignid=${mpCampaignid}`,
      this.getToken()
    );
  }

  public GetSingleCampaignTotalStats(mpCampaignid: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetSingleCampaignTotalStats?mpCampaignid=${mpCampaignid}`,
      this.getToken()
    );
  }

  public getSingleCampaignStats(mpCampaignid: number, type: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetSingleCampaignStats?mpCampaignid=${mpCampaignid}&type=${type}`,
      this.getToken()
    );
  }

  public getSingleAgeRangeStatistics(mpCampaignid: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetSingleAgeRangeStatistics?mpCampaignid=${mpCampaignid}`,
      this.getToken()
    );
  }

  public getSingleGenderStatistics(mpCampaignid: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetSingleGenderStatistics?mpCampaignid=${mpCampaignid}`,
      this.getToken()
    );
  }

  public getSinglePlattformStatistics(mpCampaignid: number) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetSinglePlattformStatistics?mpCampaignid=${mpCampaignid}`,
      this.getToken()
    );
  }

  public getGoogleDisplayCTA() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleDisplayCTA",
      this.getToken()
    );
  }

  public getGoogleDisplayMediaFormats() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleDisplayMediaFormats",
      this.getToken()
    );
  }

  public getGoogleDisplayCampaign(googleDisplayCampaignId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "MarketingPortal/GetGoogleDisplayCampaign?googleDisplayCampaignId=" +
        googleDisplayCampaignId,
      this.getToken()
    );
  }

  public createGoogleDisplayCampaign(data) {
    return this.http.post(
      environment.backendUrl + "MarketingPortal/CreateGoogleDisplayCampaign",
      data,
      this.getToken()
    );
  }

  public editGoogleDisplayCampaign(data, googleCampaignId) {
    return this.http.post(
      environment.backendUrl +
        "MarketingPortal/EditGoogleDisplayCampaign?googleCampaignId=" +
        googleCampaignId,
      data,
      this.getToken()
    );
  }

  public getGoogleDisplayCampaignByMPCampaign(mpCampaignId: number) {
    return this.http.get<MPGDNCampaign>(
      environment.backendUrl +
        "MarketingPortal/getGoogleDisplayCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId,
      this.getToken()
    );
  }

  public getGoogleDisplayAgeGroups() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleAgeGroups",
      this.getToken()
    );
  }

  public GetGoogleDisplayMediaFormats() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleDisplayMediaFormats",
      this.getToken()
    );
  }

  public getGoogleDisplayGenders() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleGenders",
      this.getToken()
    );
  }

  public getGoogleDisplayDevices() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleDevices",
      this.getToken()
    );
  }

  public getGoogleDisplayPackages() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/getGoogleDisplayPackages",
      this.getToken()
    );
  }

  public getGoogleSettings() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/getGoogleSettings",
      this.getToken()
    );
  }

  public GetGooglePerformanceByBudget(goalId, budget) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetGooglePerformanceByBudget?goalId=${goalId}&budget=${budget}`,
      this.getToken()
    );
  }

  public GetGoogleTopics() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetGoogleTopics",
      this.getToken()
    );
  }

  public getYoutubeCampaign(youtubeCampaignId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        "MarketingPortal/GetYouTubeCampaign?youtubeCampaignId=" +
        youtubeCampaignId,
      this.getToken()
    );
  }

  public getYoutubeCampaignByMPCampaign(mpCampaignId: number) {
    return this.http.get<YTCampaign>(
      environment.backendUrl +
        "MarketingPortal/getYoutubeCampaignByMPCampaign?mpCampaignId=" +
        mpCampaignId,
      this.getToken()
    );
  }

  public getYouTubeCTA() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetYouTubeCTA",
      this.getToken()
    );
  }

  public getYouTubePerformanceByBudget(goalId, budget) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetYouTubePerformanceByBudget?goalId=${goalId}&budget=${budget}`,
      this.getToken()
    );
  }

  public getYouTubePackages() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetYouTubePackages",
      this.getToken()
    );
  }

  public editYoutubeCampaign(data, youTubeCampaignId) {
    return this.http.post(
      environment.backendUrl +
        "MarketingPortal/EditYouTubeCampaign?youTubeCampaignId=" +
        youTubeCampaignId,
      data,
      this.getToken()
    );
  }

  public createYouTubeCampaign(data) {
    return this.http.post(
      environment.backendUrl + "MarketingPortal/CreateYouTubeCampaign",
      data,
      this.getToken()
    );
  }

  public getYouTubeCampaign(youtubeCampaignId) {
    return this.http.get<YTCampaign>(
      environment.backendUrl +
        "MarketingPortal/GetYouTubeCampaign?youtubeCampaignId=" +
        youtubeCampaignId,
      this.getToken()
    );
  }
  public GetAllCustomerCategories() {
    return this.http.get<any>(
      environment.backendUrl + "MarketingPortal/GetAllCustomerCategories",
      this.getToken()
    );
  }

  public UpdateCustomerTextsWithAI(customerId) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/UpdateCustomerTextsWithAI?customerId=${customerId}`,
      this.getToken()
    );
  }

  public createCustomerTextsWithAI(customerId: number, type: number = null) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/CreateCustomerTextsWithAI?customerId=${customerId}&TextType=${type}`,
      this.getToken()
    );
  }

  public deleteCustomerText(customerId: number, textId: number = null) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/DeleteCustomerText?customerId=${customerId}&id=${textId}`,
      this.getToken()
    );
  }

  public createKeywordsWithAI(customerId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/CreateKeywordsWithAI?customerId=${customerId}`,
      this.getToken()
    );
  }

  public addCustomerKeyword(customerId: number, keyword: string = null) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/AddCustomerKeyword?customerId=${customerId}&keyword=${keyword}`,
      this.getToken()
    );
  }

  public deleteCustomerKeyword(customerId: number, keywordId: number = null) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/DeleteCustomerKeyword?customerId=${customerId}&id=${keywordId}`,
      this.getToken()
    );
  }

  public updateCustomerMetaInformation(customerId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/UpdateCustomerMetaInformation?mpCustomerId=${customerId}`,
      this.getToken()
    );
  }

  public GetCampaignInvoice(page: number, itemsPerPage: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetCampaignInvoice?page=${page}&itemsPerPage=${itemsPerPage}`,
      this.getToken()
    );
  }

  public requestCustomerApproval(campaignId: number, data: {}) {
    return this.http.post<any>(
      environment.backendUrl +
        `MarketingPortal/RequestCustomerApproval?campaignId=${campaignId}`,
      data,
      this.getToken()
    );
  }

  public getCampaignApprovalHistory(campaignId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/GetCampaignApprovalHistory?campaignId=${campaignId}`,
      this.getToken()
    );
  }

  public getCustomerApprovalCampaignInfo(token: string) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetCustomerApprovalCampaignInfo?token=${token}`
    );
  }

  public getCompleteApprovalHistory(page: number, itemsPerPage: number, data) {
    return this.http.post(
      environment.backendUrl +
        `MarketingPortal/GetCompleteApprovalHistory?page=${page}&itemsPerPage=${itemsPerPage}`,
      data,
      this.getToken()
    );
  }

  public storeCustomerApprovalResult(token: string, data) {
    return this.http.post(
      environment.backendUrl +
        `MarketingPortal/StoreCustomerApprovalResult?token=${token}`,
      data
    );
  }

  public GetPublicCampaignStatistics(token: string) {
    return this.http.get(
      environment.backendUrl +
        `MarketingPortal/GetPublicCampaignStatistics?token=${token}`
    );
  }

  public getBase64FromUrl(url: string, isVideo = false) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/getBase64FromUrl?url=${encodeURIComponent(
          url
        )}&isVideo=${isVideo}`,
      this.getToken()
    );
  }

  public downloadBase64MediaAsset(mediaAssetId: number, channelId: number) {
    return this.http.get<any>(
      environment.backendUrl +
        `MarketingPortal/DownloadBase64MediaAsset?mediaAssetId=${mediaAssetId}&channelId=${channelId}`,
      this.getToken()
    );
  }
}
