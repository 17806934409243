import { Component, Input, OnInit } from "@angular/core";
import { MPNotification } from "app/model/marketing-portal/MPNotification";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-campaign-notifications",
  templateUrl: "./marketing-portal-campaign-notifications.component.html",
  styleUrls: ["./marketing-portal-campaign-notifications.component.scss"],
})
export class MarketingPortalCampaignNotificationsComponent implements OnInit {
  @Input() mpCampaignId: number;
  public isLoading: boolean;
  public notifications: MPNotification[];
  public readCount: number;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getCampaignNotifications();
  }

  async getCampaignNotifications() {
    this.isLoading = true;

    await this.crudService
      .getCampaignNotifications(this.mpCampaignId)
      .toPromise()
      .then((res: MPNotification[]) => {
        this.notifications = res;
        this.countReadNotificaitons();
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }

  countReadNotificaitons() {
    this.readCount = this.notifications.filter(
      (notification) => notification.IsRead
    ).length;
  }
}
