import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-creatvie-preview-dialog",
  templateUrl: "./creatvie-preview-dialog.component.html",
  styleUrls: ["./creatvie-preview-dialog.component.scss"],
})
export class CreatviePreviewDialogComponent implements OnInit {
  public creativeIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<CreatviePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  next() {
    if (this.creativeIndex >= this.data.creative.creatives.length - 1) {
      this.creativeIndex = 0;
      return;
    }

    this.creativeIndex++;
  }

  back() {
    if (this.creativeIndex === 0) {
      this.creativeIndex = this.data.creative.creatives.length - 1;
      return;
    }

    this.creativeIndex--;
  }
}
