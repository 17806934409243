import { MPGAMAdUnit } from "./MPGAMAdUnit";

export class MPGAMAdUnitList {
  public devices: MPGAMAdUnit[];

  constructor() {}

  public deserializeList(serializedObj: MPGAMAdUnit[]): MPGAMAdUnit[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPGAMAdUnit) =>
      new MPGAMAdUnit().deserialize(x)
    );
  }
}
