export class MPCampaignPackage {
  public packageId: number;
  public displayName: string;
  public budget: number;
  public impressionsMinimum: number;
  public impressionsMaximum: number;
  public impressionsAverage: number;
  public impressionsExpected: number;
  public goalInformation: any;

  constructor(
    packageId: number = null,
    displayName: string = null,
    budget: number = null,
    impressionsMinimum: number = null,
    impressionsMaximum: number = null,
    impressionsAverage: number = null,
    impressionsExpected: number = null,
    goalInformation: any = null
  ) {
    this.packageId = packageId;
    this.displayName = displayName;
    this.budget = budget;
    this.impressionsMinimum = impressionsMinimum;
    this.impressionsMaximum = impressionsMaximum;
    this.impressionsAverage = impressionsAverage;
    this.impressionsExpected = impressionsExpected;
    this.goalInformation = goalInformation;
  }

  deserialize(serializedObj: object): MPCampaignPackage {
    if (!serializedObj) {
      return null;
    }

    this.packageId = serializedObj["packageId"];
    this.displayName = serializedObj["displayName"];
    this.budget = serializedObj["budget"];
    this.impressionsMinimum = serializedObj["impressionsMinimum"];
    this.impressionsMaximum = serializedObj["impressionsMaximum"];
    this.impressionsAverage = serializedObj["impressionsAverage"];
    this.impressionsExpected = serializedObj["impressionsExpected"];
    this.goalInformation = serializedObj["goalInformation"];

    return this;
  }
}
