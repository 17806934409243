import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { Location } from "@angular/common";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { environment } from "environments/environment";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { CrudService } from "app/views/mapAds/crud.service";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { MPCampaignSettings } from "app/model/marketing-portal/MPCampaignSettings";
import { CreateTvFormComponent } from "app/shared/components/forms/campaign/create-tv-form/create-tv-form.component";

@Component({
  selector: "marketing-portal-channel-tv",
  templateUrl: "./marketing-portal-channel-tv.component.html",
  styleUrls: ["./marketing-portal-channel-tv.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(50px)" }),
        animate(
          "750ms cubic-bezier(0, 0, 0.2, 1)",
          style({ opacity: 1, transform: "translateY(0)" })
        ),
      ]),
    ]),
  ],
})
export class MarketingPortalChannelTvComponent implements OnInit {
  @ViewChild(CreateTvFormComponent)
  createTvFormComponent: CreateTvFormComponent;
  @Input() tvAdsCampaign?: any;
  @Input() selectedChannel: MPChannel;
  @Input() mediaAsset: number = null;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedTemplateImages: TemplateBasedImage[] = null;
  @Input() contentHeight: number;
  @Input() duplicate: number = null;
  @Input() selectedObjective: MPObjective;
  @Input() selectedResolutions: MPTemplateResolution;
  public isLoading: boolean = false;
  public settings: MPCampaignSettings;
  public mediaAssetIds: number[];
  public showCustomerApproval: boolean = false;
  readonly environment = environment;
  constructor(
    private appLoader: AppLoaderService,
    private location: Location,
    private crudService: CrudService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    setTimeout(() => this.appLoader.open());

    this.settings = await this.crudService.getATVSettings().toPromise();

    setTimeout(() => this.appLoader.close());
    this.isLoading = false;
  }

  goBack() {
    this.location.back();
  }

  onSubmit() {
    this.createTvFormComponent.onSubmit(false);
  }
}
