import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/media-asset/MPMediaAssetUploader";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { GamCampaignTextFormComponent } from "../../forms/text/gam-campaign-text-form/gam-campaign-text-form.component";

@Component({
  selector: "app-add-gam-media-asset-dialog",
  templateUrl: "./add-gam-media-asset-dialog.component.html",
  styleUrls: ["./add-gam-media-asset-dialog.component.scss"],
})
export class AddGamMediaAssetDialogComponent implements OnInit {
  @ViewChild(GamCampaignTextFormComponent)
  gamCampaignTextFormComponent: GamCampaignTextFormComponent;
  public selectedMediaFormat: GAMMediaFormat;
  public selectedMediaAsset: MPMediaAsset;
  public texts: { headline: string; body: string } = {
    headline: null,
    body: null,
  };
  public step: number = 1;

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<AddGamMediaAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.reset();

    if (this.data.gamMediaAssetFormats.length) {
      const mediaFormat = this.data.mediaFormats.find(
        (mediaFormat: GAMMediaFormat) =>
          mediaFormat.id === this.data.gamMediaAssetFormats[0].mediaFormatId
      );

      this.onSelectMediaFormat(mediaFormat);
      this.onNext();
    }
  }

  onSelectMediaFormat(mediaFormat: GAMMediaFormat) {
    this.selectedMediaFormat = mediaFormat;
  }

  onChangeMediaAsset(mediaAssets: MPMediaAsset[]) {
    this.selectedMediaAsset = mediaAssets.length ? mediaAssets[0] : null;
    this.onNext();
  }

  onFinish() {
    const data = {
      mediaAssetId: this.selectedMediaAsset.mediaAssetId,
      mediaFormatId: this.selectedMediaFormat.id,
      headline: this.texts.headline,
      body: this.texts.body,
    };

    this.reset();

    this.dialogRef.close(data);
  }

  reset() {
    this.step = 1;
    this.selectedMediaAsset = null;
    this.selectedMediaFormat = null;
  }

  async onNext() {
    if (this.step === 2) {
      await this.uploadMediaAsset()
        .then((res) => {})
        .catch((e) => {});

      this.onFinish();
      return;
    }

    this.step++;
  }

  uploadMediaAsset() {
    return new Promise(async (resolve, reject) => {
      this.appLoader.open();

      const mpMediaAssetUploader: MPMediaAssetUploader =
        new MPMediaAssetUploader(
          [this.selectedMediaAsset],
          this.crudService,
          this.data.selectedCustomer ? this.data.selectedCustomer : null
        );

      let uploadError = false;

      await mpMediaAssetUploader
        .upload()
        .then((mediaAssets: MPMediaAsset[]) => {
          this.selectedMediaAsset = mediaAssets[0];
          this.selectedMediaAsset.setThumbnailURL();
          this.selectedMediaAsset.setURL();

          this.appLoader.close();
          resolve(true);
        })
        .catch((e) => {
          uploadError = true;
          this.snackbarService.show(e.error, "danger");
        });

      if (uploadError) {
        document.querySelector(".main-content-wrap").scrollTo(0, 0);
        this.appLoader.close();
        reject();
      }
    });
  }

  onBack() {
    this.step--;
  }

  onClose() {
    this.dialogRef.close();
  }
}
