import { Component, Input, OnInit } from "@angular/core";
import { MPMetaData } from "app/model/marketing-portal/MPMetaData";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "campaign-creative-facebook-preview",
  templateUrl: "./campaign-creative-facebook-preview.component.html",
  styleUrls: ["./campaign-creative-facebook-preview.component.scss"],
})
export class CampaignCreativeFacebookPreviewComponent implements OnInit {
  @Input() mediaAssetUrl: string;
  @Input() isImage: boolean;
  @Input() link: string;
  @Input() displayText: string;
  public width: number = 402;
  public height: number = 0;
  public facebookPageId: number;
  public facebookPageName: string;
  public facebookPageImageUrl: string;
  public isMainLoading: boolean;
  public isLoadingMetaData: boolean;
  public url: URL;
  public hostname: string;
  public metaData: MPMetaData;
  public sub: any;
  public MAX_PREVIEW_MEDIA_SIZE = 240;
  constructor(private crudService: CrudService) {}

  ngOnInit() {
    this.height = this.width * 1.94;

    this.getSocialMediaSettings();
    this.setHostname();
  }

  async getSocialMediaSettings() {
    this.isMainLoading = true;

    await this.crudService
      .getSocialMediaSettings()
      .toPromise()
      .then((res) => {
        const defaultFBPage = res.DefaultFBPage;
        this.facebookPageId = defaultFBPage.FBPageId;
        this.facebookPageName = defaultFBPage.FBPageName;
        this.facebookPageImageUrl = defaultFBPage.facebookPageImageUrl;
      });

    this.isMainLoading = false;
  }

  addhttp(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "https://" + url;
    }
    return url;
  }

  setHostname() {
    if (this.link && this.link != "") {
      let tempLink = this.link;
      tempLink = this.addhttp(tempLink);
      try {
        this.url = new URL(tempLink);
        this.hostname = this.url.hostname;
        this.hostname = this.hostname.replace("www.", "");

        this.getMetaData(this.url.href);
      } catch (e) {
        this.hostname = this.link;
      }
    }
  }

  async getMetaData(url: string) {
    // Wait 1 second until the user input is finished to avoid too many requests.
    await this.delay(1000);

    // Check if the url has changed.
    if (url != this.url.href) {
      return;
    }

    // Check if url is valid.
    if (!this.isValidUrl(url)) {
      this.metaData = null;
      return;
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.isLoadingMetaData = true;
    this.sub = this.crudService.getMetaInfoFromUrl(url).subscribe(
      (res: MPMetaData) => {
        this.metaData = res;
        this.isLoadingMetaData = false;
      },
      (err) => {
        this.isLoadingMetaData = false;
        // this.snackbarService.show(err.error, "danger");
        this.metaData = null;
      }
    );
  }

  isValidUrl(url: string) {
    return url.match(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\u00C0-\u017Fa-z0-9]+([\-\.]{1}[\u00C0-\u017Fa-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
    );
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
