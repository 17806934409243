export class MPSocialMediaPlacement {
  placementId: number;
  placementName: string;
  resolutionId: number;
  resolutionName: string;

  constructor(
    placementId: number = null,
    placementName: string = null,
    resolutionId: number = null,
    resolutionName: string = null
  ) {
    this.placementId = placementId;
    this.placementName = placementName;
    this.resolutionId = resolutionId;
    this.resolutionName = resolutionName;
  }

  deserialize(serializedObj: object): MPSocialMediaPlacement {
    if (!serializedObj) {
      return null;
    }

    this.placementId = serializedObj["placementId"];
    this.placementName = serializedObj["placementName"];
    this.resolutionId = serializedObj["resolutionId"];
    this.resolutionName = serializedObj["resolutionName"];

    return this;
  }
}
