import { Component, Input, OnInit } from "@angular/core";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";

@Component({
  selector: "campaign-media-format-card",
  templateUrl: "./campaign-media-format-card.component.html",
  styleUrls: ["./campaign-media-format-card.component.scss"],
})
export class CampaignMediaFormatCardComponent implements OnInit {
  @Input() mediaFormat: GAMMediaFormat;
  @Input() isSelected: boolean;
  @Input() size?: number = 46;
  public height: number = null;
  public width: number = null;
  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.calcWidth();
  }

  calcWidth() {
    this.height = this.size;

    if (this.mediaFormat.resolutionName === "L-Banner") {
      this.width = (this.height * 16) / 9;
      return;
    }

    const resolutionSplit = this.mediaFormat.resolutionName.split(":");
    const width = parseFloat(resolutionSplit[0]);
    const height = parseFloat(resolutionSplit[1]);

    const ratio = width / height;

    this.width = this.height * ratio;
  }
}
