import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "ma-extended-configurator-customer",
  templateUrl: "./extended-configurator-customer.component.html",
  styleUrls: ["./extended-configurator-customer.component.scss"],
})
export class ExtendedConfiguratorCustomerComponent implements OnInit {
  @ViewChild("configurationSearchBar") configurationSearchBar: ElementRef;
  @ViewChild("configuratorFooter") configuratorFooter: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() configuratorHeaderHeight: number;
  public customerList: any = [];
  public isLoading: boolean;
  public isMainLoading: boolean;
  public searchString: string = "";
  public page: number = 0;
  public itemsPerPage: number = 17;
  public count: number;
  public totalPages: number;
  public sub: any;
  public contentHeight: number;
  readonly environment = environment;
  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService
  ) {}

  ngOnInit() {
    this.getCustomers(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
    this.scrollToTop();
  }

  getCustomers(init = false) {
    if (init) {
      setTimeout(() => this.appLoader.open());
      this.isMainLoading = true;
    } else {
      this.isLoading = true;
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    const data = {
      filterObjects: [
        {
          type: "customerName",
          value: this.searchString,
        },
      ],
      sortingObject: {
        column: "createdOn",
        direction: true,
      },
    };

    this.sub = this.crudService
      .getAllCustomers(this.page, this.itemsPerPage, data)
      .subscribe((res) => {
        this.customerList = res;
        this.count = this.customerList.count;
        this.totalPages = Math.ceil(this.count / this.itemsPerPage);
        if (this.count === 0 && init) {
        }

        if (init) {
          setTimeout(() => this.appLoader.close());
          this.isMainLoading = false;
        } else {
          this.isLoading = false;
        }
      });
  }

  onInputChange($event) {
    this.page = 0;
    this.searchString = $event.target.value;
    this.scrollToTop();
    this.getCustomers();
  }

  onPage(page: number) {
    this.page = page;
    this.scrollToTop();
    this.getCustomers();
  }

  clearSearch() {
    this.page = 0;
    this.searchString = "";
    this.scrollToTop();
    this.getCustomers();
  }

  scrollToTop() {
    const container = document.querySelector(
      ".extended-configurator-container"
    );
    container.scrollIntoView({ block: "start", inline: "nearest" });
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;

    windowHeight -= this.configuratorHeaderHeight;

    windowHeight -= this.configuratorFooter.nativeElement.offsetHeight;
    windowHeight -= this.configurationSearchBar.nativeElement.offsetHeight;

    const toolbar = document.querySelector(".mat-toolbar-single-row");

    if (toolbar) {
      windowHeight -= toolbar.clientHeight;
    }

    this.contentHeight = windowHeight;
  }
}
