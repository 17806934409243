import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "horizontal-divider",
  templateUrl: "./horizontal-divider.component.html",
  styleUrls: ["./horizontal-divider.component.scss"],
})
export class HorizontalDividerComponent implements OnInit {
  @Input() name: string;
  @Input() color?: string = "#cfd2e3";
  constructor() {}

  ngOnInit() {}
}
