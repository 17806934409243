import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "creative-video-template-card",
  templateUrl: "./creative-video-template-card.component.html",
  styleUrls: ["./creative-video-template-card.component.scss"],
})
export class CreativeVideoTemplateCardComponent implements OnInit {
  @ViewChild("videoPlayer") videoPlayer: ElementRef;
  @Input() template: any;
  @Input() width: number;
  public previewImageUrl: string;
  public isPlayingVideo: boolean = false;
  public showImageLoading: boolean = true;
  public theme: any;

  constructor() {}

  ngOnInit() {
    this.setPreviewImageUrl();
  }

  setPreviewImageUrl() {
    this.previewImageUrl = this.template.hasOwnProperty("singleTemplates")
      ? this.template.singleTemplates[0].imageUrlThumbnail
      : this.template.templateImageUrlThumbnail;
  }

  loadImage() {
    this.showImageLoading = false;
  }

  setSkeletonTheme() {
    this.theme = {
      width: this.width + "px",
      height: this.width + "px",
      "margin-bottom": 0,
    };
  }

  enter() {
    if (!this.template.hasOwnProperty("templateVideoUrl")) {
      return;
    }
    this.isPlayingVideo = true;
    this.videoPlayer.nativeElement.muted = true;
    this.videoPlayer.nativeElement.play();
  }

  leave() {
    if (!this.template.hasOwnProperty("templateVideoUrl")) {
      return;
    }
    this.isPlayingVideo = false;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
  }
}
