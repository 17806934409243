import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { TextSuggestionsDialogComponent } from "app/shared/components/dialogs/text-suggestions-dialog/text-suggestions-dialog.component";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
  selector: "gam-campaign-text-form",
  templateUrl: "./gam-campaign-text-form.component.html",
  styleUrls: ["./gam-campaign-text-form.component.scss"],
})
export class GamCampaignTextFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() mediaFormat: GAMMediaFormat;
  @Input() customer: MPCustomer;
  @Input() readonly: boolean;
  @Input() isEditMode?: boolean;
  @Input() data?: any;
  public form: FormGroup;
  public errors: any = {};
  constructor(private translate: TranslateService, private dialog: MatDialog) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildForm();

    if (
      changes.hasOwnProperty("isEditMode") &&
      !changes.isEditMode.firstChange
    ) {
      if (changes.isEditMode.currentValue) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

  buildForm() {
    this.form = new FormGroup({});

    if (this.mediaFormat.headlineLength) {
      this.form.addControl(
        "headline",
        new FormControl(
          this.data && this.data.headline ? this.data.headline : "",
          Validators.required
        )
      );
    }

    if (this.mediaFormat.bodyLength) {
      this.form.addControl(
        "body",
        new FormControl(
          this.data && this.data.body ? this.data.body : "",
          Validators.required
        )
      );
    }

    if (this.readonly) {
      this.form.disable();
    }
  }

  openTextSuggestions($event, type: string) {
    const dialogRef = this.dialog.open(TextSuggestionsDialogComponent, {
      width: "960px",
      panelClass: "no-spacing",
      maxHeight: "95vh",
      disableClose: true,
      data: {
        customerId: this.customer ? this.customer.customerId : null,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const { text } = res;
        const maxLength =
          type === "headline"
            ? this.mediaFormat.headlineLength
            : this.mediaFormat.bodyLength;

        const displayText =
          text.length > maxLength ? text.substring(0, maxLength) : text;

        this.form.get(type).setValue(displayText);
      }
    });
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.form.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  onSubmit() {
    this.markFormControls(this.form.controls);

    if (this.form.invalid) {
      this.getValidationErrors();
      return;
    }

    const value = this.form.getRawValue();

    this.submitted.emit(value);
  }
}
