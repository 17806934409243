export class MPMediaUploadFormatConfiguration {
  public maxHeight: number;
  public maxWidth: number;
  public minHeight: number;
  public minWidth: number;
  public format: string;
  public maxDuration: number;
  public minDuration: number;
  public minDurationWarning: number;
  public acceptTypes: string;
  public forceSize: boolean;
  public forceFormat: boolean;
  public forceDuration: boolean;

  constructor(
    maxHeight: number,
    maxWidth: number,
    minHeight: number,
    minWidth: number,
    format: string,
    maxDuration: number,
    minDuration: number,
    minDurationWarning: number,
    acceptTypes: string,
    forceSize: boolean,
    forceFormat: boolean,
    forceDuraction: boolean
  ) {
    this.maxHeight = maxHeight;
    this.maxWidth = maxWidth;
    this.minHeight = minHeight;
    this.minWidth = minWidth;
    this.format = format;
    this.maxDuration = maxDuration;
    this.minDuration = minDuration;
    this.minDurationWarning = minDurationWarning;
    this.acceptTypes = acceptTypes;
    this.forceSize = forceSize;
    this.forceFormat = forceFormat;
    this.forceDuration = forceDuraction;
  }
}
