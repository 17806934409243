import { Directive, ElementRef, Input, SimpleChanges } from "@angular/core";

@Directive({
  selector: "[loadingButton]",
})
export class LoadingButtonDirective {
  @Input("isLoading") isLoading = false;
  @Input("loadingText") loadingText = "";
  @Input("loadingSuccessMessage") loadingSuccessMessage = "";
  @Input("resetLoading") resetLoading = false;
  @Input("resetLoadingTimer") resetLoadingTimer = 0;
  @Input("fontColorClass") fontColorClass = "text-dark-blue-200";
  public originInnerHTML: string;
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.originInnerHTML = this.elementRef.nativeElement.innerHTML;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes", changes);
    if (changes.hasOwnProperty("isLoading") && !changes.isLoading.firstChange) {
      // Loading is starting
      if (changes.isLoading.currentValue && !changes.isLoading.previousValue) {
        this.showAnimation();
        return;
      }

      // Loading is finished
      this.stopAnimation();
    }
  }

  showAnimation() {
    const wrapper = this.buildButtonWrapper();
    const label = this.buildButtonLabel(this.loadingText);
    const spinner = this.buildButtonSpinner();
  }

  stopAnimation() {
    // Mit restore und so!!!
  }

  restoreOrigin() {
    this.elementRef.nativeElement.innerHTML = this.originInnerHTML;
  }

  buildButtonWrapper() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("mat-button-wrapper");

    return;
  }

  buildButtonLabel(text: string) {
    const span = document.createElement("span");
    span.classList.add(this.fontColorClass, "text-semi-bold", "ml-05");
    span.innerText = text;
    return span;
  }

  buildButtonSpinner() {}
}
