import { Component, Input, OnInit } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";

@Component({
  selector: "campaign-channel-card",
  templateUrl: "./campaign-channel-card.component.html",
  styleUrls: ["./campaign-channel-card.component.scss"],
})
export class CampaignChannelCardComponent implements OnInit {
  @Input() channel: MPChannel;
  constructor() {}

  ngOnInit() {}
}
