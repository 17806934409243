import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-video-editor-main",
  templateUrl: "./marketing-portal-video-editor-main.component.html",
  styleUrls: ["./marketing-portal-video-editor-main.component.scss"],
})
export class MarketingPortalVideoEditorMainComponent implements OnInit {
  @Output() onSelectSlide: EventEmitter<any> = new EventEmitter();
  @Input() slides: any;
  @Input() selectedSlide: any;
  @Input() duration: number;
  @Input() currentSlideIndex: number;
  public readinessInterval = null;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("selectedSlide") &&
      !changes.selectedSlide.firstChange
    ) {
      this.updateReadiness();
    }
  }

  onPrevSlide() {
    if (this.currentSlideIndex === 0) {
      return;
    }

    const prevSlide = this.slides[this.currentSlideIndex - 1];
    this.onSelectSlide.emit(prevSlide.slideId);
  }

  onNextSlide() {
    if (this.currentSlideIndex === this.slides.length - 1) {
      return;
    }

    const prevSlide = this.slides[this.currentSlideIndex + 1];
    this.onSelectSlide.emit(prevSlide.slideId);
  }

  updateReadiness() {
    if (
      this.selectedSlide.hasOwnProperty("isReady") &&
      !this.selectedSlide.isReady
    ) {
      const self = this;
      const maxRecurrences = 10;
      let counter = 0;
      this.readinessInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(self.selectedSlide.imageId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              await self.delay(1500);
              self.selectedSlide.isReady = res.isReady;
              clearInterval(self.readinessInterval);
            }

            if (counter > maxRecurrences) {
              clearInterval(self.readinessInterval);
              self.snackbarService.show(
                self.translate.instant("ErrorOccurredWhileGeneratingVideo"),
                "danger"
              );
            }
          });
      }, 3000);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
