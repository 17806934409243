import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { environment } from "environments/environment";
import { CorporateColorSelectorDialogComponent } from "../../dialogs/corporate-color-selector-dialog/corporate-color-selector-dialog.component";

@Component({
  selector: "color-box-picker",
  templateUrl: "./color-box-picker.component.html",
  styleUrls: ["./color-box-picker.component.scss"],
})
export class ColorBoxPickerComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() imageId: number;
  @Input() color: string;
  @Input() readonly?: boolean = false;
  public environment = environment;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  onColorChangeWithPicker($event) {
    const value = $event.target.value;

    this.color = value;
    this.onChange.emit(value);
  }

  openCorporateColorSelector(index: string) {
    const dialogRef = this.dialog.open(CorporateColorSelectorDialogComponent, {
      data: {
        url:
          environment.imageServerUrl +
          "api/Public/GetMediaAsset?id=" +
          this.imageId +
          "&width=300&height=300",
        color: this.color,
      },
      panelClass: "no-spacing",
      width: "860px",
    });

    dialogRef.beforeClosed().subscribe((res) => {
      if (res) {
        const value = res.hex;
        this.color = value;
        this.onChange.emit(value);
      }
    });
  }
}
