import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomerKeyword } from "app/model/customer/CustomerKeyword";

@Component({
  selector: "customer-keyword-card",
  templateUrl: "./customer-keyword-card.component.html",
  styleUrls: ["./customer-keyword-card.component.scss"],
})
export class CustomerKeywordCardComponent implements OnInit {
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Input() keyword: CustomerKeyword;
  @Input() readonly?: boolean = false;

  constructor() {}

  ngOnInit() {}
}
