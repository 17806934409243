export class MPMediaImportType {
  public type: number;
  public static MEDIA_IMPORT_TYPE_IMAGE: number = 1;
  public static MEDIA_IMPORT_TYPE_VIDEO: number = 2;
  constructor() {
    this.setToImage();
  }

  setToVideo() {
    this.setType(MPMediaImportType.MEDIA_IMPORT_TYPE_VIDEO);
  }

  setToImage() {
    this.setType(MPMediaImportType.MEDIA_IMPORT_TYPE_IMAGE);
  }

  isImage() {
    this.getType() === MPMediaImportType.MEDIA_IMPORT_TYPE_IMAGE;
  }

  isVideo() {
    this.getType() === MPMediaImportType.MEDIA_IMPORT_TYPE_VIDEO;
  }

  setType(type: number) {
    this.type = type;
  }

  getType() {
    return this.type;
  }
}
