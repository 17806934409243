import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "two-label-toggle-switch",
  templateUrl: "./two-label-toggle-switch.component.html",
  styleUrls: ["./two-label-toggle-switch.component.scss"],
})
export class TwoLabelToggleSwitchComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() options: any;
  @Input() selectedOption: number;
  public checked: boolean = false;
  constructor() {}

  ngOnInit() {
    if (this.selectedOption === 2) {
      this.checked = true;
    }
  }

  onChange($event) {
    const index = $event.target.checked ? 1 : 0;
    this.onSelect.emit(this.options[index].value);
  }
}
