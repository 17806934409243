export class MPGender {
  public genderId: number;
  public displayName: string;
  public icon: string;

  constructor(
    genderId: number = null,
    displayName: string = null,
    icon: string = null
  ) {
    this.genderId = genderId;
    this.displayName = displayName;
    this.icon = icon;
  }

  deserialize(serializedObj: object): MPGender {
    if (!serializedObj) {
      return null;
    }

    this.genderId = serializedObj["genderId"];
    this.displayName = serializedObj["displayName"];
    this.icon = serializedObj["icon"];

    return this;
  }
}
