import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-editor-templates-group",
  templateUrl: "./marketing-portal-editor-templates-group.component.html",
  styleUrls: ["./marketing-portal-editor-templates-group.component.scss"],
})
export class MarketingPortalEditorTemplatesGroupComponent implements OnInit {
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  @Input() templateGroup;
  public isOpen: boolean = false;
  public isVideo: boolean;
  constructor() {}

  ngOnInit() {
    this.setIsVideo();
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  setIsVideo() {
    this.isVideo = this.templateGroup.templates[0].templateVideoUrl;
  }
}
