import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-image-tooltip",
  templateUrl: "./image-tooltip.component.html",
  styleUrls: ["./image-tooltip.component.scss"],
})
export class ImageTooltipComponent implements OnInit {
  @Input() image = "";
  public isLoaded: boolean = false;
  constructor() {}

  ngOnInit() {}

  load() {
    this.isLoaded = true;
  }
}
