import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from "@angular/core";
import { ImageTooltipComponent } from "../components/partials/image-tooltip/image-tooltip.component";

@Directive({
  selector: "[imageTooltip]",
})
export class ImageTooltipDirective {
  @Input("image") image = "";
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom",
          panelClass: "top",
          offsetY: -8,
          offsetX: 20,
        },
        {
          originX: "center",
          originY: "bottom",
          overlayX: "center",
          overlayY: "top",
          panelClass: "bottom",
          offsetY: 8,
          offsetX: 20,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener("mouseenter")
  show() {
    if (!this.image) {
      return;
    }

    const tooltipRef: ComponentRef<ImageTooltipComponent> =
      this.overlayRef.attach(new ComponentPortal(ImageTooltipComponent));
    tooltipRef.instance.image = this.image;
  }

  @HostListener("mouseout")
  hide() {
    if (!this.image) {
      return;
    }
    this.overlayRef.detach();
  }
}
