import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";

import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { UploadedFile } from "app/model/UploadedFile";
import { CorporateColorSelectorDialogComponent } from "app/shared/components/dialogs/corporate-color-selector-dialog/corporate-color-selector-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { CustomFileUploader } from "app/views/mapAds/custom_file_uploader";
import { environment } from "environments/environment";

@Component({
  selector: "edit-customer-design",
  templateUrl: "./edit-customer-design.component.html",
  styleUrls: ["./edit-customer-design.component.scss"],
})
export class EditCustomerDesignComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() readonly: boolean = false;
  @Input() data: any = null;
  @ViewChild("form") form: NgForm;
  public showContact: boolean = false;
  public showLogo: boolean = false;
  public formGroup: FormGroup;
  public errors: any = {};
  public customUploader: CustomFileUploader;
  public uploadedFiles: Array<UploadedFile>;
  public selectedPrimaryColor: string;
  public selectedSecondaryColor: string;
  readonly environment = environment;
  public mediaAssets: MPMediaAsset[] = [];
  public uploadMessages: object = {
    title: "Kein Kundenlogo hinzugefügt",
    body: "Für diesen Kunden hast Du kein Logo hinzugefügt",
  };
  public hasLogo: boolean = false;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private crudService: CrudService,
    private router: Router,
    private appLoader: AppLoaderService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {
    this.uploadedFiles = [];
  }

  ngOnInit() {
    this.buildFormGroup(this.data);
  }

  async buildFormGroup(items) {
    this.formGroup = this.fb.group({
      id: [items ? items.customerId : null],
      mainText1: [items ? items.mainText1 : ""],
      mainText2: [items ? items.mainText2 : ""],
      mainText3: [items ? items.mainText3 : ""],
    });

    this.selectedPrimaryColor = this.convertColorFromDB(
      items ? items.customerCorporateColor : null
    );
    this.selectedSecondaryColor = this.convertColorFromDB(
      items ? items.secondaryColor : null
    );

    this.hasLogo = items && items.customerLogoThumbnailUrl ? true : false;

    if (this.readonly) {
      this.formGroup.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  async updateTextsWithAI() {
    this.appLoader.open();

    await this.crudService
      .UpdateCustomerTextsWithAI(this.data.customerId)
      .toPromise()
      .then((res) => {
        this.snackbarService.show(
          "Die Werbetexte wurden angepasst.",
          "success"
        );
        this.buildFormGroup(res);
      })
      .catch((e) => {
        this.snackbarService.show(e.error.Message, "danger");
      });
    this.appLoader.close();
  }

  convertColorFromDB(color: any): any {
    if (color) {
      if (!color.includes("#") && color.includes("(")) {
        //RGB??
        const colorSplit = color.split("(")[1];
        const r = parseInt(colorSplit.split(",")[0]);
        const g = parseInt(colorSplit.split(",")[1]);
        const b = parseInt(colorSplit.split(",")[2]);
        return this.rgbToHex(r, g, b);
      }
    }
    return color;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  onColorChangeWithPicker($event, index: string) {
    const value = $event.target.value;
    if (index === "primary") {
      this.selectedPrimaryColor = value;
    } else {
      this.selectedSecondaryColor = value;
    }
  }

  openCorporateColorSelector(index: string) {
    let color =
      index === "primary"
        ? this.selectedPrimaryColor
        : this.selectedSecondaryColor;

    const dialogRef = this.dialog.open(CorporateColorSelectorDialogComponent, {
      data: {
        url: this.data.customerLogoThumbnailUrl,
        color: color,
      },
      width: "860px",
      panelClass: "no-spacing",
    });

    dialogRef.beforeClosed().subscribe((res) => {
      if (res) {
        const rgb = res.res;
        const hex = res.hex;

        if (index === "primary") {
          this.selectedPrimaryColor = hex; //`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`;
        } else {
          this.selectedSecondaryColor = hex; //`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`;
        }
      }
    });
  }

  submit() {
    this.form.onSubmit(undefined);
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.formGroup.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.formGroup.get(name).value === "") {
      this.formGroup.get(name).clearValidators();
    } else {
      this.formGroup.get(name).setValidators(validators);
    }
    this.formGroup.get(name).updateValueAndValidity();
  }

  updateCustomerCD(goBack = true) {
    return new Promise(async (resolve, reject) => {
      const value = this.formGroup.getRawValue();

      const newCustomerDesign = {
        customerId: value.id,
        mainText1: value.mainText1 == "" ? null : value.mainText1,
        mainText2: value.mainText2 == "" ? null : value.mainText2,
        mainText3: value.mainText3 == "" ? null : value.mainText3,
        customerCorporateColor: this.selectedPrimaryColor,
        secondaryColor: this.selectedSecondaryColor,
      };

      const request = this.crudService.UpdateCustomerCD(newCustomerDesign);

      await request
        .toPromise()
        .then((res) => {
          if (res) {
            this.snackbarService.show(res.toString());
            if (goBack) {
              this.location.back();
            }
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
          reject();
        });

      resolve(true);
    });
  }

  async onSubmit(data = null) {
    this.appLoader.open();
    await this.updateCustomerCD();
    this.appLoader.close();
  }
}
