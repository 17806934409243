export class MPGAMAdUnit {
  id: number;
  name: string;
  icon: string;
  description: string;
  link: string;
  minBudget: number;

  constructor(
    id: number = null,
    name: string = null,
    icon: string = null,
    description: string = null,
    link: string = null,
    minBudget: number = null
  ) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.description = description;
    this.link = link;
    this.minBudget = minBudget;
  }

  deserialize(serializedObj: object): MPGAMAdUnit {
    if (!serializedObj) {
      return null;
    }

    this.id = serializedObj["id"];
    this.name = serializedObj["name"];
    this.icon = serializedObj["icon"];
    this.description = serializedObj["description"];
    this.link = serializedObj["link"];
    this.minBudget = serializedObj["minBudget"];

    return this;
  }
}
