import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";

@Component({
  selector: "app-video-media-libary-dialog",
  templateUrl: "./video-media-libary-dialog.component.html",
  styleUrls: ["./video-media-libary-dialog.component.scss"],
})
export class VideoMediaLibaryDialogComponent implements OnInit {
  public selected: MPLibaryMediaAsset;
  public customerId: number;
  public width: number;
  public height: number;
  public hideAdMaterials: boolean;
  constructor(
    public dialogRef: MatDialogRef<VideoMediaLibaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.customerId = this.data.customerId;
    this.width = this.data.width;
    this.height = this.data.height;
    this.hideAdMaterials = this.data.hideAdMaterials;
  }

  onSelect(video) {
    if (!this.selected) {
      this.selected = video;
      return;
    }

    this.selected = this.selected.ImageId != video.ImageId ? video : null;
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    this.dialogRef.close({ mediaAssetId: this.selected.ImageId });
  }
}
