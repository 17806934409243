import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import {
  MatTreeModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatBadgeModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatStepper,
  MatHorizontalStepper,
  MatStepperModule,
  MatTable,
  MatTableModule,
  MatExpansionModule,
  MatInputModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatSlider,
  MatSliderModule,
} from "@angular/material";

import { LottieAnimationViewModule } from "ng-lottie";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { SidebarSideComponent } from "./components/sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./components/header-top/header-top.component";
import { SidebarTopComponent } from "./components/sidebar-top/sidebar-top.component";

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from "./components/customizer/customizer.component";

// ALL TIME REQUIRED
import { AdminLayoutComponent } from "./components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "./services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "./services/app-loader/app-loader.component";
import { FilterSearchComponent } from "./components/filter-search/filter-search.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragDropUploadComponent } from "./components/drag-drop-upload/drag-drop-upload.component";
import { DragDropDirective } from "./components/drag-drop-upload/drag-drop.directive";
import { SupportPopoverButtonComponent } from "./components/support/support-popover-button/support-popover-button.component";
import { SupportPopoverComponent } from "./components/dialogs/support-popover/support-popover.component";
import { CreatePromotionDialogComponent } from "./components/dialogs/promotions/create-promotion-dialog/create-promotion-dialog.component";
import { StoreSelectionTreeComponent } from "./components/trees/store-selection-tree/store-selection-tree.component";
import { CreatePromotionFormComponent } from "./components/forms/promotions/create-promotion-form/create-promotion-form.component";
import { SearchGtinPromotionFormComponent } from "./components/forms/promotions/search-gtin-promotion-form/search-gtin-promotion-form.component";
import { PopoverComponent } from "./components/notifications/popover/popover.component";
import { PopoverPasswordCheckComponent } from "./components/notifications/popover/popover-password-check/popover-password-check.component";
import { PromotionPreviewDialogComponent } from "./components/dialogs/promotions/promotion-preview-dialog/promotion-preview-dialog.component";
import { PopoverTcontractInfoComponent } from "./components/notifications/popover/popover-tcontract-info/popover-tcontract-info.component";
import { GooglePreviewComponent } from "./components/previews/google-preview/google-preview.component";
import { InstagramPreviewComponent } from "./components/previews/instagram-preview/instagram-preview.component";
import { FacebookPreviewComponent } from "./components/previews/facebook-preview/facebook-preview.component";
import { YoutubePreviewComponent } from "./components/previews/youtube-preview/youtube-preview.component";
import { GoogleLocalInventoryPreviewComponent } from "./components/previews/google-local-inventory-preview/google-local-inventory-preview.component";

// DIRECTIVES
import { FontSizeDirective } from "./directives/font-size.directive";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { AppDropdownDirective } from "./directives/dropdown.directive";
import { DropdownAnchorDirective } from "./directives/dropdown-anchor.directive";
import { DropdownLinkDirective } from "./directives/dropdown-link.directive";
import { EgretSideNavToggleDirective } from "./directives/egret-side-nav-toggle.directive";
import { ImageTooltipDirective } from "./directives/image-tooltip.directive";
import { LoadingButtonDirective } from "./directives/loading-button.directive";

// PIPES
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { ExcerptPipe } from "./pipes/excerpt.pipe";
import { GetValueByKeyPipe } from "./pipes/get-value-by-key.pipe";
import { CurrencyPipe } from "./pipes/currency.pipe";
import { GetStoreNameByIdPipe } from "./pipes/stores/get-store-name-by-id.pipe";
import { GetTemplateNameByIdPipe } from "./pipes/templates/get-template-name-by-id.pipe";
import { GetTemplateScreenshotByIdPipe } from "./pipes/templates/get-template-screenshot-by-id.pipe";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { LayoutService } from "./services/layout.service";
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./services/auth/auth.guard";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { ButtonLoadingComponent } from "./components/button-loading/button-loading.component";
import { SearchModule } from "./search/search.module";
import { ChatService } from "./components/notifications/notifications.service";
import { FacebookPageConnectPopupComponent } from "app/views/mapAds/settings/facebook-page-connect-popup/facebook-page-connect-popup.component";
import { ErrorMessageHelperComponent } from "./components/error-message-helper/error-message-helper.component";
import { SubchannelCardComponent } from "./components/cards/subchannel-card/subchannel-card.component";
import { SubchannelGridComponent } from "./components/grids/subchannel-grid/subchannel-grid.component";
import { AdditionalChannelPackagesInfoDialogComponent } from "./components/dialogs/additional-channel-packages-info-dialog/additional-channel-packages-info-dialog.component";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { PromotionSuccessfullyCreatedDialogComponent } from "./components/dialogs/promotions/promotion-successfully-created-dialog/promotion-successfully-created-dialog.component";
import { BasicChannelCardComponent } from "./components/cards/basic-channel-card/basic-channel-card.component";
import { BasicChannelsGridComponent } from "./components/grids/basic-channels-grid/basic-channels-grid.component";
import { ExternalSystemsGridComponent } from "./components/grids/external-systems-grid/external-systems-grid.component";
import { ExternalSystemCardComponent } from "./components/cards/external-system-card/external-system-card.component";
import { UnblockPopupFirefoxInstructionComponent } from "./components/instructions/unblock-popup-firefox-instruction/unblock-popup-firefox-instruction.component";
import { PopupBlockedDialogComponent } from "./components/dialogs/popup-blocked-dialog/popup-blocked-dialog.component";
import { UnblockPopupSafariInstructionComponent } from "./components/instructions/unblock-popup-safari-instruction/unblock-popup-safari-instruction.component";
import { AllowBrowserPopupInstructionsComponent } from "./components/instructions/allow-browser-popup-instructions/allow-browser-popup-instructions.component";
import { FacebookAdsPreviewComponent } from "./components/dialogs/ads/facebook-ads-preview/facebook-ads-preview.component";
import { PanelComponent } from "./components/panel/panel.component";
import { PanelHeaderComponent } from "./components/panel/panel-header/panel-header.component";
import { SelectArrowComponent } from "./components/partials/select-arrow/select-arrow.component";
import { SelectCirclesComponent } from "./components/select-circles/select-circles.component";
import { PanelContentComponent } from "./components/panel/panel-content/panel-content.component";
import { ProgressCirclesComponent } from "./components/progress-circles/progress-circles.component";
import { AnimationCheckmarkCircleComponent } from "./components/partials/animation-checkmark-circle/animation-checkmark-circle.component";
import { StatusSelectCirclesComponent } from "./components/partials/status-select-circles/status-select-circles.component";
import { GoogleStorecodesInstructionsComponent } from "./components/instructions/google-storecodes-instructions/google-storecodes-instructions.component";
import { ChannelInfoDialogComponent } from "./components/dialogs/channel-info-dialog/channel-info-dialog.component";
import { CropImageDialogComponent } from "./components/dialogs/crop-image-dialog/crop-image-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ActiveExternalSystemsGridComponent } from "./components/grids/active-external-systems-grid/active-external-systems-grid.component";
import { ActiveExternalSystemCardComponent } from "./components/cards/active-external-system-card/active-external-system-card.component";
import { ContentBoxComponent } from "./components/content-box/content-box/content-box.component";
import { ContentBoxItemComponent } from "./components/content-box/content-box-item/content-box-item.component";
import { ContentBoxTitleComponent } from "./components/content-box/content-box-title/content-box-title.component";
import { ContentBoxItemLabelComponent } from "./components/content-box/content-box-item-label/content-box-item-label.component";
import { ContentBoxItemValueComponent } from "./components/content-box/content-box-item-value/content-box-item-value.component";
import { ContentBoxLabelValueComponent } from "./components/content-box/content-box-label-value/content-box-label-value.component";
import { InlineIconMessageComponent } from "./components/messages/inline-icon-message/inline-icon-message.component";
import { CustomActionIconMessageComponent } from "./components/messages/custom-action-icon-message/custom-action-icon-message.component";
import { SuccessAnimationActionMessageComponent } from "./components/messages/success-animation-action-message/success-animation-action-message.component";
import { InlineActionIconMessageComponent } from "./components/messages/inline-action-icon-message/inline-action-icon-message.component";
import { AddExternalSystemFormComponent } from "./components/forms/external-systems/add-external-system-form/add-external-system-form.component";
import { ErrorActionMessageComponent } from "./components/messages/error-action-message/error-action-message.component";
import { InfoActionMessageComponent } from "./components/messages/info-action-message/info-action-message.component";
import { SelectMenuComponent } from "./components/select-menu/select-menu.component";
import { SelectMenuItemComponent } from "./components/select-menu/select-menu-item/select-menu-item.component";
import { GridComponent } from "./components/grids/grid/grid.component";
import { SelectMultipleMenuComponent } from "./components/select/select-multiple-menu/select-multiple-menu.component";
import { TrendArrowComponent } from "./components/partials/trend-arrow/trend-arrow.component";
import { ChartsModule } from "ng2-charts";
import { RankingChartComponent } from "./components/statistics/ranking-chart/ranking-chart.component";
import { ChannelHeatMapComponent } from "./components/maps/channel-heat-map/channel-heat-map.component";
import { HeatMapSidebarFilterComponent } from "./components/partials/heat-map-sidebar-filter/heat-map-sidebar-filter.component";
import { StoreLocationSelectListComponent } from "./components/lists/stores/store-location-select-list/store-location-select-list.component";
import { StoreDetailCardComponent } from "./components/cards/store-detail-card/store-detail-card.component";
import { ZipcodeImpressionsSelectListComponent } from "./components/lists/zipcodes/zipcode-impressions-select-list/zipcode-impressions-select-list.component";
import { HeatMapSettingsComponent } from "./components/partials/heat-map-settings/heat-map-settings.component";
import { ProductActivitiesTimelineComponent } from "./components/partials/product-activities-timeline/product-activities-timeline.component";
import { NoDataMessageComponent } from "./components/messages/no-data-message/no-data-message.component";
import { PageHeaderComponent } from "./components/partials/page-header/page-header.component";
import { VerticalDividerComponent } from "./components/partials/divider/vertical-divider/vertical-divider.component";
import { IconMessageComponent } from "./components/messages/icon-message/icon-message.component";
import { ErrorIconMessageComponent } from "./components/messages/icon-message/error-icon-message/error-icon-message.component";
import { InfoIconMessageComponent } from "./components/messages/icon-message/info-icon-message/info-icon-message.component";
import { SuccessIconMessageComponent } from "./components/messages/icon-message/success-icon-message/success-icon-message.component";
import { HorizontalDividerComponent } from "./components/partials/divider/horizontal-divider/horizontal-divider.component";
import { CreateStoreFormComponent } from "./components/forms/stores/create-store-form/create-store-form.component";
import { AddActionBoxComponent } from "./components/ui/item-action-box/add-action-box/add-action-box.component";
import { ViewActionBoxComponent } from "./components/ui/item-action-box/view-action-box/view-action-box.component";
import { SelectItemActionBoxComponent } from "./components/ui/select/select-item-action-box/select-item-action-box.component";
import { SelectActionBoxComponent } from "./components/ui/item-action-box/select-action-box/select-action-box.component";
import { StepSliderComponent } from "./components/ui/select/step-slider/step-slider.component";
import { RadiusMapComponent } from "./components/maps/radius-map/radius-map.component";
import { SuccessActionIconMessageComponent } from "./components/messages/success-action-icon-message/success-action-icon-message.component";
import { PillComponent } from "./components/partials/pill/pill.component";
import { StoresMapComponent } from "./components/maps/stores-map/stores-map.component";
import { CreateGroupFormComponent } from "./components/forms/groups/create-group-form/create-group-form.component";
import { StoreTreeComponent } from "./components/trees/store-tree/store-tree.component";
import { AddNewActionMessageComponent } from "./components/messages/add-new-action-message/add-new-action-message.component";
import { TrendValueComponent } from "./components/partials/trend-value/trend-value.component";
import { LocalizedDatePipe } from "./pipes/localized-date.pipe";
import { ActivitiesTimelineComponent } from "./components/partials/activities-timeline/activities-timeline.component";
import { ImpressionsDifferenceComponent } from "./components/partials/impressions-difference/impressions-difference.component";
import { UploadImageDialogComponent } from "./components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { ImageBackgroundRemoverComponent } from "./components/image/image-background-remover/image-background-remover.component";
import { ImageCropperComponent } from "./components/image/image-cropper/image-cropper.component";
import { SelectImageFromSelectionComponent } from "./components/select/select-image-from-selection/select-image-from-selection.component";
import { ExternalSystemSuccessfullyConnectedDialogComponent } from "./components/dialogs/external-system-successfully-connected-dialog/external-system-successfully-connected-dialog.component";
import { ColorBoxComponent } from "./components/partials/color-box/color-box.component";
import { CorporateColorSelectorDialogComponent } from "./components/dialogs/corporate-color-selector-dialog/corporate-color-selector-dialog.component";
import { VideoTemplateSelectorDialogComponent } from "./components/dialogs/video-template-selector-dialog/video-template-selector-dialog.component";
import { VideoTemplateGridComponent } from "./components/grids/video-template-grid/video-template-grid.component";
import { VideoTemplateCardComponent } from "./components/cards/video-template-card/video-template-card.component";
import { SelectedVideoTemplateComponent } from "./components/partials/selected-video-template/selected-video-template.component";
import { ReviewYourVideoTemplateDialogComponent } from "./components/dialogs/review-your-video-template-dialog/review-your-video-template-dialog.component";
import { NoImagesUploadedConfirmationDialogComponent } from "./components/dialogs/no-images-uploaded-confirmation-dialog/no-images-uploaded-confirmation-dialog.component";
import { MaRoundedBasicLogoDarkComponent } from "./ma/logo/ma-rounded-basic-logo-dark/ma-rounded-basic-logo-dark.component";
import { MaCardCollapseContentComponent } from "./ma/cards/ma-card-collapse-content/ma-card-collapse-content.component";
import { MaCardComponent } from "./ma/cards/ma-card/ma-card.component";
import { MaCardContentComponent } from "./ma/cards/ma-card-content/ma-card-content.component";
import { MaCardHeaderComponent } from "./ma/cards/ma-card-header/ma-card-header.component";
import { MaCardHeaderTitleComponent } from "./ma/cards/ma-card-header-title/ma-card-header-title.component";
import { MaMultipleCardsComponent } from "./ma/cards/ma-multiple-cards/ma-multiple-cards.component";
import { MaIconPillListComponent } from "./ma/lists/ma-icon-pill-list/ma-icon-pill-list.component";
import { MaShapeCircleComponent } from "./ma/shapes/ma-shape-circle/ma-shape-circle.component";
import { MaShapeCircleOutlineComponent } from "./ma/shapes/ma-shape-circle-outline/ma-shape-circle-outline.component";
import { MaShapeRectangleComponent } from "./ma/shapes/ma-shape-rectangle/ma-shape-rectangle.component";
import { MaShapeRectangleOutlineComponent } from "./ma/shapes/ma-shape-rectangle-outline/ma-shape-rectangle-outline.component";
import { MaShapeTriangleComponent } from "./ma/shapes/ma-shape-triangle/ma-shape-triangle.component";
import { MaSnackbarComponent } from "./components/partials/ma-snackbar/ma-snackbar.component";
import { GoogleServiceInfoGuideComponent } from "./components/guides/google-service-info-guide/google-service-info-guide.component";
import { MaMessageComponent } from "./components/messages/ma-message/ma-message.component";
import { MaAccordionComponent } from "./ma/accordian/ma-accordion/ma-accordion.component";
import { SuccessTrophyMessageComponent } from "./ma/notification/success-trophy-message/success-trophy-message.component";
import { FacebookServiceInfoGuideComponent } from "./components/guides/facebook-service-info-guide/facebook-service-info-guide.component";
import { InstagramServiceInfoGuideComponent } from "./components/guides/instagram-service-info-guide/instagram-service-info-guide.component";
import { YoutubeServiceInfoGuideComponent } from "./components/guides/youtube-service-info-guide/youtube-service-info-guide.component";
import { MaBackgroundShapesDarkComponent } from "./ma/shapes/ma-background-shapes-dark/ma-background-shapes-dark.component";
import { MaBackgroundShapesLightComponent } from "./ma/shapes/ma-background-shapes-light/ma-background-shapes-light.component";
import { MaRoundedBasicLogoLightComponent } from "./ma/logo/ma-rounded-basic-logo-light/ma-rounded-basic-logo-light.component";
import { ChannelPreferencesPanelGridComponent } from "./components/grids/channel-preferences-panel-grid/channel-preferences-panel-grid.component";
import { ChannelPreferencesPanelCardComponent } from "./components/cards/channel-preferences-panel-card/channel-preferences-panel-card.component";
import { KeysPipe } from "./pipes/keys.pipe";
import { WeeklyChannelHistoryCardComponent } from "./components/cards/weekly-channel-history-card/weekly-channel-history-card.component";
import { FutureChannelHistoryCardComponent } from "./components/cards/future-channel-history-card/future-channel-history-card.component";
import { GoogleAccountInformationComponent } from "./components/account/google-account-information/google-account-information.component";
import { FacebookAccountInformationComponent } from "./components/account/facebook-account-information/facebook-account-information.component";
import { YoutubeAccountInformationComponent } from "./components/account/youtube-account-information/youtube-account-information.component";
import { InstagramAccountInformationComponent } from "./components/account/instagram-account-information/instagram-account-information.component";
import { ContentBoardComponent } from "./components/partials/content-board/content-board.component";
import { TipsComponent } from "./components/partials/tips/tips.component";
import { CampaignChannelActionCardComponent } from "./components/cards/campaign-channel-action-card/campaign-channel-action-card.component";
import { CampaignRegionsComponent } from "./components/partials/campaign-regions/campaign-regions.component";
import { CampaignRegionItemComponent } from "./components/partials/campaign-region-item/campaign-region-item.component";
import { AddRegionDialogComponent } from "./components/dialogs/add-region-dialog/add-region-dialog.component";
import { CampaignFacebookPreviewComponent } from "./components/previews/campaign-facebook-preview/campaign-facebook-preview.component";
import { SmartphoneComponent } from "./components/previews/smartphone/smartphone.component";
import { SmartphoneIphoneXComponent } from "./components/previews/smartphone-iphone-x/smartphone-iphone-x.component";
import { MarketingPortalEditorDialogComponent } from "./components/dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MarketingPortalTemplatesGridComponent } from "./components/grids/marketing-portal-templates-grid/marketing-portal-templates-grid.component";
import { MarketingPortalTemplatesCardComponent } from "./components/cards/marketing-portal-templates-card/marketing-portal-templates-card.component";
import { MarketingPortalEditorComponent } from "./components/marketing-portal-editor/marketing-portal-editor.component";
import { MarketingPortalEditorHeaderComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-header/marketing-portal-editor-header.component";
import { MarketingPortalEditorTemplateConfiguratorComponent } from "./components/marketing-portal-editor/marketing-portal-editor-template-configurator/marketing-portal-editor-template-configurator.component";
import { MarketingPortalEditorTemplatesComponent } from "./components/marketing-portal-editor/marketing-portal-editor-templates/marketing-portal-editor-templates.component";
import { MarketingPortalEditorTemplatesSidebarComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-templates-sidebar/marketing-portal-editor-templates-sidebar.component";
import { MarketingPortalEditorTemplatesGroupComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-templates-group/marketing-portal-editor-templates-group.component";
import { MarketingPortalEditorSidebarComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-sidebar/marketing-portal-editor-sidebar.component";
import { MarketingPortalEditorContentComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-content/marketing-portal-editor-content.component";
import { MarketingPortalEditorConfiguratorToolsetComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-configurator-toolset/marketing-portal-editor-configurator-toolset.component";
import { MarketingPortalEditorConfiguratorToolsetItemComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-configurator-toolset-item/marketing-portal-editor-configurator-toolset-item.component";
import { MarketingPortalEditorModificationIconComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-modification-icon/marketing-portal-editor-modification-icon.component";
import { MarketingPortalEditorGlobalToolsetComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-global-toolset/marketing-portal-editor-global-toolset.component";
import { MarketingPortalStatusComponent } from "./components/partials/marketing-portal/marketing-portal-status/marketing-portal-status.component";
import { FacebookCampaignAdPreviewComponent } from "./components/dialogs/ads/facebook-campaign-ad-preview/facebook-campaign-ad-preview.component";
import { LinebreaksPipe } from "./pipes/linebreaks.pipe";
import { ImportMarketingPortalEditorFileDialogComponent } from "./components/dialogs/import-marketing-portal-editor-file-dialog/import-marketing-portal-editor-file-dialog.component";
import { UnsplashImageSearchDialogComponent } from "./components/dialogs/unsplash-image-search-dialog/unsplash-image-search-dialog.component";
import { CreateCustomerFormComponent } from "./components/forms/customer/create-customer-form/create-customer-form.component";
import { CreateCustomerLocationFormComponent } from "./components/forms/customer/create-customer-location-form/create-customer-location-form.component";
import { CampaignCustomerActionCardComponent } from "./components/cards/campaign-customer-action-card/campaign-customer-action-card.component";
import { CreateSMCampaignFormComponent } from "./components/forms/campaign/create-SMcampaign-form/create-SMcampaign-form.component";
import { MediaAssetSearchDialogComponent } from "./components/dialogs/media-asset-search-dialog/media-asset-search-dialog.component";
import { CreateInstagramFacebookPostFormComponent } from "./components/forms/campaign/create-instagram-facebook-post-form/create-instagram-facebook-post-form.component";
import { CreateInstagramFacebookBoostPostFormComponent } from "./components/forms/campaign/create-instagram-facebook-boost-post-form/create-instagram-facebook-boost-post-form.component";
import { MarketingPortalCampaignCardComponent } from "./components/cards/marketing-portal-media-campaign-card/marketing-portal-campaign-card.component";
import { MarketingPortalCampaignGridComponent } from "./components/grids/marketing-portal-campaign-grid/marketing-portal-campaign-grid.component";
import { CampaignChannelActionCardSmallComponent } from "./components/cards/campaign-channel-action-card-small/campaign-channel-action-card-small.component";
import { MediaAssetPreviewImagePipe } from "./pipes/media-asset-preview-image.pipe";
import { CreateGdnFormComponent } from "./components/forms/campaign/create-gdn-form/create-gdn-form.component";
import { CampaignGdnAdsPreviewComponent } from "./components/previews/campaign-gdn-ads-preview/campaign-gdn-ads-preview.component";
import { LocaleDatePipe } from "./pipes/locale-date.pipe";
import { RequestCustomerApprovalDialogComponent } from "./components/dialogs/request-customer-approval-dialog/request-customer-approval-dialog.component";
import { SendCustomerApprovalRequestFormComponent } from "./components/forms/campaign/send-customer-approval-request-form/send-customer-approval-request-form.component";
import { ApprovalConfirmationFormComponent } from "./components/forms/approval/approval-confirmation-form/approval-confirmation-form.component";
import { ApprovalConfirmationTextInputComponent } from "./components/forms/approval/approval-confirmation-form/approval-confirmation-text-input/approval-confirmation-text-input.component";
import { ApprovalConfirmationMediaInputComponent } from "./components/forms/approval/approval-confirmation-form/approval-confirmation-media-input/approval-confirmation-media-input.component";
import { ApprovalConfirmationActionsComponent } from "./components/partials/approval-confirmation-actions/approval-confirmation-actions.component";
import { ApprovalElementCommentDialogComponent } from "./components/dialogs/approval-element-comment-dialog/approval-element-comment-dialog.component";
import { CreateApprovalElementCommentFormComponent } from "./components/forms/campaign/create-approval-element-comment-form/create-approval-element-comment-form.component";
import { ApprovalStatusComponent } from "./components/partials/marketing-portal/approval-status/approval-status.component";
import { ApprovalHistoryCollapseListComponent } from "./components/partials/marketing-portal/approval-history-collapse-list/approval-history-collapse-list.component";
import { ApprovalHistoryCollapseListItemComponent } from "./components/partials/marketing-portal/approval-history-collapse-list/approval-history-collapse-list-item/approval-history-collapse-list-item.component";
import { MediaAssetsUploaderComponent } from "./components/partials/marketing-portal/media-assets-uploader/media-assets-uploader.component";
import { UploadedMediaAssetFileCardComponent } from "./components/cards/uploaded-media-asset-file-card/uploaded-media-asset-file-card.component";
import { CreateTvFormComponent } from "./components/forms/campaign/create-tv-form/create-tv-form.component";
import { MediaAssetsUploaderFileGridComponent } from "./components/partials/marketing-portal/media-assets-uploader-file-grid/media-assets-uploader-file-grid.component";
import { MediaAssetLibaryCardComponent } from "./components/cards/media-asset-libary-card/media-asset-libary-card.component";
import { MediaAssetsLibaryAdMaterialsComponent } from "./components/partials/marketing-portal/media-assets-libary-ad-materials/media-assets-libary-ad-materials.component";
import { MediaAssetsLibaryUploadedAssetsComponent } from "./components/partials/marketing-portal/media-assets-libary-uploaded-assets/media-assets-libary-uploaded-assets.component";
import { MpPaginationComponent } from "./components/partials/marketing-portal/mp-pagination/mp-pagination.component";
import { MediaAssetsLibaryUserLogoComponent } from "./components/partials/marketing-portal/media-assets-libary-user-logo/media-assets-libary-user-logo.component";
import { CreateYoutubeFormComponent } from "./components/forms/campaign/create-youtube-form/create-youtube-form.component";
import { CampaignYoutubePreviewComponent } from "./components/previews/campaign-youtube-preview/campaign-youtube-preview.component";
import { CampaignPreviewComponent } from "./components/previews/campaign-preview/campaign-preview.component";
import { MarketingPortalVideoEditorDialogComponent } from "./components/dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { MarketingPortalVideoEditorComponent } from "./components/marketing-portal-video-editor/marketing-portal-video-editor.component";
import { VideoEditorTemplateDetailsDialogComponent } from "./components/dialogs/video-editor-template-details-dialog/video-editor-template-details-dialog.component";
import { MarketingPortalVideoEditorTemplateConfiguratorComponent } from "./components/marketing-portal-video-editor/marketing-portal-video-editor-template-configurator/marketing-portal-video-editor-template-configurator.component";
import { MarketingPortalVideoEditorContentComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-content/marketing-portal-video-editor-content.component";
import { MarketingPortalVideoEditorSlideListComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-slide-list/marketing-portal-video-editor-slide-list.component";
import { MarketingPortalVideoEditorSlideSidebarComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-slide-sidebar/marketing-portal-video-editor-slide-sidebar.component";
import { MarketingPortalVideoEditorGlobalConfigurationToolsetComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-global-configuration-toolset/marketing-portal-video-editor-global-configuration-toolset.component";
import { MarketingPortalVideoEditorSlideConfigurationToolsetComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-slide-configuration-toolset/marketing-portal-video-editor-slide-configuration-toolset.component";
import { VideoEditorTemplateSlideCardComponent } from "./components/cards/video-editor-template-slide-card/video-editor-template-slide-card.component";
import { MarketingPortalVideoEditorMainComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-main/marketing-portal-video-editor-main.component";
import { MarketingPortalVideoEditorTimelineComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-timeline/marketing-portal-video-editor-timeline.component";
import { MarketingPortalVideoEditorSoundConfigurationToolsetComponent } from "./components/marketing-portal-video-editor/partials/marketing-portal-video-editor-sound-configuration-toolset/marketing-portal-video-editor-sound-configuration-toolset.component";
import { MarketingPortalEditorFontConfigurationToolsetComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-font-configuration-toolset/marketing-portal-editor-font-configuration-toolset.component";
import { MarketingPortalVideoEditorTemplateConfiguratorDesktopComponent } from "./components/marketing-portal-video-editor/marketing-portal-video-editor-template-configurator-desktop/marketing-portal-video-editor-template-configurator-desktop.component";
import { MarketingPortalVideoEditorTemplateConfiguratorMobileComponent } from "./components/marketing-portal-video-editor/marketing-portal-video-editor-template-configurator-mobile/marketing-portal-video-editor-template-configurator-mobile.component";
import { NotificationSettingsFormComponent } from "./components/forms/settings/notification-settings-form/notification-settings-form.component";
import { CampaignNotificationSettingsComponent } from "./components/partials/marketing-portal/marketing-portal-settings/campaign-notification-settings/campaign-notification-settings.component";
import { MarketingPortalCampaignNotificationsComponent } from "./components/partials/marketing-portal/marketing-portal-campaign-notifications/marketing-portal-campaign-notifications.component";
import { NotificationPriorityStatusComponent } from "./components/partials/marketing-portal/notification-priority-status/notification-priority-status.component";
import { PasswordChangeDialogComponent } from "./components/dialogs/password-change-dialog/password-change-dialog.component";
import { ChangePasswordFormComponent } from "./components/forms/settings/change-password-form/change-password-form.component";
import { AccountSettingsComponent } from "./components/partials/marketing-portal/marketing-portal-settings/account-settings/account-settings.component";
import { FacebookAccountSettingsComponent } from "./components/partials/marketing-portal/marketing-portal-settings/account-settings/facebook-account-settings/facebook-account-settings.component";
import { McChannelsStatisticsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-statistics/mc-channels-statistics/mc-channels-statistics.component";
import { McImpressionsStatisticsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-statistics/mc-impressions-statistics/mc-impressions-statistics.component";
import { McGenderStatisticsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-statistics/mc-gender-statistics/mc-gender-statistics.component";
import { McAgeStatisticsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-statistics/mc-age-statistics/mc-age-statistics.component";
import { McHeadMapStatisticsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-statistics/mc-head-map-statistics/mc-head-map-statistics.component";
import { FacebookPageAutocompleteSelectorComponent } from "./components/ui/facebook-page-autocomplete-selector/facebook-page-autocomplete-selector.component";

import { EditCustomerDesignComponent } from "./components/forms/customer/edit-customer-design/edit-customer-design.component";
import { MediaAssetsLibaryCustomerLogoComponent } from "./components/partials/marketing-portal/media-assets-libary-customer-logo/media-assets-libary-customer-logo.component";
import { MediaAssetsLibarySuggestionsComponent } from "./components/partials/marketing-portal/media-assets-libary-suggestions/media-assets-libary-suggestions.component";
import { TextSuggestionsDialogComponent } from "./components/dialogs/text-suggestions-dialog/text-suggestions-dialog.component";
import { MediaAssetTextSuggestionCardComponent } from "./components/cards/media-asset-text-suggestion-card/media-asset-text-suggestion-card.component";
import { SelectResolutionComponent } from "./components/select/select-resolution/select-resolution.component";
import { ResolutionShapeComponent } from "./components/partials/resolution-shape/resolution-shape.component";
import { LotteAnimationIconComponent } from "./components/partials/lotte-animation-icon/lotte-animation-icon.component";
import { CustomerAdTextComponent } from "./components/marketing-portal-editor/partials/customer-ad-text/customer-ad-text.component";
import { ImageTooltipComponent } from "./components/partials/image-tooltip/image-tooltip.component";
import { ProgressBarComponent } from "./components/partials/progress-bar/progress-bar.component";
import { SelectResolutionIconCardComponent } from "./components/cards/select-resolution-icon-card/select-resolution-icon-card.component";
import { QuickMediaAssetCreationComponent } from "./components/partials/quick-media-asset-creation/quick-media-asset-creation.component";
import { GenerateCustomerMediaAssetQuickToolComponent } from "./components/customer/generate-customer-media-asset-quick-tool/generate-customer-media-asset-quick-tool.component";
import { SelectMediaAssetFileTypeComponent } from "./components/customer/generate-customer-media-asset-quick-tool/components/select-media-asset-file-type/select-media-asset-file-type.component";
import { SelectMediaAssetFormatComponent } from "./components/customer/generate-customer-media-asset-quick-tool/components/select-media-asset-format/select-media-asset-format.component";
import { SelectMediaAssetTemplateComponent } from "./components/customer/generate-customer-media-asset-quick-tool/components/select-media-asset-template/select-media-asset-template.component";
import { CustomerMediaAssetGridComponent } from "./components/grids/customer-media-asset-grid/customer-media-asset-grid.component";
import { CustomerMediaAssetCardComponent } from "./components/cards/customer-media-asset-card/customer-media-asset-card.component";
import { CustomerCrawlerAnimationDialogComponent } from "./components/dialogs/customer-crawler-animation-dialog/customer-crawler-animation-dialog.component";
import { CustomerKeywordCardComponent } from "./components/cards/customer-keyword-card/customer-keyword-card.component";
import { AddFormTextDialogComponent } from "./components/dialogs/add-form-text-dialog/add-form-text-dialog.component";
import { AddTextFormComponent } from "./components/forms/text/add-text-form/add-text-form.component";
import { AiProcessTextAnimationDialogComponent } from "./components/dialogs/ai-process-text-animation-dialog/ai-process-text-animation-dialog.component";
import { QuickMediaAssetCreationFinalComponent } from "./components/partials/quick-media-asset-creation/components/quick-media-asset-creation-final/quick-media-asset-creation-final.component";
import { QuickMediaAssetCreationLoadingAnimantionComponent } from "./components/partials/quick-media-asset-creation/components/quick-media-asset-creation-loading-animantion/quick-media-asset-creation-loading-animantion.component";
import { QuickMediaAssetCreationTypeSelectionComponent } from "./components/partials/quick-media-asset-creation/components/quick-media-asset-creation-type-selection/quick-media-asset-creation-type-selection.component";
import { ImportMarketingPortalEditorVideoFileDialogComponent } from "./components/dialogs/import-marketing-portal-editor-video-file-dialog/import-marketing-portal-editor-video-file-dialog.component";
import { PexelVideoSearchDialogComponent } from "./components/dialogs/pexel-video-search-dialog/pexel-video-search-dialog.component";
import { PexelVideoCardComponent } from "./components/cards/pexel-video-card/pexel-video-card.component";
import { VideoMediaLibaryDialogComponent } from "./components/dialogs/video-media-libary-dialog/video-media-libary-dialog.component";
import { VideoAssetLibaryCardComponent } from "./components/cards/video-asset-libary-card/video-asset-libary-card.component";
import { PaymentActionBoxComponent } from "./components/payment/payment-action-box/payment-action-box.component";
import { PaymentMethodsSettingsComponent } from "./components/partials/marketing-portal/marketing-portal-settings/payment-methods-settings/payment-methods-settings.component";
import { PaymentMethodCardComponent } from "./components/cards/payment-method-card/payment-method-card.component";
import { MarketingPortalDetailsMainComponent } from "./components/partials/marketing-portal/marketing-portal-campaign-details/components/marketing-portal-details-main/marketing-portal-details-main.component";
import { MarketingPortalDetailsTimePeriodComponent } from "./components/partials/marketing-portal/marketing-portal-campaign-details/components/marketing-portal-details-time-period/marketing-portal-details-time-period.component";
import { TimePeriodCalendarComponent } from "./components/partials/marketing-portal/time-period-calendar-view/components/time-period-calendar/time-period-calendar.component";
import { TimePeriodCalendarViewComponent } from "./components/partials/marketing-portal/time-period-calendar-view/time-period-calendar-view.component";
import { MarketingPortalLocationDisplayComponent } from "./components/partials/marketing-portal/marketing-portal-location-display/marketing-portal-location-display.component";
import { MediaAssetViewCardComponent } from "./components/cards/media-asset-view-card/media-asset-view-card.component";
import { PresalesCreativeCardComponent } from "./components/cards/presales-creative-card/presales-creative-card.component";
import { PresalesPresentationDialogComponent } from "./components/dialogs/presales-presentation-dialog/presales-presentation-dialog.component";
import { PresalesPresentationSlideComponent } from "./components/presales/presales-presentation-slide/presales-presentation-slide.component";
import { PresalesPresentationStartComponent } from "./components/presales/presales-presentation-start/presales-presentation-start.component";
import { PresalesPresentationSelectCustomerComponent } from "./components/presales/presales-presentation-select-customer/presales-presentation-select-customer.component";
import { PresalesPresentationSliderComponent } from "./components/presales/presales-presentation-slider/presales-presentation-slider.component";
import { CampaignCreativeFacebookPreviewComponent } from "./components/previews/campaign-creative-preview/components/campaign-creative-facebook-preview/campaign-creative-facebook-preview.component";
import { CampaignCreativeGdnAdsPreviewComponent } from "./components/previews/campaign-creative-preview/components/campaign-creative-gdn-ads-preview/campaign-creative-gdn-ads-preview.component";
import { CampaignCreativeYoutubePreviewComponent } from "./components/previews/campaign-creative-preview/components/campaign-creative-youtube-preview/campaign-creative-youtube-preview.component";
import { PresalesPresentationProgressLineComponent } from "./components/presales/presales-presentation-progress-line/presales-presentation-progress-line.component";
import { PresalesItemExpandedDialogComponent } from "./components/dialogs/presales-item-expanded-dialog/presales-item-expanded-dialog.component";
import { CampaignCreativeTvPreviewComponent } from "./components/previews/campaign-creative-preview/components/campaign-creative-tv-preview/campaign-creative-tv-preview.component";
import { PresalesPresentationFeedbackComponent } from "./components/presales/presales-presentation-feedback/presales-presentation-feedback.component";
import { MediaAssetCardComponent } from "./components/cards/media-asset-card/media-asset-card.component";
import { PresalesItemSelectChannelDialogComponent } from "./components/dialogs/presales-item-select-channel-dialog/presales-item-select-channel-dialog.component";
import { AssistantCompanyInformationFormComponent } from "./components/forms/assistant/assistant-company-information-form/assistant-company-information-form.component";
import { AssistantProfileCheckFormComponent } from "./components/forms/assistant/assistant-profile-check-form/assistant-profile-check-form.component";
import { ColorBoxPickerComponent } from "./components/partials/color-box-picker/color-box-picker.component";
import { AssistantLocationCardComponent } from "./components/cards/assistant-location-card/assistant-location-card.component";
import { AddCustomerRegionDialogComponent } from "./components/dialogs/add-customer-region-dialog/add-customer-region-dialog.component";
import { AssistantCreativeCardComponent } from "./components/cards/assistant-creative-card/assistant-creative-card.component";
import { AssistantExpandedCreativeCardComponent } from "./components/cards/assistant-expanded-creative-card/assistant-expanded-creative-card.component";
import { CreatviePreviewDialogComponent } from "./components/dialogs/creatvie-preview-dialog/creatvie-preview-dialog.component";
import { CampaignCreativePreviewComponent } from "./components/previews/campaign-creative-preview/campaign-creative-preview.component";
import { CampaignChannelCardComponent } from "./components/cards/campaign-channel-card/campaign-channel-card.component";
import { CreativeTemplateCardComponent } from "./components/cards/creative-template-card/creative-template-card.component";
import { CreativeImageTemplateCardComponent } from "./components/cards/creative-image-template-card/creative-image-template-card.component";
import { CreativeVideoTemplateCardComponent } from "./components/cards/creative-video-template-card/creative-video-template-card.component";
import { ImageEditorTemplateDetailsDialogComponent } from "./components/dialogs/image-editor-template-details-dialog/image-editor-template-details-dialog.component";
import { AutoMediaAssetAnimationDialogComponent } from "./components/dialogs/auto-media-asset-animation-dialog/auto-media-asset-animation-dialog.component";
import { ExtendedConfiguratorHeaderComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/partials/extended-configurator-header/extended-configurator-header.component";
import { ExtendedConfiguratorNavigationComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/partials/extended-configurator-navigation/extended-configurator-navigation.component";
import { ExtendedConfiguratorAssetTypeComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-asset-type/extended-configurator-asset-type.component";
import { ExtendedConfiguratorChannelComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-channel/extended-configurator-channel.component";
import { ExtendedConfiguratorConfigurationComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-configuration/extended-configurator-configuration.component";
import { ExtendedConfiguratorCreativeComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-creative/extended-configurator-creative.component";
import { ExtendedConfiguratorCustomerComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-customer/extended-configurator-customer.component";
import { ExtendedConfiguratorGoalsComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-goals/extended-configurator-goals.component";
import { ExtendedConfiguratorMainActionComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-main-action/extended-configurator-main-action.component";
import { ExtendedConfiguratorTemplatesComponent } from "app/views/mapAds/configurator/marketing-portal-extended-configurator/steps/extended-configurator-templates/extended-configurator-templates.component";
import { MarketingPortalChannelFacebookInstagramAdsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-configurator/channels/marketing-portal-channel-facebook-instagram-ads/marketing-portal-channel-facebook-instagram-ads.component";
import { MarketingPortalChannelFacebookInstagramPostsComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-configurator/channels/marketing-portal-channel-facebook-instagram-posts/marketing-portal-channel-facebook-instagram-posts.component";
import { FormInfoMessageComponent } from "./components/partials/form-info-message/form-info-message.component";
import { MarketingPortalChannelGdnComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-configurator/channels/marketing-portal-channel-gdn/marketing-portal-channel-gdn.component";
import { MarketingPortalChannelYoutubeComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-configurator/channels/marketing-portal-channel-youtube/marketing-portal-channel-youtube.component";
import { MarketingPortalChannelTvComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-configurator/channels/marketing-portal-channel-tv/marketing-portal-channel-tv.component";
import { AssistantHeaderComponent } from "./components/partials/assistant-header/assistant-header.component";
import { CreativeAssetCardComponent } from "./components/cards/creative-asset-card/creative-asset-card.component";
import { CreateMopoFormComponent } from "./components/forms/campaign/create-mopo-form/create-mopo-form.component";
import { MarketingPortalChannelMopoComponent } from "app/views/mapAds/marketing-portal/marketing-portal-campaign-configurator/channels/marketing-portal-channel-mopo/marketing-portal-channel-mopo.component";
import { DatePeriodCampaignFormSectionComponent } from "./components/forms/campaign/partials/date-period-campaign-form-section/date-period-campaign-form-section.component";
import { GeneralCampaignFormSectionComponent } from "./components/forms/campaign/partials/general-campaign-form-section/general-campaign-form-section.component";
import { GamAdUnitCampaignFormSectionComponent } from "./components/forms/campaign/partials/gam/gam-ad-unit-campaign-form-section/gam-ad-unit-campaign-form-section.component";
import { GamAdUnitCardComponent } from "./components/cards/gam-ad-unit-card/gam-ad-unit-card.component";
import { BudgetCampaignFormSectionComponent } from "./components/forms/campaign/partials/budget-campaign-form-section/budget-campaign-form-section.component";
import { GamGeoTargetingCampaignFormSectionComponent } from "./components/forms/campaign/partials/gam/gam-geo-targeting-campaign-form-section/gam-geo-targeting-campaign-form-section.component";
import { GamGeoTargetingCardComponent } from "./components/cards/gam-geo-targeting-card/gam-geo-targeting-card.component";
import { GamMediaAssetsCampaignFormSectionComponent } from "./components/forms/campaign/partials/gam/gam-media-assets-campaign-form-section/gam-media-assets-campaign-form-section.component";
import { CampaignFormContainerComponent } from "./components/forms/campaign/partials/campaign-form-container/campaign-form-container.component";
import { MediaFormatCampaignFormSectionComponent } from "./components/forms/campaign/partials/media-format-campaign-form-section/media-format-campaign-form-section.component";
import { CampaignMediaFormatCardComponent } from "./components/cards/campaign-media-format-card/campaign-media-format-card.component";
import { AddGamMediaAssetDialogComponent } from "./components/dialogs/add-gam-media-asset-dialog/add-gam-media-asset-dialog.component";
import { GamCampaignTextFormComponent } from "./components/forms/text/gam-campaign-text-form/gam-campaign-text-form.component";
import { GamMediaAssetActionCardComponent } from "./components/cards/gam-media-asset-action-card/gam-media-asset-action-card.component";
import { DevicesCampaignFormSectionComponent } from "./components/forms/campaign/partials/devices-campaign-form-section/devices-campaign-form-section.component";
import { CampaignGamPreviewComponent } from "./components/previews/campaign-gam-preview/campaign-gam-preview.component";
import { ApprovalCampaignFormSectionComponent } from "./components/forms/campaign/partials/approval-campaign-form-section/approval-campaign-form-section.component";
import { AddGamMediaAssetTextsDialogComponent } from "./components/dialogs/add-gam-media-asset-texts-dialog/add-gam-media-asset-texts-dialog.component";
import { TopicDemographyCampaignFormSectionComponent } from "./components/forms/campaign/partials/topic-demography-campaign-form-section/topic-demography-campaign-form-section.component";
import { GenderCampaignFormSectionComponent } from "./components/forms/campaign/partials/gender-campaign-form-section/gender-campaign-form-section.component";
import { TopicCampaignFormSectionComponent } from "./components/forms/campaign/partials/topic-campaign-form-section/topic-campaign-form-section.component";
import { AgeGroupCampaignFormSectionComponent } from "./components/forms/campaign/partials/age-group-campaign-form-section/age-group-campaign-form-section.component";
import { TwoLabelToggleSwitchComponent } from "./components/ui/select/two-label-toggle-switch/two-label-toggle-switch.component";
import { ZipcodeLocationCampaignFormComponent } from "./components/forms/campaign/partials/zipcode-location-campaign-form/zipcode-location-campaign-form.component";
import { CampaignZipcodeLocationItemComponent } from "./components/partials/campaign-zipcode-location-item/campaign-zipcode-location-item.component";
import { MediaAssetsFormSectionComponent } from "./components/forms/campaign/partials/media-assets-form-section/media-assets-form-section.component";
import { CustomerDetailsProfileCheckComponent } from "./components/customer/customer-details-profile-check/customer-details-profile-check.component";
import { CustomerKeywordsComponent } from "./components/customer/customer-keywords/customer-keywords.component";

/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  MediaAssetPreviewImagePipe,
  LinebreaksPipe,
  CurrencyPipe,
  GetStoreNameByIdPipe,
  GetTemplateNameByIdPipe,
  GetTemplateScreenshotByIdPipe,
  KeysPipe,
  LocalizedDatePipe,
  ExcerptPipe,
  DateFormatPipe,
  GetValueByKeyPipe,
  LocaleDatePipe,
  FilterSearchComponent,
  FacebookPageConnectPopupComponent,
  ErrorMessageHelperComponent,
  DragDropUploadComponent,
  DragDropDirective,
  SupportPopoverButtonComponent,
  SupportPopoverComponent,
  CreatePromotionDialogComponent,
  StoreSelectionTreeComponent,
  CreatePromotionFormComponent,
  SearchGtinPromotionFormComponent,
  PopoverComponent,
  PopoverPasswordCheckComponent,
  PromotionPreviewDialogComponent,
  PopoverTcontractInfoComponent,
  GooglePreviewComponent,
  InstagramPreviewComponent,
  FacebookPreviewComponent,
  YoutubePreviewComponent,
  GoogleLocalInventoryPreviewComponent,
  PromotionSuccessfullyCreatedDialogComponent,
  BasicChannelCardComponent,
  BasicChannelsGridComponent,
  ExternalSystemsGridComponent,
  ExternalSystemCardComponent,
  UnblockPopupSafariInstructionComponent,
  PopupBlockedDialogComponent,
  AllowBrowserPopupInstructionsComponent,
  FacebookAdsPreviewComponent,
  PanelComponent,
  PanelHeaderComponent,
  PanelContentComponent,
  ProgressCirclesComponent,
  SelectCirclesComponent,
  AnimationCheckmarkCircleComponent,
  SelectArrowComponent,
  StatusSelectCirclesComponent,
  GoogleStorecodesInstructionsComponent,
  SubchannelCardComponent,
  SubchannelGridComponent,
  AdditionalChannelPackagesInfoDialogComponent,
  ChannelInfoDialogComponent,
  ExternalSystemSuccessfullyConnectedDialogComponent,
  CropImageDialogComponent,
  UploadImageDialogComponent,
  UnblockPopupFirefoxInstructionComponent,
  ActiveExternalSystemsGridComponent,
  ActiveExternalSystemCardComponent,
  ContentBoxComponent,
  ContentBoxItemComponent,
  ContentBoxTitleComponent,
  ContentBoxItemLabelComponent,
  ContentBoxItemValueComponent,
  ContentBoxLabelValueComponent,
  InlineIconMessageComponent,
  CustomActionIconMessageComponent,
  SuccessAnimationActionMessageComponent,
  InlineActionIconMessageComponent,
  AddExternalSystemFormComponent,
  ErrorActionMessageComponent,
  InfoActionMessageComponent,
  SelectMenuComponent,
  SelectMenuItemComponent,
  GridComponent,
  SelectMultipleMenuComponent,
  TrendArrowComponent,
  RankingChartComponent,
  ChannelHeatMapComponent,
  HeatMapSidebarFilterComponent,
  StoreLocationSelectListComponent,
  StoreDetailCardComponent,
  ZipcodeImpressionsSelectListComponent,
  HeatMapSettingsComponent,
  ProductActivitiesTimelineComponent,
  NoDataMessageComponent,
  PageHeaderComponent,
  VerticalDividerComponent,
  HorizontalDividerComponent,
  IconMessageComponent,
  ErrorIconMessageComponent,
  InfoIconMessageComponent,
  SuccessIconMessageComponent,
  CreateStoreFormComponent,
  AddActionBoxComponent,
  ViewActionBoxComponent,
  SelectItemActionBoxComponent,
  SelectActionBoxComponent,
  StepSliderComponent,
  RadiusMapComponent,
  SuccessActionIconMessageComponent,
  PillComponent,
  StoresMapComponent,
  CreateGroupFormComponent,
  StoreTreeComponent,
  AddNewActionMessageComponent,
  TrendValueComponent,
  ActivitiesTimelineComponent,
  ImpressionsDifferenceComponent,
  ImageCropperComponent,
  ImageBackgroundRemoverComponent,
  SelectImageFromSelectionComponent,
  ColorBoxComponent,
  CorporateColorSelectorDialogComponent,
  VideoTemplateSelectorDialogComponent,
  VideoTemplateGridComponent,
  VideoTemplateCardComponent,
  SelectedVideoTemplateComponent,
  ReviewYourVideoTemplateDialogComponent,
  NoImagesUploadedConfirmationDialogComponent,
  MaRoundedBasicLogoDarkComponent,
  MaRoundedBasicLogoLightComponent,
  MaMultipleCardsComponent,
  MaCardCollapseContentComponent,
  MaCardComponent,
  MaCardContentComponent,
  MaCardHeaderComponent,
  MaCardHeaderTitleComponent,
  MaIconPillListComponent,
  MaShapeCircleComponent,
  MaShapeCircleOutlineComponent,
  MaShapeRectangleComponent,
  MaShapeRectangleOutlineComponent,
  MaShapeTriangleComponent,
  MaSnackbarComponent,
  GoogleServiceInfoGuideComponent,
  MaMessageComponent,
  MaAccordionComponent,
  SuccessTrophyMessageComponent,
  FacebookServiceInfoGuideComponent,
  InstagramServiceInfoGuideComponent,
  YoutubeServiceInfoGuideComponent,
  MaBackgroundShapesDarkComponent,
  MaBackgroundShapesLightComponent,
  ChannelPreferencesPanelGridComponent,
  ChannelPreferencesPanelCardComponent,
  WeeklyChannelHistoryCardComponent,
  FutureChannelHistoryCardComponent,
  GoogleAccountInformationComponent,
  FacebookAccountInformationComponent,
  YoutubeAccountInformationComponent,
  InstagramAccountInformationComponent,
  ContentBoardComponent,
  TipsComponent,
  CampaignChannelActionCardComponent,
  CampaignRegionsComponent,
  CampaignRegionItemComponent,
  AddRegionDialogComponent,
  CampaignFacebookPreviewComponent,
  SmartphoneComponent,
  SmartphoneIphoneXComponent,
  MarketingPortalCampaignGridComponent,
  MarketingPortalCampaignCardComponent,
  MarketingPortalEditorDialogComponent,
  MarketingPortalVideoEditorDialogComponent,
  MarketingPortalTemplatesGridComponent,
  MarketingPortalTemplatesCardComponent,
  MarketingPortalEditorComponent,
  MarketingPortalEditorHeaderComponent,
  MarketingPortalEditorTemplateConfiguratorComponent,
  MarketingPortalEditorTemplatesComponent,
  MarketingPortalEditorTemplatesSidebarComponent,
  MarketingPortalEditorTemplatesGroupComponent,
  MarketingPortalEditorSidebarComponent,
  MarketingPortalEditorContentComponent,
  MarketingPortalEditorConfiguratorToolsetComponent,
  MarketingPortalEditorConfiguratorToolsetItemComponent,
  MarketingPortalEditorModificationIconComponent,
  MarketingPortalEditorGlobalToolsetComponent,
  MarketingPortalStatusComponent,
  FacebookCampaignAdPreviewComponent,
  ImportMarketingPortalEditorFileDialogComponent,
  CustomerCrawlerAnimationDialogComponent,
  UnsplashImageSearchDialogComponent,
  MediaAssetSearchDialogComponent,
  CreateCustomerFormComponent,
  EditCustomerDesignComponent,
  CreateCustomerLocationFormComponent,
  CampaignCustomerActionCardComponent,
  CreateSMCampaignFormComponent,
  CreateInstagramFacebookPostFormComponent,
  CreateInstagramFacebookBoostPostFormComponent,
  CampaignChannelActionCardSmallComponent,
  CreateGdnFormComponent,
  CampaignGdnAdsPreviewComponent,
  RequestCustomerApprovalDialogComponent,
  SendCustomerApprovalRequestFormComponent,
  ApprovalConfirmationFormComponent,
  ApprovalConfirmationTextInputComponent,
  ApprovalConfirmationMediaInputComponent,
  ApprovalConfirmationActionsComponent,
  ApprovalElementCommentDialogComponent,
  CreateApprovalElementCommentFormComponent,
  ApprovalStatusComponent,
  ApprovalHistoryCollapseListComponent,
  ApprovalHistoryCollapseListItemComponent,
  MediaAssetsUploaderComponent,
  UploadedMediaAssetFileCardComponent,
  CreateTvFormComponent,
  MediaAssetsUploaderFileGridComponent,
  MediaAssetLibaryCardComponent,
  MediaAssetsLibaryAdMaterialsComponent,
  MediaAssetsLibaryCustomerLogoComponent,
  MediaAssetsLibarySuggestionsComponent,
  MediaAssetsLibaryUserLogoComponent,
  MediaAssetsLibaryUploadedAssetsComponent,
  MpPaginationComponent,
  CreateYoutubeFormComponent,
  CampaignYoutubePreviewComponent,
  CampaignPreviewComponent,
  MarketingPortalVideoEditorComponent,
  VideoEditorTemplateDetailsDialogComponent,
  MarketingPortalVideoEditorTemplateConfiguratorComponent,
  MarketingPortalVideoEditorContentComponent,
  MarketingPortalVideoEditorSlideListComponent,
  MarketingPortalVideoEditorSlideSidebarComponent,
  MarketingPortalVideoEditorGlobalConfigurationToolsetComponent,
  MarketingPortalVideoEditorSlideConfigurationToolsetComponent,
  VideoEditorTemplateSlideCardComponent,
  MarketingPortalVideoEditorMainComponent,
  MarketingPortalVideoEditorTimelineComponent,
  MarketingPortalVideoEditorSoundConfigurationToolsetComponent,
  MarketingPortalEditorFontConfigurationToolsetComponent,
  MarketingPortalVideoEditorTemplateConfiguratorDesktopComponent,
  MarketingPortalVideoEditorTemplateConfiguratorMobileComponent,
  NotificationSettingsFormComponent,
  CampaignNotificationSettingsComponent,
  MarketingPortalCampaignNotificationsComponent,
  NotificationPriorityStatusComponent,
  PasswordChangeDialogComponent,
  ChangePasswordFormComponent,
  AccountSettingsComponent,
  FacebookAccountSettingsComponent,
  McChannelsStatisticsComponent,
  McImpressionsStatisticsComponent,
  McGenderStatisticsComponent,
  McAgeStatisticsComponent,
  McHeadMapStatisticsComponent,
  TextSuggestionsDialogComponent,
  MediaAssetTextSuggestionCardComponent,
  FacebookPageAutocompleteSelectorComponent,
  SelectResolutionComponent,
  ResolutionShapeComponent,
  LotteAnimationIconComponent,
  CustomerAdTextComponent,
  ImageTooltipComponent,
  ImageTooltipDirective,
  LoadingButtonDirective,
  ProgressBarComponent,
  SelectResolutionIconCardComponent,
  QuickMediaAssetCreationComponent,
  GenerateCustomerMediaAssetQuickToolComponent,
  SelectMediaAssetFileTypeComponent,
  SelectMediaAssetFormatComponent,
  SelectMediaAssetTemplateComponent,
  CustomerMediaAssetGridComponent,
  CustomerMediaAssetCardComponent,
  CustomerKeywordCardComponent,
  AddFormTextDialogComponent,
  AddTextFormComponent,
  AiProcessTextAnimationDialogComponent,
  QuickMediaAssetCreationFinalComponent,
  QuickMediaAssetCreationLoadingAnimantionComponent,
  QuickMediaAssetCreationTypeSelectionComponent,
  ImportMarketingPortalEditorVideoFileDialogComponent,
  PexelVideoSearchDialogComponent,
  PexelVideoCardComponent,
  VideoMediaLibaryDialogComponent,
  VideoAssetLibaryCardComponent,
  PaymentActionBoxComponent,
  PaymentMethodsSettingsComponent,
  PaymentMethodCardComponent,
  MarketingPortalDetailsMainComponent,
  MarketingPortalDetailsTimePeriodComponent,
  TimePeriodCalendarComponent,
  TimePeriodCalendarViewComponent,
  MarketingPortalLocationDisplayComponent,
  MediaAssetViewCardComponent,
  PresalesCreativeCardComponent,
  PresalesPresentationDialogComponent,
  PresalesPresentationSlideComponent,
  PresalesPresentationStartComponent,
  PresalesPresentationSelectCustomerComponent,
  PresalesPresentationSliderComponent,
  CampaignCreativeFacebookPreviewComponent,
  CampaignCreativeGdnAdsPreviewComponent,
  CampaignCreativeYoutubePreviewComponent,
  PresalesPresentationProgressLineComponent,
  PresalesItemExpandedDialogComponent,
  CampaignCreativeTvPreviewComponent,
  PresalesPresentationFeedbackComponent,
  MediaAssetCardComponent,
  PresalesItemSelectChannelDialogComponent,
  AssistantCompanyInformationFormComponent,
  AssistantProfileCheckFormComponent,
  ColorBoxPickerComponent,
  AssistantLocationCardComponent,
  AddCustomerRegionDialogComponent,
  AssistantCreativeCardComponent,
  AssistantExpandedCreativeCardComponent,
  CreatviePreviewDialogComponent,
  CampaignCreativePreviewComponent,
  CampaignChannelCardComponent,
  CreativeTemplateCardComponent,
  CreativeImageTemplateCardComponent,
  CreativeVideoTemplateCardComponent,
  ImageEditorTemplateDetailsDialogComponent,
  AutoMediaAssetAnimationDialogComponent,
  ExtendedConfiguratorHeaderComponent,
  ExtendedConfiguratorNavigationComponent,
  ExtendedConfiguratorAssetTypeComponent,
  ExtendedConfiguratorChannelComponent,
  ExtendedConfiguratorConfigurationComponent,
  ExtendedConfiguratorCreativeComponent,
  ExtendedConfiguratorCustomerComponent,
  ExtendedConfiguratorGoalsComponent,
  ExtendedConfiguratorMainActionComponent,
  ExtendedConfiguratorTemplatesComponent,
  MarketingPortalChannelFacebookInstagramAdsComponent,
  MarketingPortalChannelFacebookInstagramPostsComponent,
  FormInfoMessageComponent,
  MarketingPortalChannelGdnComponent,
  MarketingPortalChannelYoutubeComponent,
  MarketingPortalChannelTvComponent,
  AssistantHeaderComponent,
  CreativeAssetCardComponent,
  CreateMopoFormComponent,
  MarketingPortalChannelMopoComponent,
  DatePeriodCampaignFormSectionComponent,
  GeneralCampaignFormSectionComponent,
  GamAdUnitCampaignFormSectionComponent,
  GamAdUnitCardComponent,
  BudgetCampaignFormSectionComponent,
  GamGeoTargetingCampaignFormSectionComponent,
  GamGeoTargetingCardComponent,
  GamMediaAssetsCampaignFormSectionComponent,
  CampaignFormContainerComponent,
  MediaFormatCampaignFormSectionComponent,
  CampaignMediaFormatCardComponent,
  AddGamMediaAssetDialogComponent,
  GamCampaignTextFormComponent,
  GamMediaAssetActionCardComponent,
  DevicesCampaignFormSectionComponent,
  CampaignGamPreviewComponent,
  ApprovalCampaignFormSectionComponent,
  AddGamMediaAssetTextsDialogComponent,
  TopicDemographyCampaignFormSectionComponent,
  GenderCampaignFormSectionComponent,
  TopicCampaignFormSectionComponent,
  AgeGroupCampaignFormSectionComponent,
  TwoLabelToggleSwitchComponent,
  ZipcodeLocationCampaignFormComponent,
  CampaignZipcodeLocationItemComponent,
  MediaAssetsFormSectionComponent,
  CustomerDetailsProfileCheckComponent,
  CustomerKeywordsComponent,
];

@NgModule({
  imports: [
    MatTreeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    SearchModule,
    PerfectScrollbarModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    DragDropModule,
    ChartsModule,
    MatSliderModule,
    ImageCropperModule,
    NgxSkeletonLoaderModule,
    LottieAnimationViewModule.forRoot(),
  ],
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    FacebookPageConnectPopupComponent,
    SupportPopoverComponent,
    CreatePromotionDialogComponent,
    PromotionPreviewDialogComponent,
    PromotionSuccessfullyCreatedDialogComponent,
    PopupBlockedDialogComponent,
    FacebookAdsPreviewComponent,
    AdditionalChannelPackagesInfoDialogComponent,
    ChannelInfoDialogComponent,
    ExternalSystemSuccessfullyConnectedDialogComponent,
    CropImageDialogComponent,
    UploadImageDialogComponent,
    CorporateColorSelectorDialogComponent,
    VideoTemplateSelectorDialogComponent,
    ReviewYourVideoTemplateDialogComponent,
    NoImagesUploadedConfirmationDialogComponent,
    AddRegionDialogComponent,
    MarketingPortalEditorDialogComponent,
    MarketingPortalVideoEditorDialogComponent,
    FacebookCampaignAdPreviewComponent,
    ImportMarketingPortalEditorFileDialogComponent,
    UnsplashImageSearchDialogComponent,
    MediaAssetSearchDialogComponent,
    RequestCustomerApprovalDialogComponent,
    ApprovalElementCommentDialogComponent,
    VideoEditorTemplateDetailsDialogComponent,
    PasswordChangeDialogComponent,
    TextSuggestionsDialogComponent,
    ImageTooltipComponent,
    CustomerCrawlerAnimationDialogComponent,
    AddFormTextDialogComponent,
    AiProcessTextAnimationDialogComponent,
    ImportMarketingPortalEditorVideoFileDialogComponent,
    PexelVideoSearchDialogComponent,
    VideoMediaLibaryDialogComponent,
    PresalesPresentationDialogComponent,
    PresalesItemExpandedDialogComponent,
    PresalesItemSelectChannelDialogComponent,
    CreatviePreviewDialogComponent,
    AddCustomerRegionDialogComponent,
    ImageEditorTemplateDetailsDialogComponent,
    AutoMediaAssetAnimationDialogComponent,
    AddGamMediaAssetDialogComponent,
    AddGamMediaAssetTextsDialogComponent,
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppLoaderService,
    ChatService,

    // LandingPageService
  ],
  declarations: classesToInclude,
  exports: classesToInclude,
})
export class SharedModule {}
