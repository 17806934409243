import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { SupportPopoverComponent } from "app/shared/components/dialogs/support-popover/support-popover.component";

@Component({
  selector: "marketing-portal-editor-templates-sidebar",
  templateUrl: "./marketing-portal-editor-templates-sidebar.component.html",
  styleUrls: ["./marketing-portal-editor-templates-sidebar.component.scss"],
})
export class MarketingPortalEditorTemplatesSidebarComponent implements OnInit {
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  @Input() groupedTemplates;
  @Input() isOpenMenu: boolean = false;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  onOpenSupportPopover() {
    this.dialog.open(SupportPopoverComponent, {
      width: "1000px",
    });
  }
}
