import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-presales-item-select-channel-dialog",
  templateUrl: "./presales-item-select-channel-dialog.component.html",
  styleUrls: ["./presales-item-select-channel-dialog.component.scss"],
})
export class PresalesItemSelectChannelDialogComponent implements OnInit {
  public selectedChannelId: number = 0;
  constructor(
    public dialogRef: MatDialogRef<PresalesItemSelectChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onSelect(channelId: number) {
    this.selectedChannelId = channelId;
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    this.dialogRef.close({ channelId: this.selectedChannelId });
  }
}
