import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ExternalSourceMedia } from "app/model/ExternalSourceMedia";
import { PexelVideo } from "app/model/stock/PexelVideo";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { base64ToFile } from "app/shared/utils/blob.utils";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-pexel-video-search-dialog",
  templateUrl: "./pexel-video-search-dialog.component.html",
  styleUrls: ["./pexel-video-search-dialog.component.scss"],
})
export class PexelVideoSearchDialogComponent implements OnInit {
  public selectedVideo: any = null;
  public page: number = 1;
  public itemsPerPage: number = 24;
  public totalPage: number = 1;
  public videoList: PexelVideo[];
  public isLoading: boolean = false;
  public searchString: string = "";
  public scrollLoading: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<PexelVideoSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private appLoader: AppLoaderService
  ) {}

  ngOnInit() {}

  onInputChange($event) {
    this.searchString = $event.target.value;
  }

  onSearch() {
    this.page = 1;
    this.scrollLoading = true;
    this.getVideos();
  }

  selectVideo(video) {
    this.selectedVideo = video;
  }

  clearSearch() {
    this.searchString = "";
  }

  onScroll($event) {
    if (!this.scrollLoading || this.isLoading) {
      return;
    }

    if (
      $event.target.scrollTop ===
      $event.target.scrollHeight - $event.target.offsetHeight
    ) {
      this.page++;
      this.getVideos(true);
    }
  }

  async getVideos(concat = false) {
    this.isLoading = true;
    const { width, height } = this.data;

    await this.crudService
      .getVideosBasedOnKeyword(
        this.searchString,
        this.page,
        this.itemsPerPage,
        width,
        height
      )
      .toPromise()
      .then((res) => {
        if (!res) {
          this.scrollLoading = false;
          return;
        }
        this.videoList = concat ? this.videoList.concat(res) : res;
      })
      .catch((e) => {
        // this.imageList = [];
      });

    this.isLoading = false;
  }

  onApply() {
    this.appLoader.open();

    this.crudService
      .getBase64FromUrl(this.selectedVideo.DownloadLink, true)
      .subscribe(
        (result) => {
          const file = base64ToFile(result);
          this.isLoading = false;
          const creditsHTML = this.buildCreditsHTML(
            this.selectedVideo.CreditText
          );

          this.dialogRef.close({
            file,
            originalSource: this.selectedVideo.DownloadLink,
            credits: creditsHTML,
            externalSource: ExternalSourceMedia.EXTERNAL_SOURCE_MEDIA_PEXELS,
          });
        },
        (error) => {
          this.snackbarService.show(error.error, "danger");
        },
        () => {
          this.appLoader.close();
        }
      );
  }

  buildCreditsHTML(credits) {
    const tagSplit = credits.split("<a");
    return `${tagSplit[0]}<a target="_blank"${tagSplit[1]}`;
  }

  onClose() {
    this.dialogRef.close();
  }
}
