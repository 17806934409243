import { Component, Input, OnInit } from "@angular/core";
import { MPGoalAndPerformance } from "app/model/marketing-portal/MPGoalAndPerformance.js";
import { ChartOptions, ChartType } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { colors } from "../../../statistics/channel-statistics-colors.js";

@Component({
  selector: "mc-channels-statistics",
  templateUrl: "./mc-channels-statistics.component.html",
  styleUrls: ["./mc-channels-statistics.component.scss"],
})
export class McChannelsStatisticsComponent implements OnInit {
  @Input() impressionStatistics;
  @Input() goal: MPGoalAndPerformance;

  public channelStatistics = {};
  public pieChartOptions: ChartOptions = {
    layout: {
      padding: 50,
    },
    legend: {
      position: "left",
      labels: {
        padding: 10,
      },
    },
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    responsive: true,
  };

  public pieChartLabels: any = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [],
    },
  ];

  constructor() {}

  ngOnInit() {
    this.genChannelStatistics();
    this.setData();
  }

  genChannelStatistics() {
    this.channelStatistics = {};

    const goalName = this.goal.goalInternalName
      ? this.goal.goalInternalName
      : "Impressions"; //: "Clicks"

    this.impressionStatistics.forEach((statistic) => {
      statistic.ChannelStatistics.forEach((channelStatistic) => {
        if (channelStatistic.Statistics) {
          if (!this.channelStatistics.hasOwnProperty(channelStatistic.Value)) {
            this.channelStatistics[channelStatistic.Value] = 0;
          }

          this.channelStatistics[channelStatistic.Value] +=
            goalName == "Impressions"
              ? channelStatistic.Statistics.Impressions
              : channelStatistic.Statistics.Clicks;
        }
      });
    });
  }

  setData() {
    this.pieChartLabels = [];
    this.pieChartData = [];
    this.pieChartColors[0].backgroundColor = [];

    let totalImpressions = this.getAllChannelImpressions();

    Object.keys(this.channelStatistics).forEach((key) => {
      this.pieChartLabels.push(key);
      this.pieChartData.push(
        Math.round((this.channelStatistics[key] / totalImpressions) * 100)
      );
      this.pieChartColors[0].backgroundColor.push(colors[key.toUpperCase()]);
    });
  }

  getAllChannelImpressions() {
    let total = 0;
    Object.keys(this.channelStatistics).forEach((key) => {
      total += this.channelStatistics[key];
    });

    return total;
  }
}
