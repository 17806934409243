import { environment } from "environments/environment";
import { ExternalSourceMedia } from "../ExternalSourceMedia";

export class MPLibaryMediaAsset {
  public ImageId: number;
  public ImageName: string;
  public ImageURL: string;
  public ImageThumbnailURL: string;
  public ImageWidth: number;
  public ImageHeight: number;
  public IsPartOfSet: boolean;
  public TemplateId: number;
  public Duration: number;
  public isCrawled: boolean;
  public isImproved: boolean;
  public resolution: string;
  public resolutionId: number;
  public channelIds: number[];
  public campaignIds: number[];
  public downloadTriggerUrl: string;
  public externalSource: ExternalSourceMedia;
  public originalSource: string;
  public credits: string;
  public creditsDisplayName: string;

  static LIBARY_MEDIA_ASSET_FILTER_ALL: number = 0;
  static LIBARY_MEDIA_ASSET_FILTER_AD_MATERIAL: number = 1;
  static LIBARY_MEDIA_ASSET_FILTER_UPLOADED_ASSETS: number = 2;

  constructor(
    ImageId: number,
    ImageName: string,
    ImageURL: string = null,
    ImageThumbnailURL: string = null,
    ImageWidth: number = 600,
    ImageHeight: number = 600,
    IsPartOfSet: boolean = false,
    TemplateId: number = null,
    Duration: number = null,
    isCrawled: boolean = null,
    isImproved: boolean = null,
    resolution: string = null,
    resolutionId: number = null,
    channelIds: number[] = null,
    campaignIds: number[] = null,
    downloadTriggerUrl: string = null,
    externalSource: ExternalSourceMedia = null,
    originalSource: string = null,
    credits: string = null,
    creditsDisplayName: string = null
  ) {
    this.ImageId = ImageId;
    this.ImageName = ImageName;
    this.ImageURL = ImageURL;
    this.ImageThumbnailURL = ImageThumbnailURL;
    this.ImageWidth = ImageWidth;
    this.ImageHeight = ImageHeight;
    this.IsPartOfSet = IsPartOfSet;
    this.TemplateId = TemplateId;
    this.Duration = Duration;
    this.isCrawled = isCrawled;
    this.isImproved = isImproved;
    this.resolution = resolution;
    this.resolutionId = resolutionId;
    this.channelIds = channelIds;
    this.campaignIds = campaignIds;
    this.downloadTriggerUrl = downloadTriggerUrl;
    this.externalSource = externalSource;
    this.originalSource = originalSource;
    this.credits = credits;
    this.creditsDisplayName = creditsDisplayName;
  }

  setImageURL(): void {
    this.ImageURL = this.genImageURL();
  }

  setImageThumbnailURL(): void {
    this.ImageThumbnailURL = this.genImageURL() + "&width=300&height=300";
  }

  genImageURL(): string {
    return (
      environment.imageServerUrl + "api/Public/GetMediaAsset?id=" + this.ImageId
    );
  }

  setStoreImageURL(): void {
    this.ImageURL = this.genStoreURL();
  }

  setStoreImageThumbnailURL(): void {
    this.ImageThumbnailURL = this.genStoreURL() + "&list";
  }

  genStoreURL(): string {
    return (
      environment.imageServerUrl +
      "api/Public/GetCenteredStoreImage?id=" +
      this.ImageId
    );
  }
}
