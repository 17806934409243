import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  IMAGE_UPLOAD_ACCEPT_TYPES,
  MAX_UPLOAD_IMAGE_SIZE,
  VIDEO_UPLOAD_ACCEPT_TYPES,
} from "../../../../../../constants";
import { forkJoin, Subscription } from "rxjs";
import { ImportMarketingPortalEditorFileDialogComponent } from "app/shared/components/dialogs/import-marketing-portal-editor-file-dialog/import-marketing-portal-editor-file-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { MatDialog } from "@angular/material";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { TranslateService } from "@ngx-translate/core";
import { UnsplashImageSearchDialogComponent } from "app/shared/components/dialogs/unsplash-image-search-dialog/unsplash-image-search-dialog.component";
import { MediaAssetSearchDialogComponent } from "app/shared/components/dialogs/media-asset-search-dialog/media-asset-search-dialog.component";
import { UploadImageDialogComponent } from "app/shared/components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";
import { MPSound } from "app/model/marketing-portal/MPSound";
import { TextSuggestionsDialogComponent } from "app/shared/components/dialogs/text-suggestions-dialog/text-suggestions-dialog.component";
import { ImportMarketingPortalEditorVideoFileDialogComponent } from "app/shared/components/dialogs/import-marketing-portal-editor-video-file-dialog/import-marketing-portal-editor-video-file-dialog.component";
import { PexelVideoSearchDialogComponent } from "app/shared/components/dialogs/pexel-video-search-dialog/pexel-video-search-dialog.component";
import { environment } from "environments/environment";
import { VideoMediaLibaryDialogComponent } from "app/shared/components/dialogs/video-media-libary-dialog/video-media-libary-dialog.component";

@Component({
  selector: "marketing-portal-video-editor-global-configuration-toolset",
  templateUrl:
    "./marketing-portal-video-editor-global-configuration-toolset.component.html",
  styleUrls: [
    "./marketing-portal-video-editor-global-configuration-toolset.component.scss",
  ],
})
export class MarketingPortalVideoEditorGlobalConfigurationToolsetComponent
  implements OnInit
{
  @ViewChild("form") form;
  @ViewChild("fileInput") fileInput: ElementRef;
  @Output() updateModification: EventEmitter<any> = new EventEmitter();
  @Output() setInputChanged: EventEmitter<any> = new EventEmitter();
  @Output() setGlobalModificationValues: EventEmitter<any> = new EventEmitter();
  @Input() templateId: number;
  @Output() markSlidesAsEdited?: EventEmitter<any> = new EventEmitter();
  @Output() generateImage?: EventEmitter<any> = new EventEmitter();
  @Input() customerId: number;
  @Input() customerLogo: string;
  @Input() channelId?: number;
  @Input() inputChanged: boolean;
  @Input() templateConfigModel: any;
  @Input() fontFamilies: MPFontFamily[];
  @Input() fontWeights: MPFontWeight[];
  @Input() sounds: MPSound[];

  public showGlobalColor: boolean;
  public itemForm: FormGroup;
  public errors: object = {};
  public uploadedImageIds: object = {};
  public uploadedVideoIds: object = {};
  public acceptImageTypes: string = IMAGE_UPLOAD_ACCEPT_TYPES;
  public acceptVideoTypes: string = VIDEO_UPLOAD_ACCEPT_TYPES;
  public onFocusImageElement: string = "";
  public onFocusImageWidth: number = 0;
  public onFocusImageHeight: number = 0;
  public onFocusVideoElement: string = "";
  public globalColor: any;
  public formSubscriptions: Subscription[] = [];
  readonly MAX_UPLOAD_IMAGE_SIZE = MAX_UPLOAD_IMAGE_SIZE;
  public readonly environment = environment;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.init();
  }

  update(templateConfigModel) {
    this.templateConfigModel = templateConfigModel;

    Object.keys(this.itemForm.controls).forEach((key) => {
      const value = this.getFormValueByKey(key);
      this.itemForm.controls[key].setValue(value);
    });

    this.setUploadedImages();
    this.setUploadedVideos();
    this.updateTemplateCongfig();
    this.generateImage.emit();
  }

  getFormValueByKey(key) {
    let value = null;
    this.templateConfigModel.modifications.forEach((globalModification) => {
      if (
        key ===
        globalModification.name + "_" + globalModification.elements[0].type
      ) {
        value = globalModification.elements[0].value;
      }
    });

    return value;
  }

  ngAfterViewInit() {
    Object.keys(this.itemForm.controls).forEach((key) => {
      const sub = this.itemForm.controls[key].valueChanges.subscribe(
        (result) => {
          this.updateTemplateCongfig();
          this.setGlobalModificationValues.emit(key);
          this.setInputChanged.emit(true);
        }
      );
      this.formSubscriptions.push(sub);
    });
  }

  ngOnDestroy() {
    this.formSubscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  init() {
    this.buildFormGroup();

    this.globalColor = this.templateConfigModel.globalColorCode;
    this.setShowGlobalColor();
    this.setUploadedImages();
    this.setUploadedVideos();
    this.updateTemplateCongfig();
  }

  setUploadedImages() {
    this.templateConfigModel.modifications.forEach((modification) => {
      if (this.hasChildren(modification)) {
        modification.children.forEach((child) => {
          child.elements.forEach((element) => {
            this.addImageToElement(element, child);
          });
        });
      } else {
        modification.elements.forEach((element) => {
          this.addImageToElement(element, modification);
        });
      }
    });
  }

  setUploadedVideos() {
    this.templateConfigModel.modifications.forEach((modification) => {
      if (this.hasChildren(modification)) {
        modification.children.forEach((child) => {
          child.elements.forEach((element) => {
            this.addVideoToElement(element, child);
          });
        });
      } else {
        modification.elements.forEach((element) => {
          this.addVideoToElement(element, modification);
        });
      }
    });
  }

  setShowGlobalColor() {
    this.showGlobalColor = this.templateConfigModel.hasGlobalColor;
  }

  hasChildren(modification) {
    return modification.hasOwnProperty("children") && modification.children;
  }

  addImageToElement(element, modification) {
    if (
      element.type === "image_id" &&
      (element.value || element.sourceThumbnail)
    ) {
      const name = modification.name + "_" + element.type;
      const url = element.value
        ? this.buildImageURL(element.value)
        : element.sourceThumbnail;
      this.addImage(name, url);
    }
  }

  addVideoToElement(element, modification) {
    if (
      element.type === "video_id" &&
      (element.value || element.sourceThumbnail)
    ) {
      const name = modification.name + "_" + element.type;
      const url = element.value
        ? this.buildImageURL(element.value)
        : element.sourceThumbnail;
      this.addVideo(name, url);
    }
  }

  updateCredits(name, value) {}

  buildFormGroup() {
    this.itemForm = new FormGroup({});

    this.templateConfigModel.modifications.forEach((modification) => {
      if (this.hasChildren(modification)) {
        modification.children.forEach((child) => {
          child.elements.forEach((element) => {
            this.addElementItemToForm(child, element);
          });
        });
      } else {
        modification.elements.forEach((element) => {
          this.addElementItemToForm(modification, element);
        });
      }
    });
  }

  addElementItemToForm(modification, element) {
    let isRequired = element.type != "image_id" || element.type != "video_id";
    let label = modification.name + "_" + element.type;

    if (isRequired) {
      this.itemForm.addControl(
        label,
        new FormControl(element.value, Validators.required)
      );
    } else {
      this.itemForm.addControl(label, new FormControl(element.value));
    }
  }

  setAllColors(value) {
    this.templateConfigModel.modifications.forEach((modification) => {
      if (modification.hasOwnProperty("elements")) {
        modification.elements.forEach((element) => {
          if (element.type === "color") {
            let label = modification.name + "_" + element.type;
            this.itemForm.get(label).setValue(value);
          }
        });
      }
    });

    this.templateConfigModel.slides.forEach((slide) => {
      slide.modifications.forEach((modification) => {
        if (modification.hasOwnProperty("elements")) {
          modification.elements.forEach((element) => {
            if (element.type === "color") {
              let label = modification.name + "_" + element.type;
              this.itemForm.get(label).setValue(value);
            }
          });
        }
      });
    });

    this.updateTemplateCongfig();
  }

  buildImageURL(mediaAssetId: number): string {
    return `${environment.imageServerUrl}api/Public/GetMediaAsset?id=${mediaAssetId}`;
  }

  onChangeGlobalColor($event) {
    const value = $event.target.value;
    this.setAllColors(value);

    this.setInputChanged.emit(true);
  }

  onDeleteImage($event) {
    this.itemForm.get($event).setValue(null);
    delete this.uploadedImageIds[$event];
    this.removeFromElement($event);
  }

  onDeleteVideo($event) {
    this.itemForm.get($event).setValue(null);
    delete this.uploadedVideoIds[$event];
    this.removeFromElement($event);
  }

  removeFromElement(name) {
    this.templateConfigModel.modifications.forEach((modification) => {
      const updatedModification = modification;

      if (modification.hasOwnProperty("children") && modification.children) {
        modification.children.forEach((child, j) => {
          child.elements.forEach((element, i) => {
            if (child.name + "_" + element.type === name) {
              updatedModification.children[j].elements[i].value = null;
              updatedModification.children[j].elements[i].credits = null;
              updatedModification.children[j].elements[i].source = null;
              updatedModification.children[j].elements[i].sourceThumbnail =
                null;
            }
          });
        });
      } else {
        if (modification.hasOwnProperty("elements")) {
          modification.elements.forEach((element, i) => {
            if (modification.name + "_" + element.type == name) {
              updatedModification.elements[i].value = null;
              updatedModification.elements[i].credits = null;
              updatedModification.elements[i].source = null;
              updatedModification.elements[i].sourceThumbnail = null;
            }
          });
        }
      }
    });
  }

  onImportImage($event) {
    const { name, height, width } = $event;

    this.onFocusImageElement = name;
    this.onFocusImageWidth = width;
    this.onFocusImageHeight = height;

    const dialogRef = this.dialog.open(
      ImportMarketingPortalEditorFileDialogComponent,
      {
        width: "800px",
        panelClass: "no-spacing",
        height: "auto",
        maxHeight: "100vh",
        data: {
          acceptTypes: this.acceptImageTypes,
        },
      }
    );

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        if (res.type === "CUSTOM_IMAGE") {
          this.emitFiles(res.event);
          return;
        }

        if (res.type === "UNSPLASH") {
          const unplashImageSearchDialogRef = this.dialog.open(
            UnsplashImageSearchDialogComponent,
            {
              width: "960px",
              panelClass: "no-spacing",
              height: "95vh",
              maxHeight: "95vh",
              disableClose: true,
            }
          );

          unplashImageSearchDialogRef.afterClosed().subscribe(async (res) => {
            if (res) {
              const { file, externalSource, originalSource, credits } = res;
              this.handleUploadedFile(
                file,
                externalSource,
                originalSource,
                credits
              );
            }
          });

          return;
        }

        if (res.type === "MEDIAASSET") {
          const mediaAssetImageSearchDialogRef = this.dialog.open(
            MediaAssetSearchDialogComponent,
            {
              width: "960px",
              panelClass: "no-spacing",

              maxHeight: "95vh",
              disableClose: true,
              data: {
                customerId: this.customerId,
                customerLogo: this.customerLogo,
                width: this.onFocusImageWidth,
                height: this.onFocusImageHeight,
                channelId: null,
                googleDisplayMediaFormatId: null,
                hideAdMaterials: true,
              },
            }
          );

          mediaAssetImageSearchDialogRef
            .afterClosed()
            .subscribe(async (res) => {
              if (res) {
                const { file, externalSource, credits, originalSource } = res;
                this.handleUploadedFile(
                  file,
                  externalSource,
                  originalSource,
                  credits
                );
              }
            });
        }
      }
    });
  }

  onImportVideo($event) {
    const { name, height, width, duration } = $event;
    this.onFocusVideoElement = name;

    const dialogRef = this.dialog.open(
      ImportMarketingPortalEditorVideoFileDialogComponent,
      {
        width: "800px",
        panelClass: "no-spacing",
        height: "auto",
        maxHeight: "100vh",
        data: {
          acceptTypes: this.acceptVideoTypes,
        },
      }
    );

    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return;
      }

      if (res.type === "CUSTOM_VIDEO") {
        this.emitVideoFile(res.event);
        return;
      }

      if (res.type === "PEXELS") {
        const dialogRef = this.dialog.open(PexelVideoSearchDialogComponent, {
          width: "960px",
          panelClass: "no-spacing",
          height: "95vh",
          maxHeight: "95vh",
          disableClose: true,
          data: {
            width,
            height,
            duration,
          },
        });

        dialogRef.afterClosed().subscribe(async (res) => {
          if (res) {
            const { file, externalSource, originalSource, credits } = res;
            this.handleUploadedVideoFile(
              file,
              externalSource,
              originalSource,
              credits
            );
          }
        });
        return;
      }

      if (res.type === "MEDIAASSET") {
        const dialogRef = this.dialog.open(VideoMediaLibaryDialogComponent, {
          width: "960px",
          panelClass: "no-spacing",
          maxHeight: "95vh",
          disableClose: true,
          data: {
            customerId: this.customerId,
            width,
            height,
            hideAdMaterials: true,
          },
        });

        dialogRef.afterClosed().subscribe(async (res) => {
          if (res) {
            const mediaAssetId = res.mediaAssetId;
            this.itemForm.get(this.onFocusVideoElement).setValue(mediaAssetId);

            const url = this.buildImageURL(mediaAssetId);
            this.addVideo(this.onFocusVideoElement, url);
            this.generateImage.emit();
          }
        });
      }
    });
  }

  emitVideoFile($event) {
    const file = $event.target.files[0];
    $event.target.value = "";

    this.handleUploadedVideoFile(file);
  }

  async handleUploadedVideoFile(
    file,
    externalSource = null,
    originalSource = null,
    credits = null
  ) {
    this.appLoader.open();

    await this.uploadMediaVideoFile(
      file,
      externalSource,
      originalSource,
      credits
    ).catch((e) => {
      this.snackbarService.show(e.message, "danger");
    });

    this.appLoader.close();

    this.generateImage.emit();
  }

  emitFiles($event) {
    const file = $event.target.files[0];
    $event.target.value = "";

    if (!this.hasValidFileSize(file)) {
      return;
    }

    this.handleUploadedFile(file);
  }

  handleUploadedFile(
    file,
    externalSource = null,
    originalSource = null,
    credits = null
  ) {
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "1000px",
      maxHeight: "95vh",
      panelClass: "no-spacing",
      disableClose: true,
      data: {
        file: file,
        useBackgroundRemover: true,
        useImageCropper: true,
        isEditor: true,
        width: this.onFocusImageWidth,
        height: this.onFocusImageHeight,
        forceAspectRatio: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const croppedFile = res.res;

        this.appLoader.open();

        await this.uploadMediaImageFile(
          croppedFile,
          externalSource,
          credits,
          originalSource
        ).catch((e) => {
          this.snackbarService.show(e.message, "danger");
        });

        this.appLoader.close();

        this.generateImage.emit();
      }
    });
  }

  uploadMediaVideoFile(
    file,
    externalSource = null,
    originalSource = null,
    credits = null
  ) {
    const observables = [
      this.crudService.uploadCampaignVideoAssets(
        file,
        null,
        null,
        null,
        this.customerId,
        externalSource,
        credits,
        originalSource
      ),
    ];

    return new Promise((resolve, reject) => {
      forkJoin(observables).subscribe(
        (x) => {
          const mediaAssetId = x[0]["body"]["successfullVideoIds"][0];
          const url = this.buildImageURL(mediaAssetId);

          this.itemForm.get(this.onFocusVideoElement).setValue(mediaAssetId);
          this.addVideo(this.onFocusVideoElement, url);
          this.setValueToElement(this.onFocusVideoElement, "credits", {
            ImageOwnerName: credits,
          });

          resolve(x);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  uploadMediaImageFile(
    croppedFile,
    externalSource = null,
    credits = null,
    originalSource = null
  ) {
    const observables = [
      this.crudService.uploadCampaignImageAssets(
        croppedFile,
        false,
        false,
        this.customerId,
        externalSource,
        credits,
        originalSource
      ),
    ];

    return new Promise((resolve, reject) => {
      forkJoin(observables).subscribe(
        (x) => {
          for (var i = 0; i < x.length; i++) {
            if (x[i]["body"] != null) {
              const mediaAssetId = x[i]["body"]["uploadInformation"][0]["Id"];
              const url = this.buildImageURL(mediaAssetId);
              this.itemForm
                .get(this.onFocusImageElement)
                .setValue(mediaAssetId);
              this.addImage(this.onFocusImageElement, url);
              this.setValueToElement(this.onFocusImageElement, "credits", {
                ImageOwnerName: credits,
              });
            }
          }
          resolve(x);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  setValueToElement(name, key, value) {
    this.templateConfigModel.modifications.forEach((modification) => {
      const updatedModification = modification;

      if (modification.hasOwnProperty("children") && modification.children) {
        modification.children.forEach((child, j) => {
          child.elements.forEach((element, i) => {
            if (child.name + "_" + element.type === name) {
              updatedModification.children[j].elements[i][key] = value;
            }
          });
        });
      } else {
        if (modification.hasOwnProperty("elements")) {
          modification.elements.forEach((element, i) => {
            if (modification.name + "_" + element.type == name) {
              updatedModification.elements[i][key] = value;
            }
          });
        }
      }
    });
  }

  addImage(key, url) {
    this.uploadedImageIds[key] = url;
  }

  addVideo(key, url) {
    this.uploadedVideoIds[key] = url;
  }

  hasValidFileSize(file) {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));
    const maxUploadSize = this.MAX_UPLOAD_IMAGE_SIZE;
    if (fileSize > maxUploadSize) {
      this.snackbarService.show(
        this.translate.instant("ImageUploadMaxSizeError", {
          maxUploadSize: maxUploadSize,
        }),
        "danger"
      );
      return false;
    }

    return true;
  }

  generateTemplateConfigValues(values) {
    const updatedTemplateConfig = [];

    this.templateConfigModel.modifications.forEach((modification) => {
      const updatedModification = modification;

      if (modification.hasOwnProperty("children") && modification.children) {
        modification.children.forEach((child, j) => {
          child.elements.forEach((element, i) => {
            updatedModification.children[j].elements[i].value =
              values[child.name + "_" + element.type];
          });
        });
      } else {
        if (modification.hasOwnProperty("elements")) {
          modification.elements.forEach((element, i) => {
            updatedModification.elements[i].value =
              values[modification.name + "_" + element.type];
          });
        }
      }

      updatedTemplateConfig.push(updatedModification);
    });

    return updatedTemplateConfig;
  }

  openTextSuggestions(name: string) {
    const dialogRef = this.dialog.open(TextSuggestionsDialogComponent, {
      width: "960px",
      panelClass: "no-spacing",
      maxHeight: "95vh",
      disableClose: true,
      data: {
        customerId: this.customerId,
        templateId: this.templateId,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.itemForm.get(name).setValue(res.text);
      }
    });
  }

  updateTemplateCongfig() {
    const values = this.itemForm.getRawValue();

    this.updateModification.emit({
      modifications: this.generateTemplateConfigValues(values),
    });
  }

  submit() {}
}
