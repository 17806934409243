import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";

@Component({
  selector: "media-asset-libary-card",
  templateUrl: "./media-asset-libary-card.component.html",
  styleUrls: ["./media-asset-libary-card.component.scss"],
})
export class MediaAssetLibaryCardComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() libaryMediaAsset: MPLibaryMediaAsset;
  @Input() selected: MPLibaryMediaAsset;

  constructor() {}

  ngOnInit() {}
}
