import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { environment } from "environments/environment";

@Component({
  selector: "app-video-editor-template-details-dialog",
  templateUrl: "./video-editor-template-details-dialog.component.html",
  styleUrls: ["./video-editor-template-details-dialog.component.scss"],
})
export class VideoEditorTemplateDetailsDialogComponent implements OnInit {
  public environment = environment;
  constructor(
    public dialogRef: MatDialogRef<VideoEditorTemplateDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
