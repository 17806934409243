import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "video-editor-template-slide-card",
  templateUrl: "./video-editor-template-slide-card.component.html",
  styleUrls: ["./video-editor-template-slide-card.component.scss"],
})
export class VideoEditorTemplateSlideCardComponent implements OnInit {
  @Input() slide: any;
  @Input() selectedSlide: any;
  @Input() index: number;
  @Input() editedSlideIds: number[];
  @Input() updatedSlideIds: number[];
  public readinessInterval = null;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.updateReadiness();
  }

  ngOnInit() {}

  updateReadiness() {
    if (this.slide.hasOwnProperty("isReady") && !this.slide.isReady) {
      const self = this;
      const maxRecurrences = 10;
      let counter = 0;
      this.readinessInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(self.slide.imageId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              await self.delay(1500);
              self.slide.isReady = res.isReady;
              clearInterval(self.readinessInterval);
            }

            if (counter > maxRecurrences) {
              clearInterval(self.readinessInterval);
              self.snackbarService.show(
                self.translate.instant("ErrorOccurredWhileGeneratingVideo"),
                "danger"
              );
            }
          });
      }, 3000);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
