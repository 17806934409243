import { MPCustomerLocation } from "./MPCustomerLocation";

export class MPCustomerLocationList {
  public mpCustomerLocations: MPCustomerLocation[];

  constructor() {}

  public deserializeList(
    serializedObj: MPCustomerLocation[]
  ): MPCustomerLocation[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPCustomerLocation) =>
      new MPCustomerLocation().deserialize(x)
    );
  }
}
