export class MPGoalAndPerformance {
  public goalId: number;
  public goalDisplayName: string;
  public goalInternalName: string;
  public goalIntervalName: string;
  public expectedPerformance: number;

  constructor(
    goalId: number = null,
    goalDisplayName: string = null,
    goalIntervalName: string = null,
    goalInternalName: string = null,
    expectedPerformance: number = null
  ) {
    this.goalId = goalId;
    this.goalDisplayName = goalDisplayName;
    this.goalIntervalName = goalIntervalName;
    this.goalInternalName = goalInternalName;
    this.expectedPerformance = expectedPerformance;
  }

  deserialize(serializedObj: object): MPGoalAndPerformance {
    if (!serializedObj) {
      return null;
    }

    this.goalId = serializedObj["goalId"];
    this.goalDisplayName = serializedObj["goalDisplayName"];
    this.goalIntervalName = serializedObj["goalIntervalName"];
    this.goalInternalName = serializedObj["goalInternalName"];
    this.expectedPerformance = serializedObj["expectedPerformance"];

    return this;
  }
}
