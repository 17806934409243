import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { CrudService } from "app/views/mapAds/crud.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { GAMCampaign } from "app/model/marketing-portal/GAMCampaign";
import { ValidationService } from "app/shared/services/validation.service";
import { datePeriodConfig } from "../config/datePeriodConfig";
import { MPGAMAdUnit } from "app/model/marketing-portal/MPGAMAdUnit";
import { MPPackage } from "app/model/marketing-portal/MPPackage";
import { MPCampaignSettings } from "app/model/marketing-portal/MPCampaignSettings";
import { MPGeoTargeting } from "app/model/marketing-portal/MPGeoTargeting";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPDevice } from "app/model/marketing-portal/MPDevice";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";

@Component({
  selector: "create-mopo-form",
  templateUrl: "./create-mopo-form.component.html",
  styleUrls: ["./create-mopo-form.component.scss"],
})
export class CreateMopoFormComponent implements OnInit {
  @ViewChild("form") form: NgForm;
  @Input() data?: GAMCampaign;
  @Input() duplicate: number;
  @Input() readonly?: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() campaignChannelId?: number = null;
  @Input() customer?: any;
  @Input() selectedTemplateImages?: TemplateBasedImage[] = null;
  @Input() selectedObjective?: MPObjective;
  @Input() selectedResolutions?: MPTemplateResolution[];
  public isLoading: boolean = false;
  public campaignForm: FormGroup;
  public errors: any = {};
  public gamAdUnits: MPGAMAdUnit[];
  public packages: MPPackage[];
  public geoTargets: MPGeoTargeting[];
  public mediaFormats: GAMMediaFormat[];
  public devices: MPDevice[];
  public settings: MPCampaignSettings;
  public manualBudgetImpressionsSub: any;
  public sendApprovalRequest: boolean = false;
  readonly mpChannel = MPChannel;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.buildForm(this.data);
    this.initData();
  }

  async initData() {
    setTimeout(() => this.appLoader.open());
    this.isLoading = true;

    try {
      this.settings = await this.crudService.getGAMSettings().toPromise();
      this.gamAdUnits = await this.crudService.getGAMAdUnits().toPromise();
      this.packages = await this.crudService.getGAMPackages().toPromise();
      this.geoTargets = await this.crudService.getGAMGeoTargeting().toPromise();
      this.mediaFormats = await this.crudService
        .getGAMMediaFormats()
        .toPromise();
      this.devices = await this.crudService.getGAMDevices().toPromise();
    } catch (e) {
      this.snackbarService.show(e.error, "danger");
      this.appLoader.close();
    }

    this.isLoading = false;
    setTimeout(() => this.appLoader.close());
  }

  getManualBudgetImpressions(
    goalId: number,
    budget: number,
    onResult: Function
  ) {
    this.manualBudgetImpressionsSub = this.crudService
      .GetGAMPerformanceByBudget(goalId, budget)
      .subscribe(
        (res) => {
          onResult(res);
        },
        (err) => {
          this.snackbarService.show(err.error, "danger");
          onResult(0);
        }
      );
  }

  buildForm(items: GAMCampaign) {
    this.campaignForm = this.formBuilder.group({
      mpCampaignId: items ? items.mpCampaignId : null,
      campaignName: [this.translate.instant("MyCampaign"), Validators.required],
      campaignPackageId: [
        items && items.campaignPackageId ? items.campaignPackageId : 0,
        Validators.required,
      ],
      campaignGoalId: [0, Validators.required],
      manualBudget: 0,
      campaignStart: [
        null,
        Validators.compose([
          ValidationService.dateGreaterThan({
            source: "campaignEnd",
            reference: "campaignStart",
            minDays: datePeriodConfig.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            maxDays: datePeriodConfig.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
          }),
          ValidationService.daysInFuture(
            datePeriodConfig.START_DATE_DAYS_IN_FUTURE
          ),
        ]),
      ],
      campaignEnd: [
        null,
        [
          ValidationService.dateGreaterThan({
            source: "campaignEnd",
            reference: "campaignStart",
            minDays: datePeriodConfig.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            maxDays: datePeriodConfig.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
          }),
        ],
      ],
      adUnitIds: [[], Validators.required],
      geoTargetingIds: [[]],
      destinationLink: [
        items ? items.gamDestinationLink : "",
        ValidationService.urlValidator,
      ],
      protocol: ["https://", Validators.required],
      mediaFormats: [[], Validators.required],
      deviceIds: [[]],
      approvalCustomerEmail: [
        this.customer && this.customer.customerMail
          ? this.customer.customerMail
          : "",
      ],
      approvalMessage: "",
    });

    if (this.readonly) {
      this.campaignForm.disable();
    }
  }

  onChangeSendApprovalRequest($event) {
    this.sendApprovalRequest = $event;
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  getValidationErrors(): Object {
    const errors = {};

    if (this.campaignForm.hasError("startDateLessThanEndDate")) {
      this.campaignForm.controls["campaignEnd"].setErrors({
        startDateLessThanEndDate: true,
      });
    }

    // FormGroup validation errors
    Object.keys(this.campaignForm.controls).forEach((key) => {
      const control = this.campaignForm.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  generatePaymentURL(
    campaignChannelId: number,
    mpCampaignId: number,
    checkoutNeeded: boolean
  ) {
    return `/mapAds/marketing-portal/checkout/campaign/${campaignChannelId}/${mpCampaignId}?checkoutNeeded=${checkoutNeeded}`;
  }

  async onSubmit(isDraft: boolean = false) {
    this.errors = this.getValidationErrors();

    if (this.campaignForm.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        this.translate.instant("PleaseFillInAllRequiredFields"),
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    let value = this.campaignForm.getRawValue();

    const {
      mpCampaignId,
      campaignName,
      campaignPackageId,
      campaignGoalId,
      manualBudget,
      campaignStart,
      campaignEnd,
      adUnitIds,
      geoTargetingIds,
      destinationLink,
      mediaFormats,
      protocol,
      deviceIds,
    } = value;

    const startDate = new Date(campaignStart);
    let offset = (startDate.getTimezoneOffset() * -1) / 60;

    const endDate = new Date(campaignEnd);
    let offset_enddate = (endDate.getTimezoneOffset() * -1) / 60;

    const data: any = {
      mpCampaignId,
      campaignName: campaignName,
      campaignStart: startDate,
      campaignEnd: endDate,
      channelId: MPChannel.CHANNEL_ID_MOPO,
      timezone: offset,
      timezone_enddate: offset_enddate,
      campaignPackageId,
      manualBudget,
      campaignGoalId,
      destinationLink: protocol + destinationLink,
      deviceIds,
      geoTargetingIds,
      adUnitIds,
      customerId: this.customer ? this.customer.customerId : null,
      mediaFormats,
    };

    const request: Promise<any> = this.isEdit
      ? this.crudService
          .editGAMCampaign(this.data.gamCampaignId, data)
          .toPromise()
      : this.crudService.createGAMCampaign(data).toPromise();

    this.appLoader.open();

    await request
      .then(async (res) => {
        if (this.isEdit) {
          this.snackbarService.show(
            this.translate.instant("YourMarketingCampaignWasSuccessfullyEdited")
          );
          this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
          setTimeout(() => this.appLoader.close());
        } else {
          this.snackbarService.show(
            this.translate.instant(
              "YourMarketingCampaignWasSuccessfullyCreated"
            )
          );

          const gamCampaignId = Number(res);

          let gamCampaign = null;

          await this.crudService
            .getGAMCampaign(gamCampaignId)
            .toPromise()
            .then((res) => {
              gamCampaign = res;
            })
            .catch((e) => {
              this.snackbarService.show(e.error, "danger");
            });

          if (this.sendApprovalRequest) {
            const body = {
              userMessage: value.approvalMessage,
              optionalMail: value.approvalCustomerEmail,
            };

            await this.crudService
              .requestCustomerApproval(gamCampaign.mpCampaignId, body)
              .toPromise()
              .then((res) => {
                this.snackbarService.show(
                  this.translate.instant(
                    "YourApprovalRequestWasSentSuccessfully"
                  ),
                  "success"
                );
              })
              .catch((e) => {
                this.snackbarService.show(e.error, "danger");
              });
          }

          let redirectUrl = isDraft
            ? "/mapAds/marketing-portal/campaigns"
            : this.generatePaymentURL(
                this.campaignChannelId,
                gamCampaign.mpCampaignId,
                this.settings.CheckoutNeeded
              );

          this.appLoader.close();

          this.router.navigateByUrl(redirectUrl);
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.appLoader.close();
      });
  }
}
