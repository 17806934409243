import { Component, Input, OnInit } from "@angular/core";
import { MPCampaignStatus } from "app/model/marketing-portal/MPCampaignStatus";

@Component({
  selector: "marketing-portal-status",
  templateUrl: "./marketing-portal-status.component.html",
  styleUrls: ["./marketing-portal-status.component.scss"],
})
export class MarketingPortalStatusComponent implements OnInit {
  @Input() status: MPCampaignStatus;
  @Input() size?: string;
  public colors: object = {
    60: {
      // Active
      backgroundColor: "background-primary",
      color: "text-white",
    },
    5: {
      // Draft
      backgroundColor: "background-blue-dark-gray",
      color: "text-dark-blue-200",
    },
    10: {
      // In Customer check
      backgroundColor: "background-purple-600",
      color: "text-white",
    },

    30: {
      // Scheduled
      backgroundColor: "background-purple-600",
      color: "text-white",
    },
    40: {
      // Publishing
      backgroundColor: "background-purple-600",
      color: "text-white",
    },
    45: {
      // Publishing
      backgroundColor: "background-purple-600",
      color: "text-white",
    },
    100: {
      // Finished
      backgroundColor: "background-dark-blue-200",
      color: "text-white",
    },
    70: {
      // Expired
      backgroundColor: "background-warning",
      color: "text-white",
    },
    11: {
      // Customer declined
      backgroundColor: "background-danger",
      color: "text-white",
    },
    50: {
      // Declined
      backgroundColor: "background-danger",
      color: "text-white",
    },
    65: {
      // PublishingError
      backgroundColor: "background-danger",
      color: "text-white",
    },
    66: {
      // Paused
      backgroundColor: "background-danger",
      color: "text-white",
    },
    69: {
      // Deleted
      backgroundColor: "background-danger",
      color: "text-white",
    },
  };
  constructor() {}

  ngOnInit() {}
}
