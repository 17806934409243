import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPDevice } from "app/model/marketing-portal/MPDevice";

@Component({
  selector: "devices-campaign-form-section",
  templateUrl: "./devices-campaign-form-section.component.html",
  styleUrls: ["./devices-campaign-form-section.component.scss"],
})
export class DevicesCampaignFormSectionComponent implements OnInit {
  @Input() deviceIdsControl: FormControl;
  @Input() devices: MPDevice[];
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;

  constructor() {}

  ngOnInit() {
    if (this.data && this.data.devices) {
      this.initData();
    }
  }

  initData() {
    let value: number[] = this.deviceIdsControl.value || [];

    this.data.devices.forEach((device: MPDevice) => {
      value.push(device.deviceId);
    });

    this.deviceIdsControl.setValue(value);
  }

  onClickDevice(deviceId: number) {
    let value = this.deviceIdsControl.value || [];

    if (value.includes(deviceId)) {
      let index = value.indexOf(deviceId);
      value.splice(index, 1);
    } else {
      value.push(deviceId);
    }

    this.deviceIdsControl.setValue(value);
  }
}
