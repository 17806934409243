import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-video-editor",
  templateUrl: "./marketing-portal-video-editor.component.html",
  styleUrls: ["./marketing-portal-video-editor.component.scss"],
})
export class MarketingPortalVideoEditorComponent implements OnInit {
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Output() onCloseWindow: EventEmitter<any> = new EventEmitter();
  @Input() customerId: string;
  @Input() customerLogo: string;
  @Input() channelId: number;
  @Input() mediaAsset: MPMediaAsset;
  @Input() resolutionId: number;
  @Input() isEdit: boolean;
  @Input() preSelectTemplateId: number;
  public templates: any;
  public selectedTemplate: any;
  public isOpenMenu: boolean = false;
  public page: number = 0;
  public itemsPerPage: number = 100;
  public isInitLoading: boolean = true;
  public originalMediaAssetId: number = null;

  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    setTimeout(() => this.appLoader.open());

    await this.crudService
      .getAllMPVideoTemplates(
        this.channelId,
        this.page,
        this.itemsPerPage,
        this.customerId
      )
      .toPromise()
      .then((res) => {
        this.templates = res.templates;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    if (this.preSelectTemplateId) {
      this.selectedTemplate = this.getDefaultTemplate(this.preSelectTemplateId);
      setTimeout(() => this.appLoader.close());
      this.isInitLoading = false;
      return;
    }

    if (this.mediaAsset) {
      this.originalMediaAssetId = this.mediaAsset.mediaAssetId;

      await this.restoreHistory()
        .then((res) => {
          this.mediaAsset = null;
          this.selectedTemplate = res;
          setTimeout(() => this.appLoader.close());
        })
        .catch((error) => {
          this.snackbarService.show(error, "danger");
          setTimeout(() => this.appLoader.close());
        });
    }

    setTimeout(() => this.appLoader.close());
    this.isInitLoading = false;
  }

  restoreHistory() {
    return new Promise(async (resolve, reject) => {
      await this.crudService
        .getHistoricVideoTemplateConfig(this.mediaAsset.mediaAssetId)
        .toPromise()
        .then((res: any) => {
          let selectedTemplate = this.getDefaultTemplate(
            this.mediaAsset.templateId
          );
          selectedTemplate["templateConfigModel"] = res;

          resolve(selectedTemplate);
        })
        .catch((e) => {
          reject(e.error);
        });
    });
  }

  getDefaultTemplate(templateId: number) {
    return this.templates.find(
      (template) => template.templateId === templateId
    );
  }

  toggleMenu() {
    this.isOpenMenu = !this.isOpenMenu;
  }

  onSelectTemplate(res) {
    this.selectedTemplate = res.template;
  }

  onDeselectTemplate() {
    this.selectedTemplate = null;
  }

  onClose() {
    this.onCloseWindow.emit();
  }
}
