import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "time-period-calendar-view",
  templateUrl: "./time-period-calendar-view.component.html",
  styleUrls: ["./time-period-calendar-view.component.scss"],
})
export class TimePeriodCalendarViewComponent implements OnInit {
  @Input() start: string | Date;
  @Input() end: string | Date;
  public months: any = [];

  constructor() {}

  ngOnInit() {
    this.genMonths();
  }

  genMonths() {
    let startDate = new Date(this.start);
    let endDate = new Date(this.end);

    const offset = startDate.getTimezoneOffset();

    startDate = new Date(startDate.getTime() - offset * 60000);
    endDate = new Date(endDate.getTime() - offset * 60000);

    const monthDiff = this.getMonthDiff(startDate, endDate);

    this.months.push({
      month: startDate.getMonth(),
      year: startDate.getFullYear(),
    });

    for (let i = 0; i < monthDiff; i++) {
      const newDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
      const year = newDate.getFullYear();
      const month = newDate.getMonth();

      this.months.push({
        month,
        year,
      });
    }
  }

  getMonthDiff(d1: Date, d2: Date) {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
}
