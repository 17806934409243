import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "environments/environment";

@Pipe({
  name: "mediaAssetPreviewImage",
})
export class MediaAssetPreviewImagePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let index = args.findIndex(
      (arg) => arg.googleDisplayMediaFormatId === value
    );

    return (
      environment.imageServerUrl +
      "api/Public/GetMediaAsset?id=" +
      args[index].mediaAssetId
    );
  }
}
