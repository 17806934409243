import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { SubChannelDetails } from "app/model/channel/SubChannelDetails";

@Component({
  selector: "app-additional-channel-packages-info-dialog",
  templateUrl: "./additional-channel-packages-info-dialog.component.html",
  styleUrls: ["./additional-channel-packages-info-dialog.component.scss"],
})
export class AdditionalChannelPackagesInfoDialogComponent implements OnInit {
  public channels: SubChannelDetails[];
  constructor(
    public dialogRef: MatDialogRef<AdditionalChannelPackagesInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.channels = [];
  }

  ngOnInit() {
    this.getChannels();
  }

  onClose() {
    this.dialogRef.close();
  }

  getChannels() {
    this.data.channelPackages.forEach((item) => {
      if (item.displayBudget <= this.data.channelPackage.displayBudget) {
        this.channels = this.channels.concat(item.SubChannelList);
      }
    });
  }
}
