import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter } from "@angular/material";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { datePeriodConfig } from "../../config/datePeriodConfig";

@Component({
  selector: "date-period-campaign-form-section",
  templateUrl: "./date-period-campaign-form-section.component.html",
  styleUrls: ["./date-period-campaign-form-section.component.scss"],
})
export class DatePeriodCampaignFormSectionComponent implements OnInit {
  @Output() validateErrors: EventEmitter<any> = new EventEmitter();
  @Input() campaignStartControl: FormControl;
  @Input() campaignEndControl: FormControl;
  @Input() errors: any;
  @Input() data: any;
  @Input() duplicate: boolean;
  public config = datePeriodConfig;

  constructor(
    private translate: TranslateService,
    private adapter: DateAdapter<any>
  ) {}

  ngOnInit() {
    this.setTranslationSubscription();
    this.setInitValues();
  }

  setTranslationSubscription() {
    this.adapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(event.lang);
    });
  }

  setInitValues() {
    const initDateObj = this.getInitDate(this.data);
    this.campaignStartControl.setValue(initDateObj.startDate);
    this.campaignEndControl.setValue(initDateObj.endDate);
  }

  getInitDate(items) {
    const initDate = {
      startDate: null,
      endDate: null,
    };

    if (!items || this.duplicate) {
      let startDate = new Date();
      startDate.setDate(
        startDate.getDate() + this.config.START_DATE_DAYS_IN_FUTURE + 1
      );
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setMilliseconds(0);
      startDate.setSeconds(0);
      initDate.startDate = startDate;

      let endDate = new Date();
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setMilliseconds(0);
      endDate.setSeconds(0);
      endDate.setDate(
        endDate.getDate() +
          this.config.START_DATE_DAYS_IN_FUTURE +
          1 +
          this.config.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS
      );

      initDate.endDate = endDate;
    } else {
      const startDate = new Date(items.start);
      let offsetStart = (startDate.getTimezoneOffset() * -1) / 60;

      const endDate = new Date(items.end);
      let offsetEnd = (endDate.getTimezoneOffset() * -1) / 60;

      initDate.startDate = new Date(items.start);
      initDate.startDate.setHours(initDate.startDate.getHours() + offsetStart);
      initDate.endDate = new Date(items.end);
      initDate.endDate.setHours(initDate.endDate.getHours() + offsetEnd);
    }

    return initDate;
  }

  onDateChange($event) {
    this.campaignStartControl.updateValueAndValidity();
    this.campaignEndControl.updateValueAndValidity();

    this.validateErrors.emit();
  }
}
