import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "mp-pagination",
  templateUrl: "./mp-pagination.component.html",
  styleUrls: ["./mp-pagination.component.scss"],
})
export class MpPaginationComponent implements OnInit {
  @Output() onPage: EventEmitter<any> = new EventEmitter();
  @Input() page: number;
  @Input() totalPages: number;
  constructor() {}

  ngOnInit() {}

  goToPage(page) {
    if (
      page < 0 ||
      page > this.totalPages - 1 ||
      (this.page + 1 === this.totalPages && page + 1 === this.totalPages) ||
      (this.page === 0 && page === 0)
    ) {
      return;
    }

    this.onPage.emit(page);
  }
}
