import { SMCampaignType } from "./SMCampaignType";

export class SMCampaignTypeList {
  public smCampaignTypes: SMCampaignType[];

  constructor() {}

  public deserializeList(serializedObj: SMCampaignType[]): SMCampaignType[] {
    return serializedObj.map((x: SMCampaignType) =>
      new SMCampaignType().deserialize(x)
    );
  }
}
