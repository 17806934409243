import { Component, OnInit } from "@angular/core";
import { fbAccount } from "app/model/account/fbAccount";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsComponent implements OnInit {
  public isLoading: boolean;
  public facebookAccount: fbAccount;
  readonly FACEBOOK_STATUS_CONNECTED: number = 3;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    await this.crudService
      .GetFacebookAccount()
      .toPromise()
      .then((res) => {
        this.facebookAccount = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }
}
