import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { PresalesItem } from "app/model/presales/PresalesItem";

@Component({
  selector: "ma-presales-presentation-start",
  templateUrl: "./presales-presentation-start.component.html",
  styleUrls: ["./presales-presentation-start.component.scss"],
})
export class PresalesPresentationStartComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() startPresention: EventEmitter<any> = new EventEmitter();
  @Output() goToCustomerSelection: EventEmitter<any> = new EventEmitter();
  @Input() customer: MPCustomer;
  @Input() presalesItems: PresalesItem[];

  constructor() {}

  ngOnInit() {}
}
