import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "uploaded-media-asset-file-card",
  templateUrl: "./uploaded-media-asset-file-card.component.html",
  styleUrls: ["./uploaded-media-asset-file-card.component.scss"],
})
export class UploadedMediaAssetFileCardComponent implements OnInit {
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onTriggerChange: EventEmitter<any> = new EventEmitter();
  @Input() mediaAsset: MPMediaAsset;
  @Input() index: number;
  @Input() readonly?: boolean = false;
  @Input() footer?: boolean = true;
  public sizeInMB: string;
  public progressInterval = null;
  public progressValue: number = 0;
  constructor(private crudService: CrudService) {}

  ngOnInit() {
    this.setSizeInMB();
    this.updateProgress();
  }

  setSizeInMB() {
    this.sizeInMB = this.mediaAsset.getSizeInMB();
  }

  updateProgress() {
    if (!this.mediaAsset.isReady) {
      const self = this;

      this.progressInterval = setInterval(function () {
        self.crudService
          .getVideoStatusByMediaAssetId(self.mediaAsset.mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              self.progressValue = 100;
              await self.delay(1500);
              self.mediaAsset.isReady = res.isReady;
              clearInterval(self.progressInterval);
              self.onTriggerChange.emit();
            } else {
              switch (self.progressValue) {
                case 0:
                  self.progressValue = 30;
                  break;
                case 30:
                  self.progressValue = 50;
                  break;
                case 50:
                  self.progressValue = 80;
                  break;
                case 80:
                  self.progressValue = 95;
                  break;
                default:
                  break;
              }
            }
          });
      }, 3000);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
