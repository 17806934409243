import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPGAMAdUnit } from "app/model/marketing-portal/MPGAMAdUnit";

@Component({
  selector: "gam-ad-unit-campaign-form-section",
  templateUrl: "./gam-ad-unit-campaign-form-section.component.html",
  styleUrls: ["./gam-ad-unit-campaign-form-section.component.scss"],
})
export class GamAdUnitCampaignFormSectionComponent implements OnInit {
  @Input() campaignAdUnitIdsControl: FormControl;
  @Input() gamAdUnits: MPGAMAdUnit[];
  @Input() data: any;
  @Input() errors: any;
  @Input() readonly: boolean = false;
  public isSelected: boolean;
  constructor() {}

  ngOnInit() {
    this.initData();
  }

  initData() {
    if (this.data) {
      let value: number[] = this.campaignAdUnitIdsControl.value || [];

      this.data.adUnits.forEach((adUnit: MPGAMAdUnit) => {
        value.push(adUnit.id);
      });

      this.campaignAdUnitIdsControl.setValue(value);
    }
  }

  onSelect(gamAdUnit: MPGAMAdUnit) {
    if (this.readonly) {
      return;
    }

    let value: number[] = this.campaignAdUnitIdsControl.value || [];
    let isSelected = value && value.includes(gamAdUnit.id);

    if (!isSelected) {
      // Add selected item
      value.push(gamAdUnit.id);
    } else {
      // Remove selected item
      let index = value.indexOf(gamAdUnit.id);
      value.splice(index, 1);
    }

    this.campaignAdUnitIdsControl.setValue(value);
  }
}
