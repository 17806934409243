import {
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPObjective } from "app/model/marketing-portal/MPObjective";

@Component({
  selector: "ma-extended-configurator-configuration",
  templateUrl: "./extended-configurator-configuration.component.html",
  styleUrls: ["./extended-configurator-configuration.component.scss"],
})
export class ExtendedConfiguratorConfigurationComponent implements OnInit {
  @Input() configuratorHeaderHeight: number;
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedChannel: MPChannel;
  @Input() selectedAssetType: number;
  @Input() selectedResolutions: string;
  @Input() selectedGoal: MPObjective;
  @Input() selectedTemplateImages: any;
  public contentHeight: number;
  readonly mpChannel = MPChannel;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;

    windowHeight -= this.configuratorHeaderHeight;
    windowHeight -= 70;

    this.contentHeight = windowHeight;
  }
}
