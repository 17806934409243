import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MPCampaignStatus } from "app/model/marketing-portal/MPCampaignStatus";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { ModuleService } from "app/shared/services/module.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-details-main",
  templateUrl: "./marketing-portal-details-main.component.html",
  styleUrls: ["./marketing-portal-details-main.component.scss"],
})
export class MarketingPortalDetailsMainComponent implements OnInit {
  @Input() mpCampaign: any;
  public channel: MPChannel;
  public locationsOverview: string;
  public mpChannel = MPChannel;
  readonly MODULE_SERVICE_CUSTOMER = MPModule.MODULE_SERVICE_CUSTOMER;
  public isPost: boolean;
  public isBoosted: boolean;
  public isPayable: boolean;
  public canBeBoosted: boolean;
  public googleDisplayMediaFormats: any = [];
  public mediaAssets: MPMediaAsset[] = [];
  public statusColors: object = {
    60: {
      // Active
      backgroundColor: "background-primary",
      color: "text-white",
    },
    5: {
      // Draft
      backgroundColor: "background-blue-dark-gray",
      color: "text-dark-blue-200",
    },
    10: {
      // In Customer check
      backgroundColor: "background-purple-600",
      color: "text-white",
    },

    30: {
      // Scheduled
      backgroundColor: "background-purple-600",
      color: "text-white",
    },
    40: {
      // Publishing
      backgroundColor: "background-purple-600",
      color: "text-white",
    },
    45: {
      // Publishing
      backgroundColor: "background-purple-600",
      color: "text-white",
    },

    100: {
      // Finished
      backgroundColor: "background-dark-blue-200",
      color: "text-white",
    },
    70: {
      // Expired
      backgroundColor: "background-warning",
      color: "text-white",
    },
    11: {
      // Customer declined
      backgroundColor: "background-danger",
      color: "text-white",
    },
    50: {
      // Declined
      backgroundColor: "background-danger",
      color: "text-white",
    },
    65: {
      // PublishingError
      backgroundColor: "background-danger",
      color: "text-white",
    },
    66: {
      // Paused
      backgroundColor: "background-danger",
      color: "text-white",
    },
    69: {
      // Deleted
      backgroundColor: "background-danger",
      color: "text-white",
    },
  };

  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    public moduleService: ModuleService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.assetsIncludeMediaFormats()) {
      this.getGoogleDisplayMediaFormats();
    }

    this.setIsPost();
    this.setIsPayable();
    this.setCanBeBoosted();
    this.setIsBoosted();
    this.getChannel();
    this.genLocationsOverview();
    this.convertMediaAssets();
  }

  setIsPayable() {
    this.isPayable =
      this.mpCampaign.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_DRAFT ||
      this.mpCampaign.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_CHECK ||
      this.mpCampaign.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED ||
      this.mpCampaign.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED;
  }

  setIsPost() {
    this.isPost =
      this.mpCampaign.channelId ===
      MPChannel.CHANNEL_ID_FACEBOOK_INSTAGRAM_POST;
  }

  hasBudget() {
    return this.mpCampaign.displayBudget || this.mpCampaign.package;
  }

  setCanBeBoosted() {
    this.canBeBoosted =
      (this.mpCampaign.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_SCHEDULED ||
        this.mpCampaign.status.statusId ===
          MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_ACTIVE ||
        this.mpCampaign.status.statusId ===
          MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_PUBLISHING ||
        this.mpCampaign.status.statusId ===
          MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_CHECK ||
        this.mpCampaign.status.statusId ===
          MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED ||
        this.mpCampaign.status.statusId ===
          MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED) &&
      this.isPost;
  }

  setIsBoosted() {
    this.isBoosted = this.isPost && this.hasBudget();
  }

  genLocationsOverview() {
    this.locationsOverview = "";

    const slicedLocations = this.mpCampaign.locations.slice(0, 2);

    slicedLocations.forEach((slicedLocation, i) => {
      this.locationsOverview += slicedLocation.locationCity;

      if (i + 1 < slicedLocations.length) {
        this.locationsOverview += ", ";
      }
    });

    if (this.mpCampaign.locations.length > slicedLocations.length) {
      this.locationsOverview += `, ... (${this.mpCampaign.locations.length})`;
    }
  }

  getChannel() {
    this.crudService.GetChannel(this.mpCampaign.channelId).subscribe(
      (res) => {
        this.channel = res;
      },
      (error) => {
        this.snackbarService.show(error.error, "danger");
      }
    );
  }

  getGoogleDisplayMediaFormats() {
    this.crudService.getGoogleDisplayMediaFormats().subscribe(
      (res) => {
        this.googleDisplayMediaFormats = res;
      },
      (error) => {
        this.snackbarService.show(error.error, "danger");
      }
    );
  }

  assetsIncludeMediaFormats() {
    return (
      this.mpCampaign.mediaAssets.length &&
      this.mpCampaign.mediaAssets[0].hasOwnProperty(
        "googleDisplayMediaFormat"
      ) &&
      this.mpCampaign.mediaAssets[0].googleDisplayMediaFormat
    );
  }

  convertMediaAssets() {
    this.mpCampaign.mediaAssets.forEach((mediaAsset) => {
      const newMediaAsset = new MPMediaAsset(
        mediaAsset.mediaAssetId,
        mediaAsset.mediaAssetName,
        mediaAsset.width,
        mediaAsset.height,
        mediaAsset.sizeInByte,
        mediaAsset.mediaAssetDataType,
        mediaAsset.mediaAssetIsTransparent,
        mediaAsset.isImage,
        mediaAsset.isVideo,
        mediaAsset.isReady,
        mediaAsset.mediaAssetUpdateTime,
        mediaAsset.mediaAssetData,
        mediaAsset.mediaAssetUrl,
        mediaAsset.mediaAssetThumbnailUrl,
        mediaAsset.isPartOfSet,
        mediaAsset.templateId,
        mediaAsset.googleDisplayMediaFormatId,
        mediaAsset.googleDisplayMediaFormat,
        mediaAsset.mediaFormatId,
        mediaAsset.mediaFormatName,
        mediaAsset.headline,
        mediaAsset.body
      );

      this.mediaAssets.push(newMediaAsset);
    });
  }

  copyToClipboard(text: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    this.snackbarService.show(this.translate.instant("LinkCopiedToClipboard"));
  }

  goToPayment() {
    this.router.navigateByUrl(
      "/mapAds/marketing-portal/checkout/campaign/" +
        this.mpCampaign.channelId +
        "/" +
        this.mpCampaign.mpCampaignId +
        "?checkoutNeeded=" +
        this.mpCampaign.isBudgetCheckoutNeeded
    );
  }
}
