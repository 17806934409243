import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { CustomerKeyword } from "app/model/customer/CustomerKeyword";
import { AddFormTextDialogComponent } from "app/shared/components/dialogs/add-form-text-dialog/add-form-text-dialog.component";
import { AiProcessTextAnimationDialogComponent } from "app/shared/components/dialogs/ai-process-text-animation-dialog/ai-process-text-animation-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "customer-keywords",
  templateUrl: "./customer-keywords.component.html",
  styleUrls: ["./customer-keywords.component.scss"],
})
export class CustomerKeywordsComponent implements OnInit {
  @Input() customerId: number;
  @Input() keywords: CustomerKeyword[];
  @Input() readonly?: boolean = false;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private appLoader: AppLoaderService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  async addKeyword(keyword: string) {
    this.appLoader.open();

    await this.crudService
      .addCustomerKeyword(this.customerId, keyword)
      .toPromise()
      .then((res) => {
        this.snackbarService.show(
          this.translate.instant("KeywordSuccessfullyAdded"),
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await this.crudService
      .getSingleCustomer(this.customerId)
      .toPromise()
      .then((res) => {
        this.keywords = res.keywords;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  async onDeleteKeyword(keywordId: number) {
    this.appLoader.open();

    await this.crudService
      .deleteCustomerKeyword(this.customerId, keywordId)
      .toPromise()
      .then((res) => {
        this.snackbarService.show(
          this.translate.instant("KeywordSuccessfullyRemoved"),
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await this.crudService
      .getSingleCustomer(this.customerId)
      .toPromise()
      .then((res) => {
        this.keywords = res.keywords;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  openAddKeywordDialog() {
    const dialogRef = this.dialog.open(AddFormTextDialogComponent, {
      width: "480px",
      maxHeight: "95vh",
      panelClass: "no-spacing",
      disableClose: true,
      data: {
        title: this.translate.instant("AddNewKeyword"),
        label: this.translate.instant("NewKeyword"),
        maxLength: 100,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.addKeyword(res.text);
      }
    });
  }

  openAIProcessDialog() {
    const loadingTexts: string[] = [
      this.translate.instant("AICreatesKeywords") + "...",
    ];
    const request = this.crudService.createKeywordsWithAI(this.customerId);

    const dialogRef = this.dialog.open(AiProcessTextAnimationDialogComponent, {
      width: "680px",
      panelClass: "no-spacing",
      disableClose: true,
      data: {
        title: "Erstelle Keywords",
        loadingTexts,
        request,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const { success, keywords } = res;

        if (success) {
          this.keywords = keywords;
          this.snackbarService.show(
            this.translate.instant("KeywordsSuccessfullyCreated") + "!",
            "success"
          );
          return;
        }

        this.snackbarService.show(
          this.translate.instant("UnknownErrorTryAgain"),
          "danger"
        );
      }
    });
  }
}
