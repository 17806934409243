import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
  selector: "approval-campaign-form-section",
  templateUrl: "./approval-campaign-form-section.component.html",
  styleUrls: ["./approval-campaign-form-section.component.scss"],
})
export class ApprovalCampaignFormSectionComponent implements OnInit {
  @Output() onChangeSendApprovalRequest: EventEmitter<any> = new EventEmitter();
  @Input() approvalCustomerEmailControl: FormControl;
  @Input() approvalMessageControl: FormControl;
  @Input() errors: any;
  public sendApprovalRequest: boolean = false;
  constructor() {}

  ngOnInit() {}

  changeShowCustomerApproval($event: any) {
    this.sendApprovalRequest = $event.checked;
    this.onChangeSendApprovalRequest.emit(this.sendApprovalRequest);

    if (!this.sendApprovalRequest) {
      this.approvalCustomerEmailControl.clearValidators();
    } else {
      this.approvalCustomerEmailControl.setValidators([
        ValidationService.emailValidator,
        Validators.required,
      ]);
    }

    this.approvalCustomerEmailControl.updateValueAndValidity();
  }
}
