import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MPCampaign } from "app/model/marketing-portal/MPCampaign";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { ModuleService } from "app/shared/services/module.service";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-campaign-card",
  templateUrl: "./marketing-portal-campaign-card.component.html",
  styleUrls: ["./marketing-portal-campaign-card.component.scss"],
})
export class MarketingPortalCampaignCardComponent implements OnInit {
  @Input() mpCampaign: MPCampaign;
  public previewImageUrl: string;
  public readonly environment = environment;
  constructor(public moduleService: ModuleService, private router: Router) {}

  ngOnInit() {
    this.previewImageUrl =
      this.mpCampaign.mediaAssets.length &&
      this.mpCampaign.mediaAssets[0].isImage
        ? environment.imageServerUrl +
          "api/Public/GetMediaAsset?id=" +
          this.mpCampaign.mediaAssets[0].mediaAssetId
        : null;
  }

  goToCustomer($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (!this.moduleService.hasAccess([MPModule.MODULE_SERVICE_CUSTOMER])) {
      return;
    }

    this.router.navigateByUrl(
      "/mapAds/customers/show/" + this.mpCampaign.customer.customerId
    );
  }
}
