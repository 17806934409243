import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";
import { MPSound } from "app/model/marketing-portal/MPSound";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { LayoutService } from "app/shared/services/layout.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-video-editor-template-configurator",
  templateUrl:
    "./marketing-portal-video-editor-template-configurator.component.html",
  styleUrls: [
    "./marketing-portal-video-editor-template-configurator.component.scss",
  ],
})
export class MarketingPortalVideoEditorTemplateConfiguratorComponent
  implements OnInit
{
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Input() template: any;
  @Input() customerId: number;
  @Input() customerLogo: string;
  @Input() channelId: number;
  @Input() isOpenMenu: boolean;
  @Input() mediaAssetId: number;
  @Input() isEdit: boolean;
  public isLoading: boolean;
  public fontFamilies: MPFontFamily[];
  public fontWeights: MPFontWeight[];
  public sounds: MPSound[];

  constructor(
    public layout: LayoutService,
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    setTimeout(() => this.appLoader.open());
    this.isLoading = true;

    await this.crudService
      .getFontFamilyForVideoTemplate(this.template.templateId)
      .toPromise()
      .then((res) => {
        this.fontFamilies = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await this.crudService
      .getFontWeightForVideoTemplate(this.template.templateId)
      .toPromise()
      .then((res) => {
        this.fontWeights = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await this.crudService
      .getSoundsForVideoTemplate(this.template.templateId)
      .toPromise()
      .then((res) => {
        this.sounds = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    setTimeout(() => this.appLoader.close());
    this.isLoading = false;
  }
}
