import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TextSuggestionsDialogComponent } from "app/shared/components/dialogs/text-suggestions-dialog/text-suggestions-dialog.component";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "customer-ad-text",
  templateUrl: "./customer-ad-text.component.html",
  styleUrls: ["./customer-ad-text.component.scss"],
})
export class CustomerAdTextComponent implements OnInit {
  // @Input() name: string;
  @Output() updated?: EventEmitter<any> = new EventEmitter();
  @Output() updateCustomerCD: EventEmitter<any> = new EventEmitter();
  @Input() label?: string;
  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() type: number;
  @Input() customerId: number;
  @Input() showActionBtns: boolean = true;
  @Input() showLargeAIBtn: boolean = false;
  public isLoadingAdText: boolean = false;
  public isLoadingSkeletonAdText: boolean = false;

  constructor(
    private dialog: MatDialog,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {}

  openTextSuggestions() {
    const dialogRef = this.dialog.open(TextSuggestionsDialogComponent, {
      width: "960px",
      panelClass: "no-spacing",
      maxHeight: "95vh",
      disableClose: true,
      data: {
        customerId: this.customerId,
        index: this.type,
        templateId: null,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.updateAdText(this.type, res.text);
      }
    });
  }

  async generateKIText() {
    this.isLoadingAdText = true;

    await this.crudService
      .createCustomerTextsWithAI(this.customerId, this.type)
      .toPromise()
      .then((res) => {
        this.control.setValue(res[`mainText${this.type}`].slice(0, 149));

        if (this.updated) {
          this.updated.emit(this.type);
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await this.delay(500);
    this.isLoadingAdText = false;

    // Show skeleton loading animation
    this.isLoadingSkeletonAdText = true;
    await this.delay(500);
    this.isLoadingSkeletonAdText = false;
  }

  async updateAdText(index: number, value: string) {
    this.control.setValue(value);

    // Show skeleton loading animation
    this.isLoadingSkeletonAdText = true;
    await this.updateCustomerCD.emit(false);
    await this.delay(500);
    this.isLoadingSkeletonAdText = false;

    if (this.updated) {
      this.updated.emit(this.type);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
