export class MPAgeGroup {
  public ageGroupId: number;
  public ageGroupDisplayName: string;
  public fromAge: number;
  public toAge: number;

  constructor(
    ageGroupId: number = null,
    ageGroupDisplayName: string = null,
    fromAge: number = null,
    toAge: number = null
  ) {
    this.ageGroupId = ageGroupId;
    this.ageGroupDisplayName = ageGroupDisplayName;
    this.fromAge = fromAge;
    this.toAge = toAge;
  }

  deserialize(serializedObj: object): MPAgeGroup {
    if (!serializedObj) {
      return null;
    }

    this.ageGroupId = serializedObj["ageGroupId"];
    this.ageGroupDisplayName = serializedObj["ageGroupDisplayName"];
    this.fromAge = serializedObj["fromAge"];
    this.toAge = serializedObj["toAge"];

    return this;
  }
}
