import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";

@Component({
  selector: "select-resolution-icon-card",
  templateUrl: "./select-resolution-icon-card.component.html",
  styleUrls: ["./select-resolution-icon-card.component.scss"],
})
export class SelectResolutionIconCardComponent {
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() resolutionList: MPTemplateResolution[];
  @Input() resolution: MPTemplateResolution;
  @Input() isSelected: boolean;
  @Input() showChannels: boolean = true;
  public height: number = null;
  public width: number = null;
  public size: number = 46;
  public isLoaded: boolean = false;
  public channels: MPChannel[];
  constructor() {}

  ngAfterContentInit() {
    this.calcWidth();
    this.findChannels();
  }

  onSelect() {
    const data = {
      format: this.resolution,
      channels: this.channels,
    };

    this.onClick.emit(data);
  }

  calcWidth() {
    this.height = this.size;

    if (this.resolution.resolution === "L-Banner") {
      this.width = (this.height * 16) / 9;
      return;
    }

    const resolutionSplit = this.resolution.resolution.split(":");
    const width = parseFloat(resolutionSplit[0]);
    const height = parseFloat(resolutionSplit[1]);

    const ratio = width / height;

    this.width = this.height * ratio;
  }

  findChannels() {
    this.channels = this.resolutionList.find(
      (resolution) => resolution.resolution === this.resolution.resolution
    ).channels;
  }
}
