import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-customer-crawler-animation-dialog",
  templateUrl: "./customer-crawler-animation-dialog.component.html",
  styleUrls: ["./customer-crawler-animation-dialog.component.scss"],
})
export class CustomerCrawlerAnimationDialogComponent implements OnInit {
  public loadingTexts: string[] = [
    this.translate.instant("CustomerWebsiteIsBeingSearched") + "...",
    this.translate.instant("ImagesAreBeingDetected") + "...",
    this.translate.instant("TextsAreBeingDetected") + "...",
    this.translate.instant("CustomerFileBeingCreated") + "...",
  ];
  public loadingTextIndex: number = 0;
  public loadingInterval: any;
  public animationHoldTime: number = 1000; // ms
  public isCrawled: boolean = false;
  public hideClose: boolean = false;

  constructor(
    private crudService: CrudService,
    public dialogRef: MatDialogRef<CustomerCrawlerAnimationDialogComponent>,
    private router: Router,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.hideClose =
      this.data.hasOwnProperty("hideClose") && this.data.hideClose;

    this.updateCustomerMetaInformation();
    await this.delay(500);
    const self = this;
    this.loadingInterval = setInterval(function () {
      if (
        self.loadingTextIndex + 2 > self.loadingTexts.length &&
        self.isCrawled
      ) {
        clearInterval(self.loadingInterval);
        self.dialogRef.close({ success: true });
        return;
      }
      self.loadingTextIndex =
        self.loadingTextIndex >= self.loadingTexts.length - 1
          ? self.loadingTexts.length - 1
          : self.loadingTextIndex + 1;
    }, this.animationHoldTime);
  }

  async updateCustomerMetaInformation() {
    await this.crudService
      .updateCustomerMetaInformation(this.data.customerId)
      .toPromise()
      .then((res) => {
        this.isCrawled = true;
      })
      .catch((e) => {
        this.dialogRef.close({ success: false, error: e.error });
      });
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  onClose() {
    this.dialogRef.close();
    this.router.navigateByUrl(`/mapAds/customers/show/${this.data.customerId}`);
  }
}
