export class MPGoogleDisplayMediaFormat {
  public googleDisplayMediaFormatId: number;
  public googleDisplayMediaFormatValue: string;
  public googleDisplayMediaFormatWidth: number;
  public googleDisplayMediaFormatHeight: number;
  public googleDisplayMediaFormatDisplayText: string;

  constructor(
    googleDisplayMediaFormatId: number = null,
    googleDisplayMediaFormatValue: string = null,
    googleDisplayMediaFormatWidth: number = null,
    googleDisplayMediaFormatHeight: number = null,
    googleDisplayMediaFormatDisplayText: string = null
  ) {
    this.googleDisplayMediaFormatId = googleDisplayMediaFormatId;
    this.googleDisplayMediaFormatValue = googleDisplayMediaFormatValue;
    this.googleDisplayMediaFormatWidth = googleDisplayMediaFormatWidth;
    this.googleDisplayMediaFormatHeight = googleDisplayMediaFormatHeight;
    this.googleDisplayMediaFormatDisplayText =
      googleDisplayMediaFormatDisplayText;
  }

  deserialize(serializedObj: object): MPGoogleDisplayMediaFormat {
    if (!serializedObj) {
      return null;
    }

    this.googleDisplayMediaFormatId =
      serializedObj["googleDisplayMediaFormatId"];
    this.googleDisplayMediaFormatValue =
      serializedObj["googleDisplayMediaFormatValue"];
    this.googleDisplayMediaFormatWidth =
      serializedObj["googleDisplayMediaFormatWidth"];
    this.googleDisplayMediaFormatHeight =
      serializedObj["googleDisplayMediaFormatHeight"];
    this.googleDisplayMediaFormatDisplayText =
      serializedObj["googleDisplayMediaFormatDisplayText"];

    return this;
  }
}
