import { LocationAddress } from "../LocationAddress";

export class MPCustomerLocation {
  public customerLocationId: number;
  public locationName: string;
  public locationDescription: string;
  public locationAddress: LocationAddress;
  public locationLogoId: number;
  public locationLogoUrl: string;
  public locationRadius: number;

  constructor(
    customerLocationId: number = null,
    locationName: string = null,
    locationDescription: string = null,
    locationAddress: LocationAddress = null,
    locationLogoId: number = null,
    locationLogoUrl: string = null,
    locationRadius: number = null
  ) {
    this.customerLocationId = customerLocationId;
    this.locationName = locationName;
    this.locationDescription = locationDescription;
    this.locationAddress = locationAddress;
    this.locationLogoId = locationLogoId;
    this.locationLogoUrl = locationLogoUrl;
    this.locationRadius = locationRadius;
  }

  deserialize(serializedObj: object): MPCustomerLocation {
    if (!serializedObj) {
      return null;
    }

    this.customerLocationId = serializedObj["customerLocationId"];
    this.locationName = serializedObj["locationName"];
    this.locationDescription = serializedObj["locationDescription"];
    this.locationAddress = new LocationAddress().deserialize(
      serializedObj["locationAddress"]
    );
    this.locationLogoId = serializedObj["locationLogoId"];
    this.locationLogoUrl = serializedObj["locationLogoUrl"];
    this.locationRadius = serializedObj["locationRadius"];

    return this;
  }
}
