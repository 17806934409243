import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { NavigationStart, Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { analyzeNgModules } from "@angular/compiler";
import * as moment from "moment";
import { ModuleService } from "app/shared/services/module.service";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { MatDialog } from "@angular/material";
import { PresalesPresentationDialogComponent } from "../dialogs/presales-presentation-dialog/presales-presentation-dialog.component";
import { configuration } from "configurations/configuration";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  private subscription: Subscription;
  readonly configuration = configuration;

  public imageSource: string = window.localStorage["StoreUrl"];
  public storeName: string = window.localStorage["storeName"];

  public availableLangs = [
    {
      name: "DE",
      code: "de",
      flag: "flag-icon-de",
    },
    {
      name: "EN",
      code: "en",
      flag: "flag-icon-gb",
    },
  ];

  public currentLang: any;
  readonly MODULE_SERVICE_PRESALES = MPModule.MODULE_SERVICE_PRESALES;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private router: Router,
    public moduleService: ModuleService,
    private dialog: MatDialog
  ) {
    this.currentLang = this.availableLangs.find(
      (lang) => lang.code === this.translate.currentLang
    );

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.menuItemsSub) {
          this.menuItemsSub.unsubscribe();
        }
      }
    });
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });

    this.layoutConf = this.layout.layoutConf;

    this.setLang(this.currentLang);

    this.navService.menuItems.next(this.navService.genDefaultMenu());
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "compact") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "compact",
    });
  }

  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }

  startPresention() {
    this.dialog.open(PresalesPresentationDialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        channelCreatives: null,
        customer: null,
      },
    });
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
    moment.locale(lng.code);
  }

  signOut() {
    localStorage.clear();

    this.router.navigate(["/sessions/signin"]);
  }
}
