import { MPGeoTargeting } from "./MPGeoTargeting";

export class MPGeoTargetingList {
  public geoTargeting: MPGeoTargeting[];

  constructor() {}

  public deserializeList(serializedObj: MPGeoTargeting[]): MPGeoTargeting[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPGeoTargeting) =>
      new MPGeoTargeting().deserialize(x)
    );
  }
}
