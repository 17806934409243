import { Component, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MPAgeGroup } from "app/model/marketing-portal/MPAgeGroup";
import { MPGender } from "app/model/marketing-portal/MPGender";
import { MPTopic } from "app/model/marketing-portal/MPTopic";

@Component({
  selector: "topic-demography-campaign-form-section",
  templateUrl: "./topic-demography-campaign-form-section.component.html",
  styleUrls: ["./topic-demography-campaign-form-section.component.scss"],
})
export class TopicDemographyCampaignFormSectionComponent implements OnInit {
  @Input() ageGroupIdControl: FormControl;
  @Input() genderIdControl: FormControl;
  @Input() topicIdControl: FormControl;
  @Input() ageGroups: MPAgeGroup[];
  @Input() topics: MPTopic[];
  @Input() genders: MPGender[];
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;
  public selection: number;
  readonly SELECTION_TOPIC = 1;
  readonly SELECTION_DEMOGRAPHYY = 2;
  public options: any[] = [
    {
      label: this.translate.instant("BroadcastingGroup"),
      value: this.SELECTION_TOPIC,
    },
    {
      label: this.translate.instant("Demographics"),
      value: this.SELECTION_DEMOGRAPHYY,
    },
  ];
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.initSelection();
  }

  onSelect(selection: number) {
    this.selection = selection;

    this.genderIdControl.setValue(null);
    this.ageGroupIdControl.setValue(null);
    this.topicIdControl.setValue(null);

    this.setValidations();
  }

  initSelection() {
    this.selection =
      !this.data || this.data.topic
        ? this.SELECTION_TOPIC
        : this.SELECTION_DEMOGRAPHYY;
    this.setValidations();
  }

  setValidations() {
    if (this.selection === this.SELECTION_TOPIC) {
      this.topicIdControl.setValidators(Validators.required);
      this.genderIdControl.setValidators(null);
      this.ageGroupIdControl.setValidators(null);
    } else {
      this.topicIdControl.setValidators(null);
      this.genderIdControl.setValidators(Validators.required);
      this.ageGroupIdControl.setValidators(Validators.required);
    }

    this.genderIdControl.updateValueAndValidity();
    this.ageGroupIdControl.updateValueAndValidity();
    this.topicIdControl.updateValueAndValidity();
  }
}
