export class MPDevice {
  public deviceId: number;
  public deviceDisplayName: string;
  public icon: string;

  constructor(
    deviceId: number = null,
    deviceDisplayName: string = null,
    icon: string = null
  ) {
    this.deviceId = deviceId;
    this.deviceDisplayName = deviceDisplayName;
    this.icon = icon;
  }

  deserialize(serializedObj: object): MPDevice {
    if (!serializedObj) {
      return null;
    }

    this.deviceId = serializedObj["deviceId"];
    this.deviceDisplayName = serializedObj["deviceDisplayName"];
    this.icon = serializedObj["icon"];

    return this;
  }
}
