import { Component, Input, OnInit } from "@angular/core";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";

@Component({
  selector: "customer-details-profile-check",
  templateUrl: "./customer-details-profile-check.component.html",
  styleUrls: ["./customer-details-profile-check.component.scss"],
})
export class CustomerDetailsProfileCheckComponent implements OnInit {
  @Input() customer: MPCustomer;
  constructor() {}

  ngOnInit() {}
}
