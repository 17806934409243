export class MPCustomerApprovalStatus {
  public approvalStatusId: number;
  public approvalStatusName: string;

  constructor(
    approvalStatusId: number = null,
    approvalStatusName: string = null
  ) {
    this.approvalStatusId = approvalStatusId;
    this.approvalStatusName = approvalStatusName;
  }

  deserialize(serializedObj: object): MPCustomerApprovalStatus {
    if (!serializedObj) {
      return null;
    }

    this.approvalStatusId = serializedObj["approvalStatusId"];
    this.approvalStatusName = serializedObj["approvalStatusName"];

    return this;
  }
}
