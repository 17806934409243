import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPGeoTargeting } from "app/model/marketing-portal/MPGeoTargeting";

@Component({
  selector: "gam-geo-targeting-campaign-form-section",
  templateUrl: "./gam-geo-targeting-campaign-form-section.component.html",
  styleUrls: ["./gam-geo-targeting-campaign-form-section.component.scss"],
})
export class GamGeoTargetingCampaignFormSectionComponent implements OnInit {
  @Input() geoTargets: MPGeoTargeting[];
  @Input() geoTargetingIdsControl: FormControl;
  @Input() errors: any;
  @Input() data: any;
  @Input() readonly: boolean;
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.initData();
    }
  }

  initData() {
    if (this.data) {
      let value: number[] = this.geoTargetingIdsControl.value || [];

      this.data.geoTargeting.forEach((geo: MPGeoTargeting) => {
        value.push(geo.id);
      });

      this.geoTargetingIdsControl.setValue(value);
    }
  }

  onSelect(geoTarget: MPGeoTargeting) {
    if (this.readonly) {
      return;
    }

    let value: number[] = this.geoTargetingIdsControl.value || [];
    let isSelected = value && value.includes(geoTarget.id);

    if (!isSelected) {
      // Add selected item
      value.push(geoTarget.id);
    } else {
      // Remove selected item
      let index = value.indexOf(geoTarget.id);
      value.splice(index, 1);
    }

    this.geoTargetingIdsControl.setValue(value);
  }
}
