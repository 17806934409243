import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "approval-status",
  templateUrl: "./approval-status.component.html",
  styleUrls: ["./approval-status.component.scss"],
})
export class ApprovalStatusComponent implements OnInit {
  @Input() approvalStatus;
  @Input() size?: string;
  readonly APPROVAL_STATUS_ID_PENDING: number = 0;
  readonly APPROVAL_STATUS_ID_REJECTED: number = 1;
  readonly APPROVAL_STATUS_ID_APPROVED: number = 2;
  public colors: object = {
    2: {
      backgroundColor: "background-primary-light",
      color: "primary-color",
    },
    0: {
      backgroundColor: "background-purple-200",
      color: "purple-600-color",
    },
    1: {
      backgroundColor: "background-danger-light",
      color: "danger-color",
    },
  };
  constructor() {}

  ngOnInit() {}
}
