import { Component, Input, OnInit } from "@angular/core";
import { MPSound } from "app/model/marketing-portal/MPSound";

@Component({
  selector: "marketing-portal-video-editor-sound-configuration-toolset",
  templateUrl:
    "./marketing-portal-video-editor-sound-configuration-toolset.component.html",
  styleUrls: [
    "./marketing-portal-video-editor-sound-configuration-toolset.component.scss",
  ],
})
export class MarketingPortalVideoEditorSoundConfigurationToolsetComponent
  implements OnInit
{
  @Input() selectedSound: MPSound;
  @Input() templateConfigModel: any;
  @Input() sounds: MPSound[];
  public selectedSoundId: number;
  public isOpen: boolean = true;
  public audioPlay: any;
  public isPlaying: boolean = false;
  constructor() {}

  ngOnInit() {
    this.selectedSoundId = this.selectedSound.Id;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  onPlay() {
    this.audioPlay = new Audio(this.selectedSound.Link);
    this.audioPlay.play();
    this.isPlaying = true;

    const self = this;
    this.audioPlay.onended = function () {
      self.isPlaying = false;
    };
  }

  onPause() {
    this.audioPlay.pause();
    this.isPlaying = false;
  }

  onChangeSound($event) {
    const soundId: number = $event.value;

    this.templateConfigModel.Sound = this.sounds.find(
      (sound) => sound.Id === soundId
    );
  }
}
