import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MPTopic } from "app/model/marketing-portal/MPTopic";

@Component({
  selector: "topic-campaign-form-section",
  templateUrl: "./topic-campaign-form-section.component.html",
  styleUrls: ["./topic-campaign-form-section.component.scss"],
})
export class TopicCampaignFormSectionComponent implements OnInit {
  @Input() topicIdControl: FormControl;
  @Input() topics: MPTopic[];
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() errors: any;
  constructor() {}

  ngOnInit() {
    if (this.data && this.data.topic) {
      this.initData();
    }
  }

  initData() {
    this.topicIdControl.setValue(this.data.topic.topicId);
  }

  onClick(topicId: number) {
    this.topicIdControl.setValue(topicId);
  }
}
