import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GAMMediaAssetFormat } from "app/model/marketing-portal/GAMMediaAssetFormat";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { environment } from "environments/environment";
import { GamCampaignTextFormComponent } from "../../forms/text/gam-campaign-text-form/gam-campaign-text-form.component";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { CrudService } from "app/views/mapAds/crud.service";
import { MatDialog } from "@angular/material";
import { MarketingPortalEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";

@Component({
  selector: "gam-media-asset-action-card",
  templateUrl: "./gam-media-asset-action-card.component.html",
  styleUrls: ["./gam-media-asset-action-card.component.scss"],
})
export class GamMediaAssetActionCardComponent implements OnInit {
  @ViewChild(GamCampaignTextFormComponent)
  gamCampaignTextFormComponent: GamCampaignTextFormComponent;
  @Output() onDelete = new EventEmitter<{
    gamMediaAssetFormat: GAMMediaAssetFormat;
    index: number;
  }>();
  @Output() onChange = new EventEmitter<{
    texts: any;
    index: number;
  }>();
  @Output() onChangeMediaAsset = new EventEmitter<{
    mediaAssetId: number;
    index: number;
  }>();
  @Input() gamMediaAssetFormat: GAMMediaAssetFormat;
  @Input() mediaFormats: GAMMediaFormat[];
  @Input() index: number;
  @Input() customer: MPCustomer;
  @Input() readonly?: boolean = false;
  @Input() channelId: number;
  @Input() resolutionId: number;
  public mediaFormat: GAMMediaFormat;
  public mediaAsset: MPMediaAsset = null;
  public isEditMode: boolean = false;
  public isLoading: boolean = true;
  readonly environment = environment;
  constructor(private crudService: CrudService, private dialog: MatDialog) {}

  ngOnInit() {
    this.initData();
  }

  onEditMediaAsset($event) {
    if (this.mediaAsset.isImage) {
      this.openEditor(this.mediaAsset);
      return;
    }
  }

  onTriggerChange() {}

  async initData() {
    this.mediaFormat = this.mediaFormats.find(
      (item: GAMMediaFormat) =>
        item.id === this.gamMediaAssetFormat.mediaFormatId
    );

    const mediaAssetInformation = await this.crudService
      .getMediaAssetInformation(this.gamMediaAssetFormat.mediaAssetId)
      .toPromise();

    this.mediaAsset = this.getMediaAssetObj(mediaAssetInformation);

    this.isLoading = false;
  }

  getMediaAssetObj(mediaAssetInformation) {
    return new MPMediaAsset(
      mediaAssetInformation.mediaAssetId,
      mediaAssetInformation.mediaAssetName,
      mediaAssetInformation.width,
      mediaAssetInformation.height,
      mediaAssetInformation.sizeInByte,
      mediaAssetInformation.mediaAssetDataType,
      mediaAssetInformation.mediaAssetIsTransparent,
      mediaAssetInformation.isImage,
      mediaAssetInformation.isVideo,
      mediaAssetInformation.isReady,
      mediaAssetInformation.mediaAssetUpdateTime,
      mediaAssetInformation.mediaAssetData,
      mediaAssetInformation.mediaAssetUrl,
      mediaAssetInformation.mediaAssetThumbnailUrl,
      mediaAssetInformation.isPartOfSet,
      mediaAssetInformation.templateId
    );
  }

  async openEditor(mediaAsset: MPMediaAsset = null) {
    const dialogRef = this.dialog.open(MarketingPortalEditorDialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.customer.customerId,
        channelId: this.channelId,
        mediaAsset: mediaAsset,
        resolutionId: this.mediaFormat.resolutionId,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const { templateMediaAssets, data } = res.templateMediaAssets;

        const templateMediaAsset = templateMediaAssets[0];

        const id = templateMediaAsset.imageId;
        const name = templateMediaAsset.displayName;
        const formatId = templateMediaAsset.formatId
          ? templateMediaAsset.formatId
          : null;
        const isPartOfSet = data.hasOwnProperty("templateSetId");
        const templateId = data.hasOwnProperty("templateSetId")
          ? data.templateSetId
          : data.templateId;
        const mediaAssetUrl =
          environment.imageServerUrl + "api/Public/GetMediaAsset?id=" + id;
        const mediaAssetThumbnailUrl = mediaAssetUrl + "&width=300&height=300";

        const newMediaAsset = new MPMediaAsset(
          id,
          name,
          null,
          null,
          null,
          "image/png",
          false,
          true,
          false,
          true,
          null,
          null,
          mediaAssetUrl,
          mediaAssetThumbnailUrl,
          isPartOfSet,
          templateId,
          formatId
        );

        this.mediaAsset = newMediaAsset;

        this.onChangeMediaAsset.emit({
          mediaAssetId: newMediaAsset.mediaAssetId,
          index: this.index,
        });
      }
    });
  }

  submitted($event) {
    this.onChange.emit({ texts: $event, index: this.index });

    this.isEditMode = false;
  }

  onSave() {
    this.gamCampaignTextFormComponent.onSubmit();
  }
}
