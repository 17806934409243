import { Component, Input, OnInit } from "@angular/core";
import { MPGoalAndPerformance } from "app/model/marketing-portal/MPGoalAndPerformance.js";
import { SelectMenu } from "app/model/SelectMenu";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { colors } from "../../../statistics/channel-statistics-colors.js";

@Component({
  selector: "mc-age-statistics",
  templateUrl: "./mc-age-statistics.component.html",
  styleUrls: ["./mc-age-statistics.component.scss"],
})
export class McAgeStatisticsComponent implements OnInit {
  @Input() ageRangeStatistics: any;
  @Input() goal: MPGoalAndPerformance;
  public statisticValues: any;
  public channelMenuItems: SelectMenu[];

  public barChartOptions: ChartOptions = {
    responsiveAnimationDuration: 500,
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 500,
      easing: "linear",
    },
    devicePixelRatio: 2,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return "";
        },
        font: {
          family: "'Roboto'",
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ": " +
            tooltipItem.yLabel.toLocaleString()
          );
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: "#aaacb9",
            fontFamily: "'Roboto'",
          },
          gridLines: {
            color: "#f0f0f0",
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: "#aaacb9",
            fontFamily: "'Roboto'",
            min: 0,
            beginAtZero: true,
            callback: function (label, index, labels) {
              return label.toLocaleString();
            },
          },
          gridLines: {
            color: "#f0f0f0",
          },
        },
      ],
    },
  };

  public barChartLabels: string[] = [];
  public barChartType: ChartType = "bar";
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [];

  constructor() {}

  ngOnInit() {
    if (this.ageRangeStatistics.length) {
      this.initFilters();
      this.setData();
    }
  }

  initFilters() {
    this.channelMenuItems = [];

    this.ageRangeStatistics.forEach((statistic) => {
      const channelName = statistic.ChannelName;
      const cleanChannelName = channelName.split(" ")[0];
      this.channelMenuItems.push({
        label: channelName,
        value: channelName.toUpperCase(),
        icon: cleanChannelName.toLowerCase() + "Logo",
        isSelected: true,
      });
    });
  }

  setData() {
    if (!this.ageRangeStatistics.length) {
      return;
    }

    const goalName = this.goal.goalInternalName
      ? this.goal.goalInternalName
      : "Impressions"; //: "Clicks"
    this.barChartLabels = [];
    this.barChartData = [];

    this.ageRangeStatistics[0].ChannelStatistics.forEach((channelStatistic) => {
      this.barChartLabels.push(channelStatistic.Value);
    });

    this.ageRangeStatistics.forEach((ageRangeStatistic, index) => {
      const channelName = ageRangeStatistic.ChannelName;
      const menuItem = this.channelMenuItems.find(
        (item) => item.label === channelName
      );

      if (menuItem.isSelected) {
        const data = [];
        ageRangeStatistic.ChannelStatistics.forEach((channelStatistic, i) => {
          data.push(channelStatistic.Statistics[goalName]);
        });

        const cleanChannelName = channelName.split(" ")[0];

        this.barChartData.push({
          data: data,
          label: channelName,
          stack: index,
          backgroundColor: colors[cleanChannelName.toUpperCase()],
          hoverBackgroundColor: colors[cleanChannelName.toUpperCase()],
          borderColor: colors[cleanChannelName.toUpperCase()],
        });
      }
    });
  }

  selectChannelMenu(menuItem: SelectMenu) {
    this.channelMenuItems.forEach((item, i) => {
      if (item.value === menuItem.value) {
        this.channelMenuItems[i].isSelected = !item.isSelected;
      }
    });

    this.setData();
  }
}
