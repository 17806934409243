import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { configuration } from "configurations/configuration";

@Component({
  selector: "app-support-popover",
  templateUrl: "./support-popover.component.html",
  styleUrls: ["./support-popover.component.scss"],
})
export class SupportPopoverComponent implements OnInit {
  readonly configuration = configuration;

  constructor(
    public dialogRef: MatDialogRef<SupportPopoverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose(): void {
    this.dialogRef.close();
  }
}
