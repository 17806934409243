import { MPDevice } from "./MPDevice";

export class MPDeviceList {
  public devices: MPDevice[];

  constructor() {}

  public deserializeList(serializedObj: MPDevice[]): MPDevice[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPDevice) => new MPDevice().deserialize(x));
  }
}
