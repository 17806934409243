import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "select-image-from-selection",
  templateUrl: "./select-image-from-selection.component.html",
  styleUrls: ["./select-image-from-selection.component.scss"],
})
export class SelectImageFromSelectionComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter(true);
  @Input() images: any;
  @Input() isEditor: boolean;
  @Input() selectedImage: any;
  constructor() {}

  ngOnInit() {}
}
