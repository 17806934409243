import { Component, OnInit, AfterViewInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { RoutePartsService } from "./shared/services/route-parts.service";
import { ThemeService } from "./shared/services/theme.service";

import { filter } from "rxjs/operators";
import { LayoutService } from "./shared/services/layout.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { configuration } from "configurations/configuration";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  readonly configuration = configuration;
  appTitle = configuration.appName;
  pageTitle = "";

  constructor(
    private translate: TranslateService,
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private layout: LayoutService,
    private renderer: Renderer2
  ) {
    var defaultLng = "de";
    if (navigator.language) {
      defaultLng = navigator.language.split("-")[0];
    }

    //not supported language?
    if (defaultLng != "de" && defaultLng != "en") defaultLng = "en"; //TODO!! dynamic

    this.translate.setDefaultLang(defaultLng);
    this.translate.use(defaultLng);
    moment.locale(defaultLng);
  }

  ngOnInit() {
    this.changePageTitle();
  }

  ngAfterViewInit() {
    this.layout.applyMatTheme(this.renderer);
  }

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        var routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) return this.title.setTitle(this.appTitle);
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${this.translate.instant(partI)}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
