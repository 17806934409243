import { Component, Input, OnInit } from "@angular/core";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";

@Component({
  selector: "campaign-creative-gdn-ads-preview",
  templateUrl: "./campaign-creative-gdn-ads-preview.component.html",
  styleUrls: ["./campaign-creative-gdn-ads-preview.component.scss"],
})
export class CampaignCreativeGdnAdsPreviewComponent implements OnInit {
  @Input() mediaAssetUrl: string;

  @Input() businessName: string;
  @Input() description: string;
  @Input() headline: string;
  @Input() customer: MPCustomer;

  constructor() {}

  ngOnInit() {}
}
