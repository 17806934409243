import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "approval-history-collapse-list-item",
  templateUrl: "./approval-history-collapse-list-item.component.html",
  styleUrls: ["./approval-history-collapse-list-item.component.scss"],
})
export class ApprovalHistoryCollapseListItemComponent implements OnInit {
  @Input() approval: any;
  @Input() index: number;
  @Input() count: number;
  public position: number;
  public isOpen: boolean = false;
  public showChanges: boolean = false;

  public thumbsUpCount: number;
  public thumbsDownCount: number;
  public commentCount: number;
  constructor() {}

  ngOnInit() {
    this.setInitIsOpen();
    this.setPosition();
    this.setCounts();
  }

  setInitIsOpen() {
    this.isOpen = this.index === 0;
  }

  setPosition() {
    this.position = this.count - this.index;
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen;
  }

  changeShowChanges($event) {
    this.showChanges = $event.checked;
  }

  setCounts() {
    this.thumbsUpCount = 0;
    this.thumbsDownCount = 0;
    this.commentCount = 0;

    const elements = this.approval.mediaAssetElements.concat(
      this.approval.textElements
    );

    elements.forEach((element) => {
      if (element.decision === true) {
        this.thumbsUpCount++;
      }

      if (element.decision === false) {
        this.thumbsDownCount++;
      }

      if (element.note) {
        this.commentCount++;
      }
    });
  }
}
