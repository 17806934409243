import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-templates-grid",
  templateUrl: "./marketing-portal-templates-grid.component.html",
  styleUrls: ["./marketing-portal-templates-grid.component.scss"],
})
export class MarketingPortalTemplatesGridComponent implements OnInit {
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  @Input() marketingPortalTemplates;
  @Input() isVideo?: boolean = false;
  constructor() {}

  ngOnInit() {}
}
