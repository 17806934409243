import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { CustomValidators } from "ng2-validation";

@Component({
  selector: "change-password-form",
  templateUrl: "./change-password-form.component.html",
  styleUrls: ["./change-password-form.component.scss"],
})
export class ChangePasswordFormComponent implements OnInit {
  public errors: Object;
  public showPasswordPopover: boolean;
  public passwordCheck: Object;
  public hidePassword: boolean;
  public hidePasswordConfirm: boolean;
  public passwordForm: FormGroup;
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private crudService: CrudService
  ) {}

  ngOnInit() {
    this.errors = {};
    this.showPasswordPopover = false;
    this.passwordCheck = {};
    this.hidePassword = true;
    this.hidePasswordConfirm = true;
    this.buildDefaultForm();
  }

  togglePasswordVisibility($event) {
    $event.stopPropagation();
    this.hidePassword = !this.hidePassword;
  }

  togglePasswordConfirmVisibility($event) {
    $event.stopPropagation();
    this.hidePasswordConfirm = !this.hidePasswordConfirm;
  }

  checkPasswordRequirements(value) {
    this.passwordCheck = ValidationService.checkPasswordRequirements(value);
    this.updateFormValidation("NewPassword");
  }

  openPasswordPopover($event) {
    this.checkPasswordRequirements($event.target.value);
    this.showPasswordPopover = true;
  }

  onBlurPassword() {
    this.showPasswordPopover = false;
    this.updateFormValidation("NewPassword");
  }

  updateFormValidation(field: string) {
    this.errors = this.getFormValidationErrors(this.passwordForm, field);
  }

  getFormValidationErrors(formGroup: FormGroup, field: string = null): Object {
    const result: Object = field != null ? this.errors : {};

    Object.keys(formGroup.controls).forEach((key) => {
      if (field === null || field === key) {
        const control = formGroup.get(key);
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          const error = Object.keys(control.errors)[0];
          result[key] = ValidationService.getValidatorErrorMessage(
            error,
            this.translate
          );
        }
      }
    });

    return result;
  }

  buildDefaultForm() {
    const oldPassword = new FormControl("", [Validators.required]);
    const password = new FormControl("", [
      Validators.required,
      ValidationService.passwordValidator,
    ]);
    const confirmPassword = new FormControl("", [
      Validators.required,
      CustomValidators.equalTo(password),
    ]);

    this.passwordForm = this.fb.group({
      OldPassword: oldPassword,
      NewPassword: password,
      ConfirmPassword: confirmPassword,
    });
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  async onSumbit(dialogRef: any) {
    this.markFormControls(this.passwordForm.controls);

    if (this.passwordForm.invalid) {
      this.errors = this.getFormValidationErrors(this.passwordForm);

      return;
    }

    this.appLoader.open();

    await this.crudService
      .changePassword(this.passwordForm.value)
      .toPromise()
      .then((res) => {
        this.snackbarService.show(
          this.translate.instant("PasswordChangedSuccess"),
          "success"
        );
        dialogRef.close();
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });
    this.appLoader.close();
  }
}
