import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { GamCampaignTextFormComponent } from "../../forms/text/gam-campaign-text-form/gam-campaign-text-form.component";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";
import { environment } from "environments/environment";

@Component({
  selector: "add-gam-media-asset-texts-dialog",
  templateUrl: "./add-gam-media-asset-texts-dialog.component.html",
  styleUrls: ["./add-gam-media-asset-texts-dialog.component.scss"],
})
export class AddGamMediaAssetTextsDialogComponent implements OnInit {
  @ViewChild(GamCampaignTextFormComponent)
  gamCampaignTextFormComponent: GamCampaignTextFormComponent;
  public index: number = 0;
  public mediaFormat: GAMMediaFormat;
  public previewImage: string;
  public environment = environment;

  constructor(
    public dialogRef: MatDialogRef<AddGamMediaAssetTextsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.setMediaFormat();
    this.setPreviewImage();
  }

  setMediaFormat() {
    this.mediaFormat = this.data.mediaFormats.find(
      (mediaFormat: GAMMediaFormat) =>
        mediaFormat.id ===
        this.data.gamMediaAssetFormats[this.index].mediaFormatId
    );
  }

  setPreviewImage() {
    this.previewImage =
      environment.imageServerUrl +
      "api/Public/GetMediaAsset?id=" +
      this.data.gamMediaAssetFormats[this.index].mediaAssetId +
      "&width=300&height=300";
  }

  onClose() {
    this.dialogRef.close();
  }

  submitted($event) {
    const texts = $event;

    if (texts.hasOwnProperty("headline")) {
      this.data.gamMediaAssetFormats[this.index]["headline"] = texts.headline;
    }

    if (texts.hasOwnProperty("body")) {
      this.data.gamMediaAssetFormats[this.index]["body"] = texts.body;
    }

    if (this.index + 1 >= this.data.gamMediaAssetFormats.length) {
      this.dialogRef.close(this.data.gamMediaAssetFormats);
      return;
    }

    this.index++;
    this.setPreviewImage();
    this.setMediaFormat();
  }

  onSave() {
    this.gamCampaignTextFormComponent.onSubmit();
  }
}
