import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { PexelVideo } from "app/model/stock/PexelVideo";

@Component({
  selector: "pexel-video-card",
  templateUrl: "./pexel-video-card.component.html",
  styleUrls: ["./pexel-video-card.component.scss"],
})
export class PexelVideoCardComponent implements OnInit {
  @ViewChild("image") image: ElementRef;
  @ViewChild("videoPlayer") videoPlayer: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() video: PexelVideo;
  @Input() isSelected: boolean;
  public sanitizedCredits: SafeHtml;
  public thumbnailLink: string;
  public isPlayingVideo: boolean = false;
  public playProgress: number = 0;
  public playTimeProgressInterval: any = null;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.sanitizeCredits();
    this.modifyThumbnailLink();
  }

  sanitizeCredits() {
    this.sanitizedCredits = this.sanitizer.bypassSecurityTrustHtml(
      this.video.CreditText
    );
  }

  /*
   *  Set the height and width of the preview image
   *  to the height and width of the video.
   */
  modifyThumbnailLink() {
    const url = new URL(this.video.ThumbnailLink);
    url.searchParams.set("w", this.video.Width.toString());
    url.searchParams.set("h", this.video.Height.toString());

    this.thumbnailLink = url.toString();
  }

  enter() {
    this.setVideoSize();
    this.playVideo();
  }

  leave() {
    this.stopVideo();
  }

  playVideo() {
    this.isPlayingVideo = true;
    this.videoPlayer.nativeElement.muted = true;
    this.videoPlayer.nativeElement.play();

    this.updatePlayTimeProgress();
  }

  stopVideo() {
    this.isPlayingVideo = false;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;

    this.playProgress = 0;

    clearInterval(this.playTimeProgressInterval);
  }

  setVideoSize() {
    this.videoPlayer.nativeElement.style.height = this.getImageHeight() + "px";
    this.videoPlayer.nativeElement.style.width = this.getImageWidth() + "px";
  }

  getImageHeight(): number {
    return this.image.nativeElement.clientHeight;
  }

  getImageWidth(): number {
    return this.image.nativeElement.clientWidth;
  }

  updatePlayTimeProgress() {
    const self = this;
    this.playTimeProgressInterval = setInterval(function () {
      self.playProgress =
        (self.videoPlayer.nativeElement.currentTime / self.video.Duration) *
        100;

      if (self.playProgress >= 100) {
        self.playProgress = 100;
        clearInterval(self.playTimeProgressInterval);
      }
    }, 50);
  }
}
