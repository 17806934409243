export enum MPConfiguratorStep {
  CUSTOMER = 1,
  OBJECTIVES = 2,
  CHANNEL = 3,
  MEDIA_TYPE = 4,
  MAIN_ACTION = 5,
  TEMPLATE = 6,
  CREATIVE_PRESENTATION = 8,
  CONFIGURATOR = 9,
}
