import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as WebFont from "webfontloader";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";

@Component({
  selector: "marketing-portal-editor-font-configuration-toolset",
  templateUrl:
    "./marketing-portal-editor-font-configuration-toolset.component.html",
  styleUrls: [
    "./marketing-portal-editor-font-configuration-toolset.component.scss",
  ],
})
export class MarketingPortalEditorFontConfigurationToolsetComponent
  implements OnInit
{
  @Output() markSlidesAsEdited: EventEmitter<any> = new EventEmitter();
  @Input() templateConfigModel: any;
  @Input() fontFamily: any;
  @Input() fontWeight: any;
  @Input() fontFamilies: MPFontFamily[];
  @Input() fontWeights: MPFontWeight[];
  public isOpen: boolean = true;
  public selectedFontFamilyId: number;
  public selectedFontWeightId: number;
  constructor() {}

  ngOnInit(): void {
    if (this.fontFamily) this.selectedFontFamilyId = this.fontFamily.Id;
    if (this.fontWeight) this.selectedFontWeightId = this.fontWeight.Id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fontFamilies) {
      this.loadFonts();
    }
  }

  loadFonts() {
    const fontConfig: string[] = this.getFontConfig();

    WebFont.load({
      google: {
        families: fontConfig,
      },
    });
  }

  getFontConfig() {
    const fontWeights = this.getFontWeightNames();

    return this.fontFamilies.map((fontFamily) => {
      return `${fontFamily.fontName}:${fontWeights.join(",")}`;
    });
  }

  getFontFamilyNames() {
    return this.fontFamilies.map((fontFamily) => fontFamily.fontName);
  }

  getFontWeightNames() {
    if (this.fontWeights) {
      return this.fontWeights.map((fontWeight) => fontWeight.fontWeightName);
    } else return ["400"]; //fallback
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  onChangeFontFamily($event) {
    const id: number = $event.value;

    this.templateConfigModel.FontFamily = this.fontFamilies.find(
      (fontFamily) => fontFamily.Id === id
    );

    this.markSlidesAsEdited.emit();
  }

  onChangeFontWeight($event) {
    if (this.fontWeights) {
      const id: number = $event.value;

      this.templateConfigModel.FontWeight = this.fontWeights.find(
        (fontWeight) => fontWeight.Id === id
      );

      this.markSlidesAsEdited.emit();
    }
  }
}
