import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "marketing-portal-details-time-period",
  templateUrl: "./marketing-portal-details-time-period.component.html",
  styleUrls: ["./marketing-portal-details-time-period.component.scss"],
})
export class MarketingPortalDetailsTimePeriodComponent implements OnInit {
  @Input() campaign: any;
  public daysDifference: number;
  public dateTimeDisplayName: string;

  constructor() {}

  ngOnInit() {
    this.calcDaysDifference();

    this.dateTimeDisplayName = this.campaign.dateTimeDisplayName.replaceAll(
      "00:00",
      ""
    );
  }

  calcDaysDifference() {
    if (!this.campaign.end) {
      return;
    }

    const startDate = new Date(this.campaign.start);
    const endDate = new Date(this.campaign.end);
    const difference = endDate.getTime() - startDate.getTime();

    this.daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
  }
}
