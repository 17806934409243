import { Component, Input, OnInit } from "@angular/core";
import { MPGeoTargeting } from "app/model/marketing-portal/MPGeoTargeting";

@Component({
  selector: "gam-geo-targeting-card",
  templateUrl: "./gam-geo-targeting-card.component.html",
  styleUrls: ["./gam-geo-targeting-card.component.scss"],
})
export class GamGeoTargetingCardComponent implements OnInit {
  @Input() geoTarget: MPGeoTargeting;
  @Input() isSelected: boolean;
  @Input() readonly: boolean = false;
  constructor() {}

  ngOnInit() {}
}
