import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaign-creative-youtube-preview",
  templateUrl: "./campaign-creative-youtube-preview.component.html",
  styleUrls: ["./campaign-creative-youtube-preview.component.scss"],
})
export class CampaignCreativeYoutubePreviewComponent implements OnInit {
  @Input() mediaAssetUrl: string;
  @Input() headline: string;
  @Input() url: string;
  @Input() customerLogoThumbnailUrl: string;
  public shortUrl: string;
  constructor() {}

  ngOnInit() {
    this.shortUrl = this.url
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      .split("/")[0];
  }
}
