import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "form-info-message",
  templateUrl: "./form-info-message.component.html",
  styleUrls: ["./form-info-message.component.scss"],
})
export class FormInfoMessageComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  constructor() {}

  ngOnInit() {}
}
