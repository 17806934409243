import { Component, Input, OnInit } from "@angular/core";
import { MPLocation } from "app/model/marketing-portal/MPLocation";

@Component({
  selector: "marketing-portal-location-display",
  templateUrl: "./marketing-portal-location-display.component.html",
  styleUrls: ["./marketing-portal-location-display.component.scss"],
})
export class MarketingPortalLocationDisplayComponent implements OnInit {
  @Input() location: MPLocation;
  public showMap: boolean = false;
  constructor() {}

  ngOnInit() {}

  toggleOpenMap() {
    this.showMap = !this.showMap;
  }
}
