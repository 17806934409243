import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPChannel } from "./MPChannel";
import { MPCustomer } from "./MPCustomer";
import { MPCustomerApprovalStatus } from "./MPCustomerApprovalStatus";
import { MPLocation } from "./MPLocation";
import { MPMediaAsset } from "../media-asset/MPMediaAsset";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";

export class MPCampaign {
  public mpCampaignId: number;
  public dateTimeDisplayName: string;
  public start: Date;
  public end: Date;
  public status: MPCampaignStatus;
  public name: string;
  public displayBudget: number;
  public mediaAssets: MPMediaAsset[];
  public locations: MPLocation[];
  public createdOn: Date;
  public customer: MPCustomer;
  public showApproalButton: boolean;
  public channelId: number;
  public channelLogo: number;
  public channelName: string;
  public isPayed: boolean;
  public customerApprovalOpen: boolean;
  public customerApprovalStatus: MPCustomerApprovalStatus;
  public campaignStatistics: MPCampaignStatistics;
  public hasNewNotifications: boolean;
  public appliedCoupon: string;
  public publicStatisticsUrl: string;
  public goalAndPerformance: MPGoalAndPerformance;
  public manualBudget: number;
  public isBudgetCheckoutNeeded: boolean;

  static INTERN_START_TIME_BUFFER = 3;
  constructor(
    mpCampaignId: number = null,
    dateTimeDisplayName: string = null,
    start: Date = null,
    end: Date = null,
    status: MPCampaignStatus = null,
    name: string = null,
    displayBudget: number = null,
    mediaAssets: MPMediaAsset[] = [],
    locations: MPLocation[] = [],
    createdOn: Date = null,
    customer: MPCustomer = null,
    showApprovalButton: boolean = null,
    channelId: number = null,
    channelLogo: number = null,
    channelName: string = null,
    isPayed: boolean = null,
    customerApprovalOpen: boolean = null,
    customerApprovalStatus: MPCustomerApprovalStatus = null,
    campaignStatistics: MPCampaignStatistics = null,
    hasNewNotifications: boolean = null,
    appliedCoupon: string = null,
    publicStatisticsUrl: string = null,
    goalAndPerformance: MPGoalAndPerformance = null,
    manualBudget: number = null,
    isBudgetCheckoutNeeded: boolean = true
  ) {
    this.mpCampaignId = mpCampaignId;
    this.dateTimeDisplayName = dateTimeDisplayName;
    this.start = start;
    this.end = end;
    this.status = status;
    this.name = name;
    this.displayBudget = displayBudget;
    this.mediaAssets = mediaAssets;
    this.locations = locations;
    this.createdOn = createdOn;
    this.customer = customer;
    this.showApproalButton = showApprovalButton;
    this.channelId = channelId;
    this.channelLogo = channelLogo;
    this.channelName = channelName;
    this.isPayed = isPayed;
    this.customerApprovalOpen = customerApprovalOpen;
    this.customerApprovalStatus = customerApprovalStatus;
    this.campaignStatistics = campaignStatistics;
    this.hasNewNotifications = hasNewNotifications;
    this.appliedCoupon = appliedCoupon;
    this.publicStatisticsUrl = publicStatisticsUrl;
    this.goalAndPerformance = goalAndPerformance;
    this.manualBudget = manualBudget;
    this.isBudgetCheckoutNeeded = isBudgetCheckoutNeeded;
  }

  hasBudget(): boolean {
    return this.displayBudget != 0 && this.displayBudget != null;
  }

  isPost() {
    return this.channelId === MPChannel.CHANNEL_ID_FACEBOOK_INSTAGRAM_POST;
  }

  isSMAd() {
    return this.channelId === MPChannel.CHANNEL_ID_FACEBOOK_INSTAGRAM_ADS;
  }

  isBoosted() {
    return this.isPost() && this.hasBudget();
  }

  canBeDeleted(checkoutNeeded: boolean): boolean {
    return (
      this.isDraft() ||
      this.isExpired() ||
      this.isCustomerDeclined() ||
      this.isDeclined() ||
      (this.isScheduled() && !checkoutNeeded)
    );
  }

  canBeEdited() {
    return !this.isInternTimeBufferExceeded() || this.isExpired();
  }

  approvalRequestCanBeSent(): boolean {
    return (
      (this.isDraft() || this.isScheduled() || this.isCustomerDeclined()) &&
      !!this.customer.customerId
    );
  }

  isDraft(): boolean {
    return this.status.statusId == MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_DRAFT;
  }

  isScheduled(): boolean {
    return (
      this.status.statusId === MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_SCHEDULED
    );
  }

  isExpired(): boolean {
    return (
      this.status.statusId == MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_EXPIRED
    );
  }

  isDeclined(): boolean {
    return (
      this.status.statusId == MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_DECLINED
    );
  }

  isCustomerDeclined(): boolean {
    return (
      this.status.statusId ==
      MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED
    );
  }

  isInternTimeBufferExceeded(): boolean {
    const dateInFuture = new Date();
    dateInFuture.setDate(
      dateInFuture.getDate() + MPCampaign.INTERN_START_TIME_BUFFER
    );

    return dateInFuture > new Date(this.start);
  }
}
