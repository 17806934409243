import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SavedPaymentMethod } from "app/model/payment/SavedPaymentMethod";

@Component({
  selector: "payment-method-card",
  templateUrl: "./payment-method-card.component.html",
  styleUrls: ["./payment-method-card.component.scss"],
})
export class PaymentMethodCardComponent implements OnInit {
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Input() paymentMethod: SavedPaymentMethod;
  constructor() {}

  ngOnInit() {}
}
