import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "ma-extended-configurator-goals",
  templateUrl: "./extended-configurator-goals.component.html",
  styleUrls: ["./extended-configurator-goals.component.scss"],
})
export class ExtendedConfiguratorGoalsComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() configuratorHeaderHeight: number;
  public contentHeight: number;
  public objectives: MPObjective[];

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getObjectives();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  async getObjectives() {
    setTimeout(() => this.appLoader.open());

    await this.crudService
      .getChannelObjectives()
      .toPromise()
      .then((res) => {
        this.objectives = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    setTimeout(() => this.appLoader.close());
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;

    windowHeight -= this.configuratorHeaderHeight;

    this.contentHeight = windowHeight;
  }
}
