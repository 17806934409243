import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaign-channel-action-card-small",
  templateUrl: "./campaign-channel-action-card-small.component.html",
  styleUrls: ["./campaign-channel-action-card-small.component.scss"],
})
export class CampaignChannelActionCardSmallComponent implements OnInit {
  @Input() channel: any;
  @Input() height: number = 200;
  @Input() imageSize: number = 60;
  @Input() horizontal: boolean = false;
  constructor() {}

  ngOnInit() {}
}
