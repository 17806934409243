import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-import-marketing-portal-editor-video-file-dialog",
  templateUrl:
    "./import-marketing-portal-editor-video-file-dialog.component.html",
  styleUrls: [
    "./import-marketing-portal-editor-video-file-dialog.component.scss",
  ],
})
export class ImportMarketingPortalEditorVideoFileDialogComponent
  implements OnInit
{
  public columns: number = 3;
  public acceptTypes: string;

  @ViewChild("fileInput") fileInput: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ImportMarketingPortalEditorVideoFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data && this.data.hasOwnProperty("hideOptions")) {
      this.columns -= this.data.hideOptions.length;
    }
    if (this.data && this.data.hasOwnProperty("acceptTypes")) {
      this.acceptTypes = this.data.acceptTypes;
    }
  }

  async emitFiles(event, isDragEvent) {
    this.dialogRef.close({
      type: "CUSTOM_VIDEO",
      event: event,
      isDragEvent: isDragEvent,
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply(type) {
    if (type === "CUSTOM_VIDEO") {
      this.fileInput.nativeElement.click(); //Safari bug
    } else {
      this.dialogRef.close({ type });
    }
  }
}
