import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyService } from "./currency.service";

export class ValidationService {
  static getValidatorErrorMessage(
    validatorName: string,
    translate: TranslateService,
    validatorValue: any = {}
  ) {
    let config = {
      required: translate.instant("Required"),
      invalidCreditCard: translate.instant("CheckCreditcard"),
      invalidEmailAddress: translate.instant("CheckEmail"),
      invalidPassword: translate.instant("CheckPassword"),
      invalidPLZ: translate.instant("CheckPLZ"),
      minLength:
        translate.instant("CheckMinLength") +
        " ${validatorValue.requiredLength}",
      equalTo: translate.instant("CheckConfirmPassword"),
      invalidURL: translate.instant("CheckImprint"),
      dateNotInFuture: translate.instant("CheckDateInFuture"),
      startDateLessThanEndDate: translate.instant(
        "CheckStartDateLessThanEndDate"
      ),
      discountGreaterThanPrice: translate.instant(
        "CheckPriceGreaterThanDiscount"
      ),
      priceGreaterThanZero: translate.instant("CheckGreaterThanZero"),
      invalidNumber: translate.instant("CheckNumber"),
      minNumber: translate.instant("CheckMinNumber") + validatorValue.min,
      maxNumber: translate.instant("CheckMaxNumber") + validatorValue.max,
      minPriceNumber: translate.instant("CheckMinPriceNumber", {
        minPrice: validatorValue.minPrice,
      }),
      maxPriceNumber: translate.instant("CheckMaxPriceNumber", {
        maxPrice: validatorValue.maxPrice,
      }),
      daysInFuture: translate.instant("CheckDaysInFuture", {
        days: validatorValue.days,
      }),
      dateMinGreaterThan: translate.instant("CheckDateMinGreaterThan", {
        days: validatorValue.minDays,
      }),
      dateMaxGreaterThan: translate.instant("CheckDateMaxGreaterThan", {
        days: validatorValue.maxDays,
      }),
      brandOrGtinIsRequired: translate.instant("CheckBrandOrGtin"),
      greaterByAmount: translate.instant("CheckGreaterByAmount", {
        number: validatorValue.value,
      }),
      greaterThan: translate.instant("CheckGreaterThan"),
      minLengthArray: translate.instant("CheckMinLengthArray"),
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static plzValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?=.*[!@#$%^&*]) - Assert a string has at least one special character.
    //(?=.*[a-z])       // should contain at least one lower case
    //(?=.*[A-Z])       // should contain at least one upper case
    if (control.value.match(/^(?=.[0-9]){5,6}$/)) {
      return null;
    } else {
      return { invalidPLZ: true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?=.*[!@#$%^&*]) - Assert a string has at least one special character.
    //(?=.*[a-z])       // should contain at least one lower case
    //(?=.*[A-Z])       // should contain at least one upper case
    if (
      control.value.match(
        /^(?=.*[0-9])(?=.*[~!@#$%^&*-+=`|\(){}\[\]:;"'<>,.?])(?=.*[a-z])(?=.*[A-Z]).{8,100}$/
      )
    ) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }
  static urlValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?=.*[!@#$%^&*]) - Assert a string has at least one special character.
    //(?=.*[a-z])       // should contain at least one lower case
    //(?=.*[A-Z])       // should contain at least one upper case
    //[A-Za-z][A-Za-z\d.+-]*:\/*
    if (
      control.value.match(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\u00C0-\u017Fa-z0-9]+([\-\.]{1}[\u00C0-\u017Fa-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
      )
    ) {
      return null;
    } else {
      return { invalidURL: true };
    }
  }

  static dateInFuture(control: FormControl) {
    const value = control.value;
    if (value !== null && new Date(value) < new Date()) {
      return { dateNotInFuture: true };
    }

    return null;
  }

  static daysInFuture(days: number): ValidatorFn {
    return (c: FormControl) => {
      const value: Date = c.value;
      const dateInFuture = new Date();

      dateInFuture.setDate(dateInFuture.getDate() + days);

      // Ignore hours, minutes and seconds
      // dateInFuture.setHours(0, 0, 0, 0);

      if (value < dateInFuture) {
        let validatorValue = { days };

        // Merge validator values  with new values
        if (c.errors) {
          validatorValue = { ...validatorValue, ...c.errors.validatorValue };
        }

        return {
          daysInFuture: true,
          validatorValue,
        };
      }

      return null;
    };
  }

  static dateGreaterThan(params: any = {}): ValidatorFn {
    return (c: AbstractControl) => {
      if (!c.parent) {
        return null;
      }

      const { source, reference, minDays, maxDays } = params;
      const sourceControl = c.parent.controls[source];
      const referenceControl = c.parent.controls[reference];

      const sourceValue = sourceControl.value;
      const referenceValue = referenceControl.value;

      let comparionDateMinDays = new Date(sourceValue);
      comparionDateMinDays.setDate(comparionDateMinDays.getDate() - minDays);

      if (comparionDateMinDays < referenceValue) {
        let validatorValue = { minDays };

        // Merge validator values  with new values
        if (sourceControl.errors) {
          validatorValue = {
            ...validatorValue,
            ...sourceControl.errors.validatorValue,
          };
        }

        if (referenceControl.errors) {
          validatorValue = {
            ...validatorValue,
            ...referenceControl.errors.validatorValue,
          };
        }

        return { dateMinGreaterThan: true, validatorValue };
      }

      let comparionDateMaxDays = new Date(sourceValue);
      comparionDateMaxDays.setDate(comparionDateMaxDays.getDate() - maxDays);

      if (comparionDateMaxDays > referenceValue) {
        let validatorValue = { maxDays };

        // Merge validator values  with new values
        if (sourceControl.errors) {
          validatorValue = {
            ...validatorValue,
            ...sourceControl.errors.validatorValue,
          };
        }

        if (referenceControl.errors) {
          validatorValue = {
            ...validatorValue,
            ...referenceControl.errors.validatorValue,
          };
        }

        return { dateMaxGreaterThan: true, validatorValue };
      }

      return null;
    };
  }

  static minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) return null;

      return { minLengthArray: { valid: false } };
    };
  }

  static startDateGreaterThanEndDate(
    startDateField: string,
    endDateField: string
  ): ValidatorFn {
    return (c: AbstractControl) => {
      const startDate = c.get(startDateField).value;
      const endDate = c.get(endDateField).value;

      if (
        startDate !== null &&
        endDate !== null &&
        new Date(startDate) > new Date(endDate)
      ) {
        return {
          startDateLessThanEndDate: true,
        };
      }

      return null;
    };
  }

  static greaterByAmount(
    firstField: string,
    secondFieldField: string,
    max: number
  ): ValidatorFn {
    return (c: AbstractControl) => {
      const firstValue = c.get(firstField).value;
      const secondValue = c.get(secondFieldField).value;

      if (firstValue - secondValue < max) {
        let validatorValue = { value: max };

        // Merge validator values  with new values
        if (c.get(firstField).errors) {
          validatorValue = {
            ...validatorValue,
            ...c.get(firstField).errors.validatorValue,
          };
        }

        if (c.get(secondFieldField).errors) {
          validatorValue = {
            ...validatorValue,
            ...c.get(secondFieldField).errors.validatorValue,
          };
        }

        return {
          greaterByAmount: true,
          validatorValue,
        };
      }

      return null;
    };
  }

  static discountGreaterThanPrice(
    priceField: string,
    discountField: string
  ): ValidatorFn {
    return (c: AbstractControl) => {
      const price = c.get(priceField).value;
      const discount = c.get(discountField).value;
      if (
        discount !== "" &&
        CurrencyService.formatPriceToNumber(price) <
          CurrencyService.formatPriceToNumber(discount)
      ) {
        return {
          discountGreaterThanPrice: true,
        };
      }

      return null;
    };
  }

  static priceGreaterThanZero(control: FormControl) {
    let value = control.value;

    if (value != "") {
      value = CurrencyService.formatPriceToNumber(value);
    }

    if (isNaN(value) || value <= 0) {
      return { priceGreaterThanZero: true };
    }

    return null;
  }

  static priceNumber(params: any = {}): ValidatorFn {
    return (c: FormControl) => {
      const value: number = CurrencyService.formatPriceToNumber(c.value);
      if (isNaN(value)) {
        return { invalidNumber: true };
      } else if (params.hasOwnProperty("min") && value < params.min) {
        let validatorValue = {
          minPrice: CurrencyService.formatNumber2Price(params.min),
        };

        if (c.errors) {
          validatorValue = {
            ...validatorValue,
            ...c.errors.validatorValue,
          };
        }

        return { minPriceNumber: true, validatorValue };
      } else if (params.hasOwnProperty("max") && value > params.max) {
        let validatorValue = {
          maxPrice: CurrencyService.formatNumber2Price(params.max),
        };

        if (c.errors) {
          validatorValue = {
            ...validatorValue,
            ...c.errors.validatorValue,
          };
        }

        return { maxPriceNumber: true, validatorValue };
      } else {
        return null;
      }
    };
  }

  static number(params: any = {}): ValidatorFn {
    return (c: FormControl) => {
      const value: number = c.value;

      if (isNaN(value)) {
        return { invalidNumber: true };
      } else if (params.hasOwnProperty("min") && value < params.min) {
        let validatorValue = { min: params.min };

        if (c.errors) {
          validatorValue = {
            ...validatorValue,
            ...c.errors.validatorValue,
          };
        }

        return { minNumber: true, validatorValue };
      } else if (params.hasOwnProperty("max") && value > params.max) {
        let validatorValue = { min: params.max };

        if (c.errors) {
          validatorValue = {
            ...validatorValue,
            ...c.errors.validatorValue,
          };
        }

        return { maxNumber: true, validatorValue };
      } else {
        return null;
      }
    };
  }

  static atLeastOne =
    (validator: ValidatorFn, controls: string[] = null) =>
    (group: FormGroup): ValidationErrors | null => {
      if (!controls) {
        controls = Object.keys(group.controls);
      }

      const hasAtLeastOne =
        group &&
        group.controls &&
        controls.some((k) => !validator(group.controls[k]));

      return hasAtLeastOne
        ? null
        : {
            atLeastOne: true,
          };
    };

  static checkPasswordRequirements(value: string) {
    const result = {};
    value = value.replace(/\s/g, "");

    result["minLength"] = value.length >= 8;
    result["number"] = this.hasNumber(value);
    result["lowerCase"] = this.hasLowerCase(value);
    result["upperCase"] = this.hasUpperCase(value);
    result["special"] = this.hasSpecial(value);

    return result;
  }

  private static hasSpecial(value: string) {
    return /[~!@#$%^&*-+=`|\(){}\[\]:;"'<>,.?]/.test(value);
  }

  private static hasNumber(value: string) {
    return /\d/.test(value);
  }

  private static hasUpperCase(value: string) {
    return /[A-Z]/.test(value);
  }

  private static hasLowerCase(value: string) {
    return /[a-z]/.test(value);
  }

  private static isFieldEmpty = (fieldName: string, fg: FormGroup) => {
    const field = fg.get(fieldName).value;
    if (typeof field === "number") {
      return field && field >= 0 ? true : false;
    }
    if (typeof field === "string") {
      return field && field.length > 0 ? true : false;
    }
  };
}
