import { Injectable } from "@angular/core";
import { MPModule } from "app/model/marketing-portal/MPModule";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  public modules: string[] = null;

  constructor() {
    this.modules = this.getModules();
  }

  hasAccess(items: string[]): Boolean {
    let hasAccess: boolean = true;

    items.forEach((item) => {
      if (!this.modules.includes(item)) {
        hasAccess = false;
      }
    });

    return hasAccess;
  }

  restoreModules(): string[] {
    return window.localStorage["modules"].split(",");
  }

  getModules() {
    if (!this.modules) {
      this.modules = this.restoreModules();
    }

    return this.modules;
  }

  isEnduser() {
    return (
      this.hasAccess([MPModule.MODULE_SERVICE_PROFILE]) &&
      window.localStorage["customerId"] != "null" &&
      window.localStorage["customerId"] != "" &&
      window.localStorage["customerId"] != undefined
    );
  }
}
