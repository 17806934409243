import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "media-asset-text-suggestion-card",
  templateUrl: "./media-asset-text-suggestion-card.component.html",
  styleUrls: ["./media-asset-text-suggestion-card.component.scss"],
})
export class MediaAssetTextSuggestionCardComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Input() text: any;
  @Input() selection: string;
  constructor() {}

  ngOnInit() {}

  onMenuClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
