import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { MPConfiguratorMainAction } from "app/model/marketing-portal/MPConfiguratorMainAction";

@Component({
  selector: "ma-extended-configurator-main-action",
  templateUrl: "./extended-configurator-main-action.component.html",
  styleUrls: ["./extended-configurator-main-action.component.scss"],
})
export class ExtendedConfiguratorMainActionComponent implements OnInit {
  @ViewChild("configuratorFooter") configuratorFooter: ElementRef;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() configuratorHeaderHeight: number;
  @Input() creationType: MPConfiguratorCreationType;
  public contentHeight: number;
  readonly mainAction = MPConfiguratorMainAction;
  readonly mpConfiguratorCreationType = MPConfiguratorCreationType;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;

    windowHeight -= this.configuratorHeaderHeight;

    if (this.configuratorFooter) {
      windowHeight -= this.configuratorFooter.nativeElement.offsetHeight;
    }

    this.contentHeight = windowHeight;
  }
}
