import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "add-customer-region-dialog",
  templateUrl: "./add-customer-region-dialog.component.html",
  styleUrls: ["./add-customer-region-dialog.component.scss"],
})
export class AddCustomerRegionDialogComponent implements OnInit {
  @ViewChild("pacInput") input: ElementRef;
  public selectedPlace: any;
  public searchString: String = "";
  public selectedLocationId: number;
  public formGroup: FormGroup;
  public errors = {};
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddCustomerRegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.buildForm();
    this.initGoogleSearch();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      zip_code: [""],
      address: [""],
      streetNumber: [""],
      city: [""],
      country: [""],
    });

    this.formGroup.get("zip_code").disable();
    this.formGroup.get("address").disable();
    this.formGroup.get("city").disable();
    this.formGroup.get("country").disable();
  }

  initGoogleSearch() {
    const input = document.getElementById("search-form") as HTMLInputElement;
    const options = {
      componentRestrictions: { country: "de" },
      fields: [
        "adr_address",
        "address_component",
        "formatted_address",
        "geometry",
        "name",
      ],
      strictBounds: false,
      types: ["address"],
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      this.selectedPlace = null;
      this.selectGooglePlace(autocomplete.getPlace());
    });
  }

  selectGooglePlace(place) {
    const regionAddress = {
      longitude: null,
      latitude: null,
      zip_code: null,
      address: null,
      streetNumber: null,
      city: null,
      state: null,
      country: null,
    };

    const zipcode = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("postal_code")
    );

    if (zipcode) {
      regionAddress.zip_code = zipcode.short_name;
    }

    const country = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("country")
    );

    if (country) {
      regionAddress.country = country.long_name;
    }

    const state = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("administrative_area_level_1")
    );

    if (state) {
      regionAddress.state = state.short_name;
    }

    const city = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("locality")
    );

    if (city) {
      regionAddress.city = city.short_name;
    }

    const address = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("route")
    );

    const streetNumber = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("street_number")
    );

    if (address) {
      regionAddress.address = address.short_name;

      var curAddress = address.long_name;

      regionAddress.address = curAddress;
    }

    if (streetNumber) regionAddress.streetNumber = streetNumber.long_name;

    regionAddress.latitude = place.geometry.location.lat();
    regionAddress.longitude = place.geometry.location.lng();

    this.selectPlace(regionAddress);
  }

  selectPlace(address) {
    this.input.nativeElement.value = "";
    this.selectedLocationId = null;
    this.selectedPlace = address;

    this.formGroup.get("zip_code").setValue(address.zip_code);
    this.formGroup.get("country").setValue(address.country);
    this.formGroup.get("city").setValue(address.city);
    this.formGroup.get("address").setValue(address.address);
    this.formGroup.get("streetNumber").setValue(address.streetNumber);
  }

  onClose() {
    this.dialogRef.close();
  }

  removeSelectedPlace() {
    this.selectedPlace = null;
    this.selectedLocationId = null;
  }

  add() {
    this.selectedPlace.address =
      this.formGroup.get("address").value +
      " " +
      this.formGroup.get("streetNumber").value;

    this.dialogRef.close({ selectedRegion: this.selectedPlace });
  }
}
