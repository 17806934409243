export class SponsoredFacebookPage {
  public sponsoredFacebookName: string;
  public sponsoredFacebookId: string;
  public sponsoredFacebookImageUrl: string;

  constructor(
    sponsoredFacebookName: string = null,
    sponsoredFacebookId: string = null,
    sponsoredFacebookImageUrl: string = null
  ) {
    this.sponsoredFacebookName = sponsoredFacebookName;
    this.sponsoredFacebookId = sponsoredFacebookId;
    this.sponsoredFacebookImageUrl = sponsoredFacebookImageUrl;
  }

  public deserialize(serializedObj: object): SponsoredFacebookPage {
    if (!serializedObj) {
      return null;
    }

    this.sponsoredFacebookName = serializedObj["sponsoredFacebookName"];
    this.sponsoredFacebookId = serializedObj["sponsoredFacebookId"];
    this.sponsoredFacebookImageUrl = serializedObj["sponsoredFacebookImageUrl"];

    return this;
  }
}
