import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FileType } from "../../models/FileType";

@Component({
  selector: "select-media-asset-file-type",
  templateUrl: "./select-media-asset-file-type.component.html",
  styleUrls: ["./select-media-asset-file-type.component.scss"],
})
export class SelectMediaAssetFileTypeComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() selected: number;
  readonly fileType = FileType;
  constructor() {}

  ngOnInit() {}
}
