import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-confirm",

  template: `
    <div class="flex flex-column">
      <div
        class="px-4 py-3 border-b-1 border-color-blue-light-gray flex justify-space-between items-center"
      >
        <h2 class="text-xl m-0 text-bold text-dark-blue-200">
          {{ data.title }}
        </h2>
        <button mat-icon-button (click)="dialogRef.close(false)">
          <mat-icon class="text-dark-blue-200">clear</mat-icon>
        </button>
      </div>
      <div
        class="p-4 relative"
        style="min-height: 120px"
        [innerHTML]="data.message"
      ></div>
      <div
        class="px-4 py-3 border-t-1 border-color-blue-light-gray flex justify-end"
      >
        <div>
          <button
            mat-stroked-button
            color="basic"
            (click)="dialogRef.close(false)"
          >
            <span>{{ "43" | translate }}</span>
          </button>
        </div>
        <div class="ml-025">
          <button
            mat-flat-button
            color="primary"
            (click)="dialogRef.close(true)"
          >
            <span> {{ "Okay" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  `,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
