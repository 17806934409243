import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-add-form-text-dialog",
  templateUrl: "./add-form-text-dialog.component.html",
  styleUrls: ["./add-form-text-dialog.component.scss"],
})
export class AddFormTextDialogComponent implements OnInit {
  public applyDisabled: boolean = true;
  public text: string = "";
  constructor(
    public dialogRef: MatDialogRef<AddFormTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  @HostListener("document:keydown.enter", ["$event"]) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    if (!this.applyDisabled) {
      this.onApply();
    }
  }

  onChangeText($event) {
    this.text = $event.target.value;

    this.applyDisabled = this.text.length === 0;
  }

  onApply() {
    this.dialogRef.close({ text: this.text });
  }

  onClose() {
    this.dialogRef.close();
  }
}
