import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";

@Component({
  selector: "app-marketing-portal-editor-dialog",
  templateUrl: "./marketing-portal-editor-dialog.component.html",
  styleUrls: ["./marketing-portal-editor-dialog.component.scss"],
})
export class MarketingPortalEditorDialogComponent implements OnInit {
  public customerId: any;
  public customerLogo: string;
  public channelId: number;
  public mediaAsset: MPMediaAsset;
  public formatMediaAssets?: any;
  public resolutionId: number;
  public googleDisplayMediaFormatId?: number;
  public isEdit: boolean;
  public preSelectTemplateId: number;
  public selectedResolutions: any;

  constructor(
    public dialogRef: MatDialogRef<MarketingPortalEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.customerId = this.data.customerId;
      this.customerLogo = this.data.customerLogo;
      this.channelId = this.data.channelId;
      this.mediaAsset = this.data.hasOwnProperty("mediaAsset")
        ? this.data.mediaAsset
        : null;
      this.formatMediaAssets = this.data.hasOwnProperty("formatMediaAssets")
        ? this.data.formatMediaAssets
        : null;
      this.resolutionId = this.data.resolutionId;
      this.googleDisplayMediaFormatId = this.data.hasOwnProperty(
        "googleDisplayMediaFormatId"
      )
        ? this.data.googleDisplayMediaFormatId
        : null;
      this.isEdit = this.data.hasOwnProperty("isEdit")
        ? this.data.isEdit
        : false;
      this.preSelectTemplateId = this.data.hasOwnProperty("preSelectTemplateId")
        ? this.data.preSelectTemplateId
        : null;
      this.selectedResolutions = this.data.hasOwnProperty("selectedResolutions")
        ? this.data.selectedResolutions
        : null;
    }
  }
  onClose() {
    this.dialogRef.close();
  }

  onApply($event) {
    this.dialogRef.close({ templateMediaAssets: $event });
  }
}
