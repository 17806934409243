import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileType } from "../../models/FileType";

@Component({
  selector: "select-media-asset-template",
  templateUrl: "./select-media-asset-template.component.html",
  styleUrls: ["./select-media-asset-template.component.scss"],
})
export class SelectMediaAssetTemplateComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() selected: number;
  @Input() templates: any;
  @Input() selectedFormat: number;
  @Input() selectedFileType: number;
  public filteredTemplates: any;
  public cols: number = 6;
  public gap: number = 4;
  public groupedTemplates: any = [];
  readonly fileType = FileType;

  constructor() {}

  ngOnInit() {
    this.filterTemplatesByFormat();
    this.groupTemplateByCategory();
  }

  filterTemplatesByFormat() {
    this.filteredTemplates = this.templates.filter(
      (template) => template.resolution === this.selectedFormat
    );
  }

  groupTemplateByCategory() {
    this.filteredTemplates.forEach((template) => {
      let categoryName = template.category.templateCategoryName;

      if (!this.categoryExists(categoryName)) {
        this.groupedTemplates.push({
          category: {
            name: categoryName,
          },
          templates: [],
        });
      }

      let index = this.getGroupedTemplateIndexByCategory(categoryName);
      this.groupedTemplates[index].templates.push(template);
    });
  }

  categoryExists(categoryName) {
    return this.getGroupedTemplateIndexByCategory(categoryName) != -1;
  }

  getGroupedTemplateIndexByCategory(categoryName) {
    return this.groupedTemplates.findIndex(
      (groupedTemplate) => groupedTemplate.category.name === categoryName
    );
  }
}
