import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "zipcode-impressions-select-list",
  templateUrl: "./zipcode-impressions-select-list.component.html",
  styleUrls: ["./zipcode-impressions-select-list.component.scss"],
})
export class ZipcodeImpressionsSelectListComponent implements OnInit {
  @Output() onSelectZipcode: EventEmitter<any> = new EventEmitter();
  @Input() locationsStatistics: any;
  @Input() goal: any = [];
  public totalLocationStatistics: any;
  constructor() {}

  ngOnInit() {
    this.setTotalLocationStatistics();
  }

  setTotalLocationStatistics() {
    this.totalLocationStatistics = this.locationsStatistics.find(
      (locationsStatistic) => locationsStatistic.ChannelName === "Total"
    );
  }
}
