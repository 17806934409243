import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MarketingPortalVideoEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { MarketingPortalEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { CrudService } from "app/views/mapAds/crud.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { MatDialog } from "@angular/material";
import { environment } from "environments/environment";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { ActivatedRoute, Router } from "@angular/router";
import { LogEventType } from "app/model/LogEventType";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "assistant-expanded-creative-card",
  templateUrl: "./assistant-expanded-creative-card.component.html",
  styleUrls: ["./assistant-expanded-creative-card.component.scss"],
})
export class AssistantExpandedCreativeCardComponent implements OnInit {
  @ViewChild("video") video: ElementRef;
  @Output() openPreview: EventEmitter<any> = new EventEmitter();
  @Input() channelCreative: any;
  public environment = environment;
  public videoProcessingCheckInterval: any;
  public showPreviewImageLoading: boolean = false;
  public mediaAssetId: number;
  public creativeIndex: number = 0;

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  async ngOnInit() {}

  async createNewCreative() {
    this.appLoader.open();
    let mediaAssetIds: number[] = this.getMediaAssetIds()
      .split(",")
      .map(Number);

    await this.crudService
      .regenerateMediaAssets(
        this.channelCreative.channel.channelId,
        this.channelCreative.creatives[0].CustomerId,
        mediaAssetIds
      )
      .toPromise()
      .then((res: any) => {
        this.updateCreatives(mediaAssetIds, res);
        this.snackbarService.show(
          this.translate.instant("AdMediaWasSuccessfullyNewCreated"),
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  createNewCampaign() {
    let mediaAssetIds: string = this.getMediaAssetIds();

    const restoreMediaAssetIdsString: string =
      this.activatedRoute.snapshot.queryParams.restoreMediaAssetIds || null;

    let path = `/mapAds/marketing-portal/configurator?creationType=${
      MPConfiguratorCreationType.CAMPAIGN
    }&returnUrl=${this.router.url.split("?")[0]}&customerId=${
      this.channelCreative.creatives[0].CustomerId
    }&mediaAssetIds=${mediaAssetIds}&preselectChannel=${
      this.channelCreative.channel.channelId
    }`;

    if (restoreMediaAssetIdsString) {
      path += `&restoreGenMediaAssetIds=${restoreMediaAssetIdsString}`;
    }

    this.router.navigateByUrl(path);

    this.crudService
      .logEvent(
        LogEventType.ASSISTANT,
        this.translate.instant("StartCampaign") +
          ": " +
          this.channelCreative.channel.channelName,
        mediaAssetIds,
        true
      )
      .subscribe(
        (res) => {},
        (error) => {}
      );

    this.router.navigateByUrl(path);
  }

  getMediaAssetIds(): string {
    return this.channelCreative.creatives
      .map((creative) => creative.ImageId)
      .join(",");
  }

  getAllChannelIds(): string {
    return this.channelCreative.creative.channelIds.join(",");
  }

  edit() {
    const { creatives } = this.channelCreative;
    const creative = creatives[0];

    const mediaAsset: MPMediaAsset = new MPMediaAsset(
      creative.ImageId,
      "",
      600,
      600,
      null,
      null,
      false,
      !creative.isVideo,
      creative.isVideo,
      true,
      null,
      null,
      null,
      null,
      false,
      creative.TemplateId
    );

    this.openEditor(mediaAsset, creative, true);
  }

  openEditor(mediaAsset: MPMediaAsset, creative: any, edit: boolean) {
    const dialogComponent = mediaAsset.isImage
      ? MarketingPortalEditorDialogComponent
      : MarketingPortalVideoEditorDialogComponent;

    const dialogRef = this.dialog.open(dialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: creative.CustomerId,
        mediaAsset: mediaAsset,
        isEdit: edit,
        channelId: null,
        resolutionId: null,
        preSelectTemplateId: null,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const oldMediaAssetIds = this.getMediaAssetIds().split(",").map(Number);

        const mediaAssetIds = mediaAsset.isImage
          ? res.templateMediaAssets.templateMediaAssets.map(
              (mediaAsset) => mediaAsset.imageId
            )
          : [res.templateMediaAsset.mediaAssetId];

        this.crudService
          .logEvent(
            LogEventType.ASSISTANT,
            "Kampagne editiert: " + this.channelCreative.channel.channelName,
            mediaAssetIds.join(","),
            true
          )
          .subscribe(
            (res) => {},
            (error) => {}
          );

        if (!mediaAsset.isImage) {
          await this.waitUntilVideoIsGenerated(mediaAssetIds[0]).catch(
            (error) => {
              this.snackbarService.show(
                this.translate.instant("ErrorOccurredWhileGeneratingVideo"),
                "danger"
              );
            }
          );
          this.reloadVideo();
        }

        this.updateCreatives(oldMediaAssetIds, mediaAssetIds);
      }
    });
  }

  reloadVideo() {
    this.video.nativeElement.src = `${environment.imageServerUrl}api/Public/GetMediaAsset?id=${this.mediaAssetId}`;
    this.video.nativeElement.load();
  }

  updateCreatives(oldImageIds, newImageIds) {
    oldImageIds.forEach((imageId, oldImageIndex) => {
      const index = this.channelCreative.creatives.findIndex(
        (creative) => creative.ImageId === imageId
      );

      let newMediaAssetId = newImageIds[oldImageIndex];

      this.channelCreative.creatives[index].ImageId = newMediaAssetId;
      this.channelCreative.creatives[index].ImageURL =
        environment.imageServerUrl +
        "api/Public/GetMediaAsset?id=" +
        newMediaAssetId;
      this.channelCreative.creatives[index].ImageThumbnailURL =
        environment.imageServerUrl +
        "api/Public/GetMediaAsset?id=" +
        newMediaAssetId +
        "&width=300&height=300";
    });

    this.channelCreative = JSON.parse(JSON.stringify(this.channelCreative));
  }

  waitUntilVideoIsGenerated(mediaAssetId: number) {
    return new Promise((resolve, reject) => {
      const maxRecurrences = 15;
      let counter = 0;
      const self = this;
      this.showPreviewImageLoading = true;
      this.videoProcessingCheckInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              resolve(true);
            }

            if (counter > maxRecurrences) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              reject();
            }
          });
      }, 1500);
    });
  }

  next() {
    if (this.creativeIndex >= this.channelCreative.creatives.length - 1) {
      this.creativeIndex = 0;
      return;
    }

    this.creativeIndex++;
  }

  back() {
    if (this.creativeIndex === 0) {
      this.creativeIndex = this.channelCreative.creatives.length - 1;
      return;
    }

    this.creativeIndex--;
  }
}
