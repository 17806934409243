import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { toBase64 } from "app/shared/utils/blob.utils";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "image-cropper-wrapper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperComponent implements OnInit {
  @Input() imageFile: any;
  @Input() croppedImage: any;
  @Input() width?: number;
  @Input() height?: number;
  @Input() forceAspectRatio?: boolean = false;
  @Input() expandImageFrame?: boolean = true;
  @Output() setCroppedImage: EventEmitter<any> = new EventEmitter(true);
  public isLoading: boolean;
  public maintainAspectRatio: boolean;
  public aspectRatio: string;
  public imageBase64 = null;
  public originImageBase64 = null;
  public originImageSize: any = null;
  public expandImageFrameModusOn: boolean = false;
  public expandedFrameWidth: number = 100;
  public expandedFrameColor: string = "#fff";
  public showCroppedImageToSmallMessage: boolean = false;
  public croppedImageWidth: number;
  public croppedImageHeight: number;
  constructor() {
    this.isLoading = true;
    this.maintainAspectRatio = true;
  }

  async ngOnInit() {
    this.imageBase64 = await toBase64(this.imageFile);
    this.originImageBase64 = this.imageBase64;
    this.setAspectRatio();
  }

  addFrameToImage() {
    const c = document.createElement("canvas");
    const width = this.originImageSize.width;
    const height = this.originImageSize.height;
    const ctx = c.getContext("2d");
    const imageObj = new Image();
    const self = this;

    c.width = width + this.expandedFrameWidth * 2;
    c.height = height + this.expandedFrameWidth * 2;

    imageObj.onload = function () {
      ctx.fillStyle = self.expandedFrameColor;
      ctx.fillRect(
        0,
        0,
        width + self.expandedFrameWidth * 2,
        height + self.expandedFrameWidth * 2
      );
      ctx.drawImage(
        imageObj,
        self.expandedFrameWidth,
        self.expandedFrameWidth,
        width,
        height
      );

      const data = c.toDataURL("image/png");
      self.imageBase64 = data;
    };
    imageObj.src = this.originImageBase64;
  }

  async toggleFrameModus($event) {
    this.expandImageFrameModusOn = $event.checked;

    if (this.expandImageFrameModusOn) {
      await this.loadOriginImageSize();
      this.addFrameToImage();
      return;
    }

    this.clearFrame();
  }

  expandedFrameWidthChange() {
    this.addFrameToImage();
  }

  expandedFrameColorChange() {
    this.addFrameToImage();
  }

  loadOriginImageSize() {
    return new Promise((resolve, reject) => {
      if (this.originImageSize != null) {
        resolve(true);
        return;
      }

      const reader = new FileReader();
      const self = this;

      reader.readAsDataURL(this.imageFile);
      reader.onload = function () {
        const image: any = new Image();
        image.src = reader.result;

        image.onload = function () {
          self.originImageSize = {
            height: this.height,
            width: this.width,
          };
          resolve(true);
        };
      };
    });
  }

  async clearFrame() {
    this.imageBase64 = this.originImageBase64;
  }

  setAspectRatio() {
    this.aspectRatio = (this.width / this.height).toString();
  }

  imageLoaded() {
    this.isLoading = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    const { x1, x2, y1, y2 } = event.imagePosition;
    this.croppedImageWidth = x2 - x1;
    this.croppedImageHeight = y2 - y1;

    this.showCroppedImageToSmallMessage = this.isCroppedImageToSmall();

    let el = document.getElementById("preview-cropped-image");
    if (event.height > event.width) {
      el.style.width = "auto";
      el.style.height = "128px";
    } else {
      el.style.width = "128px";
      el.style.height = "auto";
    }

    this.setCroppedImage.emit(event.base64);
  }

  cropperReady() {
    this.maintainAspectRatio = this.forceAspectRatio;
  }

  isCroppedImageToSmall(): boolean {
    return (
      this.croppedImageWidth < this.width ||
      this.croppedImageHeight < this.height
    );
  }
}
