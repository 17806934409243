export class MPCampaignStatistics {
  public goalDisplayName: string;
  public goalId: number;
  public impressions: number;
  public clicks: number;

  constructor(
    goalDisplayName: string = null,
    goalId: number = null,
    impressions: number = null,
    clicks: number = null
  ) {
    this.goalDisplayName = goalDisplayName;
    this.goalId = goalId;
    this.impressions = impressions;
    this.clicks = clicks;
  }

  deserialize(serializedObj: object): MPCampaignStatistics {
    if (!serializedObj) {
      return null;
    }

    this.goalDisplayName = serializedObj["goalDisplayName"];
    this.goalId = serializedObj["goalId"];
    this.impressions = serializedObj["impressions"];
    this.clicks = serializedObj["clicks"];

    return this;
  }
}
