import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-video-editor-timeline",
  templateUrl: "./marketing-portal-video-editor-timeline.component.html",
  styleUrls: ["./marketing-portal-video-editor-timeline.component.scss"],
})
export class MarketingPortalVideoEditorTimelineComponent implements OnInit {
  @Output() onSelectSlide: EventEmitter<any> = new EventEmitter();
  @Input() slides: any;
  @Input() selectedSlide: any;
  @Input() duration: number;
  constructor() {}

  ngOnInit() {}
}
