import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MPGAMAdUnit } from "app/model/marketing-portal/MPGAMAdUnit";

@Component({
  selector: "gam-ad-unit-card",
  templateUrl: "./gam-ad-unit-card.component.html",
  styleUrls: ["./gam-ad-unit-card.component.scss"],
})
export class GamAdUnitCardComponent implements OnInit {
  @Input() gamAdUnit: MPGAMAdUnit;
  @Input() isSelected: boolean;
  @Input() readonly: boolean = false;
  constructor() {}

  ngOnInit() {}
}
