import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { MediaAssetExternalSource } from "app/model/marketing-portal/MediaAssetExternalSource";
import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { FileType } from "app/shared/components/customer/generate-customer-media-asset-quick-tool/models/FileType";
import { MarketingPortalEditorDialogComponent } from "app/shared/components/dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MarketingPortalVideoEditorDialogComponent } from "app/shared/components/dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { ModuleService } from "app/shared/services/module.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { base64ToFile } from "app/shared/utils/blob.utils";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";
import * as FileSaver from "file-saver";

@Component({
  selector: "ma-extended-configurator-creative",
  templateUrl: "./extended-configurator-creative.component.html",
  styleUrls: ["./extended-configurator-creative.component.scss"],
})
export class ExtendedConfiguratorCreativeComponent implements OnInit {
  @ViewChild("video") video: ElementRef;
  @Output() goToConfigurator: EventEmitter<any> = new EventEmitter();
  @Output() setSelectedTemplateImages: EventEmitter<any> = new EventEmitter();
  @Input() configuratorHeaderHeight: number;
  @Input() selectedTemplateImages: TemplateBasedImage[];
  @Input() selectedResolutions: string[];
  @Input() selectedCustomer: MPCustomer;
  @Input() selectedAssetType: FileType;
  @Input() selectedChannel: MPChannel;
  public videoProcessingCheckInterval: any;
  public contentHeight: number;
  public isLoading: boolean;
  public isImage: boolean;
  public showPreviewImageLoading: boolean = true;
  public mediaAssetIndex: number = 0;
  public mediaAssetExternalSources: MediaAssetExternalSource[] = [];
  public showExternalSources: boolean = false;
  readonly environment = environment;
  readonly MODULE_SERVICE_PRESALES = MPModule.MODULE_SERVICE_PRESALES;
  public mpChannel = MPChannel;
  public canBeDownloaded: boolean = false;

  constructor(
    private moduleService: ModuleService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private crudService: CrudService,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.calcContentHeight();
    this.setIsImage();

    this.initData();
    this.setCanBeDownloaded();
  }

  setCanBeDownloaded() {
    this.canBeDownloaded =
      (this.environment.partnerId == 9 || this.environment.partnerId == 17) &&
      (this.selectedChannel.channelId ===
        this.mpChannel.CHANNEL_ID_ADDRESSABLE_TV ||
        !this.isImage);
  }

  async ngAfterViewInit() {
    if (!this.isImage) {
      const mediaAssetId = this.selectedTemplateImages[0].imageId;
      await this.waitUntilVideoIsGenerated(mediaAssetId).catch((error) => {
        this.snackbarService.show(
          this.translate.instant("ErrorOccurredWhileGeneratingVideo"),
          "danger"
        );
      });

      this.reloadVideo(mediaAssetId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("configuratorHeaderHeight") &&
      changes.configuratorHeaderHeight.currentValue
    ) {
      this.calcContentHeight();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calcContentHeight();
  }

  async initData() {
    setTimeout(() => this.appLoader.open());
    this.isLoading = true;

    await this.crudService
      .getExternalSourcesFromMediaAssetId(
        this.selectedTemplateImages[0].imageId
      )
      .toPromise()
      .then(
        (res) => {
          this.mediaAssetExternalSources = res;
        },
        (error) => {
          this.snackbarService.show(error.error, "danger");
        }
      );

    this.isLoading = false;
    setTimeout(() => this.appLoader.close());
  }

  setIsImage() {
    this.isImage = this.selectedAssetType === FileType.FILE_TYPE_IMAGE;
  }

  async edit() {
    this.appLoader.open();

    let mediaAsset: MPMediaAsset = null;

    await this.crudService
      .getMediaAssetInformation(
        this.selectedTemplateImages[this.mediaAssetIndex].imageId
      )
      .toPromise()
      .then((res) => {
        mediaAsset = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();

    this.openEditor(mediaAsset, true);
  }

  async addToPresales() {
    this.appLoader.open();

    let data = [this.selectedChannel.channelId];

    const promises = [];

    this.selectedTemplateImages.forEach((templateImage: TemplateBasedImage) => {
      promises.push(
        this.crudService
          .addItemToPresales(
            templateImage.imageId,
            this.selectedCustomer.customerId,
            data
          )
          .toPromise()
      );
    });

    await Promise.all(promises)
      .then((values) => {
        this.snackbarService.show(
          this.translate.instant("AdHasBeenMovedToPresalesFolder"),
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();

    this.router.navigateByUrl(
      `/mapAds/customers/show/${this.selectedCustomer.customerId}`
    );
  }

  openEditor(mediaAsset: MPMediaAsset = null, edit = false) {
    const dialogComponent = this.isImage
      ? MarketingPortalEditorDialogComponent
      : MarketingPortalVideoEditorDialogComponent;

    const dialogRef = this.dialog.open(dialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.selectedCustomer.customerId,
        mediaAsset: mediaAsset,
        isEdit: edit,
        channelId: this.selectedChannel.channelId,
        resolutionId: null,
        preSelectTemplateId: null,
        selectedResolutions: this.selectedResolutions,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const templateImages: TemplateBasedImage[] = this.isImage
          ? res.templateMediaAssets.templateMediaAssets
          : [
              {
                imageId: res.templateMediaAsset.mediaAssetId,
                formatDisplayName: null,
                formatId: null,
              },
            ];

        if (!this.isImage) {
          let mediaAssetId = templateImages[0].imageId;

          await this.waitUntilVideoIsGenerated(mediaAssetId).catch((error) => {
            this.snackbarService.show(
              this.translate.instant("ErrorOccurredWhileGeneratingVideo"),
              "danger"
            );
          });
          this.reloadVideo(mediaAssetId);
        }

        this.getMediaAssetExternalSources(templateImages[0].imageId);

        this.setSelectedTemplateImages.emit(templateImages);
      }
    });
  }

  getMediaAssetExternalSources(mediaAssetId: number) {
    this.crudService.getExternalSourcesFromMediaAssetId(mediaAssetId).subscribe(
      (res) => {
        this.mediaAssetExternalSources = res;
      },
      (error) => {
        this.snackbarService.show(error.error, "danger");
      }
    );
  }

  imageLoad() {
    this.showPreviewImageLoading = false;
  }

  reloadVideo(mediaAssetId: number) {
    this.video.nativeElement.src = `${environment.imageServerUrl}api/Public/GetMediaAsset?id=${mediaAssetId}`;
    this.video.nativeElement.load();
  }

  waitUntilVideoIsGenerated(mediaAssetId: number) {
    return new Promise((resolve, reject) => {
      const maxRecurrences = 15;
      let counter = 0;
      const self = this;
      this.showPreviewImageLoading = true;
      this.videoProcessingCheckInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              resolve(true);
            }

            if (counter > maxRecurrences) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              reject();
            }
          });
      }, 1500);
    });
  }

  async download() {
    if (this.showPreviewImageLoading) {
      return;
    }

    try {
      this.appLoader.open();

      let base64 = await this.crudService
        .downloadBase64MediaAsset(
          this.selectedTemplateImages[this.mediaAssetIndex].imageId,
          this.selectedChannel.channelId
        )
        .toPromise();
      let blob = base64ToFile(base64);

      const fileName = `download.${blob.type.split("/")[1]}`;

      FileSaver.saveAs(blob, fileName);
    } catch (exc) {
      this.snackbarService.show(exc.error, "danger");
    } finally {
      this.appLoader.close();
    }
  }

  toggleShowExternalSources() {
    this.showExternalSources = !this.showExternalSources;
  }

  next() {
    if (this.mediaAssetIndex >= this.selectedTemplateImages.length - 1) {
      this.mediaAssetIndex = 0;
      return;
    }

    this.mediaAssetIndex++;
  }

  back() {
    if (this.mediaAssetIndex === 0) {
      this.mediaAssetIndex = this.selectedTemplateImages.length - 1;
      return;
    }

    this.mediaAssetIndex--;
  }

  calcContentHeight() {
    let windowHeight = window.innerHeight;
    windowHeight -= this.configuratorHeaderHeight;

    this.contentHeight = windowHeight;
  }
}
