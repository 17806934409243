import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/services/auth/auth.guard";
import { PublicStatisticsComponent } from "./views/public-statistics/public-statistics/public-statistics.component";
//import { LoginpageComponent } from './views/loginpage/loginpage.component';

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "mapAds/dashboard/main",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: "./views/sessions/sessions.module#SessionsModule",
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "mapAds",
        loadChildren: "./views/mapAds/mapAds.module#MapAdsModule",
        data: { title: "Marketing Portal", breadcrumb: "Marketing Portal" },
      },
    ],
  },
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "onboarding",
        loadChildren: "./views/onboarding/onboarding.module#OnboardingModule",
        data: { title: "Onboarding", breadcrumb: "Onboarding" },
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "approval-verification",
        loadChildren:
          "./views/approval-verification/approval-verification.module#ApprovalVerificationModule",
        data: { title: "Freigabe", breadcrumb: "Freigabe" },
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "public-statistics",
        loadChildren:
          "./views/public-statistics/public-statistics.module#PublicStatisticsModule",
        data: { title: "Statistiken", breadcrumb: "Statistiken" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
