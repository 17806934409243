import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "ma-presales-presentation-select-customer",
  templateUrl: "./presales-presentation-select-customer.component.html",
  styleUrls: ["./presales-presentation-select-customer.component.scss"],
})
export class PresalesPresentationSelectCustomerComponent implements OnInit {
  @Output() selectCustomer: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  public customerList: any = [];
  public isLoading: boolean;
  public searchString: string = "";
  public page: number = 0;
  public itemsPerPage: number = 6;
  public count: number;
  public totalPages: number;
  public sub: any;
  readonly environment = environment;
  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService
  ) {}

  ngOnInit() {
    this.getCustomers(true);
  }

  getCustomers(init = false) {
    this.isLoading = true;

    if (init) {
      setTimeout(() => this.appLoader.open());
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    const data = {
      filterObjects: [
        {
          type: "customerName",
          value: this.searchString,
        },
      ],
      sortingObject: {
        column: "createdOn",
        direction: true,
      },
    };

    this.sub = this.crudService
      .getAllCustomers(this.page, this.itemsPerPage, data)
      .subscribe((res) => {
        this.customerList = res;
        this.count = this.customerList.count;
        this.totalPages = Math.ceil(this.count / this.itemsPerPage);
        if (this.count === 0 && init) {
        }

        this.isLoading = false;

        if (init) {
          setTimeout(() => this.appLoader.close());
        }
      });
  }

  onInputChange($event) {
    this.page = 0;
    this.searchString = $event.target.value;
    this.getCustomers();
  }

  clearSearch() {
    this.page = 0;
    this.searchString = "";
    this.getCustomers();
  }
}
