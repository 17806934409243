import { Component, Input, OnInit } from "@angular/core";
import { NotificationSettings } from "app/model/settings/NotificationSettings";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "campaign-notification-settings",
  templateUrl: "./campaign-notification-settings.component.html",
  styleUrls: ["./campaign-notification-settings.component.scss"],
})
export class CampaignNotificationSettingsComponent implements OnInit {
  @Input() mpCampaignId: number;
  public isLoading: boolean;
  public notificationSettings: NotificationSettings[];
  public isChanged: boolean = false;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private appLoader: AppLoaderService
  ) {}

  ngOnInit() {
    this.getCampaignNotifications();
  }

  isSubmitted() {
    this.updateCampaignNotification();
  }

  async getCampaignNotifications() {
    this.isLoading = true;
    await this.crudService
      .getCampaignNotificationSettings(this.mpCampaignId)
      .toPromise()
      .then((res) => {
        this.notificationSettings = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }

  async updateCampaignNotification() {
    this.appLoader.open();
    await this.crudService
      .updateCampaignNotificationSettings(
        this.notificationSettings,
        this.mpCampaignId
      )
      .toPromise()
      .then((res) => {
        this.snackbarService.show(
          "Benachrichtigungen wurden erfolgreich gespeichert",
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.isChanged = true;
      });

    this.appLoader.close();
  }
}
