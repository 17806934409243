import { MPMediaAsset } from "../media-asset/MPMediaAsset";
import { MPMediaAssetList } from "../media-asset/MPMediaAssetList";
import { GAMMediaAssetFormat } from "./GAMMediaAssetFormat";
import { MPCampaign } from "./MPCampaign";
import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPCampaignPackage } from "./MPCampaingPackage";
import { MPCustomer } from "./MPCustomer";
import { MPCustomerApprovalStatus } from "./MPCustomerApprovalStatus";
import { MPDevice } from "./MPDevice";
import { MPDeviceList } from "./MPDeviceList";
import { MPGAMAdUnit } from "./MPGAMAdUnit";
import { MPGAMAdUnitList } from "./MPGAMAdUnitList";
import { MPGeoTargeting } from "./MPGeoTargeting";
import { MPGeoTargetingList } from "./MPGeoTargetingList";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";
import { MPLocation } from "./MPLocation";
import { MPLocationList } from "./MPLocationList";

export class GAMCampaign extends MPCampaign {
  public gamCampaignId: number;
  public campaignPackageId: number;
  public gamDestinationLink: string;
  public packageId: number;
  public package: MPCampaignPackage;
  public devices: MPDevice[];
  public geoTargeting: MPGeoTargeting[];
  public adUnits: MPGAMAdUnit[];
  public linkToPreview: string;

  constructor(
    mpCampaignId: number = null,
    gamCampaignId: number = null,
    gamDestinationLink: string = null,
    packageId: number = null,
    mpPackage: MPCampaignPackage = null,
    devices: MPDevice[] = null,
    geoTargeting: MPGeoTargeting[] = null,
    adUnits: MPGAMAdUnit[] = null,
    linkToPreview: string = null,
    dateTimeDisplayName: string = null,
    start: Date = null,
    end: Date = null,
    status: MPCampaignStatus = null,
    name: string = null,
    displayBudget: number = null,
    mediaAssets: MPMediaAsset[] = null,
    locations: MPLocation[] = null,
    createdOn: Date = null,
    campaignStatistics: MPCampaignStatistics = null,
    customer: MPCustomer = null,
    showApproalButton: boolean = null,
    channelId: number = null,
    channelLogo: number = null,
    channelName: string = null,
    isPayed: boolean = null,
    customerApprovalOpen: boolean = null,
    customerApprovalStatus: MPCustomerApprovalStatus = null,
    hasNewNotifications: boolean = null,
    appliedCoupon: string = null,
    publicStatisticsUrl: string = null,
    goalAndPerformance: MPGoalAndPerformance = null,
    manualBudget: number = null,
    isBudgetCheckoutNeeded: boolean = null
  ) {
    super(
      mpCampaignId,
      dateTimeDisplayName,
      start,
      end,
      status,
      name,
      displayBudget,
      mediaAssets,
      locations,
      createdOn,
      customer,
      showApproalButton,
      channelId,
      channelLogo,
      channelName,
      isPayed,
      customerApprovalOpen,
      customerApprovalStatus,
      campaignStatistics,
      hasNewNotifications,
      appliedCoupon,
      publicStatisticsUrl,
      goalAndPerformance,
      manualBudget,
      isBudgetCheckoutNeeded
    );

    this.gamCampaignId = gamCampaignId;
    this.gamDestinationLink = gamDestinationLink;
    this.packageId = packageId;
    this.package = mpPackage;
    this.devices = devices;
    this.geoTargeting = geoTargeting;
    this.adUnits = adUnits;
    this.linkToPreview = linkToPreview;
  }

  deserialize(serializedObj: GAMCampaign): GAMCampaign {
    if (!serializedObj) {
      return null;
    }

    this.mpCampaignId = serializedObj["mpCampaignId"];
    this.gamCampaignId = serializedObj["gamCampaignId"];
    this.gamDestinationLink = serializedObj["gamDestinationLink"];
    this.linkToPreview = serializedObj["linkToPreview"];
    this.adUnits = new MPGAMAdUnitList().deserializeList(
      serializedObj["adUnits"]
    );
    this.geoTargeting = new MPGeoTargetingList().deserializeList(
      serializedObj["geoTargeting"]
    );
    this.devices = new MPDeviceList().deserializeList(serializedObj["devices"]);
    this.package = new MPCampaignPackage().deserialize(
      serializedObj["package"]
    );

    this.dateTimeDisplayName = serializedObj["dateTimeDisplayName"];
    this.start = serializedObj["start"];
    this.end = serializedObj["end"];
    this.status = new MPCampaignStatus().deserialize(serializedObj["status"]);
    this.name = serializedObj["name"];
    this.displayBudget = serializedObj["displayBudget"];
    this.mediaAssets = new MPMediaAssetList().deserializeList(
      serializedObj["mediaAssets"]
    );
    this.locations = new MPLocationList().deserializeList(
      serializedObj["locations"]
    );

    this.createdOn = serializedObj["createdOn"];
    this.campaignStatistics = new MPCampaignStatistics().deserialize(
      serializedObj["campaignStatistics"]
    );
    this.customer = new MPCustomer().deserialize(serializedObj["customer"]);
    this.showApproalButton = serializedObj["showApproalButton"];
    this.channelId = serializedObj["channelId"];
    this.channelLogo = serializedObj["channelLogo"];
    this.channelName = serializedObj["channelName"];

    this.isPayed = serializedObj["isPayed"];
    this.customerApprovalOpen = serializedObj["customerApprovalOpen"];
    this.customerApprovalStatus = new MPCustomerApprovalStatus().deserialize(
      serializedObj["customerApprovalStatus"]
    );
    this.goalAndPerformance = new MPGoalAndPerformance().deserialize(
      serializedObj["goalAndPerformance"]
    );
    this.hasNewNotifications = serializedObj["hasNewNotifications"];
    this.appliedCoupon = serializedObj["appliedCoupon"];
    this.publicStatisticsUrl = serializedObj["publicStatisticsUrl"];

    this.manualBudget = serializedObj["manualBudget"];
    this.isBudgetCheckoutNeeded = serializedObj["isBudgetCheckoutNeeded"];

    return this;
  }
}
