import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-corporate-color-selector-dialog",
  templateUrl: "./corporate-color-selector-dialog.component.html",
  styleUrls: ["./corporate-color-selector-dialog.component.scss"],
})
export class CorporateColorSelectorDialogComponent implements OnInit {
  @ViewChild("img") imgElement: ElementRef;
  @ViewChild("canvas") canvasElement: ElementRef;
  @ViewChild("canvasMagnifier") canvasMagnifierElement: ElementRef;

  public base64Image: any;
  public selectedHexColor: any = "";
  public selectedRGBAColor: any = [0, 0, 0];
  public showColorType: string = "hex";
  public form: FormGroup;
  public showMagnifier: boolean = false;

  public url: string = "";

  constructor(
    public dialogRef: MatDialogRef<CorporateColorSelectorDialogComponent>,
    private fb: FormBuilder,
    private crudService: CrudService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.url = this.data.url;

    if (this.data.color) {
      if (this.data.color.includes("#")) {
        var rgb = this.hexToRgb(this.data.color);

        this.selectedRGBAColor = [rgb.r, rgb.g, rgb.b];
        this.selectedHexColor = this.data.color;
      } else {
        const colorSplit = this.data.color.split("(")[1];
        const r = parseInt(colorSplit.split(",")[0]);
        const g = parseInt(colorSplit.split(",")[1]);
        const b = parseInt(colorSplit.split(",")[2]);

        this.selectedRGBAColor = [r, g, b];
        this.selectedHexColor = this.rgbToHex(r, g, b);
      }
    }

    this.form = this.fb.group({
      hex: this.selectedHexColor,
      rgb1: this.selectedRGBAColor[0],
      rgb2: this.selectedRGBAColor[1],
      rgb3: this.selectedRGBAColor[2],
    });

    this.base64Image = await this.crudService
      .getBase64FromUrl(this.url)
      .toPromise();
  }

  onImageClick($event) {
    let x, y;
    const canvas = this.canvasElement.nativeElement;
    const image = this.imgElement.nativeElement;

    if ($event.offsetX) {
      x = $event.offsetX;
      y = $event.offsetY;
    } else if ($event.layerX) {
      x = $event.layerX;
      y = $event.layerY;
    }

    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height);
    this.selectedRGBAColor = canvas
      .getContext("2d")
      .getImageData(x, y, 1, 1).data;

    this.selectedHexColor = this.rgbToHex(
      this.selectedRGBAColor[0],
      this.selectedRGBAColor[1],
      this.selectedRGBAColor[2]
    );

    this.form.controls["hex"].setValue(this.selectedHexColor);
    this.form.controls["rgb1"].setValue(this.selectedRGBAColor[0]);
    this.form.controls["rgb2"].setValue(this.selectedRGBAColor[1]);
    this.form.controls["rgb3"].setValue(this.selectedRGBAColor[2]);
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  onColorChangeWithPicker($event) {
    const value = $event.target.value;
    this.form.controls["hex"].setValue(value);
    this.onChangeHexInput($event);
  }

  onChangeHexInput($event) {
    let value = this.form.controls["hex"].value;

    if (value[0] != "#") {
      this.form.controls["hex"].setValue("#" + value);
    }

    if (value.length > 6) {
      this.form.controls["hex"].setValue(value.substring(0, 7));
    }

    value = this.form.controls["hex"].value;

    if (
      value.length == 3 ||
      value.length == 4 ||
      value.length == 6 ||
      value.length == 7
    ) {
      this.selectedHexColor = value;
      const rgb = this.hexToRgb(value);
      if (rgb) {
        this.selectedRGBAColor = [rgb.r, rgb.g, rgb.b];
        this.form.controls["rgb1"].setValue(rgb.r);
        this.form.controls["rgb2"].setValue(rgb.g);
        this.form.controls["rgb3"].setValue(rgb.b);
      }
    }
  }

  onChangeRGBInput($event, index) {
    let value = this.form.controls["rgb" + index].value;
    if (value > 255) {
      value = 255;
      this.form.controls["rgb" + index].setValue(value);
    } else if (value < 0) {
      value = 0;
      this.form.controls["rgb" + index].setValue(value);
    }

    this.selectedRGBAColor[index - 1] = value;

    if (
      this.selectedRGBAColor[0] != null &&
      this.selectedRGBAColor[1] != null &&
      this.selectedRGBAColor[2] != null
    ) {
      const hex = this.rgbToHex(
        this.selectedRGBAColor[0],
        this.selectedRGBAColor[1],
        this.selectedRGBAColor[2]
      );

      this.selectedHexColor = hex;
      this.form.controls["hex"].setValue(hex);
    }
  }

  onChangeColorType() {
    this.showColorType = this.showColorType === "hex" ? "rgb" : "hex";
  }

  enterImage() {
    this.showMagnifier = true;
  }

  leaveImage() {
    this.showMagnifier = false;
  }

  moveOnImage($event) {
    let x, y;
    const canvasMagnifier = this.canvasMagnifierElement.nativeElement;
    const image = this.imgElement.nativeElement;

    if ($event.offsetX) {
      x = $event.offsetX;
      y = $event.offsetY;
    } else if ($event.layerX) {
      x = $event.layerX;
      y = $event.layerY;
    }

    let width = 70;
    let height = 70;

    canvasMagnifier.setAttribute("style", `top: ${y - height}px; left: ${x}px`);

    canvasMagnifier.width = width;
    canvasMagnifier.height = height;

    const ctx = canvasMagnifier.getContext("2d");

    ctx.drawImage(image, x - 5, y - 5, 10, 10, 0, 0, width, height);

    ctx.beginPath();

    for (let z = 0; z < height; z += 7) {
      ctx.moveTo(z, 0);
      ctx.lineTo(z, height);
    }
    for (let j = 0; j < width; j += 7) {
      ctx.moveTo(0, j);
      ctx.lineTo(width, j);
    }

    ctx.strokeStyle = "#eeeeee";
    ctx.stroke();

    ctx.beginPath();

    ctx.strokeStyle = "#f23c3c";
    ctx.rect(width / 2, height / 2, 7, 7);
    ctx.stroke();
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    this.dialogRef.close({
      res: this.selectedRGBAColor,
      hex: this.selectedHexColor,
    });
  }
}
