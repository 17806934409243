import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "quick-media-asset-creation-loading-animantion",
  templateUrl: "./quick-media-asset-creation-loading-animantion.component.html",
  styleUrls: ["./quick-media-asset-creation-loading-animantion.component.scss"],
})
export class QuickMediaAssetCreationLoadingAnimantionComponent
  implements OnInit
{
  @Input() loadingTexts: string[];
  @Input() loadingTextIndex: number;
  constructor() {}

  ngOnInit() {}
}
