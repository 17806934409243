import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";

@Component({
  selector: "approval-confirmation-form",
  templateUrl: "./approval-confirmation-form.component.html",
  styleUrls: ["./approval-confirmation-form.component.scss"],
})
export class ApprovalConfirmationFormComponent implements OnInit {
  @Input() data;
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }


}
