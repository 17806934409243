export class MPTopic {
  public topicId: number;
  public topicDisplayName: string;
  public topicIcon: string;

  constructor(
    topicId: number = null,
    topicDisplayName: string = null,
    topicIcon: string = null
  ) {
    this.topicId = topicId;
    this.topicDisplayName = topicDisplayName;
    this.topicIcon = topicIcon;
  }

  deserialize(serializedObj: object): MPTopic {
    if (!serializedObj) {
      return null;
    }

    this.topicId = serializedObj["topicId"];
    this.topicDisplayName = serializedObj["topicDisplayName"];
    this.topicIcon = serializedObj["topicIcon"];

    return this;
  }
}
