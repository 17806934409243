import { MPVideoMetaData } from "app/model/marketing-portal/MPVideoMetaData";

export class VideoService {
  public file: File;
  public metaData: MPVideoMetaData = null;
  constructor(file: File) {
    this.file = file;
  }

  async getSize() {
    return new Promise(async (resolve, reject) => {
      await this.getMetaData();
      resolve({ height: this.metaData.height, width: this.metaData.width });
    });
  }

  async getDuration() {
    return new Promise(async (resolve, reject) => {
      await this.getMetaData();
      resolve(this.metaData.duration);
    });
  }

  public getMetaData(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.metaData) {
        resolve(true);
      }

      const url = URL.createObjectURL(this.file);
      const video = document.createElement("video");
      video.src = url;

      const self = this;
      video.addEventListener("loadedmetadata", function () {
        self.setMetaData(this.videoHeight, this.videoWidth, this.duration);
        resolve(true);
        video.remove();
      });
    });
  }

  public setMetaData(height: number, width: number, duration: number) {
    this.metaData = {
      height,
      width,
      duration,
    };
  }
}
