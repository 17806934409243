import { Component, Input, OnInit } from "@angular/core";
import { GAMMediaAssetFormat } from "app/model/marketing-portal/GAMMediaAssetFormat";
import { environment } from "environments/environment";

@Component({
  selector: "campaign-gam-preview",
  templateUrl: "./campaign-gam-preview.component.html",
  styleUrls: ["./campaign-gam-preview.component.scss"],
})
export class CampaignGamPreviewComponent implements OnInit {
  @Input() gamMediaAssetFormats: GAMMediaAssetFormat[];
  public index: number = 0;
  public environment = environment;
  constructor() {}

  ngOnInit() {}

  next() {
    if (this.index >= this.gamMediaAssetFormats.length - 1) {
      this.index = 0;
      return;
    }

    this.index++;
  }

  back() {
    if (this.index === 0) {
      this.index = this.gamMediaAssetFormats.length - 1;
      return;
    }

    this.index--;
  }
}
