import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LogEventType } from "app/model/LogEventType";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "assistant-header",
  templateUrl: "./assistant-header.component.html",
  styleUrls: ["./assistant-header.component.scss"],
})
export class AssistantHeaderComponent implements OnInit {
  @Input() step: number;
  constructor(private router: Router, private crudService: CrudService) {}

  ngOnInit() {}

  skip() {
    this.crudService
      .logEvent(
        LogEventType.ASSISTANT,
        "Assistent wurde übersprungen",
        this.step,
        true
      )
      .subscribe(
        (res) => {},
        (error) => {}
      );

    this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
  }
}
