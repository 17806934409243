import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { MPApprovalElementComment } from "app/model/marketing-portal/MPApprovalElementComment";
import { ApprovalElementCommentDialogComponent } from "../../dialogs/approval-element-comment-dialog/approval-element-comment-dialog.component";

@Component({
  selector: "approval-confirmation-actions",
  templateUrl: "./approval-confirmation-actions.component.html",
  styleUrls: ["./approval-confirmation-actions.component.scss"],
})
export class ApprovalConfirmationActionsComponent implements OnInit {
  @Input() type: string;
  @Input() readonly: boolean;
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Input() element;
  public rating: boolean = null;
  public comment: MPApprovalElementComment = null;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.rating = this.element.decision;
    this.comment = this.element.note;
  }

  updateRating(rating) {
    if (this.readonly) return;
    this.rating = this.rating === rating ? null : rating;
    this.action.emit({ type: "rating", value: this.rating });
  }

  openComment() {
    if (this.readonly) return;
    const dialogRef = this.dialog.open(ApprovalElementCommentDialogComponent, {
      width: "680px",
      panelClass: "no-spacing",
      maxHeight: "95vh",
      disableClose: true,
      data: {
        comment: this.comment,
        type: this.type,
        readonly: this.readonly,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.comment = res.value;
        this.action.emit({ type: "comment", value: this.comment });
      }
    });
  }
}
