import { MPMediaAsset } from "../media-asset/MPMediaAsset";
import { MPMediaAssetList } from "../media-asset/MPMediaAssetList";
import { MPAgeGroup } from "./MPAgeGroup";
import { MPCampaign } from "./MPCampaign";
import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPCampaignPackage } from "./MPCampaingPackage";
import { MPCustomer } from "./MPCustomer";
import { MPCustomerApprovalStatus } from "./MPCustomerApprovalStatus";
import { MPGender } from "./MPGender";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";
import { MPLocation } from "./MPLocation";
import { MPLocationList } from "./MPLocationList";
import { MPTopic } from "./MPTopic";

export class TVAdsCampaign extends MPCampaign {
  public atvCampaignId: number;
  public packageId: number;
  public package: MPCampaignPackage;
  public gender: MPGender;
  public ageGroup: MPAgeGroup;
  public topic: MPTopic;
  public linkToPreview: string;

  constructor(
    mpCampaignId: number = null,
    atvCampaignId: number = null,
    packageId: number = null,
    mpPackage: MPCampaignPackage = null,
    gender: MPGender = null,
    ageGroup: MPAgeGroup = null,
    topic: MPTopic = null,
    linkToPreview: string = null,
    dateTimeDisplayName: string = null,
    start: Date = null,
    end: Date = null,
    status: MPCampaignStatus = null,
    name: string = null,
    displayBudget: number = null,
    mediaAssets: MPMediaAsset[] = null,
    locations: MPLocation[] = null,
    createdOn: Date = null,
    campaignStatistics: MPCampaignStatistics = null,
    customer: MPCustomer = null,
    showApproalButton: boolean = null,
    channelId: number = null,
    channelLogo: number = null,
    channelName: string = null,
    isPayed: boolean = null,
    customerApprovalOpen: boolean = null,
    customerApprovalStatus: MPCustomerApprovalStatus = null,
    hasNewNotifications: boolean = null,
    appliedCoupon: string = null,
    publicStatisticsUrl: string = null,
    goalAndPerformance: MPGoalAndPerformance = null,
    manualBudget: number = null,
    isBudgetCheckoutNeeded: boolean = null
  ) {
    super(
      mpCampaignId,
      dateTimeDisplayName,
      start,
      end,
      status,
      name,
      displayBudget,
      mediaAssets,
      locations,
      createdOn,
      customer,
      showApproalButton,
      channelId,
      channelLogo,
      channelName,
      isPayed,
      customerApprovalOpen,
      customerApprovalStatus,
      campaignStatistics,
      hasNewNotifications,
      appliedCoupon,
      publicStatisticsUrl,
      goalAndPerformance,
      manualBudget,
      isBudgetCheckoutNeeded
    );

    this.atvCampaignId = atvCampaignId;
    this.packageId = packageId;
    this.package = mpPackage;
    this.gender = gender;
    this.ageGroup = ageGroup;
    this.topic = topic;
    this.linkToPreview = linkToPreview;
  }

  deserialize(serializedObj: TVAdsCampaign): TVAdsCampaign {
    if (!serializedObj) {
      return null;
    }

    this.mpCampaignId = serializedObj["mpCampaignId"];
    this.atvCampaignId = serializedObj["atvCampaignId"];
    this.dateTimeDisplayName = serializedObj["dateTimeDisplayName"];
    this.start = serializedObj["start"];
    this.end = serializedObj["end"];
    this.status = new MPCampaignStatus().deserialize(serializedObj["status"]);
    this.name = serializedObj["name"];
    this.displayBudget = serializedObj["displayBudget"];
    this.package = new MPCampaignPackage().deserialize(
      serializedObj["package"]
    );
    this.goalAndPerformance = new MPGoalAndPerformance().deserialize(
      serializedObj["goalAndPerformance"]
    );
    this.mediaAssets = new MPMediaAssetList().deserializeList(
      serializedObj["mediaAssets"]
    );
    this.gender = new MPGender().deserialize(serializedObj["gender"]);
    this.topic = new MPTopic().deserialize(serializedObj["topic"]);
    this.ageGroup = new MPAgeGroup().deserialize(serializedObj["ageGroup"]);
    this.locations = new MPLocationList().deserializeList(
      serializedObj["locations"]
    );

    this.createdOn = serializedObj["createdOn"];
    this.campaignStatistics = new MPCampaignStatistics().deserialize(
      serializedObj["campaignStatistics"]
    );
    this.customer = new MPCustomer().deserialize(serializedObj["customer"]);
    this.showApproalButton = serializedObj["showApproalButton"];
    this.channelId = serializedObj["channelId"];
    this.channelLogo = serializedObj["channelLogo"];
    this.channelName = serializedObj["channelName"];
    this.linkToPreview = serializedObj["linkToPreview"];
    this.isPayed = serializedObj["isPayed"];
    this.customerApprovalOpen = serializedObj["customerApprovalOpen"];
    this.customerApprovalStatus = new MPCustomerApprovalStatus().deserialize(
      serializedObj["customerApprovalStatus"]
    );
    this.hasNewNotifications = serializedObj["hasNewNotifications"];
    this.appliedCoupon = serializedObj["appliedCoupon"];
    this.publicStatisticsUrl = serializedObj["publicStatisticsUrl"];

    this.manualBudget = serializedObj["manualBudget"];
    this.isBudgetCheckoutNeeded = serializedObj["isBudgetCheckoutNeeded"];
    return this;
  }
}
