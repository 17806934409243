import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GAMMediaFormat } from "app/model/marketing-portal/GAMMediaFormat";

@Component({
  selector: "media-format-campaign-form-section",
  templateUrl: "./media-format-campaign-form-section.component.html",
  styleUrls: ["./media-format-campaign-form-section.component.scss"],
})
export class MediaFormatCampaignFormSectionComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() mediaFormats: GAMMediaFormat[];
  @Input() selectedMediaFormat: GAMMediaFormat;

  constructor() {}

  ngOnInit() {}
}
