import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ColorService } from "app/shared/services/color.service";

@Component({
  selector: "radius-map",
  templateUrl: "./radius-map.component.html",
  styleUrls: ["./radius-map.component.scss"],
})
export class RadiusMapComponent implements OnInit {
  @ViewChild("map") mapElement: ElementRef;
  @Input() radius: number;
  @Input() lat: number;
  @Input() lng: number;
  public map: google.maps.Map;
  public zoom: number;
  public marker: google.maps.Marker;
  public circle: google.maps.Circle;
  public zoomLevels: Object;
  public primaryColor: string;

  constructor(private colorService: ColorService) {}

  ngOnInit() {
    this.primaryColor = this.colorService.getPrimaryColor();

    this.zoomLevels = {
      5: 11,
      10: 10,
      15: 9,
      20: 9,
      25: 8.5,
      30: 8,
      35: 8,
      40: 8,
      45: 7.75,
      50: 7.75,
      100: 6.5,
    };

    this.setZoomLevel();
  }

  ngAfterViewInit() {
    const lngLat = new google.maps.LatLng(this.lat, this.lng);
    const mapOptions: google.maps.MapOptions = {
      center: lngLat,
      zoom: this.zoom,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      draggable: false,
      gestureHandling: "none",
      keyboardShortcuts: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.draw();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.hasOwnProperty("radius") && !changes.radius.firstChange) ||
      (changes.hasOwnProperty("lat") && !changes.lat.firstChange) ||
      (changes.hasOwnProperty("lng") && !changes.lng.firstChange)
    ) {
      this.draw();
    }
  }

  setZoomLevel() {
    for (const key of Object.keys(this.zoomLevels)) {
      if (+key >= this.radius) {
        this.zoom = this.zoomLevels[+key];
        break;
      }
    }
  }

  setMarker() {
    this.marker = new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(this.lat, this.lng),
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 0,
      },
    });
  }

  drawCircle() {
    this.circle = new google.maps.Circle({
      map: this.map,
      radius: this.radius * 1000,
      fillColor: this.primaryColor,
      strokeColor: this.primaryColor,
      strokeWeight: 2,
    });
    this.circle.bindTo("center", this.marker, "position");
  }

  clearMap() {
    this.clearMarker();
    this.clearCircle();
  }

  clearMarker() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  clearCircle() {
    if (this.circle) {
      this.circle.setMap(null);
    }
  }

  draw() {
    const lngLat = new google.maps.LatLng(this.lat, this.lng);
    this.map.setCenter(lngLat);
    this.clearCircle();
    this.setZoomLevel();
    this.map.setZoom(this.zoom);
    this.setMarker();
    this.drawCircle();
  }
}
