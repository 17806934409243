import {
  ATV_VIDEO_UPLOAD_ACCEPT_TYPES,
  ATV_VIDEO_UPLOAD_FORMAT,
  ATV_VIDEO_UPLOAD_FORMAT_DURATION_SHOW_WARNING,
  ATV_VIDEO_UPLOAD_FORMAT_MAX_DURATION,
  ATV_VIDEO_UPLOAD_FORMAT_MIN_DURATION,
  ATV_VIDEO_UPLOAD_FORMAT_MIN_HEIGHT,
  ATV_VIDEO_UPLOAD_FORMAT_MIN_WIDTH,
  FB_VIDEO_UPLOAD_ACCEPT_TYPES,
  FB_VIDEO_UPLOAD_FORMAT,
  FB_VIDEO_UPLOAD_FORMAT_DURATION_SHOW_WARNING,
  FB_VIDEO_UPLOAD_FORMAT_MIN_DURATION,
  FB_VIDEO_UPLOAD_FORMAT_MIN_HEIGHT,
  FB_VIDEO_UPLOAD_FORMAT_MIN_WIDTH,
  IMAGE_UPLOAD_ACCEPT_TYPES,
  YT_UPLOAD_ACCEPT_TYPES,
  YT_UPLOAD_FORMAT,
  YT_UPLOAD_FORMAT_DURATION_SHOW_WARNING,
  YT_UPLOAD_FORMAT_MAX_DURATION,
  YT_UPLOAD_FORMAT_MAX_HEIGHT,
  YT_UPLOAD_FORMAT_MAX_WIDTH,
  YT_UPLOAD_FORMAT_MIN_DURATION,
  YT_UPLOAD_FORMAT_MIN_HEIGHT,
  YT_UPLOAD_FORMAT_MIN_WIDTH,
} from "../../../constants";
import { MPMediaUploadFormatConfiguration } from "./MPMediaUploadFormatConfiguration";

export class MPMediaUploadFormatHandler {
  constructor() {}

  getDefaultImageConfig(
    minHeight: number,
    minWidth: number
  ): MPMediaUploadFormatConfiguration {
    return new MPMediaUploadFormatConfiguration(
      null,
      null,
      minHeight,
      minWidth,
      null,
      null,
      null,
      null,
      IMAGE_UPLOAD_ACCEPT_TYPES,
      true,
      false,
      false
    );
  }

  getFBVideoConfig(): MPMediaUploadFormatConfiguration {
    return new MPMediaUploadFormatConfiguration(
      null,
      null,
      FB_VIDEO_UPLOAD_FORMAT_MIN_HEIGHT,
      FB_VIDEO_UPLOAD_FORMAT_MIN_WIDTH,
      FB_VIDEO_UPLOAD_FORMAT,
      null,
      FB_VIDEO_UPLOAD_FORMAT_MIN_DURATION,
      FB_VIDEO_UPLOAD_FORMAT_DURATION_SHOW_WARNING,
      FB_VIDEO_UPLOAD_ACCEPT_TYPES,
      true,
      false,
      true
    );
  }

  getYoutubeVideoConfig(): MPMediaUploadFormatConfiguration {
    return new MPMediaUploadFormatConfiguration(
      YT_UPLOAD_FORMAT_MAX_HEIGHT,
      YT_UPLOAD_FORMAT_MAX_WIDTH,
      YT_UPLOAD_FORMAT_MIN_HEIGHT,
      YT_UPLOAD_FORMAT_MIN_WIDTH,
      YT_UPLOAD_FORMAT,
      YT_UPLOAD_FORMAT_MAX_DURATION,
      YT_UPLOAD_FORMAT_MIN_DURATION,
      YT_UPLOAD_FORMAT_DURATION_SHOW_WARNING,
      YT_UPLOAD_ACCEPT_TYPES,
      true,
      false,
      true
    );
  }

  getATVVideoConfig(): MPMediaUploadFormatConfiguration {
    return new MPMediaUploadFormatConfiguration(
      null,
      null,
      ATV_VIDEO_UPLOAD_FORMAT_MIN_HEIGHT,
      ATV_VIDEO_UPLOAD_FORMAT_MIN_WIDTH,
      ATV_VIDEO_UPLOAD_FORMAT,
      ATV_VIDEO_UPLOAD_FORMAT_MAX_DURATION,
      ATV_VIDEO_UPLOAD_FORMAT_MIN_DURATION,
      ATV_VIDEO_UPLOAD_FORMAT_DURATION_SHOW_WARNING,
      ATV_VIDEO_UPLOAD_ACCEPT_TYPES,
      true,
      true,
      true
    );
  }
}
