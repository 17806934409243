import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { AppLoaderComponent } from "./app-loader.component";

@Injectable()
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  public open(
    title: string = this.translate.instant("PleaseWait"),
    icon: string = null
  ): Observable<boolean> {
    this.dialogRef = this.dialog.open(AppLoaderComponent, {
      disableClose: true,
      backdropClass: "light-backdrop",
      maxWidth: "300px",
      minWidth: "200px",
    });
    // this.dialogRef.updateSize("200px");
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.icon = icon;
    return this.dialogRef.afterClosed();
  }

  public close() {
    if (this.dialogRef) this.dialogRef.close();
  }
}
