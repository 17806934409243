import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "approval-confirmation-text-input",
  templateUrl: "./approval-confirmation-text-input.component.html",
  styleUrls: ["./approval-confirmation-text-input.component.scss"],
})
export class ApprovalConfirmationTextInputComponent implements OnInit {
  @Input() element;
  @Input() readonly?: boolean = false;
  @Input() showChanges?: boolean = false;
  constructor() {}

  ngOnInit() {}

  action($event) {
    if ($event.type === "rating") {
      this.element.decision = $event.value;
    } else if ($event.type === "comment") {
      this.element.note = !$event.value ? null : $event.value.note;
    }
  }
}
