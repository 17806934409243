import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-ai-process-text-animation-dialog",
  templateUrl: "./ai-process-text-animation-dialog.component.html",
  styleUrls: ["./ai-process-text-animation-dialog.component.scss"],
})
export class AiProcessTextAnimationDialogComponent implements OnInit {
  public loadingTextIndex: number = 0;
  public loadingInterval: any;
  public animationHoldTime: number = 1000;
  constructor(
    public dialogRef: MatDialogRef<AiProcessTextAnimationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    await this.delay(500);

    const self = this;
    this.loadingInterval = setInterval(function () {
      if (self.loadingTextIndex + 2 > self.data.loadingTexts.length) {
        clearInterval(self.loadingInterval);
        self.sendRequest();
        return;
      }
      self.loadingTextIndex++;
    }, this.animationHoldTime);
  }

  async sendRequest() {
    await this.data.request
      .toPromise()
      .then((res) => {
        this.dialogRef.close({ success: true, keywords: res.keywords });
      })
      .catch((e) => {
        this.dialogRef.close({ success: false, keywords: null });
      });
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
