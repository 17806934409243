import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MPMetaData } from "app/model/marketing-portal/MPMetaData";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "campaign-facebook-preview",
  templateUrl: "./campaign-facebook-preview.component.html",
  styleUrls: ["./campaign-facebook-preview.component.scss"],
})
export class CampaignFacebookPreviewComponent implements OnInit {
  @Input() facebookPageId: string;
  @Input() facebookPageName: string;
  @Input() facebookPageImageUrl: string;
  @Input() sponsoredPage: any;
  @Input() displayText: string;
  @Input() link: string;
  @Input() previewMediaFiles: any;
  @Input() showFullTransparency?: boolean = true;
  @Input() templateImageId?: number = null;
  @Input() adMaterialType?: number = 1;
  @Input() isAd: boolean = true;
  @Input() isPost?: boolean = false;
  public AD_MATERIAL_TYPE_UPLOAD: number = 1;
  public AD_MATERIAL_TYPE_EDITOR: number = 2;
  public readonly environment = environment;
  public hostname: string;
  public url: URL;
  public imageIndex: number = 0;
  public MAX_PREVIEW_MEDIA_SIZE = 240;
  public currentDate = new Date();
  public metaData: MPMetaData;
  public sub: any;
  public isLoading: boolean = false;
  constructor(
    private translate: TranslateService,
    private datepipe: DatePipe,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.setHostname();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("link")) {
      this.setHostname();
    }

    if (
      changes.hasOwnProperty("previewMediaFiles") ||
      changes.hasOwnProperty("adMaterialType")
    ) {
      this.imageIndex = 0;
    }
  }

  addhttp(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "https://" + url;
    }
    return url;
  }

  setHostname() {
    if (this.link && this.link != "") {
      let tempLink = this.link;
      tempLink = this.addhttp(tempLink);
      try {
        this.url = new URL(tempLink);
        this.hostname = this.url.hostname;
        this.hostname = this.hostname.replace("www.", "");

        this.getMetaData(this.url.href);
      } catch (e) {
        this.hostname = this.link;
      }
    }
  }

  async getMetaData(url: string) {
    // Wait 1 second until the user input is finished to avoid too many requests.
    await this.delay(1000);

    // Check if the url has changed.
    if (url != this.url.href) {
      return;
    }

    // Check if url is valid.
    if (!this.isValidUrl(url)) {
      this.metaData = null;
      return;
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.isLoading = true;
    this.sub = this.crudService.getMetaInfoFromUrl(url).subscribe(
      (res: MPMetaData) => {
        this.metaData = res;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        // this.snackbarService.show(err.error, "danger");
        this.metaData = null;
      }
    );
  }

  isValidUrl(url: string) {
    return url.match(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\u00C0-\u017Fa-z0-9]+([\-\.]{1}[\u00C0-\u017Fa-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
    );
  }

  onImageLoad($event) {
    const img = new Image();
    img.src = $event.target.src;

    const target = $event.target;
    const self = this;

    img.onload = function () {
      if (img.height > img.width) {
        target.style.width = "auto";
        target.style.height = self.MAX_PREVIEW_MEDIA_SIZE + "px";
      } else {
        target.style.width = self.MAX_PREVIEW_MEDIA_SIZE + "px";
        target.style.height = "auto";
      }
    };
  }

  nextImage() {
    this.imageIndex =
      this.imageIndex + 1 >= this.previewMediaFiles.length
        ? 0
        : this.imageIndex + 1;
  }

  prevImage() {
    this.imageIndex =
      this.imageIndex === 0
        ? this.previewMediaFiles.length - 1
        : this.imageIndex - 1;
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
