export class LocationAddress {
  public longitude: number;
  public latitude: number;
  public zip_code: string;
  public address: string;
  public city: string;
  public state: string;
  public country: string;

  constructor(
    longitude: number = null,
    latitude: number = null,
    zip_code: string = null,
    address: string = null,
    city: string = null,
    state: string = null,
    country: string = null
  ) {
    this.longitude = longitude;
    this.latitude = latitude;
    this.zip_code = zip_code;
    this.address = address;
    this.city = city;
    this.state = state;
    this.country = country;
  }

  deserialize(serializedObj: object): LocationAddress {
    if (!serializedObj) {
      return null;
    }

    this.longitude = serializedObj["longitude"];
    this.latitude = serializedObj["latitude"];
    this.zip_code = serializedObj["zip_code"];
    this.address = serializedObj["address"];
    this.city = serializedObj["city"];
    this.state = serializedObj["state"];
    this.country = serializedObj["country"];

    return this;
  }
}
