import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";

@Component({
  selector: "general-campaign-form-section",
  templateUrl: "./general-campaign-form-section.component.html",
  styleUrls: ["./general-campaign-form-section.component.scss"],
})
export class GeneralCampaignFormSectionComponent implements OnInit {
  @Input() campaignNameControl: FormControl;
  @Input() errors: any;
  @Input() data: any;
  @Input() selectedCustomer: MPCustomer;
  @Input() channelId: number;
  @Input() duplicate: boolean;
  public MP_CHANNEL = MPChannel;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.setInitCampaignName();
  }

  setInitCampaignName() {
    let campaignName: string = "";
    let dateString: string = new Date().toLocaleDateString(
      window.localStorage["countryCode"]
    );

    let prefix: string = this.getCampaignNamePrefix();

    if (this.selectedCustomer) {
      campaignName += this.selectedCustomer.customerName + " ";
    }

    campaignName += `${prefix} ${this.translate.instant(
      "Campaign"
    )} ${dateString}`;

    if (this.data) {
      // Overwrite campaignName
      campaignName = this.duplicate
        ? "[" + this.translate.instant("Copy") + "] "
        : "";
      campaignName += this.data.name;
    }

    this.campaignNameControl.setValue(campaignName);
  }

  getCampaignNamePrefix(): string {
    switch (this.channelId) {
      case this.MP_CHANNEL.CHANNEL_ID_GOOGLE_GDN:
        return "GDN";
      case this.MP_CHANNEL.CHANNEL_ID_FACEBOOK_INSTAGRAM_ADS:
      case this.MP_CHANNEL.CHANNEL_ID_FACEBOOK_INSTAGRAM_POST:
        return "MetaAds";
      case this.MP_CHANNEL.CHANNEL_ID_YOUTUBE:
        return "YT Ads";
      case this.MP_CHANNEL.CHANNEL_ID_MOPO:
        return "MOPO";
      case this.MP_CHANNEL.CHANNEL_ID_ADDRESSABLE_TV:
        return "ATV";
      default:
        return "";
    }
  }
}
