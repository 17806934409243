import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "marketing-portal-campaign-grid",
  templateUrl: "./marketing-portal-campaign-grid.component.html",
  styleUrls: ["./marketing-portal-campaign-grid.component.scss"],
})
export class MarketingPortalCampaignGridComponent implements OnInit {
  @Input() mpCampaigns: any;
  constructor() {}

  ngOnInit() {}
}
