import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "quick-media-asset-creation-type-selection",
  templateUrl: "./quick-media-asset-creation-type-selection.component.html",
  styleUrls: ["./quick-media-asset-creation-type-selection.component.scss"],
})
export class QuickMediaAssetCreationTypeSelectionComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
